import * as types from "./actionTypes";

export function setUserDashboard(user) {
    return {
        type: types.SET_DASHBOARD_USER,
        user: user
    };
}

export function refreshUserDashboard(attr) {
    return {
        type: types.REFRESH_DASHBOARD_USER,
        attr: attr
    };
}
