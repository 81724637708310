import React from "react";
import HoverButton from "../buttons/HoverButton";

export default function KeepTestingButton(props) {

    let interestsStatus = props.interests_status ? props.interests_status : (typeof localStorage !== 'undefined' ? localStorage.getItem(`interests_status`) : '');
    if (!interestsStatus){ interestsStatus = 'not_started';}

   if (interestsStatus !== 'finished' || !!props.upgraded){
       return null;
   }

    const button =  <HoverButton color={'white'} href={'/upgrade'}>
        Keep testing
    </HoverButton>;

    return(
        <div className={'career-status__container'}>{button} <span className={'career-status__message'}>To see how you match to this career</span></div>
    )

}