import React, {Fragment} from 'react';
import {actions, InputTypeField, Loader, TextField, SelectField} from "react-fields";
import registerAPI from '../api/userAPI';
import HoverButton from "../buttons/HoverButton";
import {AGE_GROUPS, GENDERS} from "../settings";
import {validatePassword} from "../validators/password";
import PasswordStrength from '../base/passwordStrength';
import MatchField from '../base/fieldMatch';
import CustomSelectField from "../base/customSelectField";

class CompleteAccount extends React.Component {

    state = {
            user: Object.assign({country_id: this.props.country_id}, this.props.user, {errors: {}, country_id: this.props.user.country_id || "0"}),
            countries: [...this.props.countries, {id: 0, name: 'I prefer not to say'}],
            token: this.props.token,
            isLoading: false,
            validatePswdMatch: false
        };

    pswdRequired = !this.props.skipPswdValidation;
    showNameField = !(this.props.user && this.props.user.name);
    pswdStrength = React.createRef();
    pswdMatch = React.createRef();

    validate = () => {
        let user = this.state.user;
        let valid = true;
        // if(!user.name) {
        //     user.errors.name = 'Name can\'t be blank';
        //     valid = false;
        // }
        if (this.pswdRequired || !!user.password) {
            let [pswdValidRes, pswdErrorMsg] = validatePassword(user.password);
            if(!pswdValidRes) {
                user.errors.password = pswdErrorMsg;
                valid = false;
            } else if (user.password !== user.password_confirmation) {
                user.errors.passwordConfirmation = 'Password confirmation does not match password';
                valid = false;
            }
            if(!user.password_confirmation) {
                user.errors.passwordConfirmation = 'Password confirmation can\'t be blank';
                valid = false;
            }
        }
        if(!user.career_level_id) {
            user.errors.careerLevelId = 'Career level can\'t be blank';
            valid = false;
        }
        if(!user.education_level_id) {
            user.errors.educationLevelId = 'Education level can\'t be blank';
            valid = false;
        }

        if(!user.age_group) {
            user.errors.ageGroup = 'Age can\'t be blank';
            valid = false;
        }

        if(!user.gender) {
            user.errors.gender = 'Gender can\'t be blank';
            valid = false;
        }

        if(this.showNameField && !user.name) {
            user.errors.name = 'Name can\'t be blank';
            valid = false;
        }

        if (!valid){
            this.setState({user: user});
        }
        return valid;
    };

    onSaveUser = (e) => {
        e.preventDefault();
        if (!this.validate()) {
            return;
        }
        let _this = this;
        this.setState({isLoading: true}, () => {
            if (!this.props.token && this.props.user.need_post_update_account_setup) {
                registerAPI.update(this.state.user, this.onSaveSuccess, this.onSaveError);
            } else {
                registerAPI.completeAccount(_this.state.user, _this.state.token).then( async (response) => {
                    _this.onSaveSuccess(response);
                }).catch(error => {
                    console.log(error);
                    error.json().then( (value) => {
                        _this.onSaveError(value);
                    });
                });
            }
        })

    };

    onSaveSuccess = (response) => {
        localStorage.removeItem('user');
        localStorage.removeItem('interests_status');
        localStorage.removeItem('interests');
        localStorage.removeItem('interests_result');
        // _this.setState({isLoading: false});
        if(!!response.educator_id) {
            document.location.href= '/dashboard?welcome=true'
        } else {
            document.location.href= this.props.continue_path || '/dashboard'
        }
    };

    onSaveError = (errors) => {
        this.setState({user: {...this.state.user, errors: errors}, isLoading: false});
    };

    onHandleChange = (e) => {
        let name = e.target.name;
        if (name === 'username') { name = 'name' }
        let value = e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
        if (name === 'password' || name === 'password_confirmation'){
            let validatePswdMatch = this.state.validatePswdMatch;
            if (name === 'password') {
                const res = this.pswdStrength.current.checkValue(e.target.value);
                validatePswdMatch = res !== 'bad';
                this.setState({validatePswdMatch: validatePswdMatch});
            }
            this.pswdMatch.current.checkValue(user.password, user.password_confirmation, !validatePswdMatch);
        }
    };

    render() {
        return  (

                <form className="form-container set-up" onSubmit={this.onSaveUser}>
                    {this.state.isLoading ? <Loader/> : null}
                    <div className="grid-x">
                        <div className="small-12 cell text-center">
                            <h1 className={'set-up__header'}>Set up your dashboard</h1>
                            <p className={'set-up__subheader'}>These details help us to tailor your results and experience.</p>
                        </div>

                            {this.showNameField && <div className={'cell'}>
                                <TextField name={"name"} placeholder={"Full name"}
                                           value={this.state.user.name}
                                           errorMessage={this.state.user.errors.name}
                                           onChange={this.onHandleChange}/>
                            </div> }

                        {/*<div className="input-with-icon small-12 cell">*/}
                            {/*<div className="small-icons green-person"/>*/}
                            {/*<TextField name={"email"} placeholder={"Email"} value={this.state.user.email} disabled={true}/>*/}
                        {/*</div>*/}
                        {!this.props.token && this.props.user.need_post_update_account_setup ? null : <Fragment>
                            <div className="medium-6 small-12 cell med-up-padR10">
                                {/*<TextField name={"username"} placeholder={"Full Name"}*/}
                                {/*value={this.state.user.name}*/}
                                {/*errorMessage={this.state.user.errors.name}*/}
                                {/*onChange={this.onHandleChange}/>*/}
                                <PasswordStrength ref={this.pswdStrength} value={this.state.user.password} >
                                    <InputTypeField type={"password"} name={"password"}
                                                    placeholder={`Password${this.pswdRequired ? '*' : ''}`}
                                                    value={this.state.user.password}
                                                    errorMessage={this.state.user.errors.password}
                                                    onChange={this.onHandleChange}/>
                                </PasswordStrength>
                            </div>
                            <div className="medium-6 small-12 cell">
                                <MatchField value={this.state.user.password_confirmation} match_value={this.state.user.password} ref={this.pswdMatch} >
                                    <InputTypeField type={"password"} name={"password_confirmation"}
                                                    placeholder={`Confirm password${this.pswdRequired ? '*' : ''}`}
                                                    value={this.state.user.password_confirmation}
                                                    errorMessage={this.state.user.errors.passwordConfirmation}
                                                    onChange={this.onHandleChange}/>
                                </MatchField>
                            </div>
                        </Fragment>}

                        <div className="medium-6 small-12 cell med-up-padR10">
                            <SelectField name='age_group' selected={this.state.user.age_group}
                                         options={AGE_GROUPS} optionsValue='value' optionsLabel='label'
                                         onChange={this.onHandleChange} errorMessage={this.state.user.errors.ageGroup}
                                         className='label-icon-right select-label' placeholder="Age*" />
                        </div>
                        <div className="medium-6 small-12 cell">
                            <SelectField name='gender' selected={this.state.user.gender}
                                         options={GENDERS} optionsValue='value' optionsLabel='label'
                                         onChange={this.onHandleChange} errorMessage={this.state.user.errors.gender}
                                         className='label-icon-right select-label' placeholder="Gender*" />
                        </div>
                        <div className="small-12 cell">
                            {/*<SelectField name="country_id" placeholder="Location" className="label-icon-right select-label"*/}
                                         {/*onChange={this.onHandleChange}*/}
                                         {/*options={this.state.countries} optionsValue='id' optionsLabel='name'*/}
                                         {/*selected={this.state.user.country_id}*/}
                                         {/*errorMessage={this.state.user.errors.country_id} />*/}
                            <CustomSelectField name="country_id" placeholder="Location"
                                               onChange={this.onHandleChange}
                                               options={this.state.countries} optionsValue='id' optionsLabel='name'
                                               selected={this.state.user.country_id}
                                               errorMessage={this.state.user.errors.country_id} />
                        </div>
                        <div className="small-12 cell">
                            <SelectField name="career_level_id" placeholder="Career Level" className="label-icon-right select-label"
                                         onChange={this.onHandleChange}
                                         options={this.props.career_levels} optionsValue='id' optionsLabel='name'
                                         selected={this.state.user.career_level_id}
                                         errorMessage={this.state.user.errors.careerLevelId} />
                        </div>
                        <div className="small-12 cell">
                            <SelectField name="education_level_id" placeholder="Education Level" className="label-icon-right select-label"
                                         onChange={this.onHandleChange}
                                         options={this.props.education_levels} optionsValue='id' optionsLabel='name'
                                         selected={this.state.user.education_level_id}
                                         errorMessage={this.state.user.errors.educationLevelId} />
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="small-12 cell text-center">
                            <HoverButton onClick={this.onSaveUser} className={"button--with-icon button--green--large"}>Set up <span className={"small-icons next-arrow-white-l"}></span></HoverButton>
                            <p className={"set-up__info set-up__info--center"}><span className={"small-icons blue-off-tooltip"}></span>You can edit these details at any point in your account settings</p>
                        </div>
                    </div>
                </form>
        )
    }


}
export default CompleteAccount;