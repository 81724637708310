import React, {useRef, useState} from 'react';
import {TextField} from "react-fields";
import HoverButton from "../../affiliates_components/buttons/HoverButton";
import StudentDiscountApi from "../api/studentDiscountAPI";
import SuccessModal from "./successModal";

export default function ResendModal(props) {

    const [sent, setSent] = useState(false);


    const resend = () => {
            StudentDiscountApi.resend(props.email).
            then((response) => {
                setSent(true)
            }).catch(error => {
                if (error.status === 422) {
                    error.json().then( (value) => {
                        console.error(value);
                    });
                } else {
                    console.error(error);
                }
            });
    };
    const form = <React.Fragment>
        <div className="large-icon rounded-warning mar-auto small-mar-bot-20"></div>
        <h3 className="mar-bot-10">Coupon already issued</h3>
        <p className='popup__text'>Please note you already have an active student coupon assigned to your email:<span className="popup__email">{props.email}</span></p>
       <p className='popup__text popup__text--grey'>
           Check your inbox to locate your discount, or <a onClick={resend}>resend the email</a>.
       </p>
    </React.Fragment>;

    return  (
            sent ? <SuccessModal email={props.email}/> :
                <div className="grid-x align-center">
                    <div className="cell text-center">
                        {form}
                    </div>
                </div>
    )
}