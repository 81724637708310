import {actions} from 'react-fields'

export default class FullAccessCodeAPI {

    static apply(code) {
        const url = '/api/access-code/apply';
        let data = {code: code};

        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)

    }


}