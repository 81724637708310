import React, {useRef, useState} from 'react';
import {TextField, Loader} from "react-fields";
import StudentDiscountApi from "../api/studentDiscountAPI";
import HoverButton from "../../affiliates_components/buttons/HoverButton";
import PopUp from "../base/popup";
import NotFoundModal from "./notFoundModal";
import SuccessModal from "./successModal";
import ResendModal from "./resendModal";

export default function (props) {

    const [email, setEmail] = useState("");
    const [errors, setEmailErrors] = useState(null);
    const [isLoading, setLoader] = useState(false);

    const successModalRef = useRef(null);
    const educatorModalRef = useRef(null);
    const notFoundModalRef = useRef(null);
    const resendModalRef = useRef(null);


    const validate = () => {

       if(!!email){
           setLoader(true);
           StudentDiscountApi.validate(email).
           then((response) => {
               setEmailErrors(null);
             if(response.valid){
                 successModalRef.current.open();
               } else if (response.error) {
                 setEmailErrors([response.error]);
                 if (response.error === "Email is a member of group access"){
                     educatorModalRef.current.open();
                 }
                 setLoader(false);
             } else {
                 notFoundModalRef.current.open();
                 setLoader(false);
                 setEmail("");
             }
           }).catch(error => {
               setLoader(false);
               if (error.status === 422) {
                   error.json().then( (value) => {
                       if (value[0] === "Email has already been taken"){
                           resendModalRef.current.open();
                       }
                       setEmailErrors(value);
                   });
               } else {
                   console.error(error);
               }
           });
       } else {
           setEmailErrors(["Email can't be blank"]);
       }
    };

    return (
        <div className={'grid-x'}>
            {isLoading && <Loader/>}
            <div className="input-with-icon small-12 cell">
                <div className="small-icons blue-envelope"/>
                <TextField name="email" placeholder="Student email"
                       errorMessage={errors && errors[0]}
                       errorType="tooltip"
                       value={email} onChange={(e) =>  {setEmailErrors(null); setEmail(e.target.value)}}/>
            </div>
            <div className={'small-12 cell'}>
                <p className={'discount__verify-term before-fade-in fade-in'}><span className={'small-icons grey-tooltip-icon'}/>We only accept emails provided by verifiable educational institutes</p>
                <HoverButton onClick={validate} className={'button--green--large button--with-icon'}>
                    Verify
                    <span className={'small-icons next-arrow-white-l'}/>
                </HoverButton>
            </div>
            <PopUp ref={resendModalRef} contentClass={"popup__content popup__content--large"} >
                <ResendModal email={email} />
            </PopUp>
            <PopUp ref={successModalRef} contentClass={"popup__content popup__content--large"} onClose={() => {document.location.href='/upgrade'}}>
                <SuccessModal email={email} />
            </PopUp>
            <PopUp ref={notFoundModalRef} contentClass={"popup__content popup__content--discount"}>
                <NotFoundModal email={email}/>
            </PopUp>
            <PopUp ref={educatorModalRef} contentClass={"popup__content popup__content--discount"}>
                <div className="grid-x align-center">
                    <div className="cell text-center">
                        <h3 className="mar-bot-10">Your Access</h3>
                        <p className='popup__text popup__text--grey'>
                            Access to apply for a student discount is not permitted from your account. Contact your account's administrator to request additional access.
                        </p>
                    </div>
                </div>
            </PopUp>
        </div>
    )
}
