import React, {useEffect, useRef} from "react";
import HoverButton from "../buttons/HoverButton";
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

export default function LeavingPopup(props) {

  const job = props.job;
  let logoStyle = job.company_logo_url ? {background: `url('${job.company_logo_url}') no-repeat`, backgroundPosition: 'center center'} : {};

    const dateFormat = (date) => {
        let d = new Date(date);

        return `${d.getDate()} ${months[d.getMonth()+1]} ${d.getFullYear()}`
    };

    const applyVia = () => {
      if(job.application_link.indexOf('cv-library')>0){
          return 'CVLibrary'
      } else if(job.application_link.indexOf('resume-library')>0){
          return 'ResumeLibrary'
      } else {
          return "CareerAddict"
      }
    };

    return (

            <div className="grid-x align-center">
                <div className="large-9 medium-12 small-12 cell">
                    <h3 className="leaving-popup__title" dangerouslySetInnerHTML={{__html: job.title}}/>
                    <p className="leaving-popup__side">Posted {dateFormat(job.posted_date)} by {job.get_company_name}</p>
                </div>
                <div className="large-3 medium-12 small-12 cell">
                    {job.company_logo_url && <div className="leaving-popup__logo" style={logoStyle}/>}
                </div>
                <div className="grid-x leaving-popup__details">
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Location</p>
                        <p className='leaving-popup__info'>{job.display_location}</p>
                    </div>
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Salary</p>
                        <p className='leaving-popup__info'>{job.display_salary}</p>
                    </div>
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Job type</p>
                        <p className='leaving-popup__info'>{job.job_type_name}</p>
                    </div>
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Industry</p>
                        <p className='leaving-popup__info'>{job.industry_name}</p>
                    </div>
                    {job.display_start_date &&
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Start date</p>
                        <p className='leaving-popup__info'>{job.display_start_date}</p>
                    </div>
                    }
                    {job.education_name &&
                    <div className="large-4 medium-4 small-12 cell">
                        <p className='leaving-popup__category'>Min. education</p>
                        <p className='leaving-popup__info'>{job.education_name}</p>
                    </div>
                    }
                </div>
                <div className="large-7 medium-12 small-12 cell large-order-1 medium-order-1 small-order-2">
                    <p className="leaving-popup__tip"><span className={"small-icons blue-off-tooltip"}/>You will be taken to the job provider website for full details.</p>
                </div>
                <div className="large-5 medium-12  small-12 cell large-flex-end large-order-2 medium-order-2 small-order-1">
                    <HoverButton className="button--with-icon button--bigger" href={job.application_link} hrefOpts={{target: '_blank'}}><span>Apply via&nbsp;</span>{applyVia()}&nbsp;<span className="small-icons apply-icon"/></HoverButton>
                </div>
            </div>

    )
}