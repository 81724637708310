import React from 'react';
import {actions, InputTypeField, Loader, TextField} from "react-fields";
import loginAPI from '../api/loginAPI';
import HoverButton from "../buttons/HoverButton";
import PopUp from "../base/popup";
import RewriteResults from "./rewriteResults";
import CannotRewriteResults from "./cannotRewriteResults";
import Checkbox from "../base/checkbox";
import OmniAuthButtons from "../user/omniAuth/buttons";

class Login extends React.Component {

    rewriteResultsModal = React.createRef();
    cannotRewriteResultsModal = React.createRef();

   state = {
            user: {email: this.props.user.email, errors: {}},
            isLoading: false,
            currentStep: 'email',
            className: this.props.className || "form-container",
            userChecked: false,
            userState: null,
        };

    afterLogin = (user, saveAnswers) => {
       if(!!this.props.afterLogin){
           this.setState({isLoading: false});
           this.props.afterLogin(user, saveAnswers);
       } else {
           document.location.href = this.props.continue_path || '/dashboard';
       }
    };



    login = (saveAnswers) => {
        let _this = this;
        let clearSession = _this.props.clearSession !== false || !saveAnswers;
        this.setState({isLoading: true}, () => {
            let user = _this.state.user;
            loginAPI.loginUser(this.state.user, saveAnswers).then( async (response) => {
                if (response) {
                    if (clearSession) {
                        localStorage.clear();
                    }
                    _this.afterLogin(response, saveAnswers);
                } else {
                    _this.setState({isLoading: false});
                }

            }).catch(error => {
                console.log(error);
                error.json().then(function (value) {
                    user.errors = value;
                    _this.setState({user: user, isLoading: false});
                });
            });
        })

    };

    beforeLogin = (e) => {
        e.preventDefault();
        let saveAnswers = this.props.saveAnswers;
        let _this = this;
        if (!this.state.userChecked) {
            this.checkUser(e);
            return;
        }
        if (saveAnswers && this.props.user.interests_status !== 'not_started' && localStorage.getItem('interests')) {
            _this.setState({isLoading: true}, () => {
                let user = _this.state.user;
                loginAPI.beforeLoginUser(_this.state.user).then( async (response) => {
                    _this.setState({isLoading: false});
                    if (response) {
                         if(response.can_login) {
                             if(_this.props.user.educator_id && _this.props.user.interests_status === 'finished'){
                                 _this.cannotRewriteResultsModal.current.open();
                             } else {
                                 _this.rewriteResultsModal.current.open();
                             }
                         }
                    }
                }).catch(error => {
                    console.log(error);
                    error.json().then( (value) => {
                        user.errors = value;
                        _this.setState({user: user, isLoading: false});
                    });
                });
            })

        } else {
            this.login(saveAnswers);
        }
    };

    onConfirmSaveResults = () => {
        this.login(true);
        this.rewriteResultsModal.current.close();
    };

    onCancelSaveResults = () => {
        this.login(false);
        this.rewriteResultsModal.current.close();
    };
    onOkCannotSaveResults = () => {
        this.login(false);
        this.cannotRewriteResultsModal.current.close();
    };

    onHandleChange = (e) => {
        console.log('onChange');
        let name = 'customP' === e.target.name ? 'password' : e.target.name ;
        let value = name === 'remember_me' ? e.target.checked : e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
    };

    onHandleEmailChange = (e) => {
        console.log('onEmailChange');
        let name = 'email';
        let value = e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user, userChecked: false});
    };

    checkUser = (e) => {
        e.preventDefault();
        if (this.state.userChecked) {
            return;
        }
        if(!this.state.user.email) {
            this.setState({user: {...this.state.user, errors: {email: 'Email is required'}}});
            return;
        }
        let _this = this;
        this.setState({isLoading: true}, () => {
            let user = _this.state.user;
            loginAPI.getUser(this.state.user).then( (response) => {
                if(response && response.id){
                    if (response.temp) {
                        this.props.onUserLoad(response);
                    } else {
                        this.setState({userState: 'login_form', userChecked: true});
                    }
                } else {
                    user.errors.email = "Email not found";
                    _this.setState({user: user});
                }
                _this.setState({isLoading: false, userChecked: true});
            }).catch(error => {
                error.json().then(function (value) {
                    user.errors = value;
                    _this.setState({user: user, isLoading: false, userChecked: false});
                });
            });
        })
    };

    submitOnEnter = (e) => {
        if(e.key === 'Enter'){
            this.beforeLogin(e);
        }
    };

    onSocialLoginSuccess = () => {
        document.location.href = '/set-up';
    };

    omniResponse = (callback) => {
        this.setState({isLoading: true}, callback);
    };

    onSocialLoginError = (response) => {
        if (!response) {
            this.setState({isLoading: false });
            return;
        }
        this.props.openRegistration(response);
    };

    render() {
        return  (

            <div className="login-register" onSubmit={this.login}>
                {this.state.isLoading ? <Loader/> : null}
                <div className="grid-x text-center-medium">
                    <div className="small-12 cell">
                        <h1 className={'set-up__header'}>Welcome back!</h1>
                        <p className={'set-up__subheader'}>Access your career matching dashboard</p>
                    </div>
                    <div className="small-12 cell">
                        <TextField name={"customE"} errorMessage={this.state.user.errors.email}
                                   onKeyPress={this.submitOnEnter}
                                   onChange={this.onHandleEmailChange} placeholder={"Email*"}
                                   value={this.state.user.email}/>
                        <InputTypeField type={"password"} name={"customP"}
                                        placeholder={"Password*"}
                                        errorMessage={this.state.user.errors.base}
                                        onKeyPress={this.submitOnEnter}
                                        onChange={this.onHandleChange}
                                        onFocus={this.checkUser}
                                        value={this.state.user.password}/>
                    </div>
                </div>
                <div className="grid-x align-middle text-center-small">
                    <div className="medium-6 small-12 cell  text-left-center">
                        <Checkbox name='remember_me' label='Keep me logged in'
                                  onChange={this.onHandleChange} value={this.state.user.remember_me} />
                    </div>
                    <div className="medium-6 small-12 cell medium-flex-end">
                        <HoverButton className="button--green--large" onClick={this.beforeLogin}>
                            Log in <span className={"small-icons next-arrow-white-l"}/>
                        </HoverButton>
                    </div>
                </div>
                <div  className="grid-x align-middle mar-top-10 text-center-small">
                    <div className="medium-6 small-12 cell medium-order-1 small-order-2 text-left-center">
                        <p className="registration__link">Don’t have an account? <a href="/register">Sign up</a></p>
                    </div>
                    <div className="medium-6 small-12 cell medium-flex-end medium-order-2 small-order-1">
                        <a onClick={this.props.onClickForgotPassword} className="login-register__forgot">Forgot your password?</a>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="small-12 cell login-register__social">
                        <p className="login-register__or">or</p>
                        <OmniAuthButtons onCallback={this.omniResponse} label={'Log in with'} saveAnswers={this.props.saveAnswers}
                                         onSuccess={this.onSocialLoginSuccess} onError={this.onSocialLoginError} />
                    </div>
                </div>

                <PopUp ref={this.rewriteResultsModal} >
                  <RewriteResults onConfirm={this.onConfirmSaveResults} onCancel={this.onCancelSaveResults}/>
                </PopUp>
                <PopUp ref={this.cannotRewriteResultsModal} >
                    <CannotRewriteResults company={this.props.user.educator_name} onCancel={this.onOkCannotSaveResults}/>
                </PopUp>
            </div>
        )
    }


}
export default Login;