import React from 'react';
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import {useDispatch} from "react-redux";


export default function TabsOverlay(props) {

    const dispatch = useDispatch();

    const clickOnOverlay = () => {
        props.setTab(null);
        dispatch(setNeedRefreshFiltersSearch(true));
    };

    return(
        <div className={ `tabs-overlay ${(props.currentTab === null || (props.currentTab === "term" && window.innerWidth > 1023)) ? "" : "active"}`} onClick={clickOnOverlay}/>
    )
}