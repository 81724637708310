import React, { useEffect, useState, Fragment } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import HoverButton from "../buttons/HoverButton";
import {FadeInSection} from "../../components/fadeIn";
import TermsCheckboxField from "../base/termsCheckbox";
import {InputTypeField, ErrorMessage, actions, ErrorTooltip} from 'react-fields';
import TotalPrice from './totalPrice';
import CouponInput from './couponInput';
import {Loader} from 'react-fields';

export default function StripeForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const onSuccess = (response) => {
        let enrollment = response.enrollment;
        window.location.href = `${props.afterPaymentUrl}/${enrollment.encrypted_key}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        let userValid = await props.validate();

        if (!userValid){
            setIsLoading(false);
            return;
        }

        const confirmOptions = {
            return_url: `${props.afterPaymentUrl}`,
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {email: props.user.email, name: props.user.name}
            }
        };

        stripe.confirmCardPayment(props.clientSecret, confirmOptions)
            .then(function(result) {
                console.log('confirm payment result');
                console.log(result);
                if (result.error) {
                    console.log("Error result");
                    console.log(result.error);
                    if (result.error.type === "card_error" || result.error.type === "validation_error") {
                        setMessage(result.error.message);
                    } else {
                        setMessage("An unexpected error occurred.");
                    }
                    setIsLoading(false);
                } else {
                    props.pay(onSuccess, result);
                }
            });

    };

    const handleFieldChange = (e) => {
        let card = props.card;
        let field_name = e.target.name;
        card[field_name] = (field_name === 'agree' || field_name === 'newsletter_subscription') ? e.target.checked : e.target.value;
        if (field_name === 'agree' && e.target.checked) {
            card.errors[actions.camelize('base')] = "";
        } else {
            card.errors[actions.camelize(field_name)] = "";
        }
        props.onChange(card);
    };

    const onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            props.validateCouponCode();
        }
    };

    const card = props.card;
    const hideCouponArea = !!props.hideCouponArea;
    const termsErrorMessage = 'Terms and Privacy must be accepted';

    const cardStyle = {
        style: {
            base: {
                color: "#0A1E2E",
                fontFamily: 'Sofia-Pro-Regular, sans-serif',
                fontSize: "12px",
                lineHeight: '17px',
                "::placeholder": {
                    color: "rgba(10,30,46, 0.5)"
                }
            },
            invalid: {
                color: "rgb(255, 0, 0)",
                iconColor: "rgb(255, 0, 0)"
            }
        }
    };

    return (
        <FadeInSection>
            <div className="tabs-panel is-active" >
                {isLoading ? <Loader/> : null}
                <form id="payment-form" onSubmit={handleSubmit}>
                    <div className={'cardPlaceholder'}>
                        <CardElement options={cardStyle} />
                    </div>
                    <p className="secured-msg">Card payments are securely processed. We do not store any card details.</p>
                    {props.purchaseType === 'license' ?
                        <div className="grid-x">
                            <div className={` small-12 cell ${props.user && !!props.user.id ? "" : " "}`}>
                                <div className={(`license-checkout__submit`) + `${(card.errors.base) && (card.errors.base === termsErrorMessage) ? " termsError" : " "}`}>
                                    {!props.hideTotalArea && <TotalPrice price={props.price} vatType={props.vatType}
                                                                         discountAmount={props.discountAmount} purchaseType={props.purchaseType}
                                                                         student_code={!!props.user.student_discount_code || (props.coupon && !!props.coupon.student_discount)}/>
                                    }
                                </div>
                                {props.user && !!props.user.id ?
                                    null
                                    :
                                    <TermsCheckboxField name="agree" value={card.agree}
                                                        errorTypeMessage={props.errorType !== 'tooltip'}
                                                        errorMessage={card.errors.base === termsErrorMessage ? card.errors.base : null}
                                                        onChange={handleFieldChange}/>
                                }
                                <div className="complete-payment-btn">
                                    <HoverButton onClick={handleSubmit}
                                                 className={'button--green--medium button--with-icon'}>
                                        <span className="small-icons white-small-lock-m"/>
                                        <span>Complete Payment</span>
                                    </HoverButton>
                                </div>
                            </div>
                            {Object.values(card.errors).findIndex((value) => !!value) >= 0 ?
                                <div className={`error-wrapper`}>
                                    <div className="checkout-icon red-cross"/>
                                    <p>There was a problem submitting payment, see errors above</p>
                                </div>
                                : null}
                            {/* Show any error or success messages */}
                            {message && <div className={`error-wrapper`}>
                                <div className="checkout-icon red-cross"/>
                                <p>{message}</p>
                            </div>}
                            <div className={`small-12 cell`}>
                                {!hideCouponArea && <CouponInput show_student_discount={props.show_student_discount}
                                                                 discountAmount={props.discountAmount}
                                                                 coupon={props.coupon}
                                                                 student_discount_code={props.user.student_discount_code}
                                                                 onChangeCoupon={props.onCouponChange}
                                                                 validateCouponCode={props.validateCouponCode}
                                />}
                            </div>
                            {card.errors.base !== termsErrorMessage ?
                                <div className={'small-12 cell text-right mar-top-10'}>
                                    <ErrorMessage
                                        message={card.errors.base === termsErrorMessage ? null : card.errors.base}
                                        className={"validation--err"}/>
                                </div> : null}
                        </div>
                        :
                        <div>

                            <div className="grid-x align-middle">
                                <div
                                    className={` medium-6 cell large-order-1 medium-order-1 small-order-2 ${props.user && !!props.user.id ? "" : " "}`}>
                                    {props.user && !!props.user.id ?
                                        null
                                        :
                                        <TermsCheckboxField name="agree" value={card.agree}
                                                            errorTypeMessage={props.errorType !== 'tooltip'}
                                                            errorMessage={card.errors.base === termsErrorMessage ? card.errors.base : null}
                                                            onChange={handleFieldChange}/>
                                    }
                                    <div
                                        className={(props.hideTotalArea ? `license-checkout__submit-educator` : `license-checkout__submit`) + `${(card.errors.base) && (card.errors.base === termsErrorMessage) ? " termsError" : " "}`}>
                                        <div>
                                            <HoverButton onClick={handleSubmit}
                                                         className={'button--green--medium button--with-icon'}>
                                                <span className="small-icons white-small-lock-m"/>
                                                <span>Complete Payment</span>
                                            </HoverButton>

                                        </div>


                                    </div>

                                </div>
                                <div
                                    className='medium-6 cell medium-flex-end large-order-2 medium-order-2 small-order-1'>
                                    {!props.hideTotalArea && <TotalPrice price={props.price} vatType={props.vatType}
                                                                         discountAmount={props.discountAmount}
                                                                         student_code={!!props.user.student_discount_code || (props.coupon && !!props.coupon.student_discount)}/>
                                    }
                                </div>
                            </div>
                            <div className="grid-x">
                                {Object.values(card.errors).findIndex((value) => !!value) >= 0 ?
                                    <div className={`error-wrapper`}>
                                        <div className="checkout-icon red-cross"/>
                                        <p>There was a problem submitting payment, see errors above</p>
                                    </div>
                                    : null}
                                {/* Show any error or success messages */}
                                {message && <div className={`error-wrapper`}>
                                    <div className="checkout-icon red-cross"/>
                                    <p>{message}</p>
                                </div>}

                                {card.errors.base !== termsErrorMessage ?
                                    <div className={'small-12 cell text-right mar-top-10'}>
                                        <ErrorMessage
                                            message={card.errors.base === termsErrorMessage ? null : card.errors.base}
                                            className={"validation--err"}/>
                                    </div> : null}
                            </div>
                            <div className="grid-x">
                                <div className={`small-12 cell`}>
                                    {!hideCouponArea && <CouponInput show_student_discount={props.show_student_discount}
                                                                     discountAmount={props.discountAmount}
                                                                     coupon={props.coupon}
                                                                     student_discount_code={props.user.student_discount_code}
                                                                     onChangeCoupon={props.onCouponChange}
                                                                     validateCouponCode={props.validateCouponCode}
                                    />}
                                </div>
                            </div>

                        </div>
                    }
                </form>
            </div>
        </FadeInSection>
    );
}