import React, {useEffect} from 'react';
import CheckBox from '../../base/checkbox';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function JobTypes(props) {


    const jobTypes = [{id: 'full_time', name: 'Full time'},
        {id: 'contract', name: 'Contract'},
        {id: 'temporary', name: 'Temporary'},
        {id: 'part_time', name: 'Part time'}];

    let selectedJobTypes = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'job_type'));
    let initialFilters = useSelector(state => state.filters.initialFilters['job_types']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            if (!initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = jobTypes.find(jobType => jobType.id === filter);
                if (item) {
                    dispatch(addFilterValue(item, 'job_type'))
                }
            });
        }, [initialFilters]
    );


    return (
        <div className="aligned-checkboxes flex-center">
            {jobTypes.map((type) => {
                return <CheckBox key={type.id}
                                 name={type.id}
                                 label={type.name}
                                 className="aligned-checkboxes__item"
                                 onChange={(e) => e.target.checked ?
                                     dispatch(addFilterValue(type, 'job_type')) :
                                     dispatch(deleteFilterValue(type, 'job_type'))}
                                 value={!!selectedJobTypes.find((jobType) => jobType.id === type.id)} />
            })}
        </div>
    )

}