import {actions} from 'react-fields'

class CustomerReviewsAPI {

    static getCollection() {
        return fetch(actions.getData('/api/customer_reviews'))
            .then(actions.status)
            .then(actions.json)
    }


}

export default CustomerReviewsAPI;