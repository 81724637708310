import React, { Component } from 'react';
import PaymentsApi from '../api/paymentsAPI';

class CheckStatus extends Component {


    componentDidMount() {
        let _this = this;
        this.timerId = setInterval(function(){ _this.checkStatus() }, 3000);
    }

    checkStatus = () => {
        PaymentsApi.checkStatus(this.props.encrypted_id, this.onSuccess, this.onError)
    };

    onSuccess = (response) => {
        if (response.status !== 'pending'){
            clearInterval(this.timerId);
            document.location.reload();
        }
    };

    onError = () => {
      clearInterval(this.timerId)
    };

   render () {
       return false;
   }
}
export default CheckStatus;