import React from 'react';

import PopUp from "../base/popup";
import ExitModal from "./modal/content";
import UtilsExitModal from "../utilsExitModal";
import FeedbackModal from '../feedback/modal/index';
import {setCookie, getCookieValue} from "../cookies";
const CHECKOUT_EXIT_MODAL_COOKIE = 'chexm';
import PopupAPI from "../api/popupAPI";

export default class ExitPopup extends React.Component {

    state = {
        enableExitModal: !getCookieValue(CHECKOUT_EXIT_MODAL_COOKIE)
    };
    mobileBookmarkMsg = React.createRef();
    feedbackModal = React.createRef();

    componentDidMount() {
        if (this.state.enableExitModal && this.props.popup.active) {
            const removeExitIntent = UtilsExitModal({
                onExitIntent: () => {
                    this.mobileBookmarkMsg.current.open();
                    setCookie(CHECKOUT_EXIT_MODAL_COOKIE, 1, 24);
                    PopupAPI.trackShow(this.props.popup.id, 'show')
                },
            });
        }
    }

    componentWillUnmount() {
        if (typeof removeExitIntent !== 'undefined')
            removeExitIntent();
    }

    openFeedback = (e) => {
        if (e)
            e.preventDefault();
        this.mobileBookmarkMsg.current.close();
        if (typeof removeExitIntent !== 'undefined')
            removeExitIntent();
        this.feedbackModal.current.open();
    };

    render(){
        if (!this.state.enableExitModal) {
            return null;
        }

        return (
            <React.Fragment>
                <PopUp ref={this.mobileBookmarkMsg} className={'feedback'}>
                    <ExitModal recaptchaKey={this.props.recaptchaKey}
                               popup_id={this.props.popup.id}
                               test={this.props.test}
                               close={() => this.mobileBookmarkMsg.current.close()}
                               openFeedback={this.openFeedback}
                    />
                </PopUp>
                <PopUp ref={this.feedbackModal} className={'feedback'}>
                    <FeedbackModal close={() => this.feedbackModal.current.close()} recaptchaKey={this.props.recaptchaKey} hideCoupon={true} />
                </PopUp>
            </React.Fragment>
        );
    }
};
