import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';
import {addFilterValue, setNeedRefreshFiltersSearch} from "../../actions/filterActions";
import useOutsideClickHandler from '../../base/useOutsideClickHandler';
import FilterTags from "../filterTags";
import HoverButton from "../../buttons/HoverButton";

export default function SearchInput(props) {

    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');

    const [magnifyIcon, setMagnifyIcon] = useState('magnify');
    const [isActive, setActive] = useState(false);
    const [deleteButton, setDeleteButton] = useState(false);

    const inputRef = useRef(null);

    const wrapperRef = useRef(null);

    const outsideClickForDesktop = () => {
      if (window.innerWidth > 1440) {
          setActive(false);
      }
    };
    useOutsideClickHandler(wrapperRef, outsideClickForDesktop);

    useEffect(
        () => {
            setMagnifyIcon(!!searchQuery ? 'magnify green-fill' : 'magnify');
        }, [searchQuery]
    );

    const onChange = (value) => {
        setSearchQuery(value);
        value.length > 0 ? setDeleteButton(true) : setDeleteButton(false);
    };

    const updateFilters = (value) => {
        if(value.length > 0){
            dispatch(addFilterValue({name: value, id: `qsearch-${Math.random().toString(36).substring(3)}`}, 'qsearch' ));
            dispatch(setNeedRefreshFiltersSearch(true));
            setSearchQuery('');
        }
    };

    const onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            updateFilters(e.target.value);
            setDeleteButton(false);
        }
    };

    const runSearch = () => {
        updateFilters(searchQuery);
        props.afterSearch();
    };

    let activeClass = isActive ? 'active' : '';

    const toggleActive = (value) => {
        if(window.innerWidth > 1023) {
            if(value)
                inputRef.current.focus();
            if (value && props.onActive) { props.onActive() }
            setActive(value);
        }
    };

    const handleClick = () => {
        setDeleteButton(false);
        setSearchQuery("");
    };

    const resizeSearch = () => {
        if (window.innerWidth < 1440) {
            setActive(true);
        }
    };

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => resizeSearch(), 150);
        };
        window.addEventListener('resize', resizeListener);
        resizeSearch();

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    let toggleDeleteButton = deleteButton && isActive ? 'active' : '';

    return(
        <React.Fragment>
            <div className="expanding-search expanding-search--term small-order-1 large-order-2" ref={wrapperRef}>
                <label className={`expanding-search__label ${activeClass}`}
                       title={'search'}
                       onClick={() => toggleActive(!isActive)}
                       onMouseOver={() => setMagnifyIcon('magnify')}
                       onMouseLeave={() => setMagnifyIcon(!!searchQuery ? 'magnify green-fill' : 'magnify')}>
                    <span className={`small-icon expanding-search__icon expanding-search__icon--magnify ${magnifyIcon}`}/>
                </label>
                <input onChange={(e) => onChange(e.target.value)}
                       type="text"
                       name={'searchQuery'}
                       value={searchQuery}
                       autoComplete="off"
                       ref={inputRef}
                       onKeyPress={onPressEnter}
                       placeholder={'search'}
                       className={`search-field expanding-search__input ${activeClass}`}/>

                <span className={`small-icon plus-icon ${toggleDeleteButton}`} onClick={handleClick} />

            </div>

            {props.showFilters ? <FilterTags type="qsearch"/> : null}
            {props.showDoneButton ? <div className="flex-center filters__apply">
                <HoverButton onClick={runSearch} color={'filter'}>Done</HoverButton>
            </div> : null}
    </React.Fragment>
    )

}