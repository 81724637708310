import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import Index from './index';
import store from '../store/store';
import {initialStateFilter} from "../actions/filterActions";

export default function Root(props) {

    useEffect(
        () => {
            store.dispatch(initialStateFilter(props.user, 'jobs', props.initialParams))
        }, [props.user]
    );


    return (
            <Provider store={store}>
                <Index initialOptions={props.initialParams}/>
            </Provider>
        );
}