import React from 'react';
import {actions, InputTypeField, Loader} from "react-fields";
import userAPI from '../api/userAPI';
import UserValidation from '../validators/user';
import HoverButton from "../buttons/HoverButton";

const FIELDS = {email: '', email_confirmation: '', current_password: '', errors: {}};

class EmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: FIELDS,
            isLoading: false
        };
    }

    onChangeField = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
    };

    onSuccess = () => {
        this.props.onSuccess(this.state.user.email);
        this.setState({isLoading: false, user: FIELDS});
    };

    onError = (errors) => {
        this.setState({isLoading: false, user: {...this.state.user, errors: errors}});
    };

    onSubmit = () => {
        this.setState({isLoading: true}, () => {
            UserValidation.changeEmail(this.state.user, () => {
                userAPI.changeEmail(this.state.user, this.onSuccess, this.onError);
            }, this.onError);
        });
    };

    render() {
        let user = this.state.user;
        return  (
            <div className="form-container">
                <h3 className="popup__title">Change your email</h3>
                {this.state.isLoading ? <Loader /> : null}
                <InputTypeField type={"email"} name={"email"}
                                placeholder={"New email*"}
                                errorMessage={user.errors.email}
                                value={user.email}
                                onChange={this.onChangeField}/>
                <InputTypeField type={"email"} name={"email_confirmation"}
                                placeholder={"Confirm new email*"}
                                errorMessage={user.errors.email_confirmation}
                                value={user.email_confirmation}
                                onChange={this.onChangeField}/>

                <InputTypeField type={"password"} name={"current_password"}
                                placeholder={"Current password*"}
                                errorMessage={user.errors.current_password}
                                value={user.current_password}
                                onChange={this.onChangeField}/>

                <div className="medium-text-center text-right">
                    <HoverButton onClick={this.onSubmit}>Save</HoverButton>
                </div>
            </div>
        )
    }

}
export default EmailForm;