import React from 'react';

export default function NotFound() {
    return(
        <div className="small-12 cell text-center no-results-msg">
            <div className="small-icon no-results no-results-msg__icon"/>
            <h2>Sorry, we couldn’t find anything!</h2>
            <p className="no-results-msg__text">Try adjusting your search, or change or update your selected filters.</p>
        </div>
    )
}