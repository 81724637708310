import React, {useEffect, useState} from "react";
import JobsAPI from "../api/jobsAPI";
import SearchCard from './searchCard';
import Pagination from "../base/pagination";

export default function (props) {

    const [collection, setCollection] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(props.page ? parseInt(props.page) : 1);

    useEffect(
        () => {
            loadTitles(page);
        }, []
    );

    const loadTitles = (page) => {
      JobsAPI.recommendedSearches(page).then((response) => {
          setCollection(response.titles);
          setTotalPages(response.total_pages);
      });
    };

    const goToPage = (e, page) => {
        e.preventDefault();
        setPage(page);
        loadTitles(page)
    };


    return(
        <div className={'grid-container no-padding aligned-container'}>
            <div className={'grid-x mar-bot-15'} data-equalizer>
                <div className={'large-6 medium-12 small-12 cell'}>
                    <h2 className={'jobs__popular'}>
                        Recommended job searches
                    </h2>
                </div>
                <div className={'large-6 medium-12 small-12 cell large-flex-end'}>
                    <p className={'jobs__tip'}><span className={'small-icon white-tooltip-icon'}/>Job searches relating to your test scores</p>
                </div>

                {
                    collection.map((title) => {
                        return (
                            <div className={'large-3 medium-6 small-12 cell'} >
                                   <SearchCard title={`${title.name} jobs`}
                                               career={title.career}
                                               salary_from={title.salary_from}
                                               salary_to={title.salary_to}
                                               addClass={'--recommended'}
                                               count={title.jobs_count_human_format}
                                               searchParams={{careers: [title.career.id]}}/>
                                 </div>
                        )
                    })
                }
            </div>
            <Pagination page={page} options={{page: page}}
                        totalPages={totalPages}
                        goToPage={goToPage}/>
        </div>

    )

}