import React from "react";
import {ErrorTooltip, ErrorMessage} from 'react-fields'

class Checkbox extends React.Component {
    state = {
        className: this.props.className || "",
        position: this.props.position || 'left',
        animationEnabled: false
    };

    onChange = (e) => {
        this.setState({animationEnabled: true});
        this.props.onChange(e);
    };

    renderLabel = () => {
        return (<span className="check-text">{this.props.label}</span>)
    };

    render() {
        const ErrorTag = this.props.errorTypeMessage ? ErrorMessage : ErrorTooltip;
        return (
            <div className={`${this.state.className} input-tick${this.state.animationEnabled ? ' addAnimation' : ''}`}>
                <label>
                    <input
                        type="checkbox"
                        value={this.props.value || false}
                        onChange={this.onChange}
                        name={this.props.name}
                        checked={this.props.value || false}
                        disabled={this.props.disabled || false}
                    />
                    <div className="checkbox-material"><div className="custom-checkbox"/></div>
                    {this.renderLabel()}
                </label>
                {this.props.errorMessage ? <ErrorTag message={this.props.errorMessage}/> : null}
            </div>
        );


    }
}
export default Checkbox;