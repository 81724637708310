import {actions} from 'react-fields'

class CouponCodesAPI {

    static check(code, productType, productID) {
        const url = `/api/coupon_codes`;

        return fetch(actions.getData(`${url}?${actions.paramsToURI({code: code, product_type: productType, product_id: productID})}`))
            .then(actions.status)
            .then(actions.json)
    }


}

export default CouponCodesAPI;