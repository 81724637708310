import React, {useRef} from "react";
import PopUp from "../base/popup";
import PreviewImg from "./preview-img.png";

export default function VideoPopup(props) {

    const videoPopup = useRef(null);

    const openPopup = () => {
      videoPopup.current.open();
    };

    return(
        <React.Fragment>
            <div onClick={openPopup}  className="preview-img">
                <img src={PreviewImg} alt="preview image for the tutorial video" />
            </div>
            <PopUp ref={videoPopup} contentClass={"popup__content--xlarge"}>
                <div className={"video-wrapper"}>
                <iframe src="https://player.vimeo.com/video/582478387?h=500e8fb459&color=4db554&title=0&byline=0&portrait=0"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen webkitallowfullscreen mozallowfullscreen/>
                </div>
            </PopUp>
        </React.Fragment>

    )

}