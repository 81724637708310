import React, {useState, useRef} from "react";
import Chevron from './chevron';

export default function Accordion(props) {

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
    };

    return (
        <div className="accordion__section">
            <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <div className="input-tick">
                    <label>
                        <input type="checkbox"><span className="checkbox-material">
                             <span className="custom-checkbox"/></span>
                        </input>
                    </label>
                </div>
                <div className="accordion__title">{props.title}</div>
                <Chevron className={`${setRotate}`} fill={"#fff"} />
            </div>
            <div className="accordion__content" ref={content} style={{ maxHeight: `${setHeight}` }}>
                    {props.children}
            </div>
        </div>
    );
}

