import React from "react";
import {InputTypeField} from "react-fields";

export default function (props) {

    const  onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            props.validateCouponCode();
        }
    };

    return (
        <div className={`checkout-coupon-wrapper ${props.show_student_discount ? " " : "with-no-bg"}`}>
            <div>
                {props.show_student_discount ?
                    <p>Are you a student<br/>
                    <a href="/student-discount">
                        Claim your 30% discount
                    </a></p> : null}
            </div>
            <div className="flex align-middle">
                {(props.discountAmount > 0 && props.coupon.applied) || props.student_discount_code ?
                    <React.Fragment>
                        <span className={'grey-coupon-valid'}>Coupon applied</span>
                        <span className="small-icons orange-tick"/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="coupon-field">
                            <InputTypeField name="coupon_code"
                                            placeholder="Coupon code"
                                            onKeyPress={onPressEnter}
                                            onChange={(e) => props.onChangeCoupon(e.target.value)}
                                            value={props.coupon.code}
                                            errorMessage={props.coupon.errors.base}
                            />
                            <button className="coupon-button button button--white" onClick={props.validateCouponCode}>
                                Apply<span className="button__hovered"/>
                            </button>
                        </div>
                        <div className={`tooltip-trigger checkout-icon blue-off-tooltip${props.coupon.errors.base ? ' err' : ' '}`}>
                            <span className="tooltip top" >If you have a coupon code for CareerHunter, enter it here to get your discount.</span>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )

}