import React, {useState, useEffect, useRef} from 'react';
import HoverButton from "../buttons/HoverButton";
import {actions} from "react-fields";
import Autocomplete from "react-autocomplete";


export default function SmallSearchBlock(props) {



    const [searchQuery, setSearchQuery] = useState(props.q);
    const [currentLocation, setCurrentLocation] = useState(props.location);

    const [titlesAutocomplete, setTitlesAutocomplete] = useState([]);
    const [locationsAutocomplete, setLocationsAutocomplete] = useState([]);


    const onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            runSearch();
        }
    };

    useEffect(() => {

        let url = '/api/jobs/autocomplete_titles?';
        fetch(actions.getData(`${url}q=${props.q}`))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                setTitlesAutocomplete(data || []);
            });

    }, []);

    const runSearch = () => {
      document.location.href = `/jobs/search?${actions.paramsToURI({terms: [searchQuery], locations: [currentLocation]})}`;
    };


    const handleTitleSelect = (title) => {
        setSearchQuery(title);
    };

    const handleLocationSelect = (location) => {
        setCurrentLocation(location);
    };



    const updateSearchQueryList = (e) => {
        let value = e.target.value;
        setSearchQuery(value);
        if (!value){
            setTitlesAutocomplete([]);
            return;
        }

        let url = '/api/jobs/autocomplete_titles?';
        fetch(actions.getData(`${url}q=${value}`))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                setTitlesAutocomplete(data || []);
            });
    };

    const updateSearchLocations = (e) => {
        let value = e.target.value;
        setCurrentLocation(value);
        if (!value){
            setLocationsAutocomplete([]);
            return;
        }

        let url = '/api/jobs/autocomplete_locations?';
        fetch(actions.getData(`${url}q=${value}`))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                setLocationsAutocomplete(data || []);
            });
    };



    return(
        <React.Fragment>
            <div className="expanding-search expanding-search--careers expanding-search expanding-search--term">
                <label className={`expanding-search__label active`}
                       title={'search'}
                >
                    <span className={`small-icons expanding-search__icon expanding-search__icon--magnify magnify-left-s`}/>
                </label>

                <Autocomplete
                    getItemValue={(item) => item}
                    items={titlesAutocomplete}
                    inputProps={{placeholder: "Job Title", type: 'text', name:'searchQuery', className: `search-field expanding-search__input active`}}
                    wrapperStyle={{}}
                    renderItem={(item, isHighlighted) =>
                        <div>
                            {item}
                        </div>}
                    value={searchQuery}
                    onChange={updateSearchQueryList}
                    onSelect={handleTitleSelect}
                />
            </div>
            <div className="expanding-search expanding-search--line show-for-large"/>
            <div className="expanding-search expanding-search--location expanding-search--careers">
                <label className={`expanding-search__label active`}
                       title={'location'}>
                    <span className={`small-icons expanding-search__icon expanding-search__icon--location location-icon`} />
                </label>
                <Autocomplete
                    getItemValue={(item) => item}
                    items={locationsAutocomplete}
                    inputProps={{placeholder: "Location", type: 'text', name:'location', className: `location-field expanding-search__input active`}}
                    wrapperStyle={{}}
                    renderItem={(item, isHighlighted) =>
                        <div>
                            {item}
                        </div>}
                    onKeyPress={onPressEnter}
                    value={currentLocation}
                    onChange={updateSearchLocations}
                    onSelect={handleLocationSelect}
                />

            </div>
            <HoverButton className={'button--with-icon search-button'} color="white" onClick={runSearch} >Search<span
                className="small-icons next-arrow-blue"/></HoverButton>
        </React.Fragment>
    )

}