import React from 'react';
import registerAPI from "../../api/userAPI";
import {TextField, Loader, actions} from "react-fields";
import TermsCheckboxField from "../../base/termsCheckbox";
import NewsletterCheckboxField from "../../base/newsletterCheckbox";
import LoginForm from  "../../login/login";
import PopUp from "../../base/popup";
import ForgotPassword from "../../login/forgotPassword";
import ConfirmationSentModal from "../../base/modals/confirmationSent";
import chLogo from '../../../../assets/images/CareerHunterBlue.png';
import OmniAuthButtons from "../../user/omniAuth/buttons";
import NewsletterBox from "../../base/newsletterBox";

class SaveProgress extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            user: {email: '', agree_terms: false, errors: {}},
            isLoading: false,
            showLoginForm: false,
            emailSent: false,
            title: this.props.title || 'Save your progress',
            description: this.props.description || 'Enter your details to set up your CareerHunter account'
        };
        this.forgotPasswordPopup = React.createRef();
    }

    onClickForgotPassword = () => {
        this.forgotPasswordPopup.current.open();
    };

    onCloseForgotPassword = () => {
        this.forgotPasswordPopup.current.close();
    };

    onSaveUser = () => {
        let user = this.state.user;
        let _this = this;
        if(this.props.status) {
            localStorage.setItem(`${this.props.test.code}_status`, this.props.status);
        }
        this.setState({isLoading: true}, () => {
            registerAPI.register(this.state.user, true).then( async (response) => {
                localStorage.setItem('user', JSON.stringify(response));
                _this.setState({isLoading: false});
                if (response.need_login) {
                    _this.setState({showLoginForm: true, user: response.user})
                } else {
                    localStorage.setItem('user', JSON.stringify(response));
                    _this.props.onSave(response);
                    _this.setState({emailSent: true})
                }
            }).catch(error => {
                console.log(error);
                error.json().then(function (value) {
                    user.errors = value;
                    _this.setState({user: user, isLoading: false});
                });
            });
        })
    };

    afterLogin = () => {
        console.log('!!!! afterLogin');
        this.setState({isLoading: true});
        document.cookie = `answersSubmittedBeforeRedirect=1; path=/;`;
        return document.location.href= `/tests/${this.props.test.path}/result`;
    };

    onHandleChange = (e) => {
        let name = e.target.name;
        let value = (name === 'agree_terms' || name === 'newsletter_subscription') ? e.target.checked : e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
    };

    onSocialError = (response) => {
        if (!response) {
            this.setState({isLoading: false });
            return;
        }
        this.setState({user: response, fromProvider:true, isLoading: false });
    };

    omniResponse = (callback) => {
        if (this.props.status) {
            localStorage.setItem(`${this.props.test.code}_status`, this.props.status);
        }
        this.setState({isLoading: true}, callback);
    };

    render() {
        return  (
            <React.Fragment>
                {this.state.isLoading ? <Loader/> : null}
                {this.state.emailSent ?
                    <ConfirmationSentModal email={this.state.user.email} testResult={`/tests/${this.props.test.path}/result`} newsletterSubscription={this.state.user.newsletter_subscription} />
                    :
                this.state.showLoginForm ?
                <div className='popup__content-with-form popup__content-login'>
                    <LoginForm user={this.state.user}
                               saveAnswers={true}
                               afterLogin={this.afterLogin}
                               back={() => {this.setState({showLoginForm: false})}}
                               onClickForgotPassword={this.onClickForgotPassword}
                                />
                    <PopUp ref={this.forgotPasswordPopup}>
                        <ForgotPassword onClose={this.onCloseForgotPassword} />
                    </PopUp>
                </div>
                : <div className='popup__content-with-form'>
                        <img src={chLogo} alt={'Career Hunter'} width="170" height="19" className="popup__logo"/>
                        <h3>{this.state.title}</h3>
                        <p className='popup__confirm_text'>{this.state.description}</p>
                        <div className="form-container">

                            <TextField name="name" placeholder="Name"
                                       errorMessage={this.state.user.errors.name}
                                       value={this.state.user.name} onChange={this.onHandleChange}
                            />
                            <TextField name="email" placeholder="Email address"
                                       errorMessage={this.state.user.errors.email}
                                       value={this.state.user.email} onChange={this.onHandleChange}
                            />

                            <div className="grid-x align-middle agree-text">
                                <div className="cell">
                                    <NewsletterBox  errorType={this.props.errorType} newsletter_subscription={this.state.user.newsletter_subscription} onHandleChange={this.onHandleChange} />
                                </div>
                                <div className="medium-7 cell text-left-center small-mar-top-10">
                                    {/*<NewsletterCheckboxField name="newsletter_subscription" value={this.state.user.newsletter_subscription}*/}
                                                        {/*errorTypeMessage={this.props.errorType !== 'tooltip'} short={true}*/}
                                                        {/*onChange={this.onHandleChange}/>*/}
                                    {/*<br />*/}
                                    <TermsCheckboxField name="agree_terms" value={this.state.user.agree_terms}
                                                        errorTypeMessage={this.props.errorType !== 'tooltip'}
                                                        errorMessage={this.state.user.errors.agreeTerms}
                                                        onChange={this.onHandleChange}/>
                                </div>
                                <div className="medium-5 cell medium-flex-end small-mar-top-10">
                                    <button onClick={this.onSaveUser} className="button button--green--large"
                                            data-open="saved">Sign up<span className="small-icons next-arrow-white-l"/><span className="button__hovered"/>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className={"grid-x"}>
                            <div className={"cell popup__social-btns"}>
                                <OmniAuthButtons onCallback={this.omniResponse} saveAnswers={true}
                                                 onSuccess={this.afterLogin} onError={this.onSocialError}  />
                            </div>
                            <div className={"cell"}>
                                <p className="registration__link">Already have an account? <a href="/login">Log in</a></p>
                            </div>
                        </div>
                    </div>}
            </React.Fragment>
        )
    }


}
export default SaveProgress;