import React from 'react';
import {actions, InputTypeField, Loader, TextField, SelectField} from "react-fields";
import registerAPI from '../api/userAPI';
import HoverButton from "../buttons/HoverButton";
import {AGE_GROUPS, GENDERS} from "../settings";
import {validatePassword} from "../validators/password";
import PasswordStrength from '../base/passwordStrength';
import MatchField from '../base/fieldMatch';
import CustomSelectField from "../base/customSelectField";
import CompleteAccount from './completeAccountForm';

class CompleteAccountShort extends CompleteAccount {

    state = {
            user: Object.assign(this.props.user, {errors: {}}),
            token: this.props.token,
            isLoading: false,
            validatePswdMatch: false
        };

    validate = () => {
        let user = this.state.user;
        let valid = true;
        let [pswdValidRes, pswdErrorMsg] = validatePassword(user.password);
        if(!pswdValidRes) {
            user.errors.password = pswdErrorMsg;
            valid = false;
        } else if (user.password !== user.password_confirmation) {
            user.errors.passwordConfirmation = 'Password confirmation does not match password';
            valid = false;
        }
        if(!user.password_confirmation) {
            user.errors.passwordConfirmation = 'Password confirmation can\'t be blank';
            valid = false;
        }

        if (!valid){
            this.setState({user: user});
        }
        return valid;
    };

    render() {
        return  (
                <form className="login-register" onSubmit={this.onSaveUser}>
                    {this.state.isLoading ? <Loader/> : null}
                    <div className="grid-x">
                        <div className="small-12 cell text-center">
                            <h1 className={'set-up__header'}>Set up your dashboard</h1>
                        </div>

                            {this.showNameField && <div className={'cell'}>
                                <TextField name={"name"} placeholder={"Full name"}
                                           value={this.state.user.name}
                                           errorMessage={this.state.user.errors.name}
                                           onChange={this.onHandleChange}/>
                            </div> }

                        {/*<div className="input-with-icon small-12 cell">*/}
                            {/*<div className="small-icons green-person"/>*/}
                            {/*<TextField name={"email"} placeholder={"Email"} value={this.state.user.email} disabled={true}/>*/}
                        {/*</div>*/}
                        <div className="small-12 cell med-up-padR10">
                            {/*<TextField name={"username"} placeholder={"Full Name"}*/}
                                       {/*value={this.state.user.name}*/}
                                       {/*errorMessage={this.state.user.errors.name}*/}
                                       {/*onChange={this.onHandleChange}/>*/}
                            <PasswordStrength ref={this.pswdStrength} value={this.state.user.password} >
                                <InputTypeField type={"password"} name={"password"}
                                                placeholder={`Password${this.pswdRequired ? '*' : ''}`}
                                                value={this.state.user.password}
                                                errorMessage={this.state.user.errors.password}
                                                onChange={this.onHandleChange}/>
                            </PasswordStrength>
                        </div>
                        <div className="small-12 cell">
                            <MatchField value={this.state.user.password_confirmation} match_value={this.state.user.password} ref={this.pswdMatch} >
                                <InputTypeField type={"password"} name={"password_confirmation"}
                                                placeholder={`Confirm password${this.pswdRequired ? '*' : ''}`}
                                                value={this.state.user.password_confirmation}
                                                errorMessage={this.state.user.errors.passwordConfirmation}
                                                onChange={this.onHandleChange}/>
                            </MatchField>
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="small-12 cell text-center">
                            <div className={'flex-center'}>
                                <HoverButton onClick={this.onSaveUser} className={"button--with-icon button--green--large"}>Proceed <span className={"small-icons next-arrow-white-l"}></span></HoverButton>
                            </div>
                        </div>
                        <div className={"cell text-center"}>
                            <p className={"set-up__info set-up__info--center"}><span className={"small-icons blue-off-tooltip"}></span>Once entered you'll be taken to your CareerHunter dashboard</p>
                        </div>
                    </div>
                </form>
        )
    }


}
export default CompleteAccountShort;