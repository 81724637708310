import React from "react";

const match = 'match';
const notMatch = 'not_match';
const messages = {match: "Passwords Match", not_match: "Passwords do not match"};

class FieldMatch extends React.Component {
    state = {
        code: ""
    };

    checkValue = (value, confirmationValue, skipCheck) => {
        if (!confirmationValue || confirmationValue.length === 0 || skipCheck){
            return this.setState({code: ""})
        }
        if (value !==  confirmationValue) {
            return this.setState({code: notMatch});
        }

        return this.setState({code: match});

    };

    render(){
        return(
            <div className={`strong-validation ${this.state.code}`}>
                {this.props.children}
                {!!this.state.code && <div className={`strong-validation ${this.state.code}`}>
                    {messages[this.state.code]}
                    {/*<div className={"line-block"}>*/}
                        {/*<div className="line"/>*/}
                    {/*</div>*/}
                </div>}
            </div>
        );

    }

}
export default FieldMatch;