import {SET_DASHBOARD_USER, REFRESH_DASHBOARD_USER} from '../actions/actionTypes';
import initialState from './initialState';


export default function filters(state = initialState.userStatus, action) {
    switch (action.type) {
        case SET_DASHBOARD_USER:
            return action.user;
        case REFRESH_DASHBOARD_USER:
            return Object.assign({}, state, action.attr);
        default:
            return state;
    }
}