import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import RoundedLabel from './roundedLabel';
import {deleteFilterValue, setNeedRefreshFiltersSearch} from "../actions/filterActions";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export default function FilterTags(props) {

    let filters = useSelector(state => state.filters.selected);

    const dispatch = useDispatch();

    const deleteFilter = (filter) => {
        dispatch(deleteFilterValue(filter, filter.type));
        dispatch(setNeedRefreshFiltersSearch(true));
    };


    if(filters.length === 0) {
        return null;
    }

    const filterItems = filters.map((filter) => {
        return  (filter.type === props.type ?
            <CSSTransition key={filter.id} timeout={400} classNames="vanish">
                <RoundedLabel key={filter.id}
                              option={filter}
                              selected={true}
                              delete={() => deleteFilter(filter)}/>
            </CSSTransition> :null);
    });

    return (
        <div className={`grid-container recommendations-container`}>
            <div className="grid-x grid-padding-x mar-top-10 recommendations-container__content">
                <TransitionGroup className="grid-x">
                    {filterItems}
                </TransitionGroup>
            </div>
        </div>
    )

}