import React, {useEffect, useState} from 'react';
import BannersApi from "../api/adBannersAPI";
import {FadeInSection} from "../fadeIn";

export default function PromoBanner(props) {

    let [banner, setBanner] = useState(null);

    useEffect(
        () => {
            BannersApi.getPromo(props.banner_code_prefix).then((res) => {
                if (res && res.content) {
                    setBanner(res);
                }
            }).catch(error => {
                console.log(error);
            });
        }, []
    );

    if (!banner) {
        return null;
    }

    return(
        <FadeInSection>
            <div className="grid-container no-padding-top full-width">
                <div dangerouslySetInnerHTML={{__html: banner.content}} />
            </div>
        </FadeInSection>
    )

}