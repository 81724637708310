import {actions} from 'react-fields'

export default class FeedbackAPI {

    static hide() {
        const url = '/api/user';
        let data = { user:{show_feedback: false} };

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( (response) => {
            console.info('Feedback bottom area will no longer appear');
        }).catch(error => {
            console.error(error);
        });
    }

    static send(data, captchaToken, onSuccess, onError) {
        const url = '/api/feedbacks';

        return fetch(actions.postRequest(url, {feedback: data, g_recaptcha_response: captchaToken}))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async(response) => {
                onSuccess(response);
            }).catch(async error => {
                console.error(error);
                if (onError) { onError(); }
            });
    }


}