
import React, {useLayoutEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector, useDispatch, Provider} from "react-redux";

import Tabs from './tabs';
import {createSelector} from "reselect";
import {deleteAllFilters} from "../../actions/filterActions";
import SelectedFilters from "../selectedFilters";
import TabsContent from "./tabsContent";
import TabsOverlay from "../tabsOverlay";
import useWindowSize from "../../useWindowSize";

export default function Filters(props){

    const dispatch = useDispatch();

    let [currentTab, setTab] = useState(null);

    const size = useWindowSize();

    const titlesRef = useRef(null);
    const [titlesHeight, setTitlesHeight] = useState(null);

    const [filterButtonVisibility, setFilterButtonVisibility] = useState(true);

    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );

    let { selectedCareers, selectedJobTypes, salary, qsearch, location, selectedSectors} = useSelector(state => ({
        selectedCareers: filtersSelector(state, 'career'),
        selectedJobTypes: filtersSelector(state, 'job_type'),
        selectedSectors: filtersSelector(state, 'sector'),
        salary: singleFilterSelector(state, 'salary'),
        qsearch: filtersSelector(state, 'qsearch'),
        location: filtersSelector(state, 'location')

    }), shallowEqual);

    let tabs = [{name: 'Career', key: 'career', selected: selectedCareers.length > 0, hidden: false},
        {name: 'Sector', key: 'sector', selected: selectedSectors.length > 0, hidden: false},
        {name: 'Job type', key: 'job_type', selected: selectedJobTypes.length > 0, hidden: false},
        {name: 'Salary', key: 'salary', selected: (!!salary), hidden: false}
    ];

    const clearAll = () => {
        closeTab();
        dispatch(deleteAllFilters());
    };

    const scrollTo = (element, to, duration) => {
        if (duration <= 0) return;
        let difference = to - element.scrollTop;
        let perTick = difference / duration * 10;

        setTimeout(function() {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollTo(element, to, duration - 10);
        }, 10);
    };

    const toggleFilters = () => {
        if (currentTab === null) {
            setTab('career');
            setFilterButtonVisibility(false);
            document.querySelector('body').classList.add('no-scroll');
        } else {
            setFilterButtonVisibility(true);
        }
        if (titlesRef.current) {
            setTimeout(() => {
                setTitlesHeight(titlesRef.current.clientHeight);
            }, 1)
        }
    };

    const search = () => {
        closeTab();
        props.search();
    };

    const closeTab = () => {
        document.querySelector('body').classList.remove('no-scroll');
        setFilterButtonVisibility(true);
        setTab(null);
    };

    const onTitlesRef = (ref) => {
        titlesRef.current = ref;
    }

    const updateTitlesHeight = () => {
        if (titlesRef.current) {
            setTitlesHeight(titlesRef.current.clientHeight);
        }
    };

    useLayoutEffect(() => {
        updateTitlesHeight();
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => updateTitlesHeight(), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    let calculatedHeight = () => {
        if (size.width > 1199) {
            return size.height - titlesHeight  - 325 + 'px';
        } else if (size.width > 1024 && size.width < 1200) {
            return size.height - titlesHeight  - 305 + 'px';
        } else {
            return size.height - titlesHeight - 180 + 'px';
        }
    };


    return (
        <React.Fragment>
            <div className="filters-tabs-wrapper">
                <Tabs tabs={tabs} currentTab={currentTab} setTab={setTab} clearAll={clearAll}
                      toggleFilters={toggleFilters} hasLocation={true} closeTab={closeTab} onRef={onTitlesRef} showFilterButton={filterButtonVisibility}>
                    <TabsContent currentTab={currentTab} search={search} closeTab={closeTab} calculatedHeight={calculatedHeight()}/>
                </Tabs>
            </div>
            <SelectedFilters search={props.search}/>
            <TabsOverlay setTab={setTab} currentTab={currentTab}/>
       </React.Fragment>
    )
}