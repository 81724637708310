import React, {useEffect, useState} from "react";
import JobsAPI from "../api/jobsAPI";
import SearchCard from './searchCard';
import Pagination from "../base/pagination";

export default function (props) {

    const [collection, setCollection] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(props.page ? parseInt(props.page) : 1);

    useEffect(
        () => {
            loadIndustries(page);
        }, []
    );

    const loadIndustries = (page) => {
        JobsAPI.bySector(page).then((response) => {
            setCollection(response.collection);
            setTotalPages(response.total_pages);
        });
    };

    const goToPage = (e, page) => {
        e.preventDefault();
        setPage(page);
        loadIndustries(page)
    };


    return(
        <div className={'grid-container no-padding-top aligned-container'}>
            <div className={'grid-x mar-bot-15'}>
                <div className={'large-6 medium-12 small-12  cell'}>
                    <h2 className={'jobs__popular'}>
                        {props.completed ? 'Recommended job sectors' : 'Browse by job sector'}
                    </h2>
                </div>
                <div className={'large-6 medium-12 small-12  cell large-flex-end'}>
                    <p className={'jobs__tip'}><span className={'small-icon white-tooltip-icon'}/>
                        {props.completed ? 'Sectors based on your career matches' : 'Complete all tests to get tailored job suggestions'}
                    </p>
                </div>

            {
                collection.map((item) => {
                    return (
                        <div className={'large-3 medium-6 small-12 cell'}>
                            <SearchCard title={item.industry.name}
                                        count={item.count}
                                        career={item.career}
                                        salary_from={item.industry.salary_from}
                                        salary_to={item.industry.salary_to}
                                        searchParams={{sectors: [item.industry.id]}}/>
                        </div>
                    )
                })
            }
            </div>
            <Pagination page={page} options={{page_sec: page}}
                        totalPages={totalPages}
                        pageName={'page_sec'}
                        goToPage={goToPage}/>
        </div>

    )

}