import React, {useEffect, useState} from 'react'
import MotivatorsAPI from '../../api/motivatorsAPI';
import Label from '../roundedLabel';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function Motivators(props) {

    let [personalities, setMotivators] = useState([]);

    let filteredMotivators = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'motivator'));
    let initialFilters = useSelector(state => state.filters.initialFilters['personalities']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            MotivatorsAPI.getCollection().then((collection) => {
               setMotivators(collection);
            }).catch(error => {
                  console.log(error);
             });
        }, []
    );

    useEffect(
        () => {
            if (personalities.length === 0 || !initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = personalities.find(motivator => motivator.id === parseInt(filter));
                if (item) {
                    dispatch(addFilterValue(item, 'motivator'))
                }
            });
        }, [personalities, initialFilters]
    );


    return(
        <div className="grid-x grid-padding-x mar-top-10 mar-bot-10 filters__options">
            {personalities.map((item) => {
                return <Label key={item.id} option={item}
                              hasImage={true}
                              add={() => dispatch(addFilterValue(item, 'motivator'))}
                              delete={() => dispatch(deleteFilterValue(item, 'motivator'))}
                              selected={!!filteredMotivators.find((ind) => ind.id === item.id)} />
            })}
        </div>
    )

}