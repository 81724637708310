import React, {useRef} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import {createSelector} from "reselect";
import HoverButton from "../buttons/HoverButton";
import SearchInput from "./filters/searchInput";


export default function Tabs(props){

    const dispatch = useDispatch();

    const toggleTab = (e, tabName) => {
        e.preventDefault();
        props.setTab(tabName);
        if(!tabName) dispatch(setNeedRefreshFiltersSearch(true))
        document.querySelector('body').classList.add('no-scroll');
    };

    const anyFiltersSelector = createSelector(
        state => state.filters.selected,
        (selectedFilters) => selectedFilters.length > 0
    );

    const anyFilters = useSelector(state => anyFiltersSelector(state));
    const filterStatus = anyFilters ? ' filtered' : '';
    const tabs = props.tabs;


    return (
        <React.Fragment>

            <div className="grid-container no-padding-top inner-bottom">
                <div className="grid-x spaced-between padT10 flex-middle">
                {/*<ProfileCompleteInfo />*/}
                    <div>
                        {props.head_text && <p className="courses__heading">{props.head_text}</p>}
                        {props.tooltip_info && <p className="filter-area__info hide-for-large"><span className="small-icons grey-tooltip-icon"/>{props.tooltip_info}</p>}
                    </div>
                {/*<p className="courses__heading">Your career matches are ready</p>*/}
                <div className={anyFilters ? 'filter-menu small-order-3 large-order-2 with-filters' : 'filter-menu small-order-3 large-order-2'}>
                    <p className="filter-text flex-center desktop-filter">Filter by</p>
                    <ul className={`desktop-filter tabs filter-tabs ${props.currentTab === null ? "" : "active"}`}>
                        {tabs.map((tab) => {
                            return <li key={tab.key} className={"filter-tabs__title" + (tab.hidden ? " hide-at-large" : "") + (props.currentTab === tab.key ? ' is-active' : '')}>
                                <a className="filter-tabs__link" onClick={(e) => {toggleTab(e,tab.key)}} >
                                    {tab.name}
                                    {tab.selected ? <span className="filter-tabs__notification"/> : null}
                                </a>
                            </li>
                        })}
                    </ul>
                    <div style={{ display: props.showFilterButton ? "block" : "none" }} className="filter-menu__buttons">

                        <HoverButton onClick={props.toggleFilters} className={'filter-menu__buttons__item hide-at-large' + filterStatus } color={'white'}>
                            Filter{anyFilters ? <span className="filter-menu__buttons__notification"/> : null}
                        </HoverButton>
                        {anyFilters ?
                            <HoverButton onClick={props.clearAll} className={'filter-menu__buttons__item clear-all'} color={'white'}>
                                Clear all
                            </HoverButton>
                        : null}
                        <div className="search-bars__container hide-at-medium-down">
                            {props.hasSearch ? <SearchInput onActive={props.closeTab} /> : null }
                        </div>
                    </div>
                </div>
                <div className={"filters-container small-order-2 large-order-3" + (props.currentTab !== null  ? ' ' : ' hide-at-medium-down') }>
                    <div className="filters-wrapper filter-tabs">
                        <div className="grid-container filters-selection">
                            <div ref={props.onRef} className="grid-x hide-at-large filters-titles">
                                <div className="large-12 cell">
                                    <div className="filter-text">Filter by</div>
                                </div>
                                <div className="large-12 cell">
                                    <div className="tabs-container">
                                        <div className="tabs-content">
                                            <ul className={`tabs filter-tabs ${props.currentTab === null ? "" : "active"}`}>
                                                {tabs.map((tab) => {
                                                    return <li key={tab.key} className={"filter-tabs__title" + (props.currentTab === tab.key ? ' is-active' : '')}>
                                                            <a className="filter-tabs__link" onClick={(e) => {toggleTab(e,tab.key)}} >
                                                                {tab.name}
                                                                {tab.selected ? <span className="filter-tabs__notification"/> : null}
                                                            </a>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}