import React from 'react';

export default function Block(props) {

    let styleParent = {paddingBottom: '1rem', height: "100%"};
    let styleChild = {backgroundColor: 'white',
        fontFamily: "Sofia-Pro-Regular, sans-serif",
        fontSize: '60px',
        textAlign: 'center', color: '#DB3236', height: '100%'};

    return(
        <div className="adBlock" style={styleParent}>
            <div style={styleChild}>Ad</div>
        </div>
    )
}