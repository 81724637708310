import React from 'react';
import LazyLoad from 'react-lazyload';
import JobsDashboard from './dashboard';

export default function lazyJobsDashboard(props) {

    return(
        <LazyLoad>
            <JobsDashboard {...props} />
        </LazyLoad>
    )
}