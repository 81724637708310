import React, {useEffect} from 'react';
import ReactSlider from 'react-slider';
import {useSelector, useDispatch } from 'react-redux';
import {addFilterValue, deleteFilterValue} from "../../actions/filterActions";

export default function GrowthSlider(){

    const growthMin = 0, growthMax = 100;

    let value = useSelector(state => state.filters.selected.find(filter => filter.type === 'growth'));


    const dispatch = useDispatch();


    const onChange = (value) => {
        if(value[0] > growthMin || value[1] < growthMax) {
            dispatch(addFilterValue({id: 'growth', value: value,
                name: `${value[0]}% - ${value[1]}% growth`},
                'growth'));
        } else {
            dispatch(deleteFilterValue({id: 'growth'}, 'growth'));
        }
    };


    const renderHandle = (props, state) => {
        return (
            <div {...props}>
                <div className={`input-range__label--container`}>
                    <div className="input-range__label--top">
                        <div className="input-range__label--value">{state.valueNow}%</div>
                    </div>
                </div>
            </div>
        )
    };

    const onSlide = () => {
    };

    const onClick = (newValue) => {
        let val = value ?  value.value : [growthMin,growthMax];
        if(Math.abs(val[0] - newValue) < Math.abs(val[1] - newValue)) {
            onChange([newValue, val[1]])
        } else {
            onChange([val[0],newValue])
        }

    };

    return (
        <React.Fragment>
            <h4 className="white text-center">Annual growth range</h4>
            <div className={'range-slider__container'}>
                <span className={'input-range__slider--limit-label--min'}>{growthMin}%</span>
                <span className={'input-range__slider--limit-label--max'}>{growthMax}%</span>
                <ReactSlider
                    className="input-range"
                    thumbClassName="input-range__slider"
                    trackClassName="input-range__track"
                    defaultValue={[growthMin, growthMax]}
                    ariaLabel={['from', 'to']}
                    onChange={onSlide}
                    onAfterChange={onChange}
                    onSliderClick={onClick}
                    ariaValuetext={state => `{state.valueNow}%`}
                    renderThumb={(props, state) => renderHandle(props, state)}
                    pearling
                    step={5}
                    value={value ? value.value : [growthMin,growthMax]}
                    min={growthMin}
                    max={growthMax}
                    minDistance={10} />

            </div>
        </React.Fragment>
    );
}