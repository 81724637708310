import React from "react";
import Checkbox from './checkbox';

class TermsCheckboxField extends Checkbox {

    renderLabel = () => {
      return (<span className="agreeText">
          I agree to the <a href="/terms" target="_blank">Terms</a> and <a href="/privacy" target="_blank">Privacy</a>
      </span>)
    };

}
export default TermsCheckboxField;