class CommonFunctions {

    static generateUniqKey(){
        return Math.random().toString(36).substring(7)
    }

    static scrollToError() {
        let errorTag = document.querySelector('div.err, small.error');
        if (!errorTag) { return }
        let errorOffset = errorTag.offsetTop;
        if(errorOffset) {
            window.scrollTo({
                top: errorOffset - 200,
                behavior: "smooth"
            });
        }
    }

    static scrollToErrorWithTimeout(t=100) {
        setTimeout(() => this.scrollToError(), t);
    }

    static capitalize(str) {
        let newStr = str.replace('_', ' ');
        return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    }

    static camelize(str) {
        return str.replace(new RegExp('_', 'g'), ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
            return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        }).replace(/\s+/g, '');
    }

    static is_valid_url(url) {
        return /^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url);
    }

    static setUrl(str){
        let string = str;
        let strReplaceAll = string;
        let intIndexOfMatch = strReplaceAll.indexOf(' ');

        while (intIndexOfMatch !== -1) {
            strReplaceAll = strReplaceAll.replace(' ', '-');
            intIndexOfMatch = strReplaceAll.indexOf(' ');
        }

        string = strReplaceAll;

        for (var i = 0, output = '', valid = '-0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; i < string.length; i++) {
            if (valid.indexOf(string.charAt(i)) !== -1) {
                output += string.charAt(i);
            }
        }

        return output.toLowerCase();
    };
}
export default CommonFunctions;