import React from 'react';
import NotComplete from './notComplete';
import LoginForm from './login';
import PopUp from "../base/popup";
import ForgotPassword from "./forgotPassword";
import RegisterForm from "../user/registerForm";
import ExpiredToken from '../user/expiredTokenForm';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {email: "", errors: {}},
            isLoading: false,
            currentStep: this.props.currentStep || 'email_check'
        };

        this.forgotPasswordPopup = React.createRef();
    }

    backToEmailCheck = () => {
        this.setState({user:  {email: this.state.user.email, errors: {}}, currentStep: 'email_check'});
    };


    onUserLoad = (user) => {
        if (user && user.temp) {
            this.setState({user: user, currentStep: 'not_complete'});
        }
    };

    onClickForgotPassword = () => {
      this.forgotPasswordPopup.current.open();
    };

    onCloseForgotPassword = () => {
        this.forgotPasswordPopup.current.close();
    };

    openRegistration = (user) => {
        this.setState({user: {...user, errors: {}}, currentStep: 'registration'});
    };

    renderPage = () => {
        let step = this.state.currentStep;
        switch(step) {
            case 'not_complete':
                return <NotComplete user={this.state.user} back={this.backToEmailCheck} />;
            case 'expired_token':
                return <ExpiredToken onClickForgotPassword={this.onClickForgotPassword}/>;
            case 'registration':
                return <RegisterForm user={this.state.user} email={this.state.user.email} fromProvider={true} />;
            default:
                return <LoginForm onClickForgotPassword={this.onClickForgotPassword} saveAnswers={true} onUserLoad={this.onUserLoad}
                                   openRegistration={this.openRegistration} user={this.state.user} {...this.props} />;
        }
    };

    render() {
        return (<React.Fragment>
            {this.renderPage()}

            <PopUp ref={this.forgotPasswordPopup}>
                <ForgotPassword onClose={this.onCloseForgotPassword} />
            </PopUp>
        </React.Fragment>)
    }



}
export default Index;