import React from "react";
import {FadeInSection} from "../fadeIn";
import Bubble from "../base/bubble";
import {calcUserProgress} from "../utils";

export default function KeepTesting(props) {

    let bubbleScore = '0';
    let message = 'Take all tests to generate your matches and report';

    if (props.user && !!props.user.career_matches_ready_at) {
        bubbleScore = '100';
        message = 'Your report, matches and recommendations are all ready!';
    } else if (props.user) {
        bubbleScore = calcUserProgress(props.user);
    }

    return(
        <FadeInSection>
            <div className={'dashboard__notification'}>
                <span className={'dashboard__status-msg'}>{message}</span>
                <Bubble score={bubbleScore} matchText="complete" />
            </div>
        </FadeInSection>
    )

}