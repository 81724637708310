import React from 'react';
import {FadeInSection} from "../fadeIn";
import {actions} from "react-fields";

export default function DashboardJob(props) {

    let job = props.job;

    const searchUrl = () => {
        return `/jobs/search?${actions.paramsToURI(props.searchParams)}`
    };

    return (
        <div className="large-3 medium-6 small-12 cell">
            <a href={searchUrl()} rel={'nofollow'}  className={'card card--jobs'}>
                <div className="card__content disable-fade-in">
                    <h3 className={'card__jobs-title disable-fade-in'} dangerouslySetInnerHTML={{__html: job.title}}/>
                    <div className="flex-middle spaced-between">
                        {props.hideSalary ?
                            <div
                                className={'card__jobs-salary'}>{job.jobs_count_human_format || job.count} openings</div>
                            :
                            <div
                                className={'card__jobs-salary'}>{job.display_salary} | {job.jobs_count_human_format || job.count} openings</div>
                        }
                        {!job.career && <span className="card__search">Search <span className={'small-icons next-arrow-green-s'}/></span>}
                    </div>
                    {props.how_match === 'industry' && job.industry ?
                        <div className={'card__jobs-recommended'}>
                            <div className={'card__jobs-score'}><span className={'small-icon white-tooltip-icon'}/>{`because you scored ${job.industry.score}% for ${job.industry.ch_name}`}</div>
                            <a href={searchUrl()} target={'_blank'} className="card__search">Search <span className={'small-icons next-arrow-green-s'}/></a>
                        </div>
                        : job.career &&
                    <div className={'card__jobs-recommended'}>
                        <div className={'card__jobs-score'}><span className={'small-icon white-tooltip-icon'}/>{`because you scored ${job.career.score}% for ${job.career.name}`}</div>
                        <a href={searchUrl()} target={'_blank'} className="card__search">Search <span className={'small-icons next-arrow-green-s'}/></a>
                    </div>

                    }
                </div>
            </a>
        </div>
    )

}