import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import BubbleWrapper from './bubbleWrapper';
import store from '../../store/store';
import {setUserDashboard} from "../../actions/userStateActions";

export default function Root(props) {

    useEffect(
        () => {
            store.dispatch(setUserDashboard(props.user))
        }, [props.user]
    );


    return (
        <Provider store={store}>
            <BubbleWrapper />
        </Provider>
    );
}