export default {
    development: {
        publishableKey: 'pk_test_51LPLHzDaKmxxGt2j2rOLIXPWyZU27pATPHuRd8EAJpR8mEeXZggS8UZVzQmZqn7JPCCPWfLVk54OSel3rmYQmQbP00ojHCoXK1'
    },
    staging: {
        publishableKey: 'pk_test_51LPLHzDaKmxxGt2j2rOLIXPWyZU27pATPHuRd8EAJpR8mEeXZggS8UZVzQmZqn7JPCCPWfLVk54OSel3rmYQmQbP00ojHCoXK1'
    },
    production: {
        publishableKey: 'pk_live_51LPLHzDaKmxxGt2jlM1gZYX2a4XujrGmbcmTfTydBx9lFbccvE4WK2ad3k4szzwdFhXKfaNkwysnj2aMQ4hvNSum00kZKDkFLM'
    }
}[process.env.RAILS_ENV]