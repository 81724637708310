import {actions} from "react-fields";

export default class PaginationMeta {

    static update(options, total, totalPages, perPage, updateHistory) {
        const page = options.page || 1;
        this.updateDescription(page, total, perPage);

        const url = `${document.location.origin}${document.location.pathname}`;
        if (updateHistory) {
            this.updateBrowserHistory(options)
        }

        document.querySelector("link[rel='canonical']").setAttribute('href', this.generateUrl(url, (page === 1 ? {} : {page: page})));

        let metTitleTag = document.querySelector("title");
        let metOgTitle = document.querySelector("meta[property='og:title']");
        let newMetaTitle = '';

        let optionsWithoutPage = {...options};
        delete(optionsWithoutPage.page);
        let prevRel = document.querySelector("link[rel='prev']");
        if (page > 1) {
            newMetaTitle = metTitleTag.textContent.search(/Page \d+ \|/) >= 0 ? metTitleTag.textContent.replace(/Page \d+ \|/, `Page ${page} |`) : `Page ${page} | ${metTitleTag.textContent}`;
            let prevHref = this.generateUrl(url, (page > 2 ? {...optionsWithoutPage, page: (page - 1)} : optionsWithoutPage));
            if (!prevRel) {
                this.createLink('prev', prevHref);
            } else {
                prevRel.setAttribute('href', prevHref);
            }
        } else {
            newMetaTitle = metTitleTag.textContent.search(/Page \d+ \| /) >= 0 ? metTitleTag.textContent.replace(/Page \d+ \| /, '') : metTitleTag.textContent;
            if (prevRel) { prevRel.remove(); }
        }

        let nextRel = document.querySelector("link[rel='next']");
        if (page < totalPages) {
            let nextHref = this.generateUrl(url, {...optionsWithoutPage, page: (page + 1)});
            if (!nextRel) {
                this.createLink('next', nextHref)
            } else {
                nextRel.setAttribute('href', nextHref);
            }
        } else {
            if (nextRel) { nextRel.remove(); }
        }

        metTitleTag.innerText = newMetaTitle;
        metOgTitle.setAttribute("content", newMetaTitle);
    }

    static updateBrowserHistory(options) {
        let optionsHref = {...options};
        if (options.page === 1) {
            delete(optionsHref.page);
        }
        const href = this.generateUrl(`${document.location.origin}${document.location.pathname}`, optionsHref);
        window.history.pushState({href: href}, '', href);
    }

    static updateDescription(page, total, perPage) {
        let metaDescriptionTag = document.querySelector("meta[name='description']");
        let initialDescription = metaDescriptionTag.content;
        let description = '';
        const regexp = / Results \d+-\d+ of \d+$/;
        const from = (page-1)*perPage+1;
        const to = (page*perPage);
        const newResStr = `Results ${from}-${(to<total ? to : total)} of ${total}`;
        if (initialDescription.search(regexp) >= 0) {
            description = initialDescription.replace(regexp, ` ${newResStr}`);
        } else {
            description = `${initialDescription} ${newResStr}`;
        }

        metaDescriptionTag.setAttribute("content",description);
        document.querySelector("meta[property='og:description']").setAttribute("content",description);
    }

    static createLink(rel, href) {
        const canonicalTag = document.querySelector("link[rel='canonical']");
        let relTag = document.createElement('link');
        relTag.setAttribute('rel', rel);
        relTag.setAttribute('href', href);
        canonicalTag.parentNode.insertBefore(relTag, canonicalTag.nextSibling);
    }

    static generateUrl(url, options) {
        const searchStr = actions.paramsToURI(options);
        if (searchStr.length === 0) {
            return url;
        }
        return `${url}?${searchStr}`;
    }

}