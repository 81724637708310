import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import Index from './index';
import store from '../store/store';
import {initialStateFilter} from "../actions/filterActions";

export default function Root(props) {

    useEffect(
        () => {
            store.dispatch(initialStateFilter(props.user, 'careers', props.initialParams))
        }, [props.user]
    );

    let profileCompleted = props.user && props.user.full_access && props.user.interests_status === 'finished' &&
    props.user.personality_status === 'finished' && props.user.motivators_status === 'finished'
    && props.user.abstract_status === 'finished' && props.user.numerical_status === 'finished'
    && props.user.verbal_status === 'finished';

    return (
            <Provider store={store}>
                <Index initialOptions={props.initialParams} initialCollection={props.collection} initialProfileCompleted={profileCompleted} path={props.path} />
            </Provider>
        );
}