
import React, {useLayoutEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector, useDispatch, Provider} from "react-redux";

import Tabs from '../tabs';
import {createSelector} from "reselect";
import {deleteAllFilters} from "../../actions/filterActions";
import SelectedFilters from "../selectedFilters";
import TabsContent from "./tabsContent";
import TabsOverlay from "../tabsOverlay";
import useWindowSize from "../../useWindowSize";


export default function Filters(props){

    const dispatch = useDispatch();

    let [currentTab, setTab] = useState(null);
    const filtersModal = useRef(null);

    const size = useWindowSize();

    const titlesRef = useRef(null);
    const [titlesHeight, setTitlesHeight] = useState(null);

    const [filterButtonVisibility, setFilterButtonVisibility] = useState(true);

    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );





    let { selectedIndustries, selectedDuration, selectedLevels, qsearch} = useSelector(state => ({
        selectedIndustries: filtersSelector(state, 'industry'),
        selectedDuration: singleFilterSelector(state, 'duration'),
        selectedLevels: filtersSelector(state, 'level'),
        qsearch: filtersSelector(state, 'qsearch')

    }), shallowEqual);


    let tabs = [{name: 'Category', key: 'industry', selected: selectedIndustries.length > 0, hidden: false},
        {name: 'Duration', key: 'duration', selected: !!selectedDuration, hidden: false},
        {name: 'Level', key: 'level', selected: selectedLevels.length > 0, hidden: false}
    ];

    const clearAll = () => {
        closeTab();
        dispatch(deleteAllFilters());
    };

    const scrollTo = (element, to, duration) => {
        if (duration <= 0) return;
        let difference = to - element.scrollTop;
        let perTick = difference / duration * 10;

        setTimeout(function() {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollTo(element, to, duration - 10);
        }, 10);
    };

    const toggleFilters = () => {
        if (currentTab === null) {
            setTab('industry');
            setFilterButtonVisibility(false);
            document.querySelector('body').classList.add('no-scroll');
        } else {
            setFilterButtonVisibility(true);
        }
        if (titlesRef.current) {
            setTimeout(() => {
                setTitlesHeight(titlesRef.current.clientHeight);
            }, 1)
        }
    };


    const search = () => {
        closeTab();
      //  filtersModal.current.close();
        props.search();
    };

    const closeTab = () => {
        document.querySelector('body').classList.remove('no-scroll');
        setFilterButtonVisibility(true);
        setTab(null);
    };

    const onTitlesRef = (ref) => {
        titlesRef.current = ref;
    }

    const updateTitlesHeight = () => {
        if (titlesRef.current) {
            setTitlesHeight(titlesRef.current.clientHeight);
        }
    };

    useLayoutEffect(() => {
        updateTitlesHeight();
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => updateTitlesHeight(), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    const anyFiltersSelector = createSelector(
        state => state.filters.selected,
        (selectedFilters) => selectedFilters.length > 0
    );

    const anyFilters = useSelector(state => anyFiltersSelector(state));
    const filterStatus = anyFilters ? ' filtered' : '';

    let calculatedHeight = () => {
        if (size.width > 1199) {
            return size.height - titlesHeight  - 325 + 'px';
        } else if (size.width > 1024 && size.width < 1200) {
            return size.height - titlesHeight  - 305 + 'px';
        } else {
            return size.height - titlesHeight - 180 + 'px';
        }
    };

    return (
        <React.Fragment>
            <div className="filters-tabs-wrapper for-courses">

                <Tabs tabs={tabs} currentTab={currentTab} setTab={setTab} hasSearch={props.hasSearch}
                      head_text={"Browse all courses"}
                      clearAll={clearAll} toggleFilters={toggleFilters} closeTab={closeTab} onRef={onTitlesRef} showFilterButton={filterButtonVisibility}>
                    <TabsContent currentTab={currentTab} search={search} closeTab={closeTab} calculatedHeight={calculatedHeight()}/>
                </Tabs>

                {/*<HoverButton onClick={() => {filtersModal.current.open()}}*/}
                {/*             className={'filter-menu__buttons__item hide-at-large' + filterStatus }*/}
                {/*             color={'white'}>*/}
                {/*    Filter{anyFilters ? <span className="filter-menu__buttons__notification"/> : null}*/}
                {/*</HoverButton>*/}
            </div>

            {/*<PopUp ref={filtersModal} className="filters-popup" >*/}
            {/*    <Provider store={store}>*/}
            {/*        <FiltersModal hasSearch={props.hasSearch}*/}
            {/*                      setTab={setTab}*/}
            {/*                      currentTab={currentTab}*/}
            {/*                      search={search}*/}
            {/*                      tabs={tabs}>*/}
            {/*            <TabsContent currentTab={currentTab} search={search} closeTab={closeTab} calculatedHeight={calculatedHeight()}/>*/}

            {/*        </FiltersModal>*/}
            {/*    </Provider>*/}
            {/*</PopUp>*/}
            <SelectedFilters/>
            <TabsOverlay setTab={setTab} currentTab={currentTab}/>
       </React.Fragment>
    )
}