import React from "react";
import {FadeInSection} from "../fadeIn";

export default function TestState(props) {

    const testPaid = props.user && (props.user.full_access || props.user[`${props.test.code}_access`]);
    const retakesLeft = testPaid ? props.user[`${props.test.code}_retakes`] : 0;

    return (
        <FadeInSection>
            {testPaid && retakesLeft > 0 ?
                <React.Fragment>
                    <div>
                        <p className="get-access-blk--already-purchased">
                            Access already purchased
                        </p>
                        <p className="get-access-blk--includes">{retakesLeft} test {retakesLeft === 1 ? 'take' : 'takes'} remaining</p>
                    </div>
                    <div>
                        <a href={`/tests/${props.test.path}/take`} aria-label="Go To Test"
                           className="button button--with-icon button--green button--green--medium">Go To Test <span
                            className="small-icons next-arrow-white-s"/></a>
                    </div>
                </React.Fragment>
                : testPaid && retakesLeft === 0 ?
                <React.Fragment>
                    <div>
                        <p className="get-access-blk--price">
                            Price: {props.price.currency.symbol}{props.price.retakes_price.toFixed(2)}
                            {<del><span>{props.price.currency.symbol}{props.price.original_price}</span></del>}
                        </p>
                        <p className="get-access-blk--includes">Purchase 2 additional retakes</p>
                    </div>
                    <div>
                        <div className="get-access-blk--links">
                            <a href={`/checkout/retakes?test=${props.test.code}`} aria-label="Buy Access"
                               className="button button--with-icon button--green button--green--medium">Buy
                                Access <span
                                    className="small-icons next-arrow-white-s"/></a>
                            <a href={`/tests/${props.test.path}`} className="get-access-blk--learn-more">Learn
                                more</a>
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div>
                        <p className="get-access-blk--price">
                            Price: {props.price.currency.symbol}{props.price.price.toFixed(2)}{' '}
                            {props.price.price !== props.price.original_price &&
                            <del><span>{props.price.currency.symbol}{props.price.original_price}</span></del>}
                        </p>
                        <p className="get-access-blk--includes">Includes 2 takes</p>
                    </div>
                    <div>
                        <div className="get-access-blk--links">
                            <a href={`/checkout/${props.test.path}`} aria-label="Buy Access"
                               className="button button--with-icon button--green button--green--medium">Buy
                                Access <span
                                    className="small-icons next-arrow-white-s"/></a>
                            <a href={`/tests/${props.test.path}`} className="get-access-blk--learn-more">Learn
                                more</a>
                        </div>
                    </div>
                </React.Fragment>}
        </FadeInSection>
    )
}