import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import ImprovingImg from "./improvingCH.png";
import {setCookie, getCookieValue} from "../cookies";
const COOKIE_NAME = 'chpreupm';

export default function preUpdatePopup(props) {

    const preUpdatePopup = useRef(null);

    useEffect(() => {
        if (!getCookieValue(COOKIE_NAME)) {
            preUpdatePopup.current.open();
        }
    }, []);

    const onClose = () => {
        setCookie(COOKIE_NAME, 1, 72);
    };

    return (
        <PopUp ref={preUpdatePopup} onClose={onClose} className="pre-update-popup">
            <div className="grid-x align-center">
                <div className="small-12 cell">
                    <img src={ImprovingImg} alt={'CareerHunter is improving'} width="156" height="107"/>
                    <h3 className="pre-update-popup__title">CareerHunter is improving!</h3>
                    <p className="pre-update-popup__text">Hi{props.name ? ` ${props.name}` : ''}, we have some exciting and important upgrades coming your way:</p>
                    <p className="pre-update-popup__point"> <span className="pre-update-popup__bullet"></span> Easier individual access to all our tests</p>
                    <p className="pre-update-popup__point"> <span className="pre-update-popup__bullet"></span> Additional and dedicated reports for each assessment</p>
                    <p className="pre-update-popup__point"> <span className="pre-update-popup__bullet"></span> Personalized insights with more intuitive analysis</p>
                    <p className="pre-update-popup__point"> <span className="pre-update-popup__bullet"></span> Improved and more relevant study suggestions</p>
                    <p className="pre-update-popup__point"> <span className="pre-update-popup__bullet"></span> Enhanced testing experience with many more upgrades</p>
                    <p className="pre-update-popup__note">We expect to roll this out in October, so keep your eyes peeled! Until then, happy career hunting!</p>
                </div>
            </div>
        </PopUp>
    )
}