import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";

export default function StudentRestrictionModal(props) {

    const studentWelcomePopup = useRef(null);

    useEffect(() => {
        studentWelcomePopup.current.open();
    }, []);

    const onClose = () => {
        document.location.href='/dashboard'
    };

    return (
        <PopUp ref={studentWelcomePopup} onClose={onClose} >
            <div className="grid-x align-center">
                <div className="cell text-center">
                    <h3 className="mar-bot-10">{props.checkout ? 'Your' : 'Test'} Access</h3>
                    <p className='popup__text'>{props.checkout ? 'Access to purchase is not permitted from your account.' : 'Access to this test is not currently permitted on your account.'}</p>
                    <p className='popup__text'>Contact your account's administrator to request additional {props.retake ? 'retake' : 'access'}. </p>
                </div>
            </div>
        </PopUp>
    )
}