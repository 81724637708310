import React, {Fragment} from "react";

export default function (props) {

    const curSymbol = props.price.currency.symbol;
    const curCode = props.price.currency.code;
    const subtotal = (props.price.price - (props.price.taxAmount || 0) - props.discountAmount);

    const renderTaxRows = () => {
      if (!props.vatType) { return null; }
      let taxStr = 'No tax applicable';
      if (props.vatType === 'vat_added') {
          taxStr = 'VAT @ 19%'
      } else if (props.vatType === 'vat_reversed') {
          taxStr = 'VAT reverse charged'
      }
      return <Fragment>
          <div className="licenses-price__block licenses-price__line">
              <p className="licenses-price__subtotal">Subtotal</p>
              <p className="licenses-price__subtotal">{curSymbol}{subtotal.toFixed(2)}</p>
          </div>
          <div className="licenses-price__block licenses-price__line">
              <p className="licenses-price__subtotal">{taxStr}</p>
              <p className="licenses-price__subtotal">{curSymbol}{(props.price.taxAmount || 0).toFixed(2)}</p>
          </div>
      </Fragment>
    };

    return(
        <div className={`licenses-price__container ${props.purchaseType === 'license' ? "licenses-price__container-license" : " "}`}>
            {(props.price.original_price !== props.price.price || props.discountAmount > 0)  &&
                <p className="license-checkout__submit-disc-price"><del>{curSymbol}{props.price.original_price}</del></p>
            }
            {renderTaxRows()}
            {props.purchaseType === 'license' ?
                <div className="licenses-price__block licenses-price__total">
                    <p className="license-checkout__submit-price license-checkout__submit-price--blue">Total </p>
                    <p className="license-checkout__submit-price license-checkout__submit-price--blue">{curCode === "USD" ? "US " : ""}{curSymbol}{(props.price.price - props.discountAmount).toFixed(2)}</p>
                </div> :
                <p className="license-checkout__submit-price license-checkout__submit-price-user ">Total <span>{curCode === "USD" ? "US " : ""}{curSymbol}{(props.price.price - props.discountAmount).toFixed(2)}</span></p>
            }
            {props.student_code && <p className="license-checkout__submit-discount"><span>30% off</span> applied from your student discount</p>}
        </div>
    )
}