import React from 'react';
import CareersAccordion from "../filters/careersAccordion";
import HoverButton from "../../buttons/HoverButton";
import SearchInput from "../filters/searchInput";
import Industries from '../filters/industries';
import CourseLevel from "../filters/courseLevel";
import DurationWeeksSlider from "../filters/durationWeeksSlider";

export default function TabsContent(props) {

    const button = (<div className="flex-center filters__apply">
        <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
    </div>);

    return (
        <React.Fragment>
            <div style={{ height: props.calculatedHeight }}  className={"tabs-panel" + (props.currentTab === 'industry' ? ' is-active' : '')}>
                <Industries />
                <div className="filters__button--wrapper">
                    {button}
                </div>
            </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'level' ? ' is-active' : '')}>
                <CourseLevel />
                {button}
            </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'duration' ? ' is-active' : '')}>
                {props.currentTab === 'duration' ? <DurationWeeksSlider/> : null}
                {button}
            </div>

        </React.Fragment>
    )
}