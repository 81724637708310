import React from 'react';
import User from './user';
import CouponCodeApi from '../api/couponCodesAPI';
import {Loader} from 'react-fields'
import {customPriceRounding} from "../utils";
import InvisibleRecaptcha from '../contact/invisibleRecaptcha';


import PaymentForm from "./paymentForm";
import PaymentTabs from "./paymentTabsStripe";
import PaymentAPI from "../api/paymentsAPI";


class Index extends PaymentForm {


    componentDidMount() {

        this.calculateStickySettings();
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);

        const btnScrollToPayent = document.querySelector('#btnScrollToPayment');
        btnScrollToPayent.addEventListener("click", () => {
            window.scrollTo({ top: this.state.stopTop, behavior: "smooth" });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.calculateStickySettings();
    };

    handleScroll = (e) => {
        const shrinkStickyBar = document.querySelector('#contentSection');
        if (window.innerWidth <= 1024) {
            if (window.pageYOffset >= this.state.stopTop) {
                shrinkStickyBar.style.paddingTop = this.state.calculatedPad;
                this.setState({
                    displayExtended: true
                });
            } else {
                shrinkStickyBar.style.paddingTop = "0";
                this.setState({
                    displayExtended: false
                });
            }
        }
    };

    sendOrderWithoutCaptcha = (onSuccess, options) => {
        console.log('sendOrderWithoutCaptcha', options);
        this.setState({isLoading: true, options: options}, () => {
            let _this = this;
            let data = this.prepareDataForSubmit();
            data['stripe_test'] = true;
            PaymentAPI.updatePayment(data, (_this.state.paymentMethod === 'paypal' ? 'paypal' : 'stripe'), this.props.purchaseType)
                .then((response) => {
                    onSuccess(response);
                }).catch(error => {
                console.log(error);
                error.json().then((value) => {
                    _this.renderErrors(value);
                    _this.invisibleCaptcha.current.reset();
                });
            });
        });
    };

    validate = async () => {
        let _this = this;
        let user = this.state.user;
        let data = this.prepareDataForSubmit();
        return await PaymentAPI.validate(data, (_this.state.paymentMethod === 'paypal' ? 'paypal' : 'stripe'), this.props.purchaseType).then((response) => {
            console.log('is valid');
            user.errors = {};
            let enrollment = response.enrollment;
            _this.setState({isLoading: false, enrollmentId: enrollment.id, user: {...user, id: enrollment.user_id}});
            return true;
        }).catch(async error => {
            console.log(error);
            error.json().then((value) => {
                _this.renderErrors(value);
            });
            return false;
        });
    };

    calculateStickySettings = () => {
        const stickyTopBarHeight = 65; // because changes it's height on scroll, was document.querySelector('.top-menu.sticky-container').clientHeight;
        const stickyPaymentSubheaderHeight = 45;
        const orderDetailsOffsetTop = document.querySelector('#pay').offsetTop;
        const stopTop = orderDetailsOffsetTop - stickyTopBarHeight - stickyPaymentSubheaderHeight;
        const calculatedPad = ((window.innerWidth <= 1024) && (window.innerWidth >= 641)) ? "353px" : "345px";
        this.setState({stopTop: stopTop, calculatedPad: calculatedPad});
    };




    onSuccessValidCode =(code) => {
        let discountAmount = this.props.price.price;
        if(code.discount_type === 'percent') {
            discountAmount = this.props.price.price/100 * code.discount_amount;
            let priceWithDiscount = customPriceRounding(this.props.price.price - discountAmount);
            discountAmount = Math.round((this.props.price.price - priceWithDiscount) * 100) / 100;
        } else if (code.discount_type === 'amount') {
            discountAmount = code[`discount_amount_${this.props.price.currency.code.toLowerCase()}`];
        }
        if (discountAmount >= this.props.price.price){
            discountAmount = this.props.price.price - 0.01
        }
        this.setState({discountAmount: discountAmount.toFixed(2), coupon: {...this.state.coupon, applied: true}})
    };

    validateCouponCode = (e) => {
        if (e) {e.preventDefault();}
        let _this = this;
        let coupon = this.state.coupon;
        this.setState({isLoading: true}, () => {
            CouponCodeApi.check(coupon.code).then((response) => {
                this.onSuccessValidCode(response);
                this.setState({isLoading: false});
            }).catch(error => {
                console.log(error);
                error.json().then((value) => {
                    coupon.errors = value;
                    _this.setState({coupon: coupon});
                    this.setState({isLoading: false});
                })
            })

        })
    };



    render() {
        const { displayExtended } = this.state;
        const curSymbol = this.props.price.currency.symbol;
        return (
            <React.Fragment>
                <div className={'grid-x'} id="contentSection">
                    <div className={"large-5 medium-12 small-12 cell"} >
                        <div className={`license-checkout__box `  + (displayExtended ? ' license-checkout__none' : ' ')}>
                            <div className={'grid-x license-checkout__widget ' }>
                                <div className="small-12 cell">
                                    <div className="flex-middle">
                                        <div className="blue-box">
                                            <div className="checkout-icon vertical-ch"/>
                                        </div>
                                        <div className="content-box">
                                            <h3 className="white mar-bot-5">FULL LIFETIME ACCESS</h3>
                                            <p className="content-box-desc">Instantly unlock all tests and your career matches!</p>
                                        </div>
                                    </div>
                                    <p className="bold mar-bot-5">WHAT YOU GET:</p>
                                    <p className="bullet-point"><span className="checkout-icon green-tick"/> Unlock all 6 tests and full results</p>
                                    <p className="bullet-point"><span className="checkout-icon green-tick"/> Access accurate career matches</p>
                                    <p className="bullet-point"><span className="checkout-icon green-tick"/> Download a personalised report</p>
                                    <div className="grid-x mar-top-20 align-end">
                                        <div className="small-9 cell">
                                            <h3 className="grey">One-time fee of</h3>
                                        </div>
                                        <div className="small-3 cell flex-end">
                                            { this.state.discountAmount > 0 ?
                                                <React.Fragment>
                                                    <div className="group__price group__price--white small-order-2 medium-order-2">
                                                        <p className="group__sup grey-line-through small-order-1 medium-order-3">
                                                            <span className="group__small">{curSymbol}{this.props.price.price}</span>
                                                        </p>
                                                        {curSymbol}{(this.props.price.price - this.state.discountAmount).toFixed(2)}
                                                    </div>
                                                </React.Fragment>

                                                : <div className="group__price group__price--white small-order-2 medium-order-2">{curSymbol}{this.props.price.price}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="checkout-note"><div className="checkout-icon note-icon-grey"/>Includes up to 2 retakes per test and lifetime database updates to recalculate your results and matches.</div>
                                </div>
                            </div>
                        </div>
                        <div className="show-for-large">
                            <div className="trustpilot-box">
                                <div className="checkout-icon trustpilot"/>
                                <span className="trustpilot-rate">4.4</span>
                            </div>
                            <div className="checkout-icon payment-icons"/>
                        </div>
                        <div className={`hide-for-large license-checkout__box  ` + (displayExtended ? ' license-checkout__is-stuck' : 'license-checkout__none')}>
                            <div className="grid-x license-checkout__widget">
                                <div className="small-7 cell license-checkout__control">
                                    <h3 className="license-checkout__widget__counter">LIFETIME ACCESS</h3>
                                </div>
                                <div className="small-5 cell flex-end">
                                    { this.state.discountAmount > 0 ?
                                        <React.Fragment>
                                            <div className="group__price group__price--white small-order-2 medium-order-2">
                                                <p className="group__sup small-order-1 medium-order-3">
                                                    <span className="grey-line-through small">{curSymbol}{this.props.price.price}</span> <span className="group__small group__small--title">{curSymbol}{(this.props.price.price - this.state.discountAmount).toFixed(2)}</span>
                                                </p>

                                            </div>
                                        </React.Fragment>

                                        : <div className="group__price group__price--white small-order-2 medium-order-2">{curSymbol}{this.props.price.price}</div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'large-7 medium-12 small-12 cell'}  id="pay">
                        <div className="license-checkout__box upgrade-form">
                            {this.state.isLoading ? <Loader/> : null}
                            <User setUser={this.setUser} user={this.state.user} />
                            <div className="line"/>
                            <div className="grid-x form-container license-checkout__tab-area">
                                <PaymentTabs header={'Billing'}
                                             paymentMethod={this.state.paymentMethod}
                                             changePaymentMethod={this.changePaymentMethod}
                                             totalPrice={this.props.price.price}
                                             discountAmount={this.state.discountAmount}
                                             fontsCss={this.props.fontsCss}
                                             cardProps={{
                                                 onChange:this.onCardChange,
                                                 user: this.state.user,
                                                 pay: this.sendOrderWithoutCaptcha,
                                                 price: this.props.price,
                                                 card: this.state.card,
                                                 show_student_discount: this.props.show_student_discount,
                                                 afterPaymentUrl: this.props.afterPaymentUrl,
                                                 discountAmount: this.state.discountAmount,
                                                 validateCouponCode: this.validateCouponCode,
                                                 coupon: this.state.coupon,
                                                 onChangeCoupon: this.onCouponChange,
                                                 errorType: null,
                                                 stripeClientSecret: this.state.stripeClientSecret,
                                                 validate: this.validate,
                                                 countryCode: this.props.countryCode
                                             }}
                                             paypalProps={{
                                                 pay: this.sendOrderWithoutCaptcha,
                                                 user: this.state.user,
                                                 price: this.props.price,
                                                 paypal: this.state.paypal,
                                                 validate: this.validate,
                                                 description: this.props.description,
                                                 show_student_discount: this.props.show_student_discount,
                                                 afterPaymentUrl: this.props.afterPaymentUrl,
                                                 onChange: this.onPayPalChange,
                                                 totalPrice: (this.props.price.price - this.state.discountAmount).toFixed(2),
                                                 discountAmount: this.state.discountAmount,
                                                 validateCouponCode: this.validateCouponCode,
                                                 coupon: this.state.coupon,
                                                 onChangeCoupon: this.onCouponChange,
                                             }}
                                             applePayProps={{
                                                 pay: this.submitApplePay,
                                                 user: this.state.user,
                                                 price: this.props.price,
                                                 applePay: this.state.applePay,
                                                 validate: this.validate,
                                                 show_student_discount: this.props.show_student_discount,
                                                 afterPaymentUrl: this.props.afterPaymentUrl,
                                                 onChange: this.onApplePayChange,
                                                 paymentDescription: "Full Access on CareerHunter",
                                                 discountAmount: this.state.discountAmount,
                                                 validateCouponCode: this.validateCouponCode,
                                                 coupon: this.state.coupon,
                                                 onChangeCoupon: this.onCouponChange,
                                                 countryCode: this.props.countryCode
                                             }}
                                />

                                <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                                    sitekey={this.props.recaptchaKey}
                                                    verifyCallback={this.sendOrder} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="small-12 cell hide-for-large">
                    <div className="trustpilot-box">
                        <div className="checkout-icon trustpilot"/>
                        <span className="trustpilot-rate">4.4</span>
                    </div>
                    <div className="checkout-icon payment-icons"/>
                </div>
            </React.Fragment>
        )

    }



}
export default Index;
