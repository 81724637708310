import React, {useState} from 'react';
import Bubble from '../base/bubble';

export default function CareerBubble(props) {

    let user = props.user;
    let interestsStatus = user ? user.interests_status : localStorage.getItem(`interests_status`);
    if (!interestsStatus){ interestsStatus = 'not_started';}

    let userStatuses = user ? [user.interests_status, user.personality_status, user.motivators_status, user.abstract_status, user.verbal_status, user.numerical_status] : [];
    let allFinished = user ? !userStatuses.includes('not_started') && !userStatuses.includes('started') : false;

    let hasFullAccess =  user ? user.full_access : false;

    const link = () => {

        switch (true) {
            case interestsStatus === 'finished' && hasFullAccess && !allFinished:
                return '/dashboard';
            case interestsStatus !== 'finished':
                return '/tests/interests/take';
            case interestsStatus === 'finished' && !hasFullAccess:
                return '/upgrade';
            default:
                return '/dashboard';
        }
    };

    let message = <React.Fragment><a href={ link()}>Continue testing</a> to get your career matches</React.Fragment>;
    if (allFinished && !hasFullAccess) {
        message = <React.Fragment><a href={ link()}>Get full access</a> to see how you match</React.Fragment>;
    } else if (interestsStatus === 'finished' && !hasFullAccess) {
        message = <React.Fragment><a href={'/upgrade'}>Keep testing</a> to see how you match!</React.Fragment>;
    } else if (interestsStatus === 'not_started') {
        message = <React.Fragment><a href={'/tests'}>Start testing</a> to see how you match!</React.Fragment>;
    }

    return(
        <div className={'flex-middle'}>
            <p>{message}</p>
            <a href={link()}>
                <Bubble hideScore={true} score={props.score} />
            </a>
        </div>

    )

}