
import React, { Component } from 'react';

class AbstractCareers extends Component {

    state = {
        careers: [],
        isLoading: true
    };



    animateBubble = (size = 4) => {
        let animated = parseInt(Math.random() * size);
        let newCareers = this.state.careers.map((career, i) => {
            if(i === animated){
                return {...career, ...{score: Math.floor(Math.random() * 50 + 50)}}
            }
            return career;
        });
        this.setState({careers: newCareers});
    };


    render() {
      return null;
    }
}

export default AbstractCareers;