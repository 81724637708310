import React from 'react'

export default function Radio(props) {

    return(
        <div className={`input-tick addAnimation ${props.className}`}>
            <label>
                <input type="checkbox" name={props.name} checked={props.checked} value={props.value} onChange={props.onChange} />
                <span className="checkbox-material"><span className="custom-checkbox"/></span>
                <span className="check-text">{props.title}</span>
            </label>
        </div>
    )
}