class BaseFunctions {

    static millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    static scrollToError() {
        let errorTag = document.querySelector('div.err, small.error');
        if (!errorTag) { return }
        let errorOffset = errorTag.offsetTop;
        if(errorOffset) {
            window.scrollTo({
                top: errorOffset - 200,
                behavior: "smooth"
            });
        }
    }

}
export default BaseFunctions;