import {actions} from 'react-fields'

class CareerTestAPI {


    static resetTest(id) {
        let url = `/api/career_tests/${id}/reset`;
        return fetch(actions.postRequest(url))
            .then(actions.status)
            .then(actions.json)
    }


    static getResult(id) {
        let url = ` /api/career_tests/${id}/result`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)

    }

    static getProgress() {
        let url = ` /api/career_tests/progress`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static saveTempResults(data) {
        let url = `/api/career_tests/temp_results`;
        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
    }



}

export default CareerTestAPI;