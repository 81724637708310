import {actions} from 'react-fields'

class MotivatorsAPI {

    static getCollection(user, onSuccess, onError) {
        const url = '/api/motivators';

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }


}

export default MotivatorsAPI;