import React from 'react';
import CareersSliderDashboard from "./slider/dashboard";
import CareersSliderSimilar from "./slider/similar";

export default function CareersWidget(props) {

    const isCompleted = props.user && props.user.interests_status === 'finished' &&
        props.user.personality_status === 'finished' && props.user.motivators_status === 'finished'
        && props.user.abstract_status === 'finished' && props.user.numerical_status === 'finished'
        && props.user.verbal_status === 'finished';

    const hasFullAccess = props.user && props.user.full_access;

    let sliderOptions = {completed: isCompleted};
    if (!!props.careers) {
        sliderOptions['careers'] = props.careers;
    } else {
        const searchOptions = props.options || {};
        sliderOptions['options'] = (isCompleted && !props.random) ? {...searchOptions, page: 1, per_page: 4} : {...searchOptions, random: 4};
    }

    return (
        <div className="grid-x widget__cards widget-section">
            <div className="large-5 cell">
                <h2 className="widget__spaced widget__spaced--cards">{props.title || 'Careers'}</h2>
            </div>
            <div className="large-7 cell">
                <div className="widget__spaced widget__spaced--cards flex large-flex-end">
                    {isCompleted ? null : <div className="capsule capsule--status capsule--widget widget-right">
                        <span className="small-icons white-tooltip-icon"/>
                        <span className="capsule__status">
                            <a href={hasFullAccess ? "/dashboard" : '/upgrade'} className="capsule__link">Get your career matches by completing all tests</a>
                        </span>
                    </div>}
                    <a className="button button--white hide-for-small-only" href="/careers">See all careers</a>
                </div>
            </div>
            <div className="small-12 cell">
                <div className="careers-slider careers-widget-slider is-overlapping">
                    {props.type === 'similar' ?
                        <CareersSliderSimilar {...sliderOptions} />
                        :
                        <CareersSliderDashboard {...sliderOptions} />
                    }
                </div>
            </div>
            <div className="small-12 show-for-small-only mar-top-20">
                <a className="button button--white" href="/careers">See all careers</a>
            </div>
        </div>
    );

}