import React, {useEffect, useRef} from 'react';
import PopUp from '../base/popup';

export default function AlreadyLoginModal(props) {
    const popUpRef = useRef();

    useEffect(() => {
        popUpRef.current.open();
        localStorage.clear();
    }, []);

    return  (
        <PopUp ref={popUpRef}>
            <div className="grid-x align-center">
                <div className="cell text-center">
                    <h3 className="mar-bot-20 mar-top-10">You’re already logged in using another email</h3>
                    <p className='popup__text'>Please note that you are already logged into a CareerHunter account using another email: <span className={'green-text'}>{props.user.email}</span>.</p>
                    <p className='popup__text'>To keep using your existing account <br/> <a className={'green-link'} href={'/dashboard'}>continue to dashboard</a></p>
                    <p className='popup__text'>To confirm your new email and account <br/> <a className={'green-link'} href={`/register?lgt=1&token=${props.token}`}>complete account here</a></p>
                </div>
            </div>
        </PopUp>
    )
}