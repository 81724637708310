import React, { Component } from 'react';
import careersAPI from '../../api/careersAPI';
import AbstractCareers from '../../careers/abstract';

import Career from '../career';
import Carousel from '../../ui/carousel';

const SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
                dots: false,
                variableWidth: true
            }
        }
    ]
};

class Featured extends AbstractCareers {

    componentDidMount() {
        careersAPI.featured().then( (result) => {
            let careers = result.map((career) => {
                return {...career,...{score: Math.floor(Math.random() * 50 + 50)}}
            });
            this.setState({careers: careers, isLoading: true});
        }).catch((error) => {
        console.log(error);
        });
    }

    render() {
        if (!this.state.isLoading || this.state.careers.length === 0) {
            return null
        }

        return (
            <Carousel items={this.state.careers} settings={SLIDER_SETTINGS}
                      itemProps={{hideScore: false, hideMatch: true}}
                      component={Career} />
        );
    }

}

export default Featured;