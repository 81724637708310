import React from 'react';

export default function ConfirmationSentModal(props) {
    return  (
        <div className="grid-x align-center">
            <div className="small-12 cell text-center">
                <div className="main-test__top">
                    <h3>We've resent your link</h3>
                </div>
                <p>An account confirmation was sent to {props.email}, please check your inbox.</p>
            </div>
        </div>
    )
}