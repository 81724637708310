import React from 'react';
import LazyLoad from 'react-lazyload';
import TrustPilotSlider from './trustpilotSlider';

export default function lazyTrustPilotSlider(props) {

    return(
        <LazyLoad>
            <TrustPilotSlider {...props} />
        </LazyLoad>
    )
}