import React from "react";
import GoogleLogin from 'react-google-login';
import OmniauthApi from "../../api/omniauthAPI";
import {tempResultsToHash} from "../../careerTestUtils";

export default function GoogleButton(props) {

    const callback = (response, status) => {
        console.log('google', status, response);
        if (!response.code) { console.error('No google response code'); return; }
        props.onCallback(() => {
            const responseToParams = props.saveAnswers ? {...response, ...tempResultsToHash()} : response;
            OmniauthApi.loginWithProvider('google', responseToParams, props.onSuccess, props.onError);
        });
    };

    return (
        <GoogleLogin
            clientId={props.clientId}
            responseType="code"
            onSuccess={(response) => callback(response, 'success')}
            onFailure={(response) => callback(response, 'failure')}
            autoLoad={false}
            render={renderProps => (
                <button onClick={renderProps.onClick} className="button button--clear">
                    <span className={props.icon || 'small-icons is-left google-plus'} />
                    {props.label || 'Sign up using'} <span>Google</span>
                </button>
            )}
        />
    )

}