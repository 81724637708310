import React from 'react';

export default function studentWelcomeModal(props) {

    return  (
           <div className="grid-x align-center">
               <div className="cell text-center">
                   <h3 className="mar-bot-10">Welcome to CareerHunter!</h3>
                   <p className='popup__text'>You have been given access to our tests where we use a sophisticated algorithm to analyze and calculate your results, which are then processed to generate personalized insights and career recommendations.</p>
                   <p className='popup__text'>Please note that you are only permitted to take each test one time. If you need to re-take any test, you will need to contact your administrator.</p>
                   <p className='popup__text'>Good luck with the tests!</p>
               </div>
           </div>
    )

}