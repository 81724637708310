import React from 'react';
import {actions, InputTypeField, Loader} from "react-fields";
import LoginApi from '../api/loginAPI';
import HoverButton from "../buttons/HoverButton";

const FIELDS = {email: '', errors: {}};

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: FIELDS,
            isLoading: false,
            sent: false
        };
    }

    onChangeField = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
    };

    onClose = () => {
        document.location='/';
    };

    onSuccess = () => {
        this.setState({isLoading: false, user: FIELDS, sent: true});
    };

    onError = (errors) => {
        console.log(errors);
        if (errors)
            this.setState({isLoading: false, user: {...this.state.user, errors: errors}});
        else {
            this.setState({isLoading: false});
        }
    };

    onSubmit = () => {
        this.setState({isLoading: true}, () => {
            LoginApi.recoverPassword(this.state.user, this.onSuccess, this.onError);
        });
    };

    render() {
        let user = this.state.user;

        if (this.state.sent) {
            return (<div className={`grid-x align-center align-middle${this.props.expiredToken ? ' form-container' : ''}`}>
                <div className="small-12 cell text-center">
                    <h3 className="mar-bot-10">Check your inbox</h3>
                    <p className='popup__text'>We sent you an email with a link to reset your password.
                        Click on the link and follow the on-screen instructions to complete the process.
                        If you don’t see the email, please check your junk or promotional folders.</p>
                </div>
            </div>);
        }

        return  (
            <div className='form-container'>
                {this.state.isLoading ? <Loader /> : null}
                {this.props.expiredToken ?
                    <React.Fragment>
                        <h3>Reset Password</h3>
                        <h4 className='popup__text'>Your reset password token has expired! Please request a new one:</h4>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h3>Forgot your password?</h3>
                        <p className='popup__text'>Please enter the email address you used to create your CareerHunter account to reset your password.</p>
                    </React.Fragment>
                }

                <InputTypeField type={"email"} name={"email"}
                                placeholder={"Email*"}
                                errorMessage={user.errors.email||user.errors.base}
                                value={user.email}
                                onChange={this.onChangeField}/>

                <div className="medium-text-center text-right">
                    <HoverButton onClick={this.onSubmit}>
                        Send
                    </HoverButton>
                </div>
            </div>
        )
    }

}
export default ForgotPassword;