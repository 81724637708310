import {actions} from 'react-fields'

export default class ShareAPI {

    static send(form, captchaToken, onSuccess, onError) {
        const url = '/api/checkout_shares';
        let data = {
            message: {
                email: form.email,
                agree_terms: form.agreeTerms
            },
            g_recaptcha_response: captchaToken
        };
        console.log('share api');
        console.log(url, data);
        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async(response) => {
                onSuccess(response);
            }).catch(async error => {
                if (error.status === 422) {
                    error.json().then(function (value) {
                        onError(value);
                    });
                } else {
                    console.error(error);
                    onError({base: 'Server error'});
                }
            });
    }


}