import React, { useEffect } from "react";

const TooltipPopover = ({ children, coords, updateTooltipCoords, className }) => {

    useEffect(() => {
        window.addEventListener("resize", updateTooltipCoords);
        return () => window.removeEventListener("resize", updateTooltipCoords);
    }, []);

    return (
        <div style={{ ...coords }} className={`tooltip tooltip--popover ${className ? className : ''}`}>
           {children}
        </div>
    );
};

export default TooltipPopover;
