import React, {useEffect, useState, useCallback} from 'react';
import careersAPI from "../../api/careersAPI";
import Accordion from '../accordionWithCheckBox';
import Label from "../roundedLabel";
import {addFilterValue, deleteFilterValue} from "../../actions/filterActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

export default function CareersAccordion(props) {

    let [careers, setCareers] = useState({});
    let [industries, setIndustries] = useState({});

    const dispatch = useDispatch();

    const careersSelector = createSelector(
        state => state.filters.selected,
        (selectedFilters) => selectedFilters.filter(filter => filter.type === 'career')
    );

    const selectedCareers = useSelector(state => careersSelector(state));
    let selectedIndustries = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'industry'));
    let initialCareerFilters = useSelector(state => state.filters.initialFilters['careers']);
    let initialIndustryFilters = useSelector(state => state.filters.initialFilters['industries']);

    useEffect(
        () => {
            careersAPI.searchCareers().then((result) => {
                let groups = groupBy(result.career_scores, 'industry', 'name');
                let industries = {};
                result.career_scores.map((career) => {industries[career.industry.name] = career.industry});
                setIndustries(industries);
                setCareers(groups);
            }).catch(error => {
                console.log(error);
            });
        }, []
    );

    useEffect(
        () => {
            if (careers.length === 0 || !initialCareerFilters) { return; }
            let careersArray = [].concat.apply([], Object.values(careers));
            initialCareerFilters.map((filter) => {
                let item = careersArray.find(career => career.id === parseInt(filter));
                if (item) {
                    dispatch(addFilterValue(item, 'career'))
                }
            });
        }, [careers, initialCareerFilters]
    );

    useEffect(
        () => {
            if (industries.length === 0 || !initialIndustryFilters) { return; }
            let industriesArray = Object.values(industries);
            initialIndustryFilters.map((filter) => {
                let item = industriesArray.find(industry => industry.id === parseInt(filter));
                if (item) {
                    dispatch(addFilterValue(item, 'industry'))
                }
            });
        }, [industries, initialIndustryFilters]
    );


    const toggleIndustry = (e, industryTitle) => {
        if(e.target.checked){
            dispatch(addFilterValue(industries[industryTitle], 'industry'));

        } else {
            dispatch(deleteFilterValue(industries[industryTitle], 'industry'));
        }
    };

    return (
        <React.Fragment>
            {Object.keys(careers).map((industry, i) => {
                return (<Accordion key={i} title={industry} toggleSelect={toggleIndustry}
                                   selected={selectedIndustries.filter((ind) => ind.name === industry).length > 0}>
                            <div className="grid-x grid-padding-x pad-bot-10">
                                {careers[industry].map((career) => {
                                  return <Label key={career.id}
                                                option={career}
                                                hasImage={true}
                                                hasScore={true}
                                                disabledForSelect={!!props.disabledForSelect}
                                                selectChange={props.update}
                                                add={() => dispatch(addFilterValue(career, 'career'))}
                                                delete={() => dispatch(deleteFilterValue(career, 'career'))}
                                                selected={!!selectedCareers.find((ca) => ca.id === career.id)} />
                                })}
                            </div>
                        </Accordion>)
            })}

        </React.Fragment>
    )
}

const groupBy = (xs, ...keys) => {
    return xs.reduce((rv, x) => {
        (rv[x[keys[0]][keys[1]]] = rv[x[keys[0]][keys[1]]] || []).push(x);
        return rv;
    }, {});
};

