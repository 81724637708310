import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store/store';
import Form from "./form";

export default function Index(props) {


    return (
        <Provider store={store}>
            <Form {...props}/>
        </Provider>
    );
}