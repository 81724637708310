import React, { useRef, useState} from 'react';
import StartTestButton from '../buttons/startTestButton';
import HoverButton from "../buttons/HoverButton";
import {FadeInSection} from "../fadeIn";

function StartTestResultsButton (props) {

    const [testStatus, setStatus] = useState(props.status);

    let status = testStatus || props.status;

    let anyFinished = props.user &&
        (props.user[`interests_status`] === 'finished' || props.user[`motivators_status`] === 'finished' || props.user[`personality_status`] === 'finished');


    function button() {
         if (props.user && (props.user.full_access || props.user[`${props.test.code}_access`])) {
             switch (status) {
                 case 'started':
                     return <FadeInSection><HoverButton href={`/tests/${props.test.path}/take`} color={'white'}>Continue</HoverButton></FadeInSection>;
                 case 'finished':
                     return <FadeInSection><HoverButton href={`/tests/${props.test.path}/result`} color={'white'}>Results</HoverButton></FadeInSection>;
                 default:
                     return <FadeInSection><StartTestButton user={props.user}
                                             confirmed={props.confirmed}
                                             test={{code: props.test.code, path: props.test.path}}
                                             buttonName={"Start"}
                                             className={"button button--green button--with-icon"}/></FadeInSection>
             }
         } else if(!props.test.timed && !anyFinished) {
             return <FadeInSection><StartTestButton user={props.user}
                                     confirmed={props.confirmed}
                                     test={{code: props.test.code, path: props.test.path}}
                                     buttonName={"Try for Free"}
                                     className={"button button--green button--with-icon"}/></FadeInSection>
        }
         else {
             return  <FadeInSection><HoverButton href={`/checkout/${props.test.code}`} color={"button button--green"}>Get Access</HoverButton></FadeInSection>;
         }
    }


    return (
        button()
    );
}
export default StartTestResultsButton;