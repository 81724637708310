import React, {Fragment, useRef} from 'react';
import PopUp from "./base/popup";
import HoverButton from "./buttons/HoverButton";

export default function CopyToClipboard(props) {

    const inputToCopy = useRef(null);
    const modalSuccessCopy = useRef(null);

    const onClick = (e) => {
        e.preventDefault();
        inputToCopy.current.select();
        document.execCommand('copy');
        e.target.focus();
        modalSuccessCopy.current.open();
    };

    return (
        <Fragment>
            <input ref={inputToCopy} type="text" name={props.name} value={props.value} readOnly />
            {props.hoverButton ? <HoverButton onClick={onClick}>Copy</HoverButton> : <a className="button cv-coupon-button" onClick={onClick}>Copy</a> }

            <PopUp ref={modalSuccessCopy} className={'add-animation '}>
                <div className="grid-x align-center">
                    <div className="cell">
                        <h3 className="cv-writing-copied--title">{props.msg}</h3>
                    </div>
                    <div className="cell mar-top-20 text-center">
                        <HoverButton classNames={'button--big'} color={'green'} onClick={() => modalSuccessCopy.current.close()}>Close</HoverButton>
                    </div>
                </div>
            </PopUp>
        </Fragment>
    );

};