import React from 'react';

export default function UnconfirmedButton(props) {
    return  (
            <div className="capsule capsule--unconfirmed capsule--expand" onClick={props.onClick}>
                <span className="small-icon capsule__icon envelope-white"/>
                <p className="capsule__text">Unconfirmed account, please check your inbox.</p>
            </div>

    )
}