import React from 'react';
import Suitability from "../filters/suitability/index";
import SalarySlider from '../filters/salarySlider';
import GrowthSlider from '../filters/growthSlider';
import EducationLevel from '../filters/educationLevel';
import HoverButton from "../../buttons/HoverButton";
import SearchInput from "../filters/searchInput";
import Industries from "../filters/industries";
import Personalities from "../filters/personalities";
import Motivators from "../filters/motivators";
import MatchesSlider from "../filters/matchesSlider";
import GetStartedButton from "../../buttons/getStartedButton";

export default function TabsContentMobile(props) {

    const currentTab = props.currentTab;

    const button = (<div className="flex-center filters__apply">
        <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
    </div>);

    return (
        <React.Fragment>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'industry' ? ' is-active' : '')}>
                <Industries/>
                {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'personality' ? ' is-active' : '')}>
                <Personalities/>
                {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'motivator' ? ' is-active' : '')}>
                <Motivators/>
                {button}
            </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'matches' ? ' is-active' : '')}>
                {props.profileCompleted ?
                    <React.Fragment>
                        {currentTab === 'matches' ? <MatchesSlider/> : null}
                        {button}
                    </React.Fragment> :
                    <React.Fragment>
                        <div className="full-height">
                            <h2 className="no-matches-text text-center">You don't have any career matches yet!</h2>
                            <a className="button button--with-icon button--green button--green--medium" href="/upgrade">Get Full
                                Access <span className="small-icons next-arrow-white-l"/><span
                                    className="button__hovered"/></a>

                        </div>
                        <div className="flex-center filters__apply">
                            <GetStartedButton user={props.user} />
                        </div>
                    </React.Fragment> }
            </div>

                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'education' ? ' is-active' : '')}>
                    <EducationLevel />
                    {button}
                </div>

                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'salary' ? ' is-active' : '')}>
                    {currentTab === 'salary' ? <SalarySlider/> : null}
                    {button}
                </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'growth' ? ' is-active' : '')}>
                {currentTab === 'growth' ? <GrowthSlider/> : null}
                {button}
            </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'qsearch' && window.innerWidth <= 1440 ? ' is-active' : '')}>
                <div  className="responsive-search">
                    <SearchInput showFilters={true} showDoneButton={true} afterSearch={props.closeTab} />
                </div>
            </div>

        </React.Fragment>
    )
}