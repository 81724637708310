import React, { Component } from 'react';
import shuffle from 'shuffle-array';
import careersAPI from '../../api/careersAPI';
import AbstractCareers from '../../careers/abstract';
import Career from '../career';

import Carousel from '../../ui/carousel';

class Dashboard extends AbstractCareers {

    state = {
        completed: this.props.completed || (this.props.user && this.props.user.interests_status === 'finished' &&
            this.props.user.personality_status === 'finished' && this.props.user.motivators_status === 'finished'
            && this.props.user.abstract_status === 'finished' && this.props.user.numerical_status === 'finished'
            && this.props.user.verbal_status === 'finished'),
        careers: []
    };

    componentDidMount() {
        if (!!this.props.careers) {
            this.setCareers(this.props.careers);
        } else {
            this.loadCollection().then((careers) => {
                this.setCareers(careers);
            });
        }
    }

    setCareers = (careers) => {
        this.setState({careers: careers});
    };

    loadCollection = async () => {
        let careers = [];
        await careersAPI.similarCareers(this.props.options || {}).then( (result) => {
                careers = result.career_scores;
            }).catch((error) => {
                console.log(error);

            });

        if (!this.state.completed){
            careers = this.setRandomScores(shuffle(careers))
        }

        careers = careers.slice(0,4);
        return careers;

    };

    setRandomScores = (careers) => {
        return careers.map((career) => {
            let score = career.score > 0 ? career.score : Math.floor(Math.random() * 100);
            return {...career,...{score: score}}
        });
    };



    render() {
        if (this.state.careers.length === 0) {
            return null;
        }
        return (
            <div className="grid-x">
                {this.state.careers.map((career, index) => (
                    <div className="large-3 medium-6 small-12 cell show-for-large">
                        <Career key={`careerDahsboardA${index}`}
                                hideScore={!this.state.completed}
                                item={career} />
                    </div>
                    )
                )}
                <div className="small-12 cell hide-for-large">
                    <div className="careers-slider">
                        <Carousel items={this.state.careers}
                                  itemProps={{hideScore: !this.state.completed}}
                                  component={Career} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;