export default {
   development: {
       client_id: 'AXZoQPb1YMXZeHO5xTCa3iasPNcnj2fTxwLwK4_ACVl1CXqiHukI0b17R1Iaj7-kIWiBSKwIC2vCT2od'
   },
    staging: {
        client_id: 'AXZoQPb1YMXZeHO5xTCa3iasPNcnj2fTxwLwK4_ACVl1CXqiHukI0b17R1Iaj7-kIWiBSKwIC2vCT2od'
    },
    production: {
        client_id: 'ATsgN9d6cpwTqQ5xucSdf5eanA_CgO_sX0dSPYbtfGs_nmRO_UTMw7i6hnJ7G3wconxmC-Pu3KrcG-9_'
    }
}[process.env.RAILS_ENV]