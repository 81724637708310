import React from 'react';
import ResendEmailLink from '../../user/resendEmailLink';

export default function SavedModal(props) {
    return  (
        <div className="grid-x align-center">
            <div className="small-12 cell text-center">
                <div className="popup__title popup__title--centered">
                    <span className="small-icon checked"/>
                    <h3 className="mar-bot-10">Almost done!</h3>
                </div>
                <p className='popup__text'>
                    Please check your inbox and click the link in the email we sent you to finish setting up your account and permanently save your progress.
                    If you don’t see the email we sent you, please check your junk or promotional folders.
                    {props.showResend ?
                        <React.Fragment>If you haven't received email in 5 minutes you can <a href="/contact">report</a> or <ResendEmailLink btnName={'resend'} user={props.user} /></React.Fragment> : null}
                </p>
                {props.showResend ?
                    <a href={'#'} onClick={(e) => {e.preventDefault(); props.closeModal()}} className="button button--green">Close</a> : null}
            </div>
        </div>
    )
}