import React from 'react';
import LazyLoad from 'react-lazyload';
import CareersWidget from './widget';

export default function LazyWidget(props) {

    return (
        <LazyLoad>
            <CareersWidget {...props} />
        </LazyLoad>
    );

}