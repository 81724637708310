import React from "react";
import Checkbox from './checkbox';

class NewsletterCheckboxField extends Checkbox {

    state = {
        className: "input-tick--inline",
        position: this.props.position || 'left',
        animationEnabled: false
    };

    renderLabel = () => {
      return (<span className="agreeText">
          Sign up to <a href="https://dose.careeraddict.com/" target="_blank">The Dose</a> for career hacks - sent 3x week
      </span>)
    };

}
export default NewsletterCheckboxField;