import React, {useState, useEffect} from 'react';
import DashboardJob from './dashboardJob';
import jobsApi from '../api/jobsAPI';
import {FadeInSection} from "../fadeIn";

export default function jobsDashboard(props) {

    let [jobs, setJobs] = useState([]);
    let [isLoading, setLoading] = useState(true);

    useEffect(
        () => {
            loadCollection();
        }, []
    );

    const loadCollection = () => {
        jobsApi.relevant().then((result) => {
            setJobs(result);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    if (isLoading) return null;

    const groups = Array.from(
        new Array(Math.ceil(jobs.length / 4)),
        (_, i) => jobs.slice(i * 4, i * 4 + 4));

    if(props.completed) {
        let sectors = groups.pop();

        return  <React.Fragment>
            <div className="grid-x aligned-space">
                <div className="large-2 medium-12 small-12 cell">
                    <h2 className="dashboard__tests before-fade-in fade-in">My jobs</h2>
                </div>
            </div>
            <div className="grid-x aligned-space">
                <div className="large-3 medium-12 small-12 cell">
                    <h3 className="dashboard__recommended before-fade-in fade-in">Recommended job searches</h3>
                </div>
                <div className="large-9 medium-12 small-12 cell dashboard__btns">
                    <p className='dashboard__tip'><span className='small-icon white-tooltip-icon'/>Your top job suggestions based on your scores</p>
                    <a href="/jobs" className="button button--white before-fade-in fade-in"
                       style={{margin:"0 0 20px 10px"}}> Search for jobs<span className="button__hovered"/></a>
                </div>
            </div>
            {
                groups.map((group, index) => {
                    return <FadeInSection key={`section${index}`}>
                        <div className="grid-x aligned-margin">
                            {group.map((job, index) => {
                                return <DashboardJob key={`jobsDahsboard${index}`} job={job}
                                                     searchParams={job.search_terms}/>
                            })}

                        </div>
                    </FadeInSection>
                })
            }
            <div className="grid-x aligned-space mar-top-10">
                <div className="large-3 medium-12 small-12 cell">
                    <h3 className="dashboard__recommended before-fade-in fade-in">Recommended sectors</h3>
                </div>
                <div className="large-9 medium-12 small-12 cell dashboard__btns">
                    <p className='dashboard__tip'><span className='small-icon white-tooltip-icon'/>Your top job sectors based on your scores</p>
                    <a href="/jobs" className="button button--white before-fade-in fade-in"
                       style={{margin:"0 0 20px 10px"}}> Search for jobs<span className="button__hovered"/></a>
                </div>
            </div>

                 <FadeInSection>
                    <div className="grid-x aligned-margin">
                        {sectors.map((job, index) => {
                            return <DashboardJob key={`jobsDahsboard${index}`} job={job}
                                                 hideSalary={true}
                                                 how_match={'industry'}
                                                 searchParams={job.search_terms}/>
                        })}
                    </div>
                </FadeInSection>


        </React.Fragment>
    } else {
        return <React.Fragment>
            <div className="grid-x aligned-space">
                <div className="large-2 medium-12 small-12 cell">
                    <h2 className="dashboard__tests before-fade-in fade-in">My jobs</h2>
                </div>
                <div className="large-10 medium-12 small-12 cell dashboard__btns">
                    <p className='dashboard__tip'>
                        <span className='small-icon white-tooltip-icon'/>
                        {props.hasFullAccess ? 'Complete all tests to get relevant job suggestions' : 'Available only with full access'}
                    </p>
                    <a href="/jobs" className="button button--white before-fade-in fade-in"
                       style={{margin:"0 0 20px 10px"}}> Search for jobs<span className="button__hovered"/></a>
                </div>
            </div>
            {
                groups.map((group, index) => {
                    return <FadeInSection key={`section${index}`}>
                        <div className="grid-x aligned-margin">
                            {group.map((job, index) => {
                                return <DashboardJob key={`jobsDahsboard${index}`} job={job}
                                                     hideSalary={true}
                                                     searchParams={job.search_terms}/>
                            })}
                        </div>
                    </FadeInSection>
                })
            }
            </React.Fragment>


    }
}