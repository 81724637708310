import {actions} from 'react-fields'

class UserAPI {

    static register(user, onFly = false, token = null) {
        const url = onFly ? '/register-on-fly' : '/register';
        let testData = {};
        ['interests', 'personality', 'motivators'].map((testCode) => {
            if(localStorage.getItem(testCode)) {
                let tempAnswers = localStorage.getItem(testCode);
                tempAnswers = JSON.parse(tempAnswers);
                let answers = [];
                tempAnswers.filter((page) => page.answered ).map((page) => {
                    page.statements.map((statement) => {
                        answers.push({statement_id: statement.id, score: 5 - statement.order, page: page.page})
                    })
                });
                testData[`${testCode}_answers`] = answers;
            }
        });

        let interests_status = localStorage.getItem('interests_status') || 'not_started';
        let personality_status = localStorage.getItem('personality_status') || 'not_started';
        let motivators_status = localStorage.getItem('motivators_status') || 'not_started';

        let data = { user: {
                email: user.email,
                email_confirmation: user.email_confirmation,
                name: user.name,
                country_id: user.country_id,
                age_group: user.age_group,
                gender: user.gender,
                career_level_id: user.career_level_id,
                education_level_id: user.education_level_id,
                password: user.password,
                password_confirmation: user.password_confirmation,
                agree_terms: user.agree_terms,
                interests_status: interests_status,
                personality_status: personality_status,
                motivators_status: motivators_status,
                uid: user.uid,
                provider: user.provider,
                newsletter_subscription: user.newsletter_subscription
            }, interests_answers: testData.interests_answers,
            personality_answers: testData.personality_answers,
            motivators_answers: testData.motivators_answers,
            g_recaptcha_response: token};

        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }


    static completeAccount(user, token) {
        const url = '/complete-account';

        let data = { user: {
                name: user.name,
                password: user.password,
                on_profile: true,
                password_confirmation: user.password_confirmation,
                country_id: user.country_id,
                age_group: user.age_group,
                gender: user.gender,
                career_level_id: user.career_level_id,
                education_level_id: user.education_level_id
            }, token: token };

        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static update(user, onSuccess, onError) {
        const url = '/api/user';
        let data = { user: {
                name: user.name,
                country_id: user.country_id,
                age_group: user.age_group,
                gender: user.gender,
                career_level_id: user.career_level_id,
                education_level_id: user.education_level_id,
                receive_promo_emails: user.receive_promo_emails,
                receive_account_emails: user.receive_account_emails,
                receive_advice_emails: user.receive_advice_emails,
                receive_test_related_emails: user.receive_test_related_emails,
                receive_jobs_emails: user.receive_jobs_emails,
                receive_study_emails: user.receive_study_emails,
                need_post_update_account_setup: false
            } };

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static changeEmail(user, onSuccess, onError) {
        const url = '/register';
        let data = {user: { email: user.email, current_password: user.current_password}};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static changePassword(user, onSuccess, onError) {
        const url = '/register';
        let data = {user: {
                    password: user.new_password,
                    password_confirmation: user.password_confirmation,
                    current_password: user.current_password}};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static delete(onSuccess, onError) {
        const url = 'api/user';
        fetch(actions.deleteRequest(url, {} ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {
                console.log('response success');
                onSuccess();
        }).catch(error => {
            console.log('response error');
            onError();
        });
    }

    static validate(userDetails) {
        const url = '/api/user/validate';
        delete userDetails.errors;
        let data = { user: userDetails};
        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

}

export default UserAPI;