import React from "react";

export default function Review(props) {

    const review = props.review;
    const backgroundSettings = {
        backgroundImage: `url("${review.image.original}")`,
        backgroundSize: "cover",
        backgroundPosition: "center"};

    return (
        <div className="review__slide" data-more-less-container data-more-less-short-fade>
            <div className="grid-x">
                <div className="small-10 cell">
                    <h3 className="review__slide-name mar-bot-20">{review.name}
                        <div className="review__icons">
                            {review.linkedin && <a href={review.linkedin} rel={'nofollow'} target={'_blank'} className="social-icon linkedin"/>}
                            {review.twitter && <a href={review.twitter} rel={'nofollow'} target={'_blank'} className="social-icon twitter"/>}
                            {review.website && <a href={review.website} rel={'nofollow'} target={'_blank'} className="social-icon web"/>}
                        </div>
                    </h3>
                    <h3 className="review__slide-details white">{review.position}</h3>
                    <h4 className="review__slide-details review__company mar-bot-20">{review.company}</h4>
                </div>
                <div className="small-2 cell flex-end">
                    <div className="review__pic" style={backgroundSettings}/>
                </div>
            </div>
            <p className="review__desc" data-more-less-content="">{review.description}</p>
            <p data-more-less-ctrl className="how__illustrations--more">Read more</p>
        </div>
    )
};