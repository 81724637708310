import React from 'react';
import Industries from '../../filters/industries';
import MatchesSlider from '../../filters/matchesSlider';

import EducationLevel from '../../filters/educationLevel';
import GetStartedButton from "../../../buttons/getStartedButton";
import HoverButton from "../../../buttons/HoverButton";
import Personalities from "../personalities";
import Motivators from "../motivators";

export default function TabsContent(props) {

    const currentTab = props.currentTab;

    const button = (<div className="flex-center filters__apply">
        <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
    </div>);

    return (
        <React.Fragment>
                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'industry' ? ' is-active' : '')}>
                    <Industries/>
                    {button}
                </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'personality' ? ' is-active' : '')}>
                <Personalities/>
                {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'motivator' ? ' is-active' : '')}>
                <Motivators/>
                {button}
            </div>

                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'matches' ? ' is-active' : '')}>
                    {props.profileCompleted ?
                        <React.Fragment>
                            {currentTab === 'matches' ? <MatchesSlider/> : null}
                            {button}
                        </React.Fragment> :
                        <React.Fragment>
                            <div className="full-height">
                                <h2 className="no-matches-text text-center">You don't have any career matches yet!</h2>
                                <a className="button button--with-icon button--green button--green--medium" href="/upgrade">Get Full
                                    Access <span className="small-icons next-arrow-white-l"></span><span
                                        className="button__hovered"></span></a>

                            </div>
                            <div className="flex-center filters__apply">
                                <GetStartedButton user={props.user} />
                            </div>
                        </React.Fragment> }
                </div>


        </React.Fragment>
    )
}