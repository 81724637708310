import {actions} from 'react-fields'

class LoggingApi {

    static create(log_name, details = {}) {
        const url = '/api/logging';

        let data = {log_name: log_name, details: details};
        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)

    }
}

export default LoggingApi;