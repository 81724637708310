import React from "react";
import FacebookLogin from './facebookButton';
import GoogleLogin from './googleButton';
import LinkedInButton from './linkedinButton';

import Settings from './settings';

export default function OmniAuthButtons(props) {

    return (
        <React.Fragment>
            <GoogleLogin
                clientId={Settings.googleID} onCallback={props.onCallback}
                onSuccess={props.onSuccess} onError={props.onError} saveAnswers={props.saveAnswers}
                label={props.label} />
            {/*<FacebookLogin*/}
                {/*appId={Settings.facebookID} onCallback={props.onCallback}*/}
                {/*onSuccess={props.onSuccess} onError={props.onError} saveAnswers={props.saveAnswers}*/}
                {/*label={props.label} />*/}
            {/*<LinkedInButton*/}
                {/*onCallback={props.onCallback}*/}
                {/*onSuccess={props.onSuccess} onError={props.onError} saveAnswers={props.saveAnswers}*/}
                {/*icon={props.linkedinIcon} label={props.label} />*/}
        </React.Fragment>
    )

}