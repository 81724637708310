import React, {useState} from "react";
import Rate from './rate';
import Sent from './sent';

export default function FeedbackIndex(props) {

    console.log(props.rate);
    const [step, setStep] = useState('rate');
    const [coupon, setCoupon] = useState(null);
    const [rate, setRate] = useState(props.rate);

    const onSuccessSentFeedback = (response) => {
        if (response.coupon && response.coupon.promo_code && !props.hideCoupon) {
            setCoupon(response.coupon.promo_code);
        }
        setStep('sent');
    };

    const renderStep = () => {
        switch(step) {
            case 'sent':
                return <Sent close={props.close} rate={rate} full_access={props.full_access} coupon={coupon} />;
            default:
                return <Rate onSuccess={onSuccessSentFeedback} close={props.close} recaptchaKey={props.recaptchaKey}
                             rate={props.rate} onRateChange={setRate} hideCoupon={props.hideCoupon} />;
        }
    };

    return (
        <div className="grid-x align-center">
            <div className="cell small-12 text-center">
                {renderStep()}
            </div>
        </div>
    )

}