import {actions} from 'react-fields'

class IndustryAPI {

    static getCollection(options) {
        let url = '/api/industries';
        if (options) {
            url = `${url}?${actions.paramsToURI(options)}`
        }

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }


}

export default IndustryAPI;