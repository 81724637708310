import React, {useEffect, useRef} from 'react';

import PopUp from "../base/popup";
import FeedbackModal from '../feedback/modal/index';


export default function  FeedBackModal(props) {

    const feedbackModal = useRef(null);

    useEffect(() => {

        feedbackModal.current.open();
        window.history.replaceState({}, document.title, "/dashboard" );
    }, []);

    return (
        <PopUp ref={feedbackModal} className={'feedback'}>
            <FeedbackModal close={() => feedbackModal.current.close()} recaptchaKey={props.recaptchaKey} />
        </PopUp>
    )


};
