import React from 'react';
import HoverButton from "../buttons/HoverButton";

export default class GetStartedButton extends React.Component {


    renderButton = () => {
        let user = this.props.user;
        let interestsStatus = user ? user.interests_status : (typeof localStorage !== 'undefined' ? localStorage.getItem(`interests_status`) : '');
        if (!interestsStatus){ interestsStatus = 'not_started';}
        if (this.props.deleted){
            interestsStatus = 'not_started';
        }

        let userStatuses = user ? [user.interests_status, user.personality_status, user.motivators_status, user.abstract_status, user.verbal_status, user.numerical_status] : [];
        let allFinished = user ? !userStatuses.includes('not_started') && !userStatuses.includes('started') : false;
        let anyStarted = user ? userStatuses.includes('started') || userStatuses.includes('finished') : interestsStatus !== 'not_started';

        let hasFullAccess =  user ? user.full_access : false;
        let btnProps = {};
        if (this.props.className) {
            btnProps['className'] = this.props.className;
        }


        let upgradeLink = <HoverButton href={'/upgrade'} {...btnProps}  color={'green'} className={'button--green--big'}>
                Continue <span className="hideText">Testing</span>
            </HoverButton>;

        const testLink = <HoverButton href={'/tests/interests/take'} {...btnProps}  color={'green'} className={'button--green--big'}>
            {anyStarted ? <React.Fragment>Continue<span className="hideText">&nbsp;Testing</span></React.Fragment> : 'Get Started'}
        </HoverButton>;

        const dashboardLink = <HoverButton href={'/dashboard'} {...btnProps}  color={'green'} className={'button--green--big'}>
             Continue<span className="hideText">&nbsp;Testing</span>
        </HoverButton>;

        if (this.props.home) {
            upgradeLink = <HoverButton href={'/get-access'} color={'white'} className={'button--with-icon button--white--big'} >
                Get Access <span className={'small-icons green-small-lock-s'} />
            </HoverButton>
        }

        const homePage = <React.Fragment>
            <HoverButton href={'/tests'} color={'green'} className={'button--with-icon button--green--big'} >
                Try for FREE<span className="small-icons next-arrow-white-s"/>
            </HoverButton>
            <p className="banner-top--home-or before-fade-in fade-in">or</p>
            {upgradeLink}
        </React.Fragment>;

        const extraPad = <span className="padR15"/>;
        switch (true) {
            case interestsStatus === 'finished' && hasFullAccess && !allFinished:
                return dashboardLink;
            case this.props.home && interestsStatus !== 'finished' && !hasFullAccess:
                return homePage;
            case interestsStatus !== 'finished':
                return testLink;
            case interestsStatus === 'finished' && !hasFullAccess:
                return upgradeLink;
            default:
                return extraPad;
        }
    };

    render() {
        if (this.props.home) {
            return (<div className='banner-top--home-btns'>
                {this.renderButton()}
            </div>)
        }
       return  (this.renderButton())
    }




}
