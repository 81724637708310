import React, {useEffect, useRef} from 'react';
import PopUp from '../base/popup';
import HoverButton from "../buttons/HoverButton";

export default function systemUpdatePopUp() {
    const popUpRef = useRef();

    useEffect(() => {
        const cookie_name = 'systemUpdatePopUpShown';
        let match = document.cookie.match(new RegExp(cookie_name + '=([^;]+)'));
        if(match == null) {
            document.cookie = cookie_name + "=1";
            popUpRef.current.open();
        }
    }, []);

    return  (
            <PopUp ref={popUpRef} className={'system-update-popup'}>
                <div className="grid-x align-center">
                    <div className="cell large-6 medium-12 small-12 large-order-1 small-order-2 system-update-popup__content">
                        <h3 className="mar-bot-10">We've updated our platform!</h3>
                        <p className='popup__text'>
                            We have updated CareerHunter. Some of our tests have gone through a change and will need to be retaken. Although you can't pick up where you left off, if you completed all tests, your old results are available for download as a report in your account settings.
                        </p>
                        <div className={"system-update-popup__buttons-line"}>
                            <HoverButton onClick={() => popUpRef.current.close()}>OK</HoverButton>
                            <HoverButton href={'/settings'} color={'trustpilot'}>Go to my account settings</HoverButton>
                        </div>
                    </div>
                    <div className="cell large-6 medium-12 small-12 large-order-2 small-order-1">
                        <div className="system-update-popup__image"></div>
                    </div>
                </div>
            </PopUp>
    )

}