import {actions} from 'react-fields'

export default class OmniauthApi {

    static loginWithProvider(provider, response, onSuccess, onError) {
        let url = `/auth/${provider}/callback`;
        let token = document.querySelector("meta[name='csrf-token']").content;
        const options = {
            method: 'POST',
            body: JSON.stringify(response),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }
        };

        let request = new Request(url, options);

        fetch(request)
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async () => {
                console.log('!!!! loginWithProvider success');
                //document.location.href = '/set-up';
                onSuccess();
        }).catch(errorResponse => {
            if (errorResponse.status === 422) {
                console.log('error log in');
                errorResponse.json().then(function (value) {
                    onError(value);
                });
            }
            else {
                console.log('error');
                onError();
            }
        });

    }

}
