import React, {useState, useEffect, useRef} from 'react'
import coursesAPI from '../api/coursesAPI';
import Pagination from '../base/pagination';
import {actions, Loader} from 'react-fields';
import Filters from '../filtering/courses/index';
import NotFound from './notFound';
import Collection from '../base/collection';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import Course from "./course";
import ScrollToTop from '../ScrollToTop/index';
import PaginationMeta from '../paginationMeta';

export default function Index(props) {

    let [courses, setCourses] = useState([]);
    let [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(props.initialOptions && props.initialOptions.page ? parseInt(props.initialOptions.page) : 1);
    let [isLoading, setLoading] = useState(true);

    const contentRef = useRef(null);

    const dispatch = useDispatch();

    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );

    let needRefreshSearch = useSelector(state => state.filters.needRefreshSearch);


    let { selectedCareers, selectedIndustries, selectedDuration, selectedLevels, qSearch} = useSelector(state => ({
        selectedCareers: filtersSelector(state, 'career'),
        selectedIndustries: filtersSelector(state, 'industry'),
        selectedDuration: singleFilterSelector(state, 'duration'),
        selectedLevels: filtersSelector(state, 'level'),
        qSearch: filtersSelector(state, 'qsearch')
    }), shallowEqual);


    useEffect(
        () => {
            loadCollection(props.initialOptions);
        }, []
    );

    useEffect(
        () => {
            if(needRefreshSearch) {
                runSearch();
                dispatch(setNeedRefreshFiltersSearch(false))
            }
        }
    );

    const goToPage = (e, page) => {
        e.preventDefault();
        let options = {...prepareOptionsSearch(), page: page};
        runSearch(options, page);
        window.scrollTo({top: contentRef.current.offsetTop - 180, behavior: 'smooth'});
    };

    const loadCollection = (options = {}, updateHistory = false) => {
        coursesAPI.search(options)
            .then((result) => {
                setCourses(result.courses);
                setTotalPages(result.total_pages);
                setLoading(false);
                PaginationMeta.update(options, result.total, result.total_pages, 28, updateHistory);
            }).catch((error) => {
                console.log(error);
            });
    };

    const runSearch = (options = prepareOptionsSearch(), page = 1) =>  {
        setLoading(true);
        setPage(page);
        loadCollection(options, true);
    };

    const prepareOptionsSearch = () => {
        let options = {page: 1};
        if(selectedCareers){options.careers = selectedCareers.map(ca => ca.id)}
        if(selectedIndustries){options.industries = selectedIndustries.map(ind => ind.id)}
        if(selectedLevels){options.levels = selectedLevels.map(l => l.id)}
        if (!!selectedDuration) {
            options.duration = {from: selectedDuration.value[0], to: selectedDuration.value[1]}
        }
        if(!!qSearch){options.terms = qSearch.map(qTerm => qTerm.name)}
        return options;
    };


    return (
        <React.Fragment>
            <Filters search={runSearch} hasSearch={true}/>
            <div className="grid-container results-area aligned-container" ref={contentRef}>
                    {isLoading ? <Loader size={'small-ok'}/> : null}
                    {!isLoading && courses.length === 0 ? <NotFound/> :
                        courses && courses.length > 0 ?
                        <React.Fragment>
                            <Collection collection={courses}>
                                <Course className={'card--courses'}/>
                            </Collection>
                            <div className="hide-for-large">
                                <ScrollToTop/>
                            </div>
                            <Pagination page={page} options={prepareOptionsSearch()}
                                        totalPages={totalPages}
                                        goToPage={goToPage}/>
                        </React.Fragment> : null
                    }
                </div>


        </React.Fragment>

    )
}
