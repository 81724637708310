import React, {useEffect} from 'react';
import ReactSlider from 'react-slider';
import {useSelector, useDispatch } from 'react-redux';
import {addFilterValue, deleteFilterValue} from "../../actions/filterActions";

export default function SalarySlider(props){

    const salaryMin = 0, salaryMax = props.salaryMax || 500;

    let value = useSelector(state => state.filters.selected.find(filter => filter.type === 'salary'));


    const dispatch = useDispatch();


    const onChange = (value) => {
        if(value[0] > salaryMin || value[1] < salaryMax) {
            dispatch(addFilterValue({id: 'salary', value: value,
                name: `${value[0]}k - ${value[1]}k${value[1] === salaryMax ? '+':''} salary`},
                'salary'));
        } else {
            dispatch(deleteFilterValue({id: 'salary'}, 'salary'));
        }
    };


    const renderHandle = (props, state) => {
        return (
            <div {...props}>
                <div className={`input-range__label--container`}>
                    <div className="input-range__label--top">
                        <div className="input-range__label--value">${state.valueNow}k</div>
                    </div>
                </div>
            </div>
        )
    };

    const onSlide = () => {
    };

    const onClick = (newValue) => {
        let val = value ?  value.value : [salaryMin,salaryMax];
        if(Math.abs(val[0] - newValue) < Math.abs(val[1] - newValue)) {
            onChange([newValue, val[1]])
        } else {
            onChange([val[0],newValue])
        }

    };

    return (
        <React.Fragment>
            <h4 className="white text-center">Annual salary range</h4>
            <div className={'range-slider__container'}>
                <span className={'input-range__slider--limit-label--min'}>${salaryMin}k</span>
                <span className={'input-range__slider--limit-label--max for-salary'}>${salaryMax}k</span>
                <ReactSlider
                    className="input-range"
                    thumbClassName="input-range__slider"
                    trackClassName="input-range__track"
                    defaultValue={[salaryMin, salaryMax]}
                    ariaLabel={['from', 'to']}
                    onChange={onSlide}
                    onAfterChange={onChange}
                    onSliderClick={onClick}
                    ariaValuetext={state => `${state.valueNow}k`}
                    renderThumb={(props, state) => renderHandle(props, state)}
                    pearling
                    step={props.step || 5}
                    value={value ? value.value : [salaryMin,salaryMax]}
                    min={salaryMin}
                    max={salaryMax}
                    minDistance={props.minDistance || 10} />

            </div>
        </React.Fragment>
    );
}