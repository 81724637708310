import React, {Component} from "react";
import Slider from "react-slick";
import SliderArrow from "../base/sliderArrow";
import {FadeInSection} from "../fadeIn";
import ReactDOM from "react-dom";
import {isSmallScreen} from "../utils";
import CustomerReviewsAPI from "../api/customerReviewsAPI";

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
    prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
    className: 'feedback-blk__slider',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 641,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class customerSlider extends Component {
    sliderRef = React.createRef();

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings, slidesToShow: this.props.slidesToShow || 3} :
            {...DEFAULT_SLIDER_SETTINGS, slidesToShow: this.props.slidesToShow || 3},
        slides: [],
        readMoreCntrl: null
    };

    componentDidMount() {
        CustomerReviewsAPI.getCollection().then((response) => {
            this.setState({slides: response})
        }).catch(error => {
            console.log(error);
        });
    }

    handleSlideChange = () => {
        if (!isSmallScreen()) {
            return;
        }
        // this.state.readMoreCntrl.collapseAll();
        const slider = ReactDOM.findDOMNode(this.sliderRef.current);
        const bounding = slider.getBoundingClientRect();
        const tabContentVisibleY = bounding.top - 80;
        if (tabContentVisibleY < 0 || tabContentVisibleY > (window.innerHeight || document.documentElement.clientHeight)) {
            window.scrollTo({
                top: window.scrollY + bounding.top-60,
                behavior: "smooth"
            });
        }
    };

    renderSlide = (item, index) => {
        return <div className="swiper-slide" key={`trustpilotSlide${index}`}>
            <div className="feedback-blk__details">
                <p className="feedback-blk__commenter">{item.name} <span className="feedback-blk__date">{item.date}</span></p>
                <p className="feedback-blk__comment">“{item.description}”</p>
            </div>
        </div>;
    };

    render() {
        if (this.state.slides.length === 0) {
            return null;
        }
        return(
            <FadeInSection>
                <Slider {...this.state.settings} beforeChange={this.handleSlideChange} ref={this.sliderRef}>
                    {this.state.slides.map((item, i) => (this.renderSlide(item, i)))}
                </Slider>
            </FadeInSection>
        );
    }
}