import {actions} from 'react-fields'

class JobsAPI {

    static search(options) {
        const url = '/api/jobs/search';

        return fetch(actions.getData(`${url}?${actions.paramsToURI(options)}`))
            .then(actions.status)
            .then(actions.json)
    }

    static popularTitles(page) {
        let url = '/api/jobs/popular_titles';
        if(page && parseInt(page) > 1){
            url = `${url}?page=${page}`
        }
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static recommendedSearches(page) {
        let url = '/api/jobs/recommended_searches';
        if(page && parseInt(page) > 1){
            url = `${url}?page=${page}`
        }
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static bySector(page = 1, all = false) {
        let url = '/api/jobs/by_sector';
        if(page && parseInt(page) > 1){
            url = `${url}?page_sec=${page}`
        }

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static relevant() {
        const url = '/api/jobs/relevant';

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }


}

export default JobsAPI;