import React from 'react';
import {actions, InputTypeField, Loader} from "react-fields";
import userAPI from '../api/userAPI';
import UserValidation from "../validators/user";
import HoverButton from "../buttons/HoverButton";

import PasswordStrength from '../base/passwordStrength';
import MatchField from '../base/fieldMatch';

const FIELDS = {new_password: '', password_confirmation: '', current_password: '', errors: {}};

class PasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: FIELDS,
            isLoading: false,
            validatePswdMatch: false
        };

        this.pswdStrength = React.createRef();
        this.pswdMatch = React.createRef();
    }

    onChangeField = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user}, () => {
            if (name === 'new_password' || name === 'password_confirmation'){
                let validatePswdMatch = this.state.validatePswdMatch;
                if (name === 'new_password') {
                    const res = this.pswdStrength.current.checkValue(user.new_password);
                    validatePswdMatch = res !== 'bad';
                    this.setState({validatePswdMatch: validatePswdMatch});
                }
                this.pswdMatch.current.checkValue(user.new_password, user.password_confirmation, !validatePswdMatch);
            }
        });
    };

    onSuccess = () => {
        this.props.onSuccess();
        this.setState({isLoading: false, user: FIELDS});
    };

    onError = (errors) => {
        this.setState({isLoading: false, user: {...this.state.user, errors: errors}});
    };

    onSubmit = () => {
        this.setState({isLoading: true}, () => {
            UserValidation.changePassword(this.state.user, () => {
                userAPI.changePassword(this.state.user, this.onSuccess, this.onError);
            }, this.onError, true);
        });
    };

    render() {
        let user = this.state.user;
        return  (
            <div className="form-container grid-x">
                <h3 className="popup__title">Change your password</h3>
                {this.state.isLoading ? <Loader /> : null}
                <div className="small-12 cell">
                    <PasswordStrength ref={this.pswdStrength} value={user.new_password}>
                        <InputTypeField type={"password"} name={"new_password"}
                                        placeholder={"New password*"}
                                        errorMessage={user.errors.newPassword || user.errors.password}
                                        value={user.new_password}
                                        onChange={this.onChangeField}/>
                    </PasswordStrength>
                </div>
                <div className="small-12 cell">
                    <MatchField value={user.password_confirmation} match_value={this.state.user.password} ref={this.pswdMatch} >
                        <InputTypeField type={"password"} name={"password_confirmation"}
                                        placeholder={"Confirm new password*"}
                                        errorMessage={user.errors.passwordConfirmation}
                                        value={user.password_confirmation}
                                        onChange={this.onChangeField}/>
                    </MatchField>
                </div>
                <div className="small-12 cell">
                    <InputTypeField type={"password"} name={"current_password"}
                                    placeholder={"Current password*"}
                                    errorMessage={user.errors.currentPassword}
                                    value={user.current_password}
                                    onChange={this.onChangeField}/>
                </div>
                <div className="medium-text-center text-right">
                    <HoverButton onClick={this.onSubmit}>Save</HoverButton>
                </div>
            </div>
        )
    }

}
export default PasswordForm;