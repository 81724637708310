import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import StudentWelcomeModal from "../user/studentWelcomeModal";

export default function StudentWelcomePopup(props) {

    const studentWelcomePopup = useRef(null);

    useEffect(() => {

        studentWelcomePopup.current.open();
        window.history.replaceState({}, document.title, "/dashboard" );
    }, []);

    return (
        <PopUp ref={studentWelcomePopup} >
            <StudentWelcomeModal educator_name={props.educator_name}/>
        </PopUp>
    )
}