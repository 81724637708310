import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch } from 'react-redux';
import {addFilterValue, deleteFilterValue, setNeedRefreshFiltersSearch, setLocationFilter} from "../../actions/filterActions";
import useOutsideClickHandler from '../../base/useOutsideClickHandler'
import HoverButton from "../../buttons/HoverButton";
import FilterTags from "../filterTags";

export default function LocationInput(props) {

    const dispatch = useDispatch();

    const location = useSelector(state => state.filters.location);
    const [isActive, setActive] = useState(false);
    const [locationIcon, setLocationIcon] = useState('location-icon');
    const [deleteButton, setDeleteButton] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(location);

    const inputRef = useRef(null);

    const wrapperRef = useRef(null);
    useOutsideClickHandler(wrapperRef, () => setActive(false));

    useEffect(
        () => {
            setLocationIcon(!!location ? 'location-icon green-fill' : 'location-icon');
            setDeleteButton(!!location);
        }, [location]
    );

    const onChange = (value) => {
        setCurrentLocation(value);
        dispatch(setLocationFilter(value));
    };

    const updateFilters = (value) => {
        dispatch(setLocationFilter(value));
        if(value.length > 0){
            dispatch(addFilterValue({name: value, id: `location-${Math.random().toString(36).substring(3)}`}, 'location' ));
        }
        dispatch(setNeedRefreshFiltersSearch(true));
        setCurrentLocation('');
    };

    const onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            updateFilters(e.target.value)
        }
    };

    let activeClass = isActive ? 'active' : '';

    const toggleActive = (value) => {
        if(value)
            inputRef.current.focus();
        if (value && props.onActive) { props.onActive() }
        setActive(value);
    };

    const handleClick = () => {
        toggleActive(!isActive);
        setCurrentLocation("");
        updateFilters("");
    };

    const runSearch = () => {
        updateFilters(currentLocation);
        props.afterSearch();
    };

    let toggleDeleteButton = deleteButton && isActive ? 'active' : '';

    return(
         <React.Fragment>
            <div className="expanding-search expanding-search--location small-order-2 large-order-1" ref={wrapperRef}>
                <label className={`expanding-search__label ${activeClass}`}
                       title={'location'}
                       onClick={() => toggleActive(!isActive)}
                       onMouseOver={() => setLocationIcon('location-icon')}
                       onMouseLeave={() => setLocationIcon(!!location ? 'location-icon green-fill' : 'location-icon')}>
                    <span className={`small-icon expanding-search__icon expanding-search__icon--location ${locationIcon}`} />
                </label>
                <input onChange={(e) => onChange(e.target.value)}
                       type="text"
                       name={'location'}
                       value={currentLocation}
                       ref={inputRef}
                       onKeyPress={onPressEnter}
                       placeholder={'enter location'}
                       className={`location-field expanding-search__input ${activeClass}`}/>

                <span className={`small-icon plus-icon ${toggleDeleteButton}`}
                      onClick={handleClick}
                />
            </div>
             {props.showFilters ? <FilterTags type="location"/> : null}
             {props.showDoneButton ? <div className="flex-center filters__apply">
                 <HoverButton onClick={runSearch} color={'filter'}>Done</HoverButton>
             </div> : null}
         </React.Fragment>
    )
}

