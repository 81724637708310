import cf from "../../admin_components/common";
import {validatePassword} from "./password";

export default class UserValidation {

    static changeEmail(user, onSuccess, onError) {
        let errors = {};

        ['email', 'email_confirmation', 'current_password'].map((name) => {
            if (!user[name])
                errors[name] = `${cf.capitalize(name)} is required`;
        });

        if (user.email && user.email_confirmation && user.email !== user.email_confirmation) {
            errors['email_confirmation'] = "Emails don't match";
        }

        if (Object.keys(errors).length) {
            onError(errors);
        } else {
            onSuccess();
        }
    }

    static changePassword(user, onSuccess, onError, validateCurrentPassword) {
        let errors = {};

        ['new_password', 'password_confirmation'].map((name) => {
            if (!user[name])
                errors[cf.camelize(name)] = `${cf.capitalize(name)} is required`;
        });

        if (validateCurrentPassword && !user.current_password) {

            errors[cf.camelize('current_password')] = `${cf.capitalize('current_password')} is required`;
        }

        if (user.new_password && user.password_confirmation && user.new_password !== user.password_confirmation) {
            errors[cf.camelize('password_confirmation')] = "Password Confirmation doesn't match";
        }

        let [pswdValidRes, pswdErrorMsg] = validatePassword(user.new_password);
        if (user.new_password && !pswdValidRes) {
            errors['newPassword'] = pswdErrorMsg;
        }

        if (Object.keys(errors).length) {
            onError(errors);
        } else {
            onSuccess();
        }
    }

    static updateAccount(user, onSuccess, onError) {
        let errors = {};

        ['name', 'gender'].map((name) => {
            if (!user[name])
                errors[name] = `${cf.capitalize(name)} is required`;
        });

        ['country', 'career_level', 'education_level'].map((attrName) => {
            const name = `${attrName}_id`;
            if (!user[name])
                errors[name] = `${cf.capitalize(attrName)} is required`;
        });

        if(!user.age_group) {
            errors['age_group'] = 'Age can\'t be blank';
        }

        if (Object.keys(errors).length) {
            onError(errors);
        } else {
            onSuccess();
        }
    }

}
