import React from 'react';
import LazyLoad from 'react-lazyload';
import Blocks from './blocks';

export default class RandomizedBlocks extends React.Component {

    render() {
        return (
            <LazyLoad>
                <Blocks {...this.props} />
            </LazyLoad>
        );
    }

}