import React from 'react';

export default function Message(props) {

    const started = props.started || localStorage.getItem(`interests_status`) === 'started';

    const messageTypesNotCompleted = {career_match: "to discover your career matches",
        courses: "to get relevant courses",
    jobs: "to get tailored job recommendations"};

    const messageTypesCompleted = {career_match: "Your career matches are ready",
        courses: "Recommended courses based on your results",
        jobs: "Recommended jobs based on your results"};

    const navigateToTestsArea = () => {
        let testArea = document.getElementById("testsProgressArea");
        window.scrollTo({
            top: testArea.offsetTop - 200,
            behavior: "smooth"
        });
    };

    const statusNotCompleted =  <React.Fragment><span className="capsule__dot capsule__dot--red"/>
        <span className="capsule__status">
            {started ?
                <a href="/tests/interests/take" className="capsule__link capsule__link--left">Complete all tests</a>
                :
                props.full_access ?
                <a role="button" onClick={navigateToTestsArea} className="capsule__link capsule__link--left">Continue testing</a>
                    :
                <a href={"/upgrade"} className="capsule__link capsule__link--left">Continue testing</a>
            }{messageTypesNotCompleted[props.type]}
        </span>
    </React.Fragment>;

    const statusReady =  <React.Fragment><span className="capsule__dot capsule__dot--green"/>
        <span className="capsule__status">{messageTypesCompleted[props.type]}</span>
    </React.Fragment>;

    return (
        <div className="capsule capsule--status capsule--widget widget-left">
            {props.completed ? statusReady : statusNotCompleted}
        </div>
    )
}