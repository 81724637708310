import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const TooltipPortal = ({ children, areaRef }) => {
  const rootElemRef = useRef(document.createElement('div'));

    useEffect(() => {

        areaRef.current.appendChild(rootElemRef.current);
        return () => areaRef.current.removeChild(rootElemRef.current);
    }, []);

    return createPortal(children, rootElemRef.current);
};

export default TooltipPortal;
