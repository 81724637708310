import React from 'react';

export default function NotFound(props) {
    return(
        <div className="small-12 cell text-center no-results-msg">
            <div className="small-icon no-results no-results-msg__icon"/>
            <h2>Sorry, we couldn't find any jobs</h2>
            <p className="no-results-msg__text">There are no job results appearing for your current set of search filters.</p>
        </div>
    )
}