import React, {useEffect, useState} from 'react';
import TooltipPopover from "../tooltipPopover/TooltipPopover";
import Portal from "../tooltipPopover/Portal";
import TestsApi from "../api/careerTestAPI";
import {useSelector, useDispatch } from 'react-redux';
import {refreshUserDashboard} from "../actions/userStateActions";
import {FadeInSection} from "../fadeIn";

function Test (props) {

    const [timerId, setTimerId] = useState(null);

    const dispatch = useDispatch();


    const status = props.user[`${props.test.code}_status`];
    const hasAccess = (props.user.full_access || props.user[`${props.test.code}_access`]);


    const elmRef = React.createRef();
    const [coords, setCoords] = useState({});
    const [unLock, setLock] = useState(false);

    const updateTooltipCoords = getTooltip => {
        const rect = getTooltip.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
        });
    };

    const progressStyle = {};
    if (status === 'started') {
        progressStyle['height'] = `${props.progress}%`;
    }

    const resetStatus = () => {
        let startedAt = localStorage.getItem(`${props.test.code}StartedAt`);
        let timeLeft = startedAt ?  (parseInt(startedAt) + props.test.time * 60000) - Date.now() : 0;
        if (timeLeft <= 0){
             console.log('Reset Test');
            TestsApi.resetTest(props.test.id).then((data) => {
                localStorage.removeItem(`${props.test.code}StartedAt`);
                localStorage.removeItem(props.test.code);
                setStatus(data.status);
                let attr = {};
                attr[`${props.test.code}_status`] = data.status;
                dispatch(refreshUserDashboard(attr))
            });
        } else {
           let timer = setTimeout(() => {resetStatus()}, timeLeft+100);
            setTimerId(timer);
        }
    };

    useEffect(
        () => {
            if(props.status === 'started' && props.test.timed && !localStorage.getItem(props.test.code)){
                resetStatus();
            }
            return () => {
                if (timerId) {
                    clearTimeout(timerId);
                }
            };
        }, []
    );

    const renderRightCornerIcon = () => {
      if (hasAccess || (!props.test.timed && status === 'finished')) {return null;}
      if (!props.test.timed) {return <a href={`/tests/${props.test.path}/take`} className={'progress__link'}>Try for free</a>}
      return <div className="tooltip-trigger small-icons locked"  data-tooltip ref={elmRef}
                  onMouseOver={e => { updateTooltipCoords(e.target);  setLock(!unLock) }} onMouseOut={e => {setLock(!unLock)} }>
      </div>;
    };

    return (
        <FadeInSection>
            <div className={status ==='finished' ? 'test-container': 'test-container opacity'}>
                <a href={"/tests/" + props.test.path}>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            {renderRightCornerIcon()}
                            {!hasAccess && unLock && (
                                <Portal>
                                    <TooltipPopover coords={coords} updateTooltipCoords={() => updateTooltipCoords(elmRef.current)}>
                                        <span>Upgrade to take these tests</span>
                                    </TooltipPopover>
                                </Portal>
                            )}
                        </div>
                    </div>
                    <div className={"large-icon float-center " + props.test.code + "-small-white"}/>
                    <p className="test-title text-center">
                        {props.test.name}
                    </p>
                    <div className={"status-circle " + (status)}><div className={status === 'finished' ? 'large-icon completed-test':''}/> </div>
                </a>
            </div>
        </FadeInSection>
    );
}
export default Test;