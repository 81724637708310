import React, {useState, useRef, useEffect} from 'react';
import Popup from '../base/popup';
import RatePopup from './modal/index';
import feedbackAPI from '../api/feedbackAPI';
import RatePoor from "../../../assets/images/rate-poor.png";
import RateAverage from "../../../assets/images/rate-average.png"
import RateGreat from "../../../assets/images/rate-great.png";
import UtilsExitModal from "../utilsExitModal";

export default function FeedbackSticker(props) {

    let [visible, setVisibility] = useState(!props.delay);
    let [rate, setRate] = useState('');
    let ratePopup = useRef(null);

    useEffect(() => {
       if (props.show_popup){
           openPopup();
       }
       if (props.delay) {
           const removeExitIntent = UtilsExitModal({
               onExitIntent: () => {
                   setVisibility(true);
               },
           });
       }
        return () => {
            if (typeof removeExitIntent !== 'undefined')
                removeExitIntent();
        };
    }, []);

    useEffect(() => {
        if (rate) {
            openPopup();
        }
    }, [rate]);

    function close() {
        feedbackAPI.hide();
        setVisibility(false);
    }

    function openPopup() {
        //feedbackAPI.hide();
        setVisibility(false);
        ratePopup.current.open();
    }

    console.log(rate);

    return(
        <React.Fragment>
            {visible ?
                <div className="bottom-sticker">
                    {<div className="bottom-sticker__close" onClick={close}/>}
                   <div className="bottom-sticker__wrapper">
                       <p className='bottom-sticker__content'>How would you rate your experience?</p>
                       <div className="bottom-sticker__feedback">
                           <div className={`feedback__rate`}>
                               <div className={'feedback__rate-circle'} onClick={() => setRate('poor_rate')} >
                                   <img src={RatePoor} alt={"Poor"} width="44" height="44"/>
                               </div>
                               <p className={'feedback__rate-txt'}>Poor</p>
                           </div>
                           <div className={`feedback__rate`}>
                               <div className={'feedback__rate-circle'} onClick={() => setRate('average_rate')} >
                                   <img src={RateAverage} alt={"Average"} width="44" height="44"/>
                               </div>
                               <p className={'feedback__rate-txt'}>Average</p>
                           </div>
                           <div className={`feedback__rate`}>
                               <div className={'feedback__rate-circle'} onClick={() => setRate('great_rate')} >
                                   <img src={RateGreat} alt={"Great"} width="44" height="44"/>
                               </div>
                               <p className={'feedback__rate-txt'}>Great</p>
                           </div>
                       </div>
                   </div>
                </div> : null}
            <Popup ref={ratePopup} className={'feedback'}>
                <RatePopup close={() => ratePopup.current.close()} recaptchaKey={props.recaptchaKey} rate={rate} full_access={props.full_access} />
            </Popup>
        </React.Fragment>
    )

}