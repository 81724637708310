import React from 'react';
import Suitability from "../filters/suitability/index";
import SalarySlider from '../filters/salarySlider';
import GrowthSlider from '../filters/growthSlider';
import EducationLevel from '../filters/educationLevel';
import HoverButton from "../../buttons/HoverButton";
import SearchInput from "../filters/searchInput";

export default function TabsContent(props) {

    const currentTab = props.currentTab;

    const button = (<div className="flex-center filters__apply">
        <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
    </div>);

    return (
        <React.Fragment>
                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'suitability' ? ' is-active' : '')}>
                    <Suitability/>
                    {button}
                </div>

                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'education' ? ' is-active' : '')}>
                    <EducationLevel />
                    {button}
                </div>

                <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'salary' ? ' is-active' : '')}>
                    {currentTab === 'salary' ? <SalarySlider salaryMax={500} step={10} minDistance={10}/> : null}
                    {button}
                </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'growth' ? ' is-active' : '')}>
                {currentTab === 'growth' ? <GrowthSlider/> : null}
                {button}
            </div>

            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (currentTab === 'qsearch' && window.innerWidth <= 1440 ? ' is-active' : '')}>
                <div  className="responsive-search">
                    <SearchInput showFilters={true} showDoneButton={true} afterSearch={props.closeTab} />
                </div>
            </div>

        </React.Fragment>
    )
}