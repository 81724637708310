import React from 'react';
import LoginAPI from "../../api/loginAPI";
import {Loader} from "react-fields";

export default class ConfirmAccountModal extends React.Component{

    state = {
        isLoading: false,
        sent: false
    };

    resendEmail = (e) => {
        e.preventDefault();
        let _this = this;
        this.setState({isLoading: true}, () => {
            LoginAPI.resendAccountInstructions(this.props.user).then( () => {
                _this.setState({isLoading: false, sent: true});
            }).catch(error => {
                console.log(error);
            });
        });
    };

    render(){
        return  (
            <div className="grid-x align-center">
                {this.state.isLoading ? <Loader/> : null}
                {this.state.sent ?
                    <div className="cell text-center">
                        <h3 className="mar-bot-10">Confirmation link resent</h3>
                        <p className='popup__text'>We sent an email to {this.props.user.email} with a new confirmation link to finish setting up your account.
                            If you don’t see the email, please check your junk or promotional folders.</p>
                    </div>
                    :
                    <div className="cell text-center">
                        <div className="large-icon rounded-warning mar-auto small-mar-bot-10"></div>
                        <h3 className="mar-bot-10">Confirm your account</h3>
                        <p className='popup__text'>In order to take further free tests and save your progress at CareerHunter, you need to activate your account</p>
                        <p className="popup__text popup__text-link">Can’t locate the activation email? <a onClick={this.resendEmail}> Resend email</a></p>
                        <p className={"popup__tip"}><span className={"small-icons blue-off-tooltip"}></span>If you don’t see the email in your inbox, please check your junk or promotional folders.</p>
                    </div>
                }
            </div>
        )
    }

}