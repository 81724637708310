import React, {useEffect, useRef, useState} from "react";
import CareerMatchesAPI from "../api/careerMatchesApi";
import PopUp from "../base/popup";
import NotificationPopup from './notificationPopup';
import {Loader} from 'react-fields';

export default function NotificationUpdate(props) {

    if(!props.message || !props.career_matches_ready_at || (new Date(props.career_matches_ready_at) > new Date(props.message.created_at))) {
        return null;
    }
    const [isLoading, setIsLoading] = useState(false);
    const afterRefresh = useRef(null);

    const refreshResults = () => {
        setIsLoading(true);
        CareerMatchesAPI.refresh().then(() => {
            afterRefresh.current.open();
            setIsLoading(false)
        })
    };
    useEffect(() => {
        let wrapper = document.getElementById('wrapper');
        wrapper.style.marginTop = '40px';
    }, []);

    return (
        <div className={'top-notification'}>
        <div className="top-notification--message-box">
            {isLoading ? <Loader/> : null}
            <div className="tooltip-trigger small-icon green-tooltip-icon">
                <span className="tooltip bottom">{props.message.tooltip_description}</span>
            </div>
            <span className="top-notification--message-box__message">{props.message.notification}</span>
            {props.hide_button ? null :
                <React.Fragment>
                    <span className={'small-icon reset'}/>
                    <span className={'top-notification--link'} onClick={refreshResults}>Refresh results</span>
                </React.Fragment> }
        </div>
            <PopUp onClose={() => {document.location.reload();}} ref={afterRefresh}>
                <NotificationPopup/>
            </PopUp>
        </div>
    )
}