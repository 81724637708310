import React from 'react'
import AdBlock from '../ads/block';


export default class CollectionWithAds extends React.Component {

    state = {
        adSlot1: null,
        adSlot2: null,
        adSlot3: null,
        adSlot4: null,
        slots: []
    };

    componentDidMount() {
        this.defineSlots();
    }


    defineSlots = () => {
        let length = this.props.collection.length;
        let slots = [];
        let [ad1, ad2, ad3, ad4] = [null, null, null, null];
        if (length >= 5) {
            let ad1 = parseInt(Math.random() * 3) + 3;
            if (ad1 > length) {
                ad1 = length;
            }
            slots.push(ad1);
            if (length >= 12) {
                ad2 = parseInt(Math.random() * 3) + 11;
                if (ad2 > length) {
                    ad2 = length;
                }
                slots.push(ad2);
            }
            if (length >= 19) {
                ad3 = parseInt(Math.random() * 3) + 18;
                if (ad3 > length) {
                    ad3 = length;
                }
                slots.push(ad3);
            }
            if (length >= 26) {
                ad4 = parseInt(Math.random() * 3) + 25;
                if (ad4 > length) {
                    ad4 = length;
                }
                slots.push(ad4);
            }
            this.setState({adSlot1: ad1, adSlot2: ad2, adSlot3: ad3, adSlot4: ad4, slots: slots})
        }
    };



    render(){
        let currentSlot = -1;
        return (
            <div className="grid-x mar-top-30 aligned-margin">
                {this.props.collection.map((item, i) => {
                    currentSlot +=1;
                    return (<React.Fragment key={i}>
                        <div className="large-3 medium-6 small-12 cell">
                            {React.Children.map(this.props.children, child =>
                                React.cloneElement(child, {item: item})
                            )}
                        </div>
                        {this.state.slots.includes(currentSlot) ?
                            <div className="large-3 medium-6 small-12 cell">
                                <AdBlock/>
                            </div> : null}
                    </React.Fragment>)
                })}
            </div>
        )
    }

}