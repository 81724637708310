export function tempResultsToHash() {
    let testData = {};
    ['interests', 'personality', 'motivators'].map((testCode) => {
        if(localStorage.getItem(testCode)) {
            let tempAnswers = localStorage.getItem(testCode);
            tempAnswers = JSON.parse(tempAnswers);
            let answers = [];
            tempAnswers.filter((page) => page.answered ).map((page) => {
                page.statements.map((statement) => {
                    answers.push({statement_id: statement.id, score: 5 - statement.order, page: page.page})
                })
            });
            testData[`${testCode}_answers`] = answers;
        }
    });

    let interests_status = localStorage.getItem('interests_status') || 'not_started';
    let personality_status = localStorage.getItem('personality_status') || 'not_started';
    let motivators_status = localStorage.getItem('motivators_status') || 'not_started';
    return {
        interests_status: interests_status,
        personality_status: personality_status,
        motivators_status: motivators_status,
        interests_answers: testData.interests_answers,
        personality_answers: testData.personality_answers,
        motivators_answers: testData.motivators_answers
    }
}