import React, {useState, useRef} from 'react';

import chLogo from "../../../../assets/images/CareerHunterBlue.png"
import HoverButton from "../../buttons/HoverButton";
import EmailForm from './emailForm';
import BookmarkModal from './bookmarkMobile';

import {isDevice} from "../../utils";
import ExitModal from "../exitModal";
import PopupAPI from "../../api/popupAPI";

export default function ExitPopupContent(props) {
    const [isEmailFormOpened, setEmailFormOpened] = useState(false);
    const mobileBookmarkMsg = useRef(null);

    const trackClick = (clickType) => {
        PopupAPI.trackShow(props.popup_id, 'click', clickType);
    };

    const bookmark = () => {
        trackClick('bookmark');
        if (isDevice()) {
            mobileBookmarkMsg.current.open();
        } else
        if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
            window.sidebar.addPanel(document.title, window.location.href, '');
            // } else if ((window.sidebar && /Firefox/i.test(navigator.userAgent) && !Object.fromEntries) || (window.opera && window.print)) { // Firefox 23-62 and Opera <=14
            //     this.title = document.title;
            //     this.href = window.location.href;
            //     this.rel = 'sidebar';
            //     return true;
        } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) { // Opera Hotlist
            this.title = document.title;
            return true;
        } else { // webkit - safari/chrome
            alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
        }
    };

    return (
        <div className={'grid-x align-center checkout-exit-popup'}>
            <div className={'small-12 cell text-center'}>
                <img src={chLogo} alt={'Career Hunter'} width="170" height="19"/>
                <h3 className="feedback__msg feedback__msg--with-bot-space">Not quite ready to test further?</h3>
                <p className='feedback__txt'>Save and return later:</p>

                <div className={'grid-x share-function-box'}>
                    <div className={'small-8 cell'}><p className={' share-function-text'}>Share to your email</p></div>
                    <div className={'small-4 cell flex-end'}><HoverButton className="button--grey-white" onClick={() => {trackClick('share'); setEmailFormOpened(true)}}><span className="checkout-icon blue-email"/></HoverButton></div>

                    {isEmailFormOpened ? <EmailForm recaptchaKey={props.recaptchaKey} test={props.test} /> : null}
                </div>
                <div className={'grid-x share-function-box'}>
                    <div className={'small-8 cell'}><p className={' share-function-text'}>Bookmark product</p></div>
                    <div className={'small-4 cell flex-end'}><HoverButton className="button--grey-white" onClick={bookmark}><span className="checkout-icon blue-star"/></HoverButton></div>
                </div>

                <div className={'grid-x share-function-box'}>
                    <div className={'small-8 cell'}><p className={' share-function-text'}>Print product details</p></div>
                    <div className={'small-4 cell flex-end'}><HoverButton onClick={() => {trackClick('print');  window.open(`/checkout-details/download-pdf${props.test ? `/${props.test.code}` : ''}`,'_blank');}} className="button--grey-white" ><span className="checkout-icon blue-print"/></HoverButton></div>
                </div>


                <HoverButton  href={'/tests'} className={'button--green--large'}>Browse all Tests<span className="small-icons next-arrow-white-l"/></HoverButton>
                <p className={'checkout-exit-note'}><strong>Have an issue?</strong> We would love to get your opinion, <a onClick={props.openFeedback}>leave feedback</a>.</p>

            </div>
            <BookmarkModal ref={mobileBookmarkMsg} />
        </div>
    );

};
