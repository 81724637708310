import React, {useEffect, useRef, useState} from "react";
import ReactHover, { Trigger, Hover } from 'react-hover'
import PopUp from "../base/popup";
import ReactPlayer from 'react-player'
import {FadeInSection} from "../fadeIn";
const mobilePaddings = [10,10];
const desktopPaddings = [30,50];

export default function VideoButton() {

    const [previewPlaying, setPreviewPlaying] = useState(false);
    const [paddingVideo, setPaddingVideo] = useState(0);
    const [marginTopVideo, setMarginTopVideo] = useState(0);

    const options = {
        followCursor: true,
        shiftX: -60,
        shiftY: -120,
    };


    const videoPopupRef = useRef(null);

    const calcVideoRation = () => {
        let height = window.innerHeight;
        let width = window.innerWidth;
        if (width < 640){
            height = height - mobilePaddings[0]*2;
            width = width - mobilePaddings[1] *2;
        } else {
            height = height - desktopPaddings[0]*2;
            width = width - desktopPaddings[1] *2;
        }
        let ratio = 100/(width/height);
        setPaddingVideo(ratio);
    };

    useEffect(
        () => {
            calcVideoRation();
            document.addEventListener('resize', calcVideoRation);
        }, []
    );

    const isDevice = () => {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    const handleButtonOnHover = (e) => {
        const rect = e.target.getBoundingClientRect();
        const offset = {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        };
        const parentOffset = offset,
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
        if (e.target.querySelector('.button__hovered')) {
            e.target.querySelector('.button__hovered').style.top = relY + 'px';
            e.target.querySelector('.button__hovered').style.left = relX + 'px';
        }
    };

    const onMouseEnter = (e) => {
        handleButtonOnHover(e);
        setPreviewPlaying(true);

    };

    const onMouseLeave = () => {
        setPreviewPlaying(false)
    };

    const showVideo = () => {
        videoPopupRef.current.open();
        let playerHeight = document.getElementById('popUpPlayer').clientHeight;
        let topMargin = window.innerWidth/2 - playerHeight/2;
    //    setMarginTopVideo(topMargin);

    };

    const button =  (<button onClick={showVideo} aria-label="See how we helped Jack"
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                          className="block__link button button--white button--with-svg">
            <span className="play-icon"/><span className="button--text">See how we helped Jack</span>
            <span className="button__hovered"/></button>);


    return (
        <FadeInSection>
            <React.Fragment>
                {isDevice() ? button :
                    <ReactHover options={options}>
                    <Trigger type="trigger" >
                        {button}
                    </Trigger>
                    <Hover type="hover">
                        <ReactPlayer url={"https://player.vimeo.com/video/210418248?color=3dd144&title=0&byline=0&portrait=0&loop=1&controls=false"}
                                     controls={false}
                                     width={"168px"}
                                     height={"100px"}
                                     playing={previewPlaying}
                                     id={'previewPlayer'}
                                     muted={true}
                                     loop={true}
                                      />

                    </Hover>
                    </ReactHover>}
                <PopUp ref={videoPopupRef} contentClass={"popup__content--full"} parentClass={"popup popup--full"} closeClass={"popup__close popup__close--video"}>
                    <div className='player-wrapper' style={{paddingTop: `${paddingVideo}%`, marginTop: `${marginTopVideo}px`}}>
                        <ReactPlayer url={"https://player.vimeo.com/video/210418248?color=3dd144&title=0&byline=0&portrait=0"}
                                     playing={true}
                                     className='react-player'
                                     controls={true}
                                     id={'popUpPlayer'}
                                     width='100%'
                                     height='100%'
                        />
                    </div>
               </PopUp>
            </React.Fragment>
        </FadeInSection>
    )
}