import React from "react";
import FacebookShareButton from 'react-share/es/FacebookShareButton';
import LinkedinShareButton from 'react-share/es/LinkedinShareButton';
import TwitterShareButton from 'react-share/es/TwitterShareButton';
import EmailShareButton from 'react-share/es/EmailShareButton';


class ShareButtons extends React.Component {



    render(){
        const url = this.props.url || location.href;

        let props = {url: url, title: this.props.share_text,
            description: this.props.share_text};

        const emailUrl = `${url + (url.indexOf('?')>0 ? '&' : '?') + 'utm_source=careerhunter&utm_medium=email.m&utm_campaign=share'}`;
        return(
            <React.Fragment >
                <div className="popup-social-button mar-bot-15" >
                    <EmailShareButton {...props}
                                      style={{outline: "none"}}
                                      url={''}
                                      subject={this.props.share_text}
                                      body={`Hi,\n${this.props.email_body}${emailUrl}\n\nBest,\n${this.props.user_name}\n\n`}>
                        <div className="small-icons blue-small-letter"/>
                        <p>Share by email</p>
                    </EmailShareButton>
                </div>
                {/*<div className="popup-social-button mar-bot-15" >*/}
                {/*    <FacebookShareButton url={url} quote={this.props.share_text} style={{outline: "none"}}>*/}
                {/*        <div className="small-icons blue-facebook"/>*/}
                {/*        <p>Post to Facebook</p>*/}
                {/*    </FacebookShareButton>*/}
                {/*</div>*/}
                <div className="popup-social-button mar-bot-15" >
                    <TwitterShareButton {...Object.assign({}, props, {title: this.props.share_text})} via="CAcareerhunter " style={{outline: "none"}}>
                        <div className="small-icons blue-twitter"/>
                        <p>Post tweet</p>
                    </TwitterShareButton>
                </div>
                {/*<div className="popup-social-button mar-bot-15" >*/}
                {/*    <LinkedinShareButton {...props} source='CareerHunter' summary={this.props.share_text} style={{outline: "none"}}>*/}
                {/*        <div className="small-icons blue-linkedin"/>*/}
                {/*        <p>Share update</p>*/}
                {/*    </LinkedinShareButton>*/}
                {/*</div>*/}

            </React.Fragment>

        )
    }
}
export default ShareButtons;
