export function validatePassword(password) {
    if (!password) { return [false, 'Name can\'t be blank'] }

    let errorMessages = [];
    if (password.length < 7) {
        errorMessages.push('Should have at least seven characters.');
    }
    if (!password.match(/\d/) || !hasAnnyLetter(password)) {
        errorMessages.push('Should contain both numeric and alphabetic characters.');
    } else if (!hasLowerAndUpperCaseChar(password)) {
        errorMessages.push('Should contain at least 1 uppercase and 1 lowercase letter.');
    }
    return [(errorMessages.length === 0), errorMessages.join(' ')];
}

export function hasLowerAndUpperCaseChar(str) {
    let upper = false,
        lower = false;

    for (const character of str) {
        if (character.toUpperCase() === character.toLowerCase()) continue;
        upper = upper || character === character.toUpperCase();
        lower = lower || character === character.toLowerCase();
        if (upper && lower) return true;
    }

    return false;
}

export function hasLowerCaseChar(str) {
    let lower = false;

    for (const character of str) {
        if (character.toUpperCase() === character.toLowerCase()) continue;
        lower = lower || character === character.toLowerCase();
        if (lower) return true;
    }

    return false;
}

export function hasUpperCaseChar(str) {
    let upper = false;

    for (const character of str) {
        if (character.toUpperCase() === character.toLowerCase()) continue;
        upper = upper || character === character.toUpperCase();
        if (upper) return true;
    }

    return false;
}

export function hasAnnyLetter(str) {
    return /\p{L}/u.test(str);
}