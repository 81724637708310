import React from 'react';
import JobTypes from '../filters/jobTypes';
import SalarySlider from '../filters/salarySlider';
import HoverButton from "../../buttons/HoverButton";
import Sectors from "../filters/sectors";
import CareersAccordion from "../filters/careersAccordion";

export default function TabsContent(props) {

    const button = (<div className="flex-center filters__apply">
        <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
    </div>);

    return (
        <React.Fragment>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'career' ? ' is-active' : '')}>
                <CareersAccordion  />
              {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'sector' ? ' is-active' : '')}>
                <Sectors />
                {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'job_type' ? ' is-active' : '')}>
                <JobTypes/>
                {button}
            </div>
            <div style={{ height: props.calculatedHeight }} className={"tabs-panel" + (props.currentTab === 'salary' ? ' is-active' : '')}>
                {props.currentTab === 'salary' ? <SalarySlider/> : null}
                {button}
            </div>
        </React.Fragment>
    )
}