import React from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";

function LinkedInBtn(props) {
    const { linkedInLogin } = useLinkedIn({
        clientId: props.clientId,
        redirectUri: `${window.location.origin}/auth/linkedin/callback`,
        onSuccess: (code) => {
            console.log('code', code);
            setCode(code);
            setErrorMessage("");
        },
        scope: "r_emailaddress r_liteprofile",
        onError: (error) => {
            console.log('error', error);
            setCode("");
            setErrorMessage(error.errorMessage);
        },
    });
    const [code, setCode] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    return (
        <React.Fragment>
            <div onClick={linkedInLogin} className="button button--clear">
                <span className="upgrade-icon is-left linkedin-icon"/>Sign up using <span>LinkedIn</span>
            </div>

            {!code && <div>No code</div>}
            {code && (
                <div>
                    <div>Authorization Code: {code}</div>
                    {/*<div>*/}
                        {/*Follow{" "}*/}
                        {/*<Link*/}
                            {/*target="_blank"*/}
                            {/*href="https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fconsumer%2Fcontext&tabs=HTTPS#step-3-exchange-authorization-code-for-an-access-token"*/}
                            {/*rel="noreferrer"*/}
                        {/*>*/}
                            {/*this*/}
                        {/*</Link>{" "}*/}
                        {/*to continue*/}
                    {/*</div>*/}
                </div>
            )}
            {errorMessage && <div>{errorMessage}</div>}
        </React.Fragment>
    );
}

export default LinkedInBtn;