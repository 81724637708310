import React from "react";
import HoverButton from "../buttons/HoverButton";
export default function CanNotRewriteResults(props) {

    return(
        <div className="grid-x">
            <div className="cell">
                <h3 className="popup__title">Test results cannot be saved</h3>
                <p className='popup__text'>
                    It seems you have already taken the Career Interests test on a previous occasion.
                </p>
                <p className='popup__text'>
                    You are only permitted by your administrator to take each test once. Please contact your account administrator '{props.company}' to request for a retake, if needed.                </p>
            </div>
            <div className="cell mar-top-20 text-center">
                <HoverButton onClick={props.onCancel} className="button button--green"
                             data-open="saved">Ok
                </HoverButton>

            </div>
        </div>
    )

}