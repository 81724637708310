import React from 'react';
import {actions, ErrorMessage, InputTypeField} from "react-fields";

class User extends React.Component {



    onHandleChange = (e) => {
        let name = e.target.name;
        let user = this.props.user;
        user[name] = e.target.value;
        if (name === 'email') {
            user['confirmation_email_sent'] = false;
        }
        user.errors[actions.camelize(name)] = "";
        this.props.setUser(user);
    };

    onBlur = (e) => {
        if (!!this.props.user.email && !this.props.user.confirmation_email_sent) {
            let data = {email: this.props.user.email};
            if (!!this.props.careerTestId) { data['career_test_id'] = this.props.careerTestId }
            fetch("/api/user/abandoned_email", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            }).then((res) => this.onConfirmationSent())
        }
    };

    onConfirmationSent = () => {
        console.log('onConfirmationSent');
        let user = this.props.user;
        user['confirmation_email_sent'] = true;
        this.props.setUser(user);
    };

    render() {
        return  (
            <div className="form-container checkout-user-details">
                <div className="grid-x">
                    <div className="small-12 cell">
                        <div className="flex-middle">
                            <h3 className="account-title">Your account</h3>
                             <div className="tooltip-trigger checkout-icon blue-tooltip">
                                <span className="tooltip top" >This is your name and email address that will be used to access your CareerHunter account. Upon submitting your payment, we will create your account access. In case you have already registered or are logged in, you should use your existing email address.</span>
                            </div>
                        </div>
                        {this.props.user.id ?
                            <div className="input-with-icon">
                                <div className="small-icons green-person"/>
                                <input type="text" value={this.props.user.email} disabled/>
                            </div>
                            :
                            <div className="grid-x">
                                <div className="large-6 medium-6 small-12 cell med-up-padR10">
                                <InputTypeField name="name" placeholder="Name"
                                           errorMessage={this.props.user.errors.name}
                                           value={this.props.user.name} onChange={this.onHandleChange}
                                           />
                                </div>
                                <div className="large-6 medium-6 small-12 cell med-up-padL10">
                                <InputTypeField type="email" name="email" placeholder="Email address" onBlur={this.onBlur}
                                           errorMessage={this.props.user.errors.email || this.props.user.errors.base}
                                           value={this.props.user.email} onChange={this.onHandleChange}/>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }


}
export default User;