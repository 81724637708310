import React, { Component } from 'react';
import PopUp from '../base/popup';
import SaveProgressModal from "./modal/saveProgress";
import SavedModal from "./modal/saved";
import ConfirmAccount from "./modal/confirmAccount";
import SaveProgressButton from "./button/save";
import CheckEmailButton from "./button/checkEmail";
import UnconfirmedButton from "./button/unconfirmed";

import Bubble from './bubble/root';

class Index extends Component {


    constructor(props) {
        super(props);
        this.progressModal = React.createRef();
        let user = this.props.user || localStorage.getItem('user');
        this.state = {
            currentProgress: this.setupProgress(user),
            user: user
        };
    }

    setupProgress = (user) => {
        return user && user.id ? (user.name ? (this.props.confirmed ? 'confirmed' : 'unconfirmed') : 'temporary' ) : 'not_saved';
    };

    onSaveUser = (user) => {
        localStorage.clear();
        this.setState({user: user, currentProgress: this.setupProgress(user)});
        this.closeModal();
        this.openModal();
    };

    openModal = () => {
        this.progressModal.current.open();
    };

    closeModal = () => {
        this.progressModal.current.close();
    };

    renderButton = () => {
        switch(this.state.currentProgress) {
            case 'not_saved':
                return <SaveProgressButton onClick={this.openModal} type={this.props.type} />;
            case 'temporary':
                return <CheckEmailButton onClick={this.openModal}/>;
            case 'unconfirmed':
                return <UnconfirmedButton user={this.props.user} onClick={this.openModal}/>;
            case 'confirmed':
                return <Bubble user={this.props.user} />;
            default:
                return null;
        }
    };

    renderModal = () => {
        switch(this.state.currentProgress) {
            case 'not_saved':
                return <SaveProgressModal onSave={this.onSaveUser}/>;
            case 'temporary':
                return <SavedModal showResend={true} user={this.state.user} closeModal={this.closeModal}/>;
            case 'unconfirmed':
                return <React.Fragment>
                    <ConfirmAccount user={this.props.user}/>
                </React.Fragment>;
            default:
                return <div>Progress Bubble</div>;
        }
    };



    render() {
        return (
            <React.Fragment>
                <div>
                    {this.renderButton()}
                </div>

                <PopUp ref={this.progressModal}>
                    {this.renderModal()}
                </PopUp>

            </React.Fragment>

        );
    }
}

export default Index;