import React from "react";
import TestsApi from "../../../admin_components/api/testsApi";
import CareerTestAPI from "../../api/careerTestAPI";
import {tempResultsToHash} from "../../careerTestUtils";

const LINKEDIN_AUTH_URL = '/auth/linkedin';

export default class LinkedInButton extends React.Component {

    state = {errorMessage: null};

    componentWillUnmount() {
        if (this.popup) {
            console.log('unsubscribeMsg');
            window.removeEventListener('message', this.receiveLinkedInRegStatus);
            this.popup.close();
        }
    }

    linkedInLogin = (e) => {
        e.preventDefault();
        this.props.onCallback(() => {
            const y = window.top.outerHeight / 2 + window.top.screenY - 300;
            const x = window.top.outerWidth / 2 + window.top.screenX - 300;
            this.popup = window.open(`${window.location.origin}${LINKEDIN_AUTH_URL}`, '_blank', `width=600,height=600,top=${y},left=${x}`);
            window.addEventListener('message', this.receiveLinkedInRegStatus);
        });
    };

    receiveLinkedInRegStatus = ({ origin, data: { source, result, errors} }) => {
        if (origin !== window.location.origin ) return;
        if (source !== 'ch-linkedin-omniauth' ) return;
        if (this.popup) {
            window.removeEventListener('message', this.receiveLinkedInRegStatus);
            this.popup.close();
        }
        if (result === 'success') {
            if (this.props.saveAnswers) {
                CareerTestAPI.saveTempResults(tempResultsToHash()).then((data) => {
                    this.props.onSuccess();
                });
            } else {
                this.props.onSuccess();
            }
        } else {
            console.log(errors);
            let parsedErrors = {base: errors};
            try {
                parsedErrors = JSON.parse(errors);
            } catch (err) {
                console.error(err);
            }
            props.onError();
            this.setState({errorMessage: parsedErrors['base']});
        }
    };

    render() {
        return (
            <React.Fragment>
                <button onClick={this.linkedInLogin} className="button button--clear">
                    <span className={this.props.icon || 'small-icons is-left linkedin'}/>{this.props.label || 'Sign up using'} <span>LinkedIn</span>
                </button>

                {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
            </React.Fragment>
        );
    }

}