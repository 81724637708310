import {SETUP_INITIAL_FILTERS_STATE, ADD_FILTER_VALUE, DELETE_FILTER_VALUE,
    SET_NEED_REFRESH_SEARCH, SET_Q_SEARCH_FILTER, DELETE_ALL_FILTERS,
    SET_LOCATION_FILTER} from '../actions/actionTypes';
import initialState from './initialState';


export default function filters(state = initialState.filters, action) {
    switch (action.type) {
        case SETUP_INITIAL_FILTERS_STATE:
            let profileCompleted = action.user && action.user.interests_status === 'finished' &&
                action.user.personality_status === 'finished' && action.user.motivators_status === 'finished'
                && action.user.abstract_status === 'finished' && action.user.numerical_status === 'finished'
                && action.user.verbal_status === 'finished';
            let selectedFilters = initialFiltersToSelected(action.initialFilters);
            return {...state, profileCompleted: profileCompleted, user: action.user || null,
                page: action.page, initialFilters: action.initialFilters, selected: selectedFilters};
        case ADD_FILTER_VALUE:
            let selected = state.selected.filter((filter) => filter.type !== action.value.type
                    || filter.id !== action.value.id);
            return {...state,...{selected: [...selected, action.value], needRefreshSearch: action.value.type === 'qsearch'}};
        case DELETE_FILTER_VALUE:
            let qSearch = action.value.type === 'qsearch' ? "" : state.qSearch;
            let location = action.value.type === 'location' ? "" : state.location;
            let needRefreshSearch = action.value.type === 'qsearch';
            return {...state,...{selected: state.selected.filter((filter) => filter.type !== action.value.type
                        || filter.id !== action.value.id),qSearch: qSearch, location: location,
                    needRefreshSearch: needRefreshSearch}};
        case SET_NEED_REFRESH_SEARCH:
            return {...state, needRefreshSearch: action.value};
        case SET_Q_SEARCH_FILTER:
            return {...state, qSearch: action.value};
        case SET_LOCATION_FILTER:
            return {...state, location: action.value};
        case DELETE_ALL_FILTERS:
            return {...state,...{selected: [], qSearch: '', location: '', needRefreshSearch: true}};
        default:
            return state;
    }
}

function initialFiltersToSelected(initialFilters) {
    let selectedFilters = [];

    if (initialFilters['salary']) {selectedFilters.push({type: 'salary', id: 'salary',
        value: [initialFilters['salary']['from'], initialFilters['salary']['to']],
        name: `${initialFilters['salary']['from']}k - ${initialFilters['salary']['to']}k salary`
    })}
    if (initialFilters['growth']) {selectedFilters.push({type: 'growth', id: 'growth',
        value: [initialFilters['growth']['from'], initialFilters['growth']['to']],
        name: `${initialFilters['growth']['from']}k - ${initialFilters['growth']['to']}k salary`
    })}
    if (initialFilters['score']) {selectedFilters.push({type: 'match', id: 'match',
        value: [initialFilters['score']['from'], initialFilters['score']['to']],
        name: `${initialFilters['score']['from']}% - ${initialFilters['score']['to']}% matches`
    })}
    if (initialFilters['terms'] && initialFilters['terms'].length > 0) {
        initialFilters['terms'].filter((el) => { return el }).map((filter, i) => selectedFilters.push({type: 'qsearch', id: `qsearch-${i}`, name: filter}));
    }
    if (initialFilters['locations'] && initialFilters['locations'].length > 0) {
        initialFilters['locations'].filter((el) => { return el }).map((filter, i) =>  selectedFilters.push({type: 'location', id: `location-${i}`, name: filter} ));
    }

    if (initialFilters['personalities'] && initialFilters['personalities'].length > 0) {
        initialFilters['personalities'].filter((el) => { return el }).map((filter, i) =>  selectedFilters.push({type: 'personality', id: filter, name: filter} ));
    }

    if (initialFilters['motivators'] && initialFilters['motivators'].length > 0) {
        initialFilters['motivators'].filter((el) => { return el }).map((filter, i) =>  selectedFilters.push({type: 'motivator', id: filter, name: filter} ));
    }


    return selectedFilters;
}

