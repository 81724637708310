import React from "react";
import HoverButton from "../../buttons/HoverButton";
import LinkedinShareButton from 'react-share/es/LinkedinShareButton';

export default function ShareStepButtons(props) {

    const iconColorPostfix = props.iconColor ? `-${props.iconColor}` : '';
    return (
        <div className={props.divClassName || 'affiliates__share__area'}>
            <HoverButton onClick={() => props.onClick('email')} className={props.btnClassName || 'affiliates__share__button mar-bot-15 button--white'}>
                <span className={`share-icon email${iconColorPostfix}`}/>
                <span className="share-text">Send email</span>
            </HoverButton>
            <HoverButton onClick={() => props.onClick('facebook')} className={props.btnClassName || 'affiliates__share__button mar-bot-15 button--white'}>
                <span className={`share-icon facebook${iconColorPostfix}`}/>
                <span className="share-text">Post to facebook</span>
            </HoverButton>
            <HoverButton onClick={() => props.onClick('twitter')} className={props.btnClassName || 'affiliates__share__button mar-bot-15 button--white'}>
                <span className={`share-icon twitter${iconColorPostfix}`}/>
                <span className="share-text">Post tweet</span>
            </HoverButton>
            <LinkedinShareButton url={props.url} style={{outline: "none", width: '100%'}}>
            <HoverButton  span={true} className={props.btnClassName || 'affiliates__share__button mar-bot-15 button--white'}>
                <span className={`share-icon linkedin${iconColorPostfix}`}/>
                <span className="share-text">Share update</span>
            </HoverButton>
            </LinkedinShareButton>
        </div>
    );
}