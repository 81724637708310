import React, {useEffect, useState} from 'react';

export default function Label(props) {

    let styleBgImage = props.option.image && props.option.image.small ? {
                        background: `url(${props.option.image.small})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover"} : {};

    let action = props.disabledForSelect ? null : (props.selected ? props.delete : props.add);

    let scoreNum = parseInt(props.option.score/10);
    let ratedClass = props.hasScore ? `rated` : '';
    let selectedClass = props.selected ? 'selected' : '';
    let disableHover =  props.disabledForSelect ? 'disable-hover' : '';

    const color = () => {
        if(!props.selected){
            return ''
        }
        if(props.option.type === "education"){
            return "selected--white-1"
        } else if (props.option.type === "growth" || props.option.type === "job_type") {
            return "selected--white-2"
        }   else if (props.option.type === "match") {
            return "selected--white-3"
        } else if (props.option.type === "salary") {
            return "selected--white"
        } else {
            ''
        }
    };

    return (
        <div className="filters-topic">
            <div className={`grid-x filters-topic__select ${ratedClass} ${selectedClass} ${color()} ${disableHover}`} onClick={action}>
                {props.hasScore ? <div className={`filters-topic__rating flex-center score-bg-${scoreNum}`}>{props.option.score === 0 ? '?' : `${props.option.score}%`}</div> : null}
                {props.hasImage ? <div className={`filters-topic__img ${ratedClass}`} style={styleBgImage} /> : null }
                <div className={'cell auto ' + (props.hasImage ? '' : ' no-img')}>
                    <p className="filters-topic__name">{props.option.name}</p>
                </div>
                {props.disabledForSelect ? null :
                    <div className="cell shrink">
                        <div className="remove"><span className="small-icon green-cross"/></div>
                    </div>
                }
            </div>
        </div>
    )

}