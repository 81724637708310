import React, {Fragment} from 'react';

import HoverButton from "../../buttons/HoverButton";
import TermsCheckboxField from "../../base/termsCheckbox";
import {actions, InputTypeField, Loader} from "react-fields";
import InvisibleRecaptcha from '../../contact/invisibleRecaptcha';
import {disableCaptchaForTest} from "../../settings";

import shareApi from '../../api/shareAPI';

const DEFAULT_FORM = {email: '', agreeTerms: false, errors: {}};

export default class ExitPopupEmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {form: {...DEFAULT_FORM}};

        this.invisibleCaptcha = React.createRef();
    }

    onChange = (e) => {
        let updatedForm = {...this.state.form};

        let name = e.target.name;
        updatedForm[name] = name === 'agreeTerms' ? e.target.checked : e.target.value;
        updatedForm.errors[name] = "";
        this.setState({form: updatedForm});
    };

    onSend = (e) => {
        e.preventDefault();
        try {
            if (disableCaptchaForTest()) {
                this.send('valid');
            } else if (window.grecaptcha) {
                this.invisibleCaptcha.current.execute();
            }
        } catch (error) {
            console.warn('something is wrong with invisible captcha....');
        }
    };

    send = (token) => {
        shareApi.send(this.state.form, token, this.onSuccess, this.onError);
        if (!disableCaptchaForTest()) { this.invisibleCaptcha.current.reset(); }
    };

    onSuccess  = () => {
        console.log(this.props.test);
        console.log(!!this.props.test ? 'is true' : 'is false');
        const subject = encodeURIComponent(`CareerHunter: ${!!this.props.test ? this.props.test.name : 'Full'} Test Access`);
        const body = encodeURIComponent('Hi,\n' +
            '\n' +
            'I’m just sharing CareerHunter’s accurate and reliable career testing platform with you. \n' +
            '\n' +
            (!!this.props.test ? this.props.test.description : 'Find career matches that suit you best, discover your strengths and weaknesses, get a detailed and personalised career report, and much more...') +
            '\n\n' +
            `Unlock test${ !!this.props.test ? '' : 's'} here: https://careerhunter.io/${ !!this.props.test ? `tests/${this.props.test.code}` : 'upgrade'}\n` + '\n');
        const link = `mailto:${this.state.form.email}?subject=${subject}&body=${body}`;
        //window.open(link);
        window.location.href = link;
    };

    onError  = (errors) => {
        let updatedForm = {...this.state.form};
        updatedForm.errors = errors;
        this.setState({form: updatedForm});
    };

    render() {
        const form = this.state.form;
        return (
            <div className={'grid-x  form-container'}>
                <div className={`small-12 cell align-left ${!!form.errors.agreeTerms ? 'termsError' : ''}`}>
                    <TermsCheckboxField name="agreeTerms" value={form.agreeTerms} errorTypeMessage={true}
                                        errorMessage={form.errors.agreeTerms} onChange={this.onChange}/>
                </div>
                <div className={'small-12 cell share-email-input'}>
                    <InputTypeField type={"email"} name={"email"}
                                    placeholder={"Enter email"}
                                    errorMessage={form.errors.email}
                                    value={form.email}
                                    onChange={this.onChange}/>
                    <HoverButton  className="button--grey-white" onClick={this.onSend}>Send</HoverButton>
                </div>

                <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                    sitekey={this.props.recaptchaKey}
                                    verifyCallback={this.send}/>
            </div>
        );
    }

};
