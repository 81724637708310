import React from 'react';
import ReactSlider from 'react-slider';
import {useSelector, useDispatch } from 'react-redux';
import {addFilterValue, deleteFilterValue} from "../../actions/filterActions";

export default function MatchesSlider() {

    let filterValue = useSelector(state => state.filters.selected.find((filter) => filter.type === 'match'));

    const dispatch = useDispatch();


    const onChange = (value) => {
        if(value[0] > 0 || value[1] < 100) {
            dispatch(addFilterValue({id: 'match', value: value, name: `${value[0]}% - ${value[1]}% matches`}, 'match'));
        } else {
            dispatch(deleteFilterValue({id: 'match'}, 'match'));
        }
    };

    const onSlide = (value) => {

    };

    const renderHandle = (props, state) => {
        let fillClass = `fill_${parseInt(state.valueNow/10)}`;
        let translateY = 44 - ((44 * state.valueNow) / 100);
        let backgroundClass = `${fillClass}--background`;
        return (
            <div {...props}>
                <div  className={`input-range__label--container--big match-tank ${backgroundClass}`}>
                    <div className="input-range__label--inner--big inner">
                        <div className={`fill fillAction ${fillClass}`}
                             style={{transform: `translate(0, ${translateY}px)`}}>
                            <svg version="1.1" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50"
                                 enableBackground="new 0 0 50 50">
                                <path className="wave-shape"
                                      d="M250,250V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4 c-12.3
                                      ,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,
                                      2.4c-12.3,1.1-30.4,1.8-32,1.9 c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V250H250z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="input-range__label match-percent matches">
                        <div className="input-range__label--value qm matches">{state.valueNow}%</div>
                    </div>
                </div>
            </div>
        )
    };

    let value = filterValue ? filterValue.value : [0,100];

    return (

        <React.Fragment>
            <h4 className="white-text text-center mar-bot-40">Showing Career Matches between: {value[0]}% - {value[1]}%</h4>
            <div className={'range-slider__container'}>
                <ReactSlider
                    className="input-range input-range__single-track"
                    thumbClassName="input-range__slider--big"
                    trackClassName="input-range__track"
                    defaultValue={[0, 100]}
                    withTracks={false}
                    ariaLabel={['from', 'to']}
                    onChange={onSlide}
                    onAfterChange={onChange}
                    ariaValuetext={state => `${state.valueNow}% match`}
                    renderThumb={(props, state) => renderHandle(props, state)}
                    value={value}
                    pearling
                    minDistance={10} />

            </div>
        </React.Fragment>
    );

}