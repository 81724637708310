import {actions} from 'react-fields'

export default class adBannersAPI {

    static getPromo(banner_code_prefix=null) {
        let url = '/api/ad_banners/promo';
        if (banner_code_prefix) {
            url = `${url}?banner_code_prefix=${banner_code_prefix}`
        }

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }


}