import {actions} from 'react-fields'

class StudentDiscountApi {


    static validate(email) {
        const url = '/api/student_discounts/validate';
        let data = {email: email.trim()};
        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }


    static request(email) {
        const url = '/api/student_discounts/new_domain';
        let data = {name: email};
        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }


    static resend(email) {
        const url = '/api/student_discounts/resend';
        let data = {email: email.trim()};
        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

}

export default StudentDiscountApi;
