import React, { Component } from 'react';
import PropTypes from "prop-types";

class InvisibleRecaptcha extends Component {

    scriptId = 'google-recaptcha-v3';
    placeholderDivId = `${Math.random().toString(36).substring(7)}`;
    googleRecaptchaSrc = 'https://www.google.com/recaptcha/api.js';
    captcha = undefined;
    captchaWidget = undefined;

    componentDidMount() {
        if (!this.props.sitekey) {
            return;
        }

        this.injectGoogleReCaptchaScript();
    }

    injectGoogleReCaptchaScript = () => {
        if (document.getElementById(this.scriptId)) {
            this.handleOnLoad();
            return;
        }

        const head = document.getElementsByTagName('head')[0];

        const js = document.createElement('script');
        js.id = this.scriptId;
        js.src = `${this.googleRecaptchaSrc}?render=explicit`;
        js.onload = this.handleOnLoad;

        head.appendChild(js);
    };

    handleOnLoad = () => {
        if (!window || !window.grecaptcha) {
            console.warn('Google recaptcha is not available');
            return;
        }

        window.grecaptcha.ready(() => {
            console.log(' window.grecaptcha ready');
            this.captcha = window.grecaptcha;
            this.captchaWidget = this.captcha.render(this.placeholderDivId, {
                sitekey: this.props.sitekey,
                callback: this.props.verifyCallback,
                size: 'invisible'
            });
        });
    };

    reset() {
        if (this.captcha) {
            this.captcha.reset(this.captchaWidget);
        }
    }

    execute() {
        if (this.captcha) {
            this.captcha.execute(this.captchaWidget);
        } else {
            console.warn('reCaptcha is not yet enabled')
        }
    }

    render() {
        return  (
            <div id={this.placeholderDivId} />
        );
    }
}

InvisibleRecaptcha.propTypes = {
    sitekey: PropTypes.string.isRequired,
    verifyCallback: PropTypes.func.isRequired
};
export default InvisibleRecaptcha;