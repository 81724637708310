import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import RoundedLabel from './roundedLabel';
import {deleteFilterValue, setNeedRefreshFiltersSearch} from "../actions/filterActions";
import useWindowSize from "../useWindowSize";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MotivatorsAPI from "../api/motivatorsAPI";
import PersonalityAPI from "../api/personalityAPI";

export default function SelectedFilters(props) {

    let size = useWindowSize();
    let content_height_limit =  size.width > 640 ? 100 : 0;

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState(`${content_height_limit}px`);
    const [showMore, setShowMore] = useState(true);
    const [personalities, setPersonalities] = useState([]);
    const [motivators, setMotivators] = useState([]);
    const content = useRef(null);



    const toggleShowMore = () => {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? `${content_height_limit}px` : `${content.current.scrollHeight}px`
        );
        setShowMore(!showMore);
    };

    const resizeRecommendationsContainer = () => {
        if (size.width > 1024 && setActive === "") {
            setHeightState(`${content_height_limit}px`);
        }
    };

    useEffect(
        () => {
            if(!!filters.find((f) => {return f.type === 'motivator'})){
                MotivatorsAPI.getCollection().then((response) => {
                    setMotivators(response);

                }).catch(error => {
                    console.log(error);
                });
            }
            if(!!filters.find((f) => {return f.type === 'personality'})){
                PersonalityAPI.getCollection().then((response) => {
                    setPersonalities(response);
                }).catch(error => {
                    console.log(error);
                });
            }

        }, []
    );

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => resizeRecommendationsContainer(), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    let filters = useSelector(state => state.filters.selected);


    const dispatch = useDispatch();

    const deleteFilter = (filter) => {
        dispatch(deleteFilterValue(filter, filter.type));
        dispatch(setNeedRefreshFiltersSearch(true));
    };


    if(filters.length === 0) {
        return null;
    }

    let needShowMore = !!content.current ? content.current.scrollHeight > content_height_limit : false;

    const filterItems = filters.map((filter) => {
        if(filter.type === 'personality' && personalities.length > 0) {
            filter.name = personalities.find((p) => {return p.id === parseInt(filter.id)})["name"]
        }
        if(filter.type === 'motivator' && motivators.length > 0) {
            filter.name = motivators.find((m) => {return m.id === parseInt(filter.id)})["name"]
        }
        return (
            <CSSTransition key={filter.id} timeout={400} classNames="vanish">
                <RoundedLabel key={filter.id}
                              option={filter}
                              hasScore={filter.type === 'career' && filter.score > 0}
                              selected={true}
                              delete={() => deleteFilter(filter)}
                              hasImage={filter.type === 'industry' || filter.type === 'career'} />
            </CSSTransition>
        ) ;
    });

    const showMoreText = () => {
      if (showMore) {
          if (size.width > 1024) {
              return 'SHOW MORE FILTERS';
          } else {
              return 'SHOW CURRENT FILTERS';
          }
      } else {
          return 'HIDE FILTERS';
      }
    };

    return (
        <div className={`grid-container no-padding recommendations-container ${setActive}` + (needShowMore ? ' more' : '')}>
            <div className="grid-x grid-padding-x mar-top-10 recommendations-container__content" ref={content} style={{ maxHeight: `${setHeight}`}}>
                <TransitionGroup className="grid-x">
                    {filterItems}
                </TransitionGroup>
            </div>
            { needShowMore ? <a className="show-more" onClick={toggleShowMore}>{showMoreText()}</a> : null}
        </div>
    )

}