import React, {useRef, useEffect,useState} from 'react';
import {ErrorMessage, ErrorTooltip, SelectField} from "react-fields";

export default function CustomSelectField(props) {

    const wrapperRef = useRef(null);
    const selectRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(
        () => {
            return () => {
                if (isOpen) {
                    window.removeEventListener('scroll', close);
                    window.addEventListener('click', onClickOutside);
                }
            };
        },[]
    );

    const open = () => {
        window.addEventListener('scroll', close);
        window.addEventListener('click', onClickOutside);


        console.log('open')
        let e = document.getElementsByName("country_id");
        e[0].focus();
        e[0].options[0].scrollIntoView({block: "nearest", inline: "nearest"});
        setIsOpen(true);
    };

    const close = () => {
        window.removeEventListener('scroll', close);
        window.removeEventListener('click', onClickOutside);


        setIsOpen(false);
    };

    const onClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            close();
        }
    };

    const renderError = () => {
        switch (props.errorType) {
            case 'tooltip':
                return <ErrorTooltip message={props.errorMessage}/>;
            default:
                return <ErrorMessage message={props.errorMessage}/>;
        }
    };

    let placeholder = props.placeholder;
    if (!!props.selected) {
        placeholder = props.options.find(opt => opt.id === parseInt(props.selected)).name;
    }

    const className = `custom-sized-select-wrapper${isOpen ? ' is-active' : ''}` + (props.errorMessage ? " err" : "");

    return  (
        <div className={className}>
            <div className={`custom-sized-select-placeholder label-icon-right select-label ${!!props.selected ? 'is-selected' : ''}`}
                 onClick={() => {isOpen ? close() : open()}} ref={wrapperRef}>
                {placeholder}

            <SelectField {...{...props, errorMessage: '', size: 6, className: 'drop-down-wrapper'}} />
            </div>
            {renderError()}
        </div>
    );

}
