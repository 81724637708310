import React from 'react';

export default function ExpiredTokenForm(props) {

        return  (
            <div className="login-register">
                <div className="grid-x">
                    <div className="small-12 cell">
                        <h1 className={'login-register__header'}>Your token has been expired</h1>
                    </div>
                    <div className="small-12 cell">
                        <p>Please use forgot password to resend your instructions.</p>
                        <a onClick={props.onClickForgotPassword}>Forgot Password</a>

                    </div>
                </div>
            </div>
        )


}
