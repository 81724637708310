import React from 'react';
import PaymentAPI from '../api/paymentsAPI';
import {scrollToError} from "../utils";
import {disableCaptchaForTest} from "../settings";


class PaymentForm extends React.Component {

    state = {
        user: !!this.props.user ? {...this.props.user, ...{errors: {}}} : {email: "", email_confirmation: "", errors: {}},
        card: { errors: {}, agree: !!this.props.user && !!this.props.user.id},
        coupon: {errors: {}, code: "", applied: false},
        paypal: {...this.props.paypal, ...{errors: {}, agree: !!this.props.user && !!this.props.user.id}},
        applePay: {errors: {}, agree: !!this.props.user && !!this.props.user.id},
        isLoading: false,
        paymentMethod: 'card',
        discountAmount: this.props.discountAmount || 0,
        totalPrice: this.props.price,
        options: {}
    };

    invisibleCaptcha = React.createRef();




    onCardChange = (card) => {
        this.setState({card: card})
    };

    onCouponChange = (code) => {
        let coupon = this.state.coupon;
        coupon.errors = {};
        coupon.code = code;
        this.setState({coupon: coupon})
    };

    onPayPalChange = (paypal) => {
        this.setState({paypal: paypal})
    };

    onApplePayChange = (applePay) => {
        this.setState({applePay: applePay})
    };

    setUser = (user) => {
        this.setState({user: user});
    };


    prepareDataForSubmit = () => {
        let _this = this;
        let user = _this.state.user;
        let card = _this.state.card;
        let paypal = _this.state.paypal;
        let coupon = _this.state.coupon;
        let applePay = _this.state.applePay;
        let enrollmentId = _this.state.enrollmentId;
        let testId = _this.props.test ? _this.props.test.id : null;
        let offer_id = _this.props.offer ? _this.props.offer.id : null;
        return {userDetails: user, coupon: coupon.code, cardDetails: card, payPalDetails: paypal, career_test_id: testId,
            applePayDetails: applePay, options: this.state.options,  enrollmentId: enrollmentId, offer_id: offer_id};
    };


    validate = async () => {
        let _this = this;
        let user = this.state.user;
        let data = this.prepareDataForSubmit();
        return await PaymentAPI.validate(data, (_this.state.paymentMethod === 'card' ? 'stripe' : _this.state.paymentMethod), this.props.purchaseType).then((response) => {
            console.log('is valid');
            user.errors = {};
            let enrollment = response.enrollment;
            _this.setState({isLoading: false, enrollmentId: enrollment.id, user: {...user, id: enrollment.user_id}});
            return true;
        }).catch(async error => {
            console.log(error);
            error.json().then((value) => {
                _this.renderErrors(value);
            });
            return false;
        });
    };

    sendOrder = (token) => {
        let _this = this;
        let data = this.prepareDataForSubmit();
        PaymentAPI.submitPayment(data, (_this.state.paymentMethod === 'card' ? 'stripe' : _this.state.paymentMethod), this.props.purchaseType, token)
            .then((response) => {
                this.state.callback(response);

            }).catch(error => {
            console.log(error);
            error.json().then((value) => {
                _this.renderErrors(value);
                _this.invisibleCaptcha.current.reset();
            });
        });
    };

    sendOrderWithoutCaptcha = (onSuccess, options) => {
        this.setState({isLoading: true, options: options}, () => {
            let _this = this;
            let data = this.prepareDataForSubmit();
            PaymentAPI.updatePayment(data, (_this.state.paymentMethod === 'card' ? 'stripe' : _this.state.paymentMethod), this.props.purchaseType)
                .then((response) => {
                    onSuccess(response);
                }).catch(error => {
                console.log(error);
                error.json().then((value) => {
                    _this.renderErrors(value);
                    _this.invisibleCaptcha.current.reset();
                });
            });
        });
    };

    submitApplePay = (paymentData, onSuccess) => {
        let applePay = this.state.applePay;
        applePay.payment_data = paymentData;
        this.setState({applePay: applePay}, () => {
            this.submitOrder(onSuccess);
        })
    };

    submitOrder = (onSuccess, options = {}) => {
        this.setState({isLoading: true, callback: onSuccess, options: options}, () => {
            try {
                if (disableCaptchaForTest()) {
                    console.log('captcha disabled');
                    this.sendOrder('token');
                } else if (window.grecaptcha) {
                    this.invisibleCaptcha.current.execute();
                }
            } catch (error) {
                console.warn('something is wrong with invisible captcha....');
                console.log(error);
            }
        });
    };


    renderErrors = (value) => {
        let user = this.state.user;
        let card = this.state.card;
        let paypal = this.state.paypal;
        let applePay = this.state.applePay;
        let newErrorState = {isLoading: false};
        if(value.user) {
            user.errors = value.user;
            newErrorState['user'] = user;
        }
        if(value.card) {
            card.errors = value.card;
            newErrorState['card'] = card;
        }
        if(value.paypal) {
            paypal.errors = value.paypal;
            newErrorState['paypal'] = paypal;
        }
        if(value.apple_pay) {
            applePay.errors = value.apple_pay;
            newErrorState['applePay'] = applePay;
        }
        this.setState(newErrorState, () => {scrollToError(230);});
    };



    changePaymentMethod = (e, method) => {
        e.preventDefault();
        this.setState({paymentMethod: method, enrollmentId: null})
    };


    render() {
       return null;
    }



}
export default PaymentForm;
