import React from "react";
import {actions} from "react-fields";

export default function (props) {

    const searchUrl = () => {
        return `/jobs/search?${actions.paramsToURI(props.searchParams)}`
    };

    const salaryRange = () => {
        //return props.salary_from && props.salary_to ? `$${props.salary_from} - $${props.salary_to}` : 'N/A'
        return props.salary_to ? `up to $${props.salary_to}` : 'N/A'
    }

    return(
        <a href={searchUrl()} className={'card card--jobs'}>
            <div className={'card__content'}>
                <h3 className={'card__jobs-title' + (props.addClass ? ` card__jobs-title${props.addClass}` : '')}>{props.title}</h3>
                <div className="flex-middle spaced-between">
                    <div className={'card__jobs-salary'}>{props.count} openings</div>
                    {!props.career &&<a href={searchUrl()} target={'_blank'} className="card__search">Search <span className={'small-icons next-arrow-green-s'}/></a>}
                </div>
                {props.career &&
                    <div className={'card__jobs-recommended'}>
                        <div className={'card__jobs-score'}><span className={'small-icon white-tooltip-icon'}/>{`because you scored ${props.career.score}% for ${props.career.name}`}</div>
                        <a href={searchUrl()} target={'_blank'} className="card__search">Search <span className={'small-icons next-arrow-green-s'}/></a>
                    </div>
                }
            </div>
        </a>
    )
}