import React, { Component, Fragment } from 'react';
import TermsCheckboxField from "../base/termsCheckbox";
import {InputTypeField, ErrorMessage} from "react-fields";
import {FadeInSection} from "../../components/fadeIn";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PayPalSettings from '../paypalSettings';
import LoggingApi from "../api/loggingAPI";
import CouponInput from "./couponInput";
import TotalPrice from "./totalPrice";

class PayPalForm extends Component {

    initialOptions = {
        "client-id": PayPalSettings.client_id,
        currency: this.props.price.currency.code,
    };


    handleSubmit = (details) => {
        this.props.pay(this.onSuccess, details);
    };

    handleFieldChange = (e) => {
        let paypal = this.props.paypal;
        let field_name = e.target.name;
        paypal[field_name] = e.target.checked;
        paypal.errors.base = "";
        this.props.onChange(paypal);
    };

    onError = (error) => {
        let paypal = this.props.paypal;
        paypal.errors.system = error;
        this.props.onChange(paypal);
    };

    onSuccess = (response) => {
        let enrollment = response.enrollment;
        window.location.href = `${this.props.afterPaymentUrl}/${enrollment.encrypted_key}`;
    };

    renderPayPalButton = (_this) => {
        return (
            <PayPalScriptProvider options={this.initialOptions}>
                <PayPalButtons style={{
                    layout: 'horizontal',
                    tagline: 'false',
                    shape: 'pill',
                    height: 44,
                    size: "large",
                    label: "checkout"
                }}
                               createOrder={(data, actions) => {
                                   console.log(this.props.price.currency.code);
                                   return actions.order.create({
                                       intent: "capture",
                                       purchase_units: [{
                                           description: this.props.description,
                                           amount: {
                                               currency_code: this.props.price.currency.code,
                                               value: this.props.totalPrice,
                                           }
                                       }]
                                   });
                               }}
                               onClick={async (data, actions) => {
                                   let userValid = await _this.props.validate();
                                   if (!userValid) {
                                       return actions.reject();
                                   } else {
                                       return actions.resolve();
                                   }
                               }}

                               onApprove={(data, actions) => {
                                   return actions.order.capture().then((details) => {
                                       LoggingApi.create('paypal', details);
                                       console.log(details);
                                       _this.handleSubmit(details);
                                   });
                               }}
                               onCancel={() => {

                               }}
                               onError={(err) => {
                                   _this.onError("Unable to proceed transaction, system error");
                                   console.log('ONERROR:');
                                   console.log(err);
                                   if (!!err) {
                                       LoggingApi.create('paypal', err.message);
                                       LoggingApi.create('paypal', err.stack);
                                   } else {
                                       LoggingApi.create('paypal', 'Error undefined');
                                   }
                               }}
                />
            </PayPalScriptProvider>
        )
    };

    renderTotalPrice = () => {
        return (
            !this.props.hideTotalArea &&
            <TotalPrice price={this.props.price} vatType={this.props.vatType}
                        discountAmount={this.props.discountAmount}
                        student_code={!!this.props.user.student_discount_code || (this.props.coupon && !!this.props.coupon.student_discount)}/>
        )
    };

    renderTermsCheckBox = (paypal, termsError) => {
        return (
            this.props.user && !!this.props.user.id ? null :
                    <TermsCheckboxField name="agree" value={paypal.agree}
                                        errorMessage={termsError}
                                        errorTypeMessage={true}
                                        onChange={this.handleFieldChange}/>
        )
    };

    render() {
        let paypal = this.props.paypal;
        let hideCouponArea = this.props.hideCouponArea;
        let _this = this;
        const termsErrorMessage = 'Terms and Privacy must be accepted';
        let  termsError = paypal.errors.base || "";
        return(
            <FadeInSection>
                <div className="tabs-panel is-active tabs-panel-paypal">
                    <p className="secured-msg">You'll be taken to PayPal to complete your purchase.</p>
                    <div className="grid-x align-middle">
                        {this.props.purchaseType === 'license' ?
                            <div className={`small-12 cell `}>
                                {this.renderTermsCheckBox(paypal,termsError)}
                                <div className={`license-checkout__submit license-checkout__submit-license paypal-group-checkout ${(termsError) && (termsError.trim() === termsErrorMessage) ? "termsError" : " "} `}>
                                    {this.renderPayPalButton(_this)}
                                    {this.renderTotalPrice()}
                                </div>
                                <ErrorMessage message={paypal.errors.system}/>
                            </div>
                        :
                            <React.Fragment>
                                <div className={`medium-6 cell large-order-1 medium-order-1 small-order-2`}>
                                    {this.renderTermsCheckBox(paypal,termsError)}
                                    <div className={`license-checkout__submit license-checkout__submit-license ${(termsError) && (termsError.trim() === termsErrorMessage) ? "termsError" : " "} `}>
                                        {this.renderPayPalButton(_this)}
                                    </div>
                                </div>
                                <div className={` medium-6 cell medium-flex-end medium-order-2 large-order-2 small-order-1`}>
                                    {this.renderTotalPrice()}
                                </div>
                                <ErrorMessage message={paypal.errors.system}/>
                            </React.Fragment>
                        }
                    </div>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            {!hideCouponArea &&
                            <CouponInput show_student_discount={this.props.show_student_discount}
                                         discountAmount={this.props.discountAmount}
                                         coupon={this.props.coupon}
                                         onChangeCoupon={this.props.onCouponChange}
                                         validateCouponCode={this.props.validateCouponCode}
                                         student_discount_code={this.props.user.student_discount_code}
                            />}
                        </div>
                    </div>
                </div>
            </FadeInSection>
        )
    }
}

export default PayPalForm;
