import React from 'react';
import NewsletterCheckboxField from "./newsletterCheckbox";
import {TooltipPortal, TooltipPopover} from "../tooltip/tooltip";

class NewsletterBox extends React.Component {

    state = {
        showTooltip: false
    };

    tooltipRef = React.createRef();

    newsletterTooltip = () => {
        return (<TooltipPortal areaRef={this.tooltipRef}>
            <TooltipPopover>
                <span>The Dose by CareerAddict is sent 3 times per week and covers the latest and best career success hacks! Unsubscribe anytime, it's FREE!</span>
            </TooltipPopover>
        </TooltipPortal>);
    };

    render() {
        return (
            <React.Fragment>
                {this.state.showTooltip && this.newsletterTooltip()}
                <div className={'input-tick-wrapper'} ref={this.tooltipRef}
                     onMouseOver={() => {
                         this.setState({showTooltip: true});
                     }}
                     onMouseLeave={() => {
                         this.setState({showTooltip: false});
                     }}>
                    <NewsletterCheckboxField name="newsletter_subscription" value={this.props.newsletter_subscription}
                                             errorTypeMessage={this.props.errorType !== 'tooltip'}
                                             onChange={this.props.onHandleChange}/>
                </div>
            </React.Fragment>
        )
    }

}

export default NewsletterBox;