import React from "react";
import {Loader, ErrorMessage, actions, InputTypeField} from 'react-fields';
import loginAPI from '../api/loginAPI';
import PopUp from "../base/popup";
import HoverButton from "../buttons/HoverButton";

import PasswordStrength from '../base/passwordStrength';
import MatchField from '../base/fieldMatch';
import UserValidation from "../validators/user";

class SetNewPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            user: {reset_password_token: this.props.reset_password_token, errors: {}},
            email: "",
            errors:{},
            validatePswdMatch: false
        };

        this.passwordSavedPopup = React.createRef();
        this.pswdStrength = React.createRef();
        this.pswdMatch = React.createRef();
    }

    handleFieldChange = (e) => {
        let user = this.state.user;
        let field_name = e.target.name;
        user[field_name] = e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        this.setState({user: user});
        if (field_name === 'new_password' || field_name === 'password_confirmation') {
            let validatePswdMatch = this.state.validatePswdMatch;
            if (field_name === 'new_password') {
                const res = this.pswdStrength.current.checkValue(user.new_password);
                validatePswdMatch = res !== 'bad';
                this.setState({validatePswdMatch: validatePswdMatch});
            }
            this.pswdMatch.current.checkValue(user.new_password, user.password_confirmation, !validatePswdMatch);
        }
    };

    renderErrors = (errors) => {
        let user = this.state.user;
        user.errors = errors;
        this.setState({isLoading: false, user: user});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        UserValidation.changePassword(this.state.user, () => {
            loginAPI.updatePassword(this.state.user, this.onSuccess, this.renderErrors);
        }, this.renderErrors);
        // let [pswdValidRes, pswdErrorMsg] = validatePassword(this.state.user.password);
        // if (pswdValidRes) {
        //     loginAPI.updatePassword(this.state.user, this.onSuccess, this.renderErrors)
        // } else {
        //     this.renderErrors({password: pswdErrorMsg});
        // }
    };

    onSuccess = () => {
        this.setState({isLoading: false});
        this.passwordSavedPopup.current.open();
    };

    render(){
        let user = this.state.user;
        return(
            <React.Fragment>
                <form className="form-container grid-x" onSubmit={this.handleSubmit}>
                    {this.state.isLoading ? <Loader size="small-ok"/> : null}
                    <h3>Reset Password</h3>

                    <div className={'cell'}>
                        <PasswordStrength ref={this.pswdStrength} value={user.new_password} >
                            <InputTypeField onKeyPress={this.submitOnEnter} name="new_password"
                                            onChange={this.handleFieldChange} value={user.new_password}
                                            placeholder="New Password*" type="password" errorMessage={user.errors.password || user.errors.newPassword}/>
                        </PasswordStrength>
                    </div>
                    <div className={'cell'}>
                        <MatchField value={user.password_confirmation} match_value={user.new_password} ref={this.pswdMatch}>
                            <InputTypeField onKeyPress={this.submitOnEnter} name="password_confirmation"
                                            onChange={this.handleFieldChange} value={user.password_confirmation}
                                            placeholder="Confirm Password*" type="password" errorMessage={user.errors.passwordConfirmation}/>
                        </MatchField>
                    </div>
                    <ErrorMessage message={user.errors.base}/>

                    <div className="grid-x align-middle">
                        <div className="cell text-center">
                            <HoverButton onClick={this.handleSubmit}>
                                Save
                            </HoverButton>
                        </div>
                    </div>
                </form>

                <PopUp ref={this.passwordSavedPopup} onClose={() => {document.location='/dashboard'}}>
                    <h3 className='text-center'>Password Changed!</h3>
                </PopUp>
            </React.Fragment>
        );
    }
}
export default SetNewPassword;