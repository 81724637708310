import React, { Component } from 'react';
import TermsCheckboxField from "../../components/base/termsCheckbox";
import HoverButton from "../buttons/HoverButton";
import {actions} from "react-fields";
import {FadeInSection} from "../../components/fadeIn";
import TotalPrice from "../../components/payment/totalPrice";

class BankwireForm extends Component {

    state = {
        price: this.props.price
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.pay(this.onSuccess);
    };

    handleFieldChange = (e) => {
        let user = this.props.user;
        let field_name = e.target.name;
        user[field_name] = e.target.checked;
        user.errors[actions.camelize(field_name)] = "";
        this.props.onChange(user);
    };

    onSuccess = (response) => {
        window.location.href = `${this.props.afterPaymentUrl}/${response.enrollment.encrypted_key}`;
    };

    render() {
        let user = this.props.user;
        return(
            <FadeInSection>
                <div className="tabs-panel is-active">
                    <div className="grid-x">
                        <div className="medium-10 small-12 cell">
                            <p className="license-checkout__info">To make a bankwire transfer, please click ‘Request details’ and a customer support representative will contact you with our bank details.</p>
                        </div>
                        <div className="small-12 cell">
                            <div className="license-checkout__submit license-checkout__submit--bankwire">
                                <TotalPrice price={this.props.price} vatType={this.props.vatType}
                                            discountAmount={this.props.discountAmount}
                                            student_code={!!this.props.user.student_discount_code || (this.props.coupon && !!this.props.coupon.student_discount)}/>
                            </div>
                            <div className="license-checkout__terms-area">
                                    {this.props.user && !!this.props.user.id ? null :
                                        <TermsCheckboxField name="agree_terms" value={user.agree_terms}
                                                            errorMessage={user.errors.agreeTerms}
                                                            onChange={this.handleFieldChange}/> }
                                    <div>
                                        <HoverButton onClick={this.handleSubmit}>
                                            Request details
                                        </HoverButton>
                                    </div>
                               </div>
                        </div>
                    </div>
                </div>
            </FadeInSection>
        )
    }
}

export default BankwireForm;
