import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import HoverButton from "../buttons/HoverButton";
export default function LeavingPopup(props) {

    const course = props.course;

    return (

            <div className="grid-x align-center">
                <div className="large-8 medium-12 small-12 cell leaving-popup__left">
                    <p className="leaving-popup__side show-for-small-only" style={{marginBottom:'-10px'}}>Offered by</p>
                    <div className="leaving-popup__logo show-for-small-only" style={{marginLeft:'-8px',background: `url("${course.logo_url}") center left / contain no-repeat`}}/>
                    <h3 className="mar-bot-10">{course.name}</h3>
                    <div className="leaving-popup__right hide-for-large">
                        <p className="leaving-popup__side hide-for-small-only">Offered by</p>
                        <div className="leaving-popup__logo hide-for-small-only" style={{marginLeft:'-8px',background: `url("${course.logo_url}") center left / contain no-repeat`}}/>
                        {course.parsed_runs.length > 0 ?
                            <React.Fragment>
                                <p className='leaving-popup__course leaving-popup__course--space'><span>Start dates:</span></p>
                                {course.parsed_runs.map((start) => {
                                    return <p className='leaving-popup__course'>{start.start_date} / {start.duration_in_weeks} weeks duration</p>;
                                })}
                            </React.Fragment>
                            :
                            course.duration && <p className='leaving-popup__course'><b>{course.duration} weeks duration</b></p>
                        }
                    </div>
                    {course.level && <p className='leaving-popup__course leaving-popup__course--big'>{course.level} level{course.has_certificates ?`,professional certificate` : ''}</p>}
                    {course.introduction && <p className='leaving-popup__course' >{course.introduction}</p>}
                    {/*{course.learning_outcomes && <ul className={'leaving-popup__course leaving-popup__course--list'}>*/}
                    {/*    {course.learning_outcomes.map((out) => {*/}
                    {/*        return <li>{out}</li>*/}
                    {/*    })}*/}
                    {/*</ul>}*/}
                    {/*{course.requirements &&*/}
                    {/*<React.Fragment>*/}
                    {/*    <p className='leaving-popup__course'><span>Requirements:</span></p>*/}
                    {/*    <p className='leaving-popup__course' dangerouslySetInnerHTML={{__html: course.short_requirements}}/>*/}
                    {/*</React.Fragment>*/}
                    {/*}*/}
                </div>
                <div className="large-4 medium-12 small-12 cell leaving-popup__right show-for-large">
                    <p className="leaving-popup__side">Offered by</p>
                    <div className="leaving-popup__logo " style={{marginRight:'-8px',background: `url("${course.logo_url}") center right / contain no-repeat`}}/>
                    {course.parsed_runs.length > 0 ?
                        <React.Fragment>
                            <p className='leaving-popup__course leaving-popup__course--space'><span>Start dates:</span></p>
                            {course.parsed_runs.map((start) => {
                                return <p className='leaving-popup__course'>{start.start_date} / {start.duration_in_weeks} weeks duration</p>;
                            })}
                        </React.Fragment>
                        :
                        course.duration && <p className='leaving-popup__course'>{course.duration} weeks duration</p>
                    }
                </div>
                <div className="large-8 medium-12 small-12 cell large-order-1 medium-order-1 small-order-2">
                    <p className="leaving-popup__tip"><span className={"small-icons blue-off-tooltip"}/>You will be taken to the job provider website for full details.</p>
                </div>
                <div className="large-4 medium-12  small-12 cell large-flex-end large-order-2 medium-order-2 small-order-1">
                    <HoverButton href={course.url} hrefOpts={{target: '_blank'}} className="button--with-icon button--bigger">
                        <span>Enroll via&nbsp;</span>FutureLearn&nbsp;<span className="small-icons apply-icon"/>
                    </HoverButton>
                </div>
            </div>

    )
}