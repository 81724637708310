import React, { Component } from 'react';
import SliderTests from './sliderTests';
import careerTestApi from '../api/careerTestAPI';
import { Provider } from 'react-redux';
import store from '../store/store';

class Index extends Component {

    state = {
        user: !!this.props.user ? {...this.props.user, ...{confirmed: !!this.props.confirmed}} : {},
        progress: {}
    };


    componentDidMount() {
      if (!this.state.user.id) {
          this.setState({user: {interests_status: localStorage.getItem('interests_status') || 'not_started',
                  personality_status: localStorage.getItem('personality_status') || 'not_started',
                  motivators_status: localStorage.getItem('motivators_status') || 'not_started',
                  abstract_status: 'not_started',
                  numerical_status: 'not_started',
                  verbal_status: 'not_started'}})
      }
      this.loadProgress().then((progress) => {
          let progressPercents = {};
          Object.keys(progress).map(code => {
              let tempAnswers = localStorage.getItem(code);
              let questionsCount = progress[code].total;
              let answersCount = progress[code].answered;
              if(tempAnswers) {
                  tempAnswers = JSON.parse(tempAnswers);
                  answersCount = tempAnswers.filter((q) => q.answered || !!q.answer_variant_id ).length;
              }
              progressPercents[code] = Math.round(answersCount/questionsCount*100);
          });
          this.setState({
              progress: progressPercents
          });
      });
    }

    loadProgress = async () => {
        let progress = {};
        await careerTestApi.getProgress().then( (result) => {
            progress = result.progress;
        }).catch((error) => {
            console.error(error);
        });
        return progress;
    };

    confirmationRequired = () => {
        let user = this.state.user;
        return (!user.confirmed && (user.interests_status === 'finished' || user.personality_status === 'finished' || user.motivators_status === 'finished'));
    };


    render() {
        return (
            <Provider store={store}>
              <SliderTests options={this.props.tests} user={this.state.user} progress={this.state.progress} confirmationRequired={this.confirmationRequired()} />
            </Provider>
        );
    }
}

export default Index;