import React from "react";
import {validatePassword, hasLowerCaseChar, hasUpperCaseChar} from "../validators/password";

const shortPass = 'short';
const badPass = 'bad';
const averagePass = 'average';
const goodPass = 'good';
const strongPass = 'strong';
const messages = {short: 'Too short password', bad: 'Password not strong enough', average: 'Password not strong enough', good: 'Password not strong enough', strong: 'Strong password'};

class PasswordStrength extends React.Component {
    state = {
        code: ""
    };

    checkValue = (value) => {
        if (!value || value.length === 0){
            return this.setCode("")
        }
        // let [pswdValidRes, pswdErrorMsg] = validatePassword(value);
        // if (!pswdValidRes) { return this.setCode(badPass); }

        // if (value.length < 8 ) {
        //     return this.setCode(shortPass);
        // }

        let score = this.calculateScore(value);

        if ( score < 0 ){score = 0;}
        if ( score > 100 ){score = 100;}

        switch (true) {
            case score <= 25:
                return this.setCode(badPass);
            case score > 25 && score <= 50:
                return this.setCode(averagePass);
            case score > 50 && score <= 75:
                return this.setCode(goodPass);
            default:
                return this.setCode(strongPass);
        }
    };

    setCode = (code) => {
        this.setState({code: code});
        return code;
    };

    calculateScore = (value) => {
        let score = 0;

        if (value.match(/\d/)) {score += 25;}
        if (value.length >= 8) {score += 25;}
        if (hasLowerCaseChar(value)) {score += 25;}
        if (hasUpperCaseChar(value)) {score += 25;}

        // password length
        // score += value.length * 4;
        // [1,2,3,4].map((i) => {
        //     score += ((this.checkRepetition(i,value).length - value.length ));
        // });
        // //password has 3 numbers
        // if (value.match(/(.*[0-9].*[0-9].*[0-9])/)){ score += 5;}
        //
        // //password has 2 symbols
        // if (value.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)){ score += 5 ;}
        //
        // //password has Upper and Lower chars
        // if (hasLowerAndUpperCaseChar(value)){  score += 10;}
        //
        // //password has number and chars
        // if (hasAnnyLetter(value) && value.match(/([0-9])/)){  score += 15;}
        // //
        // //password has number and symbol
        // if (value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && value.match(/([0-9])/)){  score += 15;}
        //
        // //password has char and symbol
        // if (value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && hasAnnyLetter(value)){score += 15;}
        //
        // //password is just a numbers or chars
        // if (value.match(/^\w+$/) || value.match(/^\d+$/) ){ score -= 10;}

        return score;
    };

    checkRepetition = (pLen,str) =>  {
        let res = "";
        for (let i=0; i<str.length ; i++ )
        {
            let repeated=true;

            for (let j=0;j < pLen && (j+i+pLen) < str.length;j++){
                repeated=repeated && (str.charAt(j+i)===str.charAt(j+i+pLen));
                if (j<pLen){repeated=false;}
            }

            if (repeated) {
                i+=pLen-1;
                repeated=false;
            }
            else {
                res+=str.charAt(i);
            }
        }
        return res;
    };


    render(){
        return(
            <div className={`strong-validation ${this.state.code}`}>
                {this.props.children}
                {!!this.state.code && <React.Fragment>
                    {messages[this.state.code]}
                    <div className={"line-block"}>
                        <div className="line"/>
                    </div>
                    <div className={"validation-msg"}>Password must have at least 8 characters, uppercase and lowercase letters, and numbers.</div>
                </React.Fragment> }
            </div>
        );

    }
}
export default PasswordStrength;