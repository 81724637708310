import React, {useRef} from 'react';
import {FadeInSection} from "../fadeIn";
import HoverButton from "../buttons/HoverButton";
import PopUp from "../base/popup";
import LeavingPopup from "./leavingPopup";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

export default function Job(props) {

    let job = props.job;

    let logoStyle = job.company_logo_url ? {background: `url('${job.company_logo_url}') no-repeat`, backgroundPosition: 'center center'} : {};

    const dateFormat = (date) => {
        let d = new Date(date);

        return `${d.getDate()} ${months[d.getMonth()+1]} ${d.getFullYear()}`
    };

    const previewPopup = useRef(null);

    const openPreview = (e) => {
        e.preventDefault();
        previewPopup.current.open();

    };

    return (
        <React.Fragment>

        <FadeInSection>
            <a onClick={openPreview}>
                <div className="grid-x jobs disable-fade-in">
                    <div className="large-10 medium-12 cell">
                        <div className="jobs__name">
                            <h3 className={'disable-fade-in'} dangerouslySetInnerHTML={{__html: job.title}}/>
                            <h4 className={'disable-fade-in'}>{job.get_company_name}<span> on {dateFormat(job.posted_date)}</span></h4>
                        </div>
                        <div className="jobs__details">
                            <div className="grid-x">
                                <div className="medium-3 small-12 cell">
                                    <p className={'disable-fade-in'}>{job.job_type_name}</p>
                                </div>
                                <div className="medium-3 small-12 cell">
                                    <p className={'disable-fade-in'}>{job.display_salary}</p>
                                </div>
                                <div className="medium-3 small-12 cell">
                                    <p className={'disable-fade-in'}>{job.display_location}</p>
                                </div>
                                <div className="medium-3 small-12 cell medium-flex-end-top">
                                    <HoverButton className={'button button--white-off'} href={job.application_link} hrefOpts={{target: '_blank'}}>Go to job <span className={'small-icon right-arrow'}/></HoverButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="large-2 cell show-for-large">
                        {job.company_logo_url ?
                            <div className="jobs__logo" style={logoStyle}/>
                            : null }
                    </div>
                </div>
            </a>
        </FadeInSection>
            <PopUp ref={previewPopup}  className="leaving-popup">
                <LeavingPopup job={job} />
            </PopUp>
        </React.Fragment>
    )

}