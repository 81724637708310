import React from 'react';
import HoverButton from '../../buttons/HoverButton';

export default function SaveButton(props) {
    return  (
        <HoverButton className="capsule" color={'white'} onClick={props.onClick}>
            <span className="small-icon capsule__icon envelope-white" style={{margin: "6px 10px 0 0"}}/>
            {props.type === 'short' ? <p className="capsule__text capsule__save-progress">Save<span className="hide-for-small-only"> your progress</span></p> :
                <p className="capsule__text capsule__save-progress">Save your progress</p> }
        </HoverButton>
    )
}