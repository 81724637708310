import React from 'react';

export default function ConfirmationSentModal(props) {
    return  (
        <div className="grid-x align-center confirmation-popup">
            <div className="cell text-center">
                <div className="large-icon email-sent"/>
                <h3 className="mar-bot-10">Confirmation mail sent!!</h3>
                <p className='popup__confirm_text'>We sent a confirmation email to <span className="popup__email-address">{props.email}</span>.</p>
                <p className='popup__text'>
                    If you don’t see the email, please ensure to check your junk or promotional folders.
                </p>
                {props.newsletterSubscription && <div className={'popup__box'}>
                    <p className={'popup__confirm_text mar-0'}>🚨 NOTE: You’ve also received a welcome mail for The Dose!</p>
                </div>}
                <a href={props.testResult || "/dashboard"} className="button button--green--large">{props.testResult ? 'Continue to Results' : 'Return to dashboard'}<span
                    className="small-icons next-arrow-white-l"/><span className="button__hovered"/>
                </a>
            </div>
        </div>
    )
}