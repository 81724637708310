
import React, {useState, useEffect} from 'react';
import Bubble from './bubble';
import {useSelector} from "react-redux";
import {calcUserProgress} from "../../utils";

export default function ProgressBubble(props) {

    const [progress, setProgress] = useState(0);

    const user = useSelector(state => state.userStatus);


    useEffect(() => {
        let progress = calcUserProgress(user);
        setProgress(progress);
    });

    return (
        <React.Fragment>
            <div className="flex-middle is-spaced">
                <span className="match-info">{progress === 100 ? 'Your Career Matches are ready!' :
                    'Discover your career matches by completing all 6 tests'}</span>
                <Bubble score={progress} hideMatch={false} matchText={'complete'}/>
            </div>
        </React.Fragment>
    );

}

