import React from 'react';
import { useSelector } from "react-redux";

export default function ProfileCompleteInfo(props) {

    let completed = useSelector(state => state.filters.profileCompleted);
    let page = useSelector(state => state.filters.page);

    let readyMessages = {
        careers: 'Your career matches are ready',
        jobs: 'Job searches relating to your test scores',
        courses: 'Recommended courses based on your results'};

    let link = "/dashboard";

    let completeMessages = {
        careers: <span className="capsule__status">Discover your career matches by  <a href={link} className="capsule__link">completing all tests</a></span>,
        jobs: <span className="capsule__status">Complete all tests to get tailored job suggestions</span>,
        courses: <span className="capsule__status">Get relevant courses by <a href={link} className="capsule__link">completing all tests</a></span>
    };


    return (
        <div className="capsule capsule--status capsule--responsive small-order-1 large-order-1">
            {completed ?
                <React.Fragment>
                    <span className="capsule__dot capsule__dot--green"/>
                    {readyMessages[page]}
                </React.Fragment> :
                <React.Fragment>
                    <span className="capsule__dot capsule__dot--red"/>
                    {completeMessages[page]}
                </React.Fragment>
            }
        </div>
    )
}