import React, {Component,Suspense } from "react";
import Slider from "react-slick";
import SliderArrow from "../../base/sliderArrow";
import {FadeInSection} from "../../fadeIn";
import ReactDOM from "react-dom";
import {isSmallScreen} from "../../utils";
import Review from './review';

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
    prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
    className: 'review__slider',
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class ReviewSlider extends Component {
    sliderRef = React.createRef();

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS,
        readMoreCntrl: null
    };

    componentDidMount() {
        let readMore = new ReadMoreLess(ReactDOM.findDOMNode(this.sliderRef.current), 100, false);
        this.setState({readMoreCntrl: readMore});
    }

    handleSlideChange = () => {
        if (!isSmallScreen()) {
            return;
        }
        this.state.readMoreCntrl.collapseAll();
        const slider = ReactDOM.findDOMNode(this.sliderRef.current);
        const bounding = slider.getBoundingClientRect();
        const tabContentVisibleY = bounding.top - 80;
        if (tabContentVisibleY < 0 || tabContentVisibleY > (window.innerHeight || document.documentElement.clientHeight)) {
            window.scrollTo({
                top: window.scrollY + bounding.top-60,
                behavior: "smooth"
            });
        }
    };

    render() {
        return(
            <FadeInSection>
                <Slider {...this.state.settings} beforeChange={this.handleSlideChange} ref={this.sliderRef}>
                    {this.props.reviews.map((review) => {
                        return (
                              <Review review={review} key={review.id} />
                        )
                    })}

                </Slider>
            </FadeInSection>
        );
    }
}