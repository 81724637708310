import React from 'react';
import LoginAPI from "../api/loginAPI";
import {Loader} from "react-fields";

export default class ConfirmAccountModal extends React.Component{

    state = {
        isLoading: false,
        sent: false
    };

    resendEmail = (e) => {
        e.preventDefault();
        let _this = this;
        this.setState({isLoading: true}, () => {
            LoginAPI.resendAccountInstructions(this.props.user).then( () => {
                _this.setState({isLoading: false, sent: true});
            }).catch(error => {
                console.log(error);
            });
        });
    };

    render(){
        return  (
            <div className="grid-x align-center">
                {this.state.isLoading ? <Loader/> : null}
                {this.state.sent ?
                    <div className="cell text-center">
                        <h3 className="mar-bot-10">Activation link resent</h3>
                        <p className='popup__text'>We sent an email to {this.props.user.email} with a new confirmation link to finish setting up your account.
                            If you don’t see the email, please check your junk or promotional folders.</p>
                    </div>
                    :
                    <div className="cell text-center">
                        <h3 className="mar-bot-10">Check your email</h3>
                        <p className='popup__text'>Please check your inbox for a link to finish setting up your account.
                            If you don’t see the email, please check your junk or promotional folders.</p>
                        <p className="popup__text">Didn't receive the link?
                            <button onClick={this.resendEmail} className="button button-link">Resend<span className="hovered"/>
                            </button></p>
                    </div>
                }
            </div>
        )
    }

}