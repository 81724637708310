import React, {useRef, useState} from 'react';
import {TextField, Loader} from "react-fields";
import HoverButton from "../../affiliates_components/buttons/HoverButton";
import StudentDiscountApi from "../api/studentDiscountAPI";

export default function NotFoundModal(props) {

    const [email, setEmail] = useState(props.email);
    const [sent, setSent] = useState(false);
    const [isLoading, setLoader] = useState(false);

    const requestNewDomain = () => {
        if(!!email){
            setLoader(true);
            StudentDiscountApi.request(email).
            then((response) => {
                setSent(true);
                setLoader(false);
            }).catch(error => {
                if (error.status === 422) {
                    error.json().then( (value) => {
                        console.error(value);
                    });
                } else {
                    console.error(error);
                }
                setLoader(false);
            });
        }
    };

    const form = <React.Fragment>
        <div className="large-icon not-verified small-mar-bot-20"/>
        <h3 className="mar-bot-10">Email domain not verified</h3>
        <p className='popup__text-large mar-bot-30'>Sorry, we couldn’t verify your email from our list of approved school domains.</p>
        <div>
            <p className="popup__text-large popup__text-large--bold">Submit your student domain for consideration</p>
            <div className="input-with-button small-12 cell">
                <div className="small-icons green-letter"/>
                <TextField name="email" placeholder="john.doe@nyu.edu"
                           value={email} onChange={(e) => setEmail(e.target.value)}/>
                <HoverButton onClick={requestNewDomain} className={' button--with-icon'}>
                    Submit <span className="small-icons next-arrow-white-l"/>
                </HoverButton>
            </div>
            <p className="popup__terms"><span className={'small-icons blue-off-tooltip'}/>Approval process takes 2–3 business days.</p>
        </div>
    </React.Fragment>;

    const message = <React.Fragment>
        <h3 className="mar-bot-10">Thank you</h3>
        <p className='popup__text'>We will review your domain within 2-3 business days</p>
    </React.Fragment>;

    return  (
        <div className="grid-x align-center">
            {isLoading && <Loader/>}
            <div className="cell text-center">
                {sent ? message : form}
            </div>
        </div>
    )
}