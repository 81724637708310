import {actions} from 'react-fields'
import cf from "../common";

export default class TestsApi {

    static async save(test, onSuccess, onError) {
        let valid = true;
        let errorRes = '';

        await this.saveTest(test).then( async(response) => {
            onSuccess();
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                errorRes = value;
            });
        });

        if (!valid) {
            console.log(errorRes);
            onError(errorRes);
        }
    }

    static async saveTest(test){
        const url = '/api/admin/career_tests';
        let fields = ['name', 'path', 'time', 'introduction', 'instructions', 'description', 'assessment_description', 'bullet_description',
            'mean', 'standard_deviation', 'meta_title', 'meta_description', 'result_description'];
        let data = {};
        fields.map(f => (data[f] = test[f] || ''));

        if (test.type === 'aptitude') {
            data['questions_attributes'] = QuestionsPreparation.prepareQuestions(test.questions, test.itemsDeleted);
            data['questions_attributes'].push(...QuestionsPreparation.prepareQuestions(test.exampleQuestions, []));
        } else {
            data['statements_attributes'] = QuestionsPreparation.prepareQuestions(test.items, test.itemsDeleted);
        }

        return fetch(actions.createOrUpdateRequest(url, {career_test: data}, test.id ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static validate(test, onSuccess, onError) {
        let errors = {};

        ['name', 'path', 'introduction', 'instructions', 'description', 'result_description'].map((name) => {
            if (!test[name])
                errors[cf.camelize(name)] = `${cf.capitalize(name)} is required`;
        });

        if (!!test.timed && !test.time) {
            errors['time'] = 'Time is required';
        }

        if (test.type === 'aptitude') {
            ['mean', 'standard_deviation'].map((name) => {
                if (!test[name])
                    errors[cf.camelize(name)] = `${cf.capitalize(name)} is required`;
            });
            if (!test.questions || test.questions.length <= 1) {
                errors['items'] = 'Items required';
            }
        } else {
            if (!test.items || test.items.length <= 1) {
                errors['items'] = 'Items required';
            }
        }



        if (Object.keys(errors).length) {
            onError(errors);
        } else {
            onSuccess();
        }
    }

}

class QuestionsPreparation {

    static prepareQuestions(questions, questionsDeleted) {
        let preparedQuestions = [];
        preparedQuestions.push(...QuestionsPreparation.prepareQuestionsAttributes(questions, false));
        preparedQuestions.push(...QuestionsPreparation.prepareQuestionsAttributes(questionsDeleted, true));
        return preparedQuestions;
    }

    static prepareQuestionsAttributes(questions, isDeleted) {
        /* remove extra fields and update number: */
        return questions.map(({key, career_test_id, answer_variants, answers, answersDeleted, ...attrs}, i) => (
            {...attrs, ...{number: i+1, _destroy:  (isDeleted ? '1' : '0')}}
        ))
    }

}