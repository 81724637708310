import React from 'react';
import HoverButton from './HoverButton';
import {FadeInSection} from "../fadeIn";

export default class StartTestButton extends React.Component {

    constructor(props){
        super(props);

    }



    render() {
        return  (
            <React.Fragment>
                <FadeInSection>
                    <HoverButton href={`/tests/${this.props.test.path}`} color={'white'} >
                        Learn More
                    </HoverButton>
                </FadeInSection>
            </React.Fragment>
        )
    }




}
