import {actions} from 'react-fields'

class CoursesAPI {

    static search(options) {
        const url = '/api/courses/search';

        return fetch(actions.getData(`${url}?${actions.paramsToURI(options)}`))
            .then(actions.status)
            .then(actions.json)
    }

    static relevant(limit = 20) {
        const url = `/api/courses/relevant?limit=${limit}`;

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static featured() {
        const url = '/api/courses/featured';

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }


}

export default CoursesAPI;