import React, {useEffect, useState} from 'react'
import careersAPI from "../../api/careersAPI";
import Label from '../roundedLabel';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function Careers(props) {

    let [careers, setCareers] = useState([]);

    let filteredCareers = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'career'));
    let initialFilters = useSelector(state => state.filters.initialFilters['careers']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            careersAPI.getCollection().then((collection) => {
               setCareers(collection);
            }).catch(error => {
                  console.log(error);
             });
        }, []
    );

    useEffect(
        () => {
            if (careers.length === 0 || !initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = careers.find(career => career.id === parseInt(filter));
                if (item) {
                    dispatch(addFilterValue(item, 'career'))
                }
            });
        }, [careers, initialFilters]
    );


    return(
        <div className="grid-x grid-padding-x mar-top-10 mar-bot-10 filters__options">
            {careers.map((item) => {
                return <Label key={item.id} option={item}
                              hasImage={true}
                              hasScore={item.score > 0}
                              add={() => dispatch(addFilterValue(item, 'career'))}
                              delete={() => dispatch(deleteFilterValue(item, 'career'))}
                              selected={!!filteredCareers.find((car) => car.id === item.id)} />
            })}
        </div>
    )

}