import React from 'react';
import {actions, InputTypeField, Loader, TextField, SelectField} from "react-fields";
import registerAPI from '../api/userAPI';
import TermsCheckboxField from "../base/termsCheckbox";
import UnconfirmedModal from "../base/modals/confirmationSent";
import PopUp from '../base/popup';
import InvisibleRecaptcha from '../contact/invisibleRecaptcha';

import HoverButton from "../buttons/HoverButton";
import {disableCaptchaForTest} from "../settings";

import {AGE_GROUPS, GENDERS} from "../settings";
import cf from "../../admin_components/common";
import registerImg from "../../../assets/images/register.png"

import CustomSelectField from '../base/customSelectField';
import OmniAuthButtons from "./omniAuth/buttons";

import NewsletterBox from "../base/newsletterBox";

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: Object.assign({country_id: this.props.country_id}, this.props.user, {errors: {}, email: this.props.email || ""}),
            countries: [{id: 0, name: 'І prefer not to say'},...this.props.countries],
            fromProvider: this.props.fromProvider || false,
            token: this.props.token,
            isLoading: false,
            ssrDone: false
        };
        this.checkEmailModal = React.createRef();
        this.invisibleCaptcha = React.createRef();

        this.pswdStrength = React.createRef();
        this.pswdMatch = React.createRef();
    }

    onSocialRegisterError = (response) => {
        if (!response) {
            this.setState({isLoading: false });
            return;
        }
        this.setState({user: response, fromProvider:true, isLoading: false });
    };

    componentDidMount() {
        this.setState({ssrDone: true})
    }

    onSocialRegisterSuccess = () => {
        document.location.href = '/set-up';
    };

    omniResponse = (callback) => {
        this.setState({isLoading: true}, callback);
    };

    validate = () => {
        let user = this.state.user;
        let valid = true;
        ['email', 'gender'].map((name) => {
            if (!user[name]) {
                user.errors[name] = `${cf.capitalize(name)} is required`;
                valid = false;
            }
        });

        if(!user.career_level_id) {
            user.errors.careerLevelId = 'Career level can\'t be blank';
            valid = false;
        }
        if(!user.education_level_id) {
            user.errors.educationLevelId = 'Education level can\'t be blank';
            valid = false;
        }

        if(!user.age_group) {
            user.errors.ageGroup = 'Age can\'t be blank';
            valid = false;
        }

        if (!valid){
            this.setState({user: user});
        }
        return valid;
    };


    onSubmit = (e) => {
        e.preventDefault();
        if (!this.validate()) {
            return;
        }
        try {
            if (disableCaptchaForTest()) {
                console.log('captcha disabled');
                this.onSaveUser('valid');
            } else if (window.grecaptcha) {
                this.invisibleCaptcha.current.execute();
            }
        } catch (error) {
            console.warn('something is wrong with invisible captcha....');
            console.log(error);
        }
    };


    onSaveUser = (token) => {
        let _this = this;
        this.setState({isLoading: true}, () => {
            let user = _this.state.user;
            registerAPI.register(this.state.user, false, token).then( async (response) => {
                localStorage.removeItem('user');
                localStorage.removeItem('interests_status');
                localStorage.removeItem('interests');
                localStorage.removeItem('interests_result');
                _this.refs.checkEmailModal.open();
                _this.setState({isLoading: false})
            }).catch(error => {
                console.log(error);
                error.json().then(function (value) {
                    user.errors = value;
                    _this.setState({user: user, isLoading: false});
                });
            });
        });
        if (!disableCaptchaForTest()) { this.invisibleCaptcha.current.reset(); }
    };

    onCloseModal = () => {
        document.location.href= '/dashboard';
    };

    onHandleChange = (e) => {
        let name = e.target.name;
        if (name === 'username') { name = 'name' }
        let value = (name === 'agree_terms' || name === 'newsletter_subscription') ? e.target.checked : e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
        if (name === 'password' || name === 'password_confirmation'){
            if (name === 'password') {
                this.pswdStrength.current.checkValue(e.target.value);
            }
            this.pswdMatch.current.checkValue(user.password, user.password_confirmation);
        }
    };

    render() {
        let user = this.state.user;
        return  (
            <React.Fragment>
            <form className="registration__form" onSubmit={this.onSaveUser}>
                {this.state.isLoading ? <Loader/> : null}
                <div className="grid-x text-center-medium">
                    <div className="large-8 medium-12 small-12 cell registration__left-side">
                        {this.state.fromProvider ?
                            <div className="small-12 cell">
                                <h1 className={'set-up__header'}>Complete Registration</h1>
                            </div>
                            :
                            <React.Fragment>
                                <div className="small-12 cell">
                                    <h1 className={'set-up__header'}>Create a CareerHunter Account</h1>
                                    <p className={'set-up__subheader'}>To store your progress, test results, and matches</p>
                                </div>
                            </React.Fragment>
                        }
                        <div className="grid-x">
                            <div className="medium-6 small-12 cell med-up-padR10">
                                <TextField name={"username"} placeholder={"Name*"}
                                            value={user.name}
                                            errorMessage={user.errors.name}
                                            onChange={this.onHandleChange}/>
                                <SelectField name='age_group' selected={user.age_group}
                                              options={AGE_GROUPS} optionsValue='value' optionsLabel='label'
                                              onChange={this.onHandleChange} errorMessage={user.errors.ageGroup}
                                              className={`label-icon-right select-label ${!!user.age_group ? 'is-selected' : ''}`} placeholder="Age*" />
                            </div>
                            <div  className="medium-6 small-12 cell">
                                <TextField name={"email"} onChange={this.onHandleChange}
                                            placeholder={"Email address*"}
                                            errorMessage={user.errors.email}
                                            value={user.email}/>
                                <SelectField name='gender' selected={user.gender}
                                              options={GENDERS} optionsValue='value' optionsLabel='label'
                                              onChange={this.onHandleChange} errorMessage={user.errors.gender}
                                              className={`label-icon-right select-label ${!!user.gender ? 'is-selected' : ''}`} placeholder="Gender*" />
                            </div>
                        </div>
                        <div className="small-12 cell">

                            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?
                                  <SelectField name="country_id" placeholder="Location" className="label-icon-right select-label"
                                    onChange={this.onHandleChange}
                                    options={this.state.countries} optionsValue='id' optionsLabel='name'
                                    selected={user.country_id}
                                    errorMessage={user.errors.country_id} />
                             :

                            <CustomSelectField name="country_id" placeholder="Location"
                                               onChange={this.onHandleChange}
                                               options={this.state.countries} optionsValue='id' optionsLabel='name'
                                               selected={user.country_id}
                                               errorMessage={user.errors.country_id} />
                            }


                            <SelectField name="career_level_id" placeholder="Career Level"
                                         className={`label-icon-right select-label ${!!user.career_level_id ? 'is-selected' : ''}`}
                                         onChange={this.onHandleChange}
                                         options={this.props.career_levels} optionsValue='id' optionsLabel='name'
                                         selected={this.state.user.career_level_id}
                                         errorMessage={this.state.user.errors.careerLevelId} />
                            <SelectField name="education_level_id" placeholder="Education Level"
                                         className={`label-icon-right select-label ${!!user.education_level_id ? 'is-selected' : ''}`}
                                         onChange={this.onHandleChange}
                                         options={this.props.education_levels} optionsValue='id' optionsLabel='name'
                                         selected={this.state.user.education_level_id}
                                         errorMessage={this.state.user.errors.educationLevelId} />
                        </div>
                        <div className={'cell'}>
                            <NewsletterBox errorType={this.props.errorType} newsletter_subscription={user.newsletter_subscription} onHandleChange={this.onHandleChange} />
                        </div>
                        <div className="grid-x align-middle agree-text">
                            <div className="large-8 medium-12 small-12 cell">
                                <TermsCheckboxField name="agree_terms" value={user.agree_terms} errorTypeMessage={this.props.errorType !== 'tooltip'}
                                                        errorMessage={user.errors.agreeTerms}
                                                        onChange={this.onHandleChange}/>
                            </div>
                            <div className="large-4 medium-12 small-12 cell large-flex-end medium-o-m-top-10">
                                <HoverButton onClick={this.onSubmit} className={"cancel button--green--large"}>Register <span className={"small-icons next-arrow-white-l"}></span></HoverButton>
                            </div>
                            <div className="small-12 cell">
                                <p className={"set-up__info"}><span className={"small-icons blue-off-tooltip"}></span><span>You can edit these details at any point in your account settings</span></p>
                            </div>

                        </div>
                    </div>
                    <div className="large-4 medium-12 small-12 cell registration__right-side">
                        <div className="green-circle top-half"></div>
                        <div className="circle top-small"></div>
                        <div className="circle bottom-half"></div>
                        <div className="registration__img-block">
                            <p className="registration__link">Already have an account? <a href="/login">Log in</a></p>
                            <img src={registerImg} alt="Create a CareerHunter Account" width="261px" height="166px"/>
                            <p  className="registration__social">You can also sign up using your socials:</p>
                            {this.state.ssrDone && <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                                                       sitekey={this.props.recaptchaKey}
                                                                       verifyCallback={this.onSaveUser} />}
                            {this.state.fromProvider ? null :
                                <div className="grid-x align-middle">
                                    <div className="small-12 cell registration__social-btns">
                                        <OmniAuthButtons onCallback={this.omniResponse} linkedinIcon={'upgrade-icon is-left linkedin-icon'}
                                                         onSuccess={this.onSocialRegisterSuccess} onError={this.onSocialRegisterError} />
                                        {/*<GoogleLogin*/}
                                            {/*clientId={this.props.googleID} onCallback={this.omniResponse}*/}
                                            {/*onSuccess={this.onSocialRegisterSuccess} onError={this.onSocialRegisterError} />*/}
                                        {/*<FacebookLogin*/}
                                            {/*appId={this.props.facebookID} onCallback={this.omniResponse}*/}
                                            {/*onSuccess={this.onSocialRegisterSuccess} onError={this.onSocialRegisterError} />*/}
                                        {/*<LinkedInButton onSuccess={this.onSocialRegisterSuccess} />*/}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
                <PopUp ref="checkEmailModal" onClose={this.onCloseModal} >
                    <UnconfirmedModal email={this.state.user.email} newsletterSubscription={this.state.user.newsletter_subscription} />
               </PopUp>
            </React.Fragment>
        )
    }

}
export default RegisterForm;