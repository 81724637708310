
import React, {useState, useRef, useLayoutEffect} from 'react';
import TabsContent from "./tabsContent";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

export default function Filters(props){

    const [currentTab, setCurrentTab] = useState("industry");


    const dispatch = useDispatch();

    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );


    let {profileCompleted, selectedIndustries, selectedPersonalities,selectedMotivators, matches } = useSelector(state => ({
        profileCompleted: state.filters.profileCompleted,
        selectedIndustries: filtersSelector(state, 'industry'),
        selectedPersonalities: filtersSelector(state, 'personality'),
        selectedMotivators: filtersSelector(state, 'motivator'),
        matches: singleFilterSelector(state, 'match')
    }), shallowEqual);

    let tabs = [{name: 'Interests', key: 'industry', selected: selectedIndustries.length > 0},
        {name: 'Personality', key: 'personality', selected: selectedPersonalities.length > 0},
        {name: 'Motivator', key: 'motivator', selected: selectedMotivators.length > 0},
        {name: 'Match %', key: 'matches', selected: (!!matches)}
    ];




    const toggleTab = (e, key) => {
        setCurrentTab(key)
    };



    return (
        <React.Fragment>
            <div className="grid-container sub-filters-container">
                <div className="grid-x spaced-between padT10 flex-center">
                    <div className="filter-menu">
                        <ul className={`tabs filter-tabs ${currentTab ? "" : "active"}`}>
                            {tabs.map((tab) => {
                                return <li key={tab.key} className={"filter-tabs__title"  + (currentTab === tab.key ? ' is-active' : '')}>
                                    <a className="filter-tabs__link" onClick={(e) => {toggleTab(e,tab.key)}} >
                                        {tab.name} {currentTab === tab.key ? <span className="dot"></span> : ''}

                                    </a>
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className={"filters-container small-order-2 large-order-3"  }>
                        <div className="filters-wrapper sub-filters filter-tabs">
                            <div className="grid-container filters-selection">
                                <TabsContent currentTab={currentTab}
                                             profileCompleted={profileCompleted}
                                             search={props.search}
                                             user={props.user}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}