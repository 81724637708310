import React from 'react';

export default function NotificationPopup() {
    return  (
        <div className="grid-x align-center">
            <div className="small-12 cell text-center">
                <div className="popup__title popup__title--centered">
                    <span className="small-icon checked"/>
                    <h3 className="mar-bot-10">Careers updated</h3>
                </div>
                <p className='popup__text'>Your career matches have been recalculated based on our latest changes to our database.</p>
            </div>
        </div>
    )
}