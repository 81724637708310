
import React from 'react';
import Bubble from '../base/bubble';

export default function Career(props) {

    let career = props.item;

    let bgDivHtmlAttrs = {};
    const bgImagePath = (career.image && career.image.small) ? career.image.small : career.image_path;
    if (!!bgImagePath) {
        bgDivHtmlAttrs.style = {backgroundImage: `url("${bgImagePath}")`}
    }

    const content = <React.Fragment>
        <div className="card__image" {...bgDivHtmlAttrs} />
        <div className="card__section card__section--careers">
            <p className="card__name disable-fade-in">{career.name}</p>
            {career.salary_from && career.salary_to ?
                <p className="card__salary">${career.salary_from}-${career.salary_to} annually</p> :
                <p className="card__salary"> N/A </p>
            }
            <p className="card__education">{career.projected_growth} growth | {career.education_level} | {career.study_time}</p>
            <p className="card__link">Go to Career <span className="small-icons next-arrow-green-xs"/></p>
        </div>
        <Bubble score={career.score} hideScore={props.hideScore} hideMatch={props.hideMatch}/>
    </React.Fragment>;

    return (
        <div className={`card card--careers ${props.className || ''}`}>
            {props.featured ?
                <div>{content}</div>
                :
                <a href={(`/careers/${career.path}`)} className={'disable-fade-in'}>
                    {content}
                    <Bubble score={career.score} hideScore={props.hideScore} hideMatch={props.hideMatch}/>
                    <div className="card__hover">
                    </div>
                </a>
            }
        </div>

    );
}
