import React, { Component } from 'react';
import {
    TextField, ErrorMessage, actions, NumberField, ErrorTooltip
} from 'react-fields';

import TermsCheckboxField from "../base/termsCheckbox";
import HoverButton from "../buttons/HoverButton";
import {generateForm, generateInput} from '../utils';
import {FadeInSection} from "../../components/fadeIn";
import CouponInput from './couponInput';
import TotalPrice from './totalPrice';

import InputMask from 'react-input-mask';

class CardForm extends Component {
    state = {
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        cardNumberCount: 16,
        price: this.props.price
    };

    handleFieldChange = (e) => {
        let card = this.props.card;
        let field_name = e.target.name;
        card[field_name] = field_name === 'agree' ? e.target.checked : e.target.value;
        if (field_name === 'agree' && e.target.checked) {
            card.errors[actions.camelize('base')] = "";
        } else {
            card.errors[actions.camelize(field_name)] = "";
        }
        if(field_name === 'number'){
            let count = 16 - e.target.value.length;
            this.setState({cardNumberCount: count})
        }
        this.props.onChange(card);
    };

    handleExpireDateChange = (e) => {
        const value = e.target.value;
        let card = this.props.card;
        card.errors['expiry'] = "";
        [card.expire_month, card.expire_year] = value.split('/');
        this.props.onChange(card);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.pay(this.onSuccess);
    };

    onSuccess = (response) => {
        if (response["status"] === "requested") {
            const url = response["url"];
            let form = generateForm(url);
            let pa_req = response["pa_req"].includes("?") ? `${response["pa_req"]}&s=05` : `${response["pa_req"]}?s=05`;
            form.appendChild(generateInput('PaReq', pa_req));
            form.appendChild(generateInput('MD', response["md"]));
            form.appendChild(generateInput('TermUrl', this.props.afterPaymentUrl));
            document.body.appendChild(form);
            form.submit();
        } else {
            this.afterPayment(response['enrollment']['encrypted_key']);
        }
    };

    afterPayment = (enrollment_key) => {
        window.location.href = `${this.props.afterPaymentUrl}/${enrollment_key}`;
    };




    render() {
        let card = this.props.card;
        let termsErrorMessage = 'Terms and Privacy must be accepted';
        let hideCouponArea = !!this.props.hideCouponArea;
        const ErrorTag = this.props.errorType === 'tooltip' ? ErrorTooltip : ErrorMessage;
        return(
            <FadeInSection>
                <div className="tabs-panel is-active" >
                    <TextField name="name" onChange={this.handleFieldChange}
                               placeholder={"Full name on card"}
                               errorType={this.props.errorType}
                               value={card.name}
                               errorMessage={card.errors.name}/>

                    <div className="grid-x align-middle">
                        <div className="small-12 cell">
                            <div style={{position: 'relative'}} className="card-num">
                                <NumberField name="number" maxLength="16" onChange={this.handleFieldChange}
                                                     value={card.number}
                                                     placeholder={"Card number"}
                                                     errorType={this.props.errorType}
                                                     autocomplete="off"
                                                     errorMessage={card.errors.number}/>
                                <span className='count-hint'>{this.state.cardNumberCount} digit</span>
                            </div>
                            <div className={'float-right'}>
                            <div className={`expiry-date relative mar-b-10${!!card.errors.expiry ? ' err' : ''}`}>
                                <InputMask mask="99/99" maskChar={null} value={this.state.test} onChange={this.handleExpireDateChange}
                                           type={'text'}
                                           placeholder={'MM / YY'}
                                           alwaysShowMask={true}
                                           autoComplete="cc-exp" />
                                <span className={'expiry-date--label'}>Expiry</span>

                                <ErrorTag message={card.errors.expiry}/>
                            </div>
                            <div className="cvv-input">
                                <div className="input-with-icon small">
                                    <div className={`relative mar-b-10 cvc${!!card.errors.csc ? ' err' : ''}`}>
                                        <InputMask mask="999" maskChar={null}
                                                   name={'csc'}
                                                   value={card.csc} onChange={this.handleFieldChange}
                                                   type={'text'}
                                                   placeholder={'CVV'}
                                                   alwaysShowMask={true}
                                                   autoComplete="new-password"
                                                    />
                                        <ErrorTag message={card.errors.csc}/>
                                    </div>

                                        <div className="tooltip-trigger  checkout-icon blue-off-tooltip cvv-tooltip">
                                            <span className="tooltip top" >The CVV is the 3-digit code printed or embossed usually on the back of your credit/debit card.</span>
                                        </div>
                                        {/*<div className="tooltip into-field small-icon green-tooltip-icon" style={{display: card.errors.csc ? 'none' :''}}>*/}
                                            {/*<span className="tooltip-text top" >The CSV/CVC code is the 3 digit code that is usually embossed or*/}
                                                  {/*printed on the back of your card. Also known as card verification data (CVD),*/}
                                                  {/*card verification number, card verification value (CVV), card verification value code,*/}
                                                  {/*card verification code (CVC), verification code (V-code or V code), or signature panel code (SPC)</span>*/}
                                        {/*</div>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        <p className="secured-msg">Card payments are securely processed. We do not store any card details.</p>
                    </div>
                    <div className="grid-x align-middle">
                        <div className={` small-12 cell ${this.props.user && !!this.props.user.id ? "" : " "}`}>
                            <div className={(this.props.hideTotalArea ? `license-checkout__submit-educator` : `license-checkout__submit`) + `${(card.errors.base) && (card.errors.base ===termsErrorMessage) ? "termsError" : " "}`}>
                                <div>
                                <HoverButton onClick={this.handleSubmit} className={'button--green--medium button--with-icon'}>
                                    <span className="small-icons white-small-lock-m"/>
                                    <span>Complete Payment</span>
                                </HoverButton>

                                </div>
                                {!this.props.hideTotalArea && <TotalPrice price={this.props.price}
                                                                          discountAmount={this.props.discountAmount}
                                                                          student_code={!!this.props.user.student_discount_code || (this.props.coupon && !!this.props.coupon.student_discount)}/>
                                }

                        </div>
                            {this.props.user && !!this.props.user.id ?
                                null
                                :
                                <TermsCheckboxField name="agree" value={card.agree} errorTypeMessage={this.props.errorType !== 'tooltip'}
                                                    errorMessage={card.errors.base === termsErrorMessage ? card.errors.base : null}
                                                    onChange={this.handleFieldChange} />
                            }

                        </div>
                        {Object.values(card.errors).findIndex((value) => !!value) >= 0 ?
                            <div className={`error-wrapper`}>
                                <div className="checkout-icon red-cross"/>
                                <p>There was a problem submitting payment, see errors above</p>
                            </div>
                            : null }
                        <div className={`small-12 cell`}>
                            { !hideCouponArea &&  <CouponInput show_student_discount={this.props.show_student_discount}
                                                                discountAmount={this.props.discountAmount}
                                                                coupon={this.props.coupon}
                                                                student_discount_code={this.props.user.student_discount_code}
                                                                onChangeCoupon={this.props.onCouponChange}
                                                                validateCouponCode={this.props.validateCouponCode}
                            /> }
                        </div>
                        {card.errors.base !== termsErrorMessage ?
                            <div className={'small-12 cell text-right mar-top-10'}>
                                <ErrorMessage message={card.errors.base === termsErrorMessage ? null  : card.errors.base} className={"validation--err"} />
                            </div> : null}
                    </div>
                </div>
            </FadeInSection>)
        }

}
export default CardForm;