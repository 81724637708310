import React from 'react';
import LazyLoad from 'react-lazyload';
import PromoBanner from "./promoBanner";
import {FadeInSection} from "../fadeIn";

export default function LazyPromo(props) {

    return (
        <FadeInSection>
            <LazyLoad>
                <PromoBanner {...props} />
            </LazyLoad>
        </FadeInSection>
    );

}