import React from 'react';
import LoginAPI from '../api/loginAPI';
import PopUp from '../base/popup';
import UnconfirmedModal from "../base/modals/confirmationSent";

class ResendEmailButton extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            className: this.props.className || "button button--green",
            btnName: this.props.btnName || "Resend"
        };
        this.checkEmailModal = React.createRef();
    }


    resendEmail = (e) => {
        if (e) {e.preventDefault();}
        let _this = this;
        this.setState({isLoading: true}, () => {
            LoginAPI.resendAccountInstructions(this.props.user).then( () => {
                _this.setState({isLoading: false});
                _this.checkEmailModal.current.open();
            }).catch(error => {
                console.log(error);
            });
        })

    };

    render() {
        return  (
            <React.Fragment>
               <button onClick={this.resendEmail} className={this.state.className}>{this.state.btnName}<span className="button__hovered"/></button>

               <PopUp ref={this.checkEmailModal}>
                   <UnconfirmedModal email={this.props.user ? this.props.user.email : ''}/>
               </PopUp>
            </React.Fragment>
        )
    }




}
export default ResendEmailButton;