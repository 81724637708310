import React from 'react';
import ResendEmailButton from '../user/resendEmailButton';
class NotComplete extends React.Component {

    render() {
        return  (
            <React.Fragment>
                <form className="form-container" onSubmit={this.resendEmail}>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            <h1 className={'login-register__header'}>Log In</h1>
                        </div>
                        <div className="input-with-icon small-12 cell">
                            <div className="small-icons green-person"/>
                            <input type="text" name="email" placeholder="Email" disabled value={this.props.user.email}/>
                        </div>
                        <h4 className="login-msg">Please check your inbox and click the link in the email we sent you to confirm your email address and create your CareerHunter account.
                        </h4>
                    </div>
                    <div className="grid-x align-middle">
                        <div className="small-12 cell flex-end">
                            <ResendEmailButton user={this.props.user} />
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }




}
export default NotComplete;