import React from "react";
import Slider from "react-slick";

const DEFAULT_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [

        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ]
};

export default function carousel(props) {

    const settings = props.settings || DEFAULT_SETTINGS;
    const ItemComponent = props.component;
    const itemProps = props.itemProps || {};

    return(
        <Slider {...settings}>
            {props.items.map((item, i) => (
                <ItemComponent key={item.id} item={item} {...itemProps} />
            ))}
        </Slider>
    )
}