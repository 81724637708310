import React, {useRef} from 'react';
import {FadeInSection} from "../fadeIn";
import LeavingPopup from "./leavingPopup";
import PopUp from "../base/popup";

export default function Course(props) {

    let course = props.item;
    let classNames = ['card', props.className].join(' ').trim();
    const max_length = 50;

    const previewPopup = useRef(null);

    const openPreview = (e) => {
        e.preventDefault();
        previewPopup.current.open();

    };

    return (
            <div className={classNames}>
                <FadeInSection>
                <a  target={'_blank'} className={'disable-fade-in'} rel={'nofollow'} onClick={openPreview}>
                    <div>
                        <img src={course.image_url} alt={course.name}/>
                    </div>
                    <div className="card__section card__section--extra-padding" data-equalizer-watch>
                        <div className="flex-start">
                            <div className="card__courses-logo" style={{background: `url("${course.logo_url}") center center / contain no-repeat`}}/>
                            <h3 className="card__title card__title--courses" style={{flex: 1}} ellipsizemode='tail' numberoflines={2}>
                                {course.name}</h3>
                        </div>
                        {!!course.level && !!course.duration ?
                            <p className="card__courses-details">{course.level || ''}{course.level && course.duration && ' | '}{course.duration && `${course.duration} weeks`}</p>
                            :
                            <p className="card__courses-details">{course.organization}{course.duration && ` | ${course.duration} weeks`}</p>
                        }
                    </div>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            <p className={"card__link"}><a href={course.url} target={'_blank'}>Go to Course <span className='small-icons next-arrow-green-xs'/></a></p>
                        </div>
                    </div>
                </a>
                </FadeInSection>
                <PopUp ref={previewPopup}  className="leaving-popup">
                   <LeavingPopup course={course} />
                </PopUp>
            </div>

    )

}
let maxHeight = 0;
function equalize(els) {
    els.forEach(function(el){
        let elHeight = el.clientHeight;
        if (elHeight > maxHeight) { maxHeight = elHeight }
        el.style.height = maxHeight + 'px';
    });
}