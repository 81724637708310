import React, {Fragment} from 'react';
import {InputTypeField, Loader, TextField, SelectField, actions} from "react-fields";
import Checkbox from '../base/checkbox';
import userAPI from '../api/userAPI';

import PopUp from '../base/popup';
import EmailForm from "./changeEmailForm";
import PasswordForm from './changePasswordForm';
import FinishSetupAccount from './finishSetupAccount';
import DeleteAccountForm from "./deleteAccountForm";
import HoverButton from '../buttons/HoverButton';

import UserValidation from '../validators/user';
import FullAccessCodeAPI from '../api/fullAccessCodeAPI';
import {AGE_GROUPS, GENDERS} from "../settings";
import CustomSelectField from "../base/customSelectField";

class EditForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: Object.assign({}, this.props.user, {errors: {}, country_id: this.props.user.country_id || "0"}),
            countries: [...this.props.countries, {id: 0, name: 'I prefer not to say'}],
            isLoading: false,
            showFullAccessCodeArea: !this.props.user.full_access && !this.props.user.educator_id,
            showFullAccessCode: false,

        };

        this.changeEmailPopup = React.createRef();
        this.changePasswordPopup = React.createRef();
        this.deletePopup = React.createRef();
        this.successPopup = React.createRef();
        this.successFullAccessPopup = React.createRef();
    }

    reset = (e) => {
        if (e) {e.preventDefault();}
        this.setState({user: Object.assign({}, this.props.user, {errors: {}})});
    };

    toggleFullAccessCode = () => {
        this.setState({showFullAccessCode: !this.state.showFullAccessCode})
    };

    onChangeField = (e) => {
        let name = e.target.name;
        if (name === 'username') { name = 'name' }
        let value = ['receive_promo_emails', 'receive_advice_emails', 'receive_account_emails',
            'receive_jobs_emails', 'receive_study_emails'].includes(name) ? e.target.checked : e.target.value;
        let user = this.state.user;
        user[name] = value;
        user.errors[actions.camelize(name)] = "";
        this.setState({user: user});
    };

    onChangeEmail = (email) => {
        this.setState({user: {...this.state.user, email: email}});
        this.changeEmailPopup.current.close();
        this.successPopup.current.open();
    };

    onChangePassword = () => {
        this.changePasswordPopup.current.close();
        this.successPopup.current.open();
    };

    onSuccess = () => {
        this.setState({isLoading: false});
        this.successPopup.current.open();
    };

    onError = (errors) => {
        this.setState({isLoading: false, user: {...this.state.user, errors: errors}});
    };

    onSubmit = () => {
        this.setState({isLoading: true}, () => {
            UserValidation.updateAccount(this.state.user, () => {
                userAPI.update(this.state.user, this.onSuccess, this.onError);
            }, this.onError);
        });
    };

    applyFullAccessCode = () => {
        let user = this.state.user;
        let _this = this;
        FullAccessCodeAPI.apply(user.full_access_code).then((data) => {
            return data;
        }).then(() => {
            _this.setState({showFullAccessCodeArea: false});
            _this.successFullAccessPopup.current.open();
        }).catch(error => {
                error.json().then((value) => {
                    console.log(value)
                    _this.setState({ user: {...user, errors: value}});
                });
        });
    };

    isUserTemp = () => {
        return this.props.user.temp;
    };

    render() {
        let user = this.state.user;
        return  (
            <React.Fragment>
            <div className="">
                {this.state.isLoading ? <Loader /> : null}
                <label>
                    <span>Full name</span>
                    <TextField name="username"
                               value={user.name}
                               errorMessage={user.errors.name}
                               onChange={this.onChangeField}/>
                </label>

                <div className="input-link">
                    <label>
                        <span>Email</span>
                        <TextField name="email" value={user.email} disabled={true} />
                        {!!this.props.user.educator_id ? null : <a onClick={() => this.changeEmailPopup.current.open()}>Change email</a> }
                    </label>
                </div>

                <div className="input-link">
                    <label>
                        <span>Password</span>
                        <InputTypeField type="password" name="password" value={this.isUserTemp() ? '' : '****************'} disabled={true} />
                        <a onClick={() => this.changePasswordPopup.current.open()}>Change password</a>
                    </label>
                </div>

                <label>
                    <span>Age</span>
                    <SelectField name='age_group' selected={user.age_group}
                                 options={AGE_GROUPS} optionsValue='value' optionsLabel='label'
                                 onChange={this.onChangeField} errorMessage={user.errors.ageGroup}
                                 className='label-icon-right select-label' placeholder="Age*" />
                </label>

                <label>
                    <span>Gender</span>
                    <SelectField name='gender' selected={user.gender}
                                 options={GENDERS} optionsValue='value' optionsLabel='label'
                                 onChange={this.onChangeField} errorMessage={user.errors.gender}
                                 className='label-icon-right select-label' placeholder="Gender*" />
                </label>

                <label>
                    <span>Country</span>
                    {/*<SelectField name="country_id" placeholder="Select Country" className="label-icon-right select-label"*/}
                                 {/*onChange={this.onChangeField}*/}
                                 {/*options={this.state.countries} optionsValue='id' optionsLabel='name'*/}
                                 {/*selected={user.country_id}*/}
                                 {/*errorMessage={user.errors.country_id} />*/}
                </label>
                    <CustomSelectField name="country_id" placeholder="Location"
                                       onChange={this.onChangeField}
                                       options={this.state.countries} optionsValue='id' optionsLabel='name'
                                       selected={user.country_id}
                                       errorMessage={user.errors.country_id} />


                <label>
                    <span>Career level</span>
                    <SelectField name="career_level_id" placeholder="Select career Level" className="label-icon-right select-label"
                                 onChange={this.onChangeField}
                                 options={this.props.career_levels} optionsValue='id' optionsLabel='name'
                                 selected={user.career_level_id}
                                 errorMessage={user.errors.careerLevelId} />
                </label>

                <label>
                    <span>Education level</span>
                    <SelectField name="education_level_id" placeholder="Select education Level" className="label-icon-right select-label"
                                 onChange={this.onChangeField}
                                 options={this.props.education_levels} optionsValue='id' optionsLabel='name'
                                 selected={user.education_level_id}
                                 errorMessage={user.errors.educationLevelId} />
                </label>

                {this.state.showFullAccessCodeArea ?
                    <div className="grid-x align-middle custom mar-bot-20">
                        <div className="small-12 cell delete">
                            <a onClick={this.toggleFullAccessCode}>Do you have full access code?</a>
                            <div className="tooltip-trigger into-label align-left small-icon green-tooltip-icon">
                                        <span className="tooltip top" >This field is only relevant to specific business and institutions that have been previously provided with a code.
                              Please ignore this field if do not have a code</span>
                            </div>
                        </div>
                        {this.state.showFullAccessCode ?
                            <div className="grid-x align-middle custom">
                                <div className="small-8 cell delete">
                                    <TextField name="full_access_code" onChange={this.onChangeField}
                                               errorMessage={user.errors.fullAccessCode}
                                               placeholder={'Full Access Code'} value={user.full_access_code} />
                                </div>
                                <div className="small-4 cell flex-end">
                                    <HoverButton style={{marginLeft:"10px"}}
                                                 onClick={this.applyFullAccessCode}
                                                 className={'save'}>Apply</HoverButton>
                                </div>

                            </div> : null}
                    </div> :
                    null }

                {!!this.props.user.educator_id ? null : <Fragment>
                    <label><span>Notifications</span></label>
                    <div className="grid-x">
                        <div className="large-6 medium-6 small-12 cell padR5">
                            <Checkbox name='receive_advice_emails' value={user.receive_advice_emails}
                                      onChange={this.onChangeField} label='Receive advice emails' />
                        </div>
                        <div className="large-6 medium-6 small-12 cell">
                            <Checkbox name='receive_study_emails' value={user.receive_study_emails}
                                      onChange={this.onChangeField} label='Receive course recommendations' />
                        </div>
                    </div>

                    <div className="grid-x">
                        <div className="large-6 medium-6 small-12 cell padR5">
                            <Checkbox name='receive_jobs_emails' value={user.receive_jobs_emails}
                                      onChange={this.onChangeField} label='Receive job recommendations' />
                        </div>
                        <div className="large-6 medium-6 small-12 cell">
                            <Checkbox name='receive_promo_emails' value={user.receive_promo_emails}
                                      onChange={this.onChangeField} label='Receive promotional emails' />
                        </div>
                        <div className="large-6 medium-6 small-12 cell">
                            <Checkbox name='receive_account_emails' value={user.receive_account_emails}
                                      onChange={this.onChangeField} label='Receive account status emails' />
                        </div>
                    </div>
                </Fragment> }

                <div className="grid-x align-middle custom">
                    <div className="small-4 cell delete mar-bot-20">
                        {!user.educator_id ? <a onClick={() => this.deletePopup.current.open()}>Delete account</a>: null}
                    </div>
                    <div className="small-8 cell flex-end">
                        <HoverButton onClick={this.reset} className='cancel' color={'white'}>Cancel</HoverButton>
                        <HoverButton onClick={this.onSubmit} className={'save'}>Save</HoverButton>
                    </div>
                </div>
            </div>

                <PopUp ref={this.changeEmailPopup}>
                    <EmailForm user={user} onSuccess={this.onChangeEmail} onCancel={() => this.changeEmailPopup.current.close()} />
                </PopUp>
                <PopUp ref={this.changePasswordPopup}>
                    {this.isUserTemp() ?
                        <FinishSetupAccount user={this.props.user}/> :
                        <PasswordForm user={user} onSuccess={this.onChangePassword}
                                      onCancel={() => this.changePasswordPopup.current.close()}/>
                    }
                </PopUp>
                <PopUp ref={this.deletePopup}>
                    <DeleteAccountForm onCancel={() => this.deletePopup.current.close()} />
                </PopUp>

                <PopUp ref={this.successPopup}>
                    <div className="grid-x align-center">
                        <div className="small-12 cell text-center">
                            <div className="popup__title popup__title--centered">
                                <span className="small-icon checked"/>
                                <h3>Changes saved</h3>
                            </div>
                        </div>
                    </div>
                </PopUp>
                <PopUp ref={this.successFullAccessPopup}>
                    <div className="grid-x align-center">
                        <div className="small-12 cell text-center">
                            <div className="large-icon payment-successful"></div>
                            <div className="popup__title popup__title--centered">
                                <h3>You now have full access!</h3>
                            </div>
                            <p className="popup__blue-text">Navigate to your dashboard to continue testing and try out all of CareerHunter's features.</p>
                        </div>
                    </div>
                </PopUp>
            </React.Fragment>
        )
    }

}
export default EditForm;