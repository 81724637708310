import React, {useEffect} from 'react';
import CheckBox from '../../base/checkbox';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function EducationLevel(props) {

    const educationLevels = [{id: 'no_education', name: 'No education'},
        {id: 'high_school', name: 'High school'},
        {id: 'undergraduate', name: 'Undergraduate'},
        {id: 'postgraduate', name: 'Postgraduate'},
        {id: 'vocational', name: 'Vocational'}];

    let selectedEducations = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'education'));
    let initialFilters = useSelector(state => state.filters.initialFilters['educations']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            if (!initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = educationLevels.find(education => education.id === filter);
                if (item) {
                    dispatch(addFilterValue(item, 'education'))
                }
            });
        }, [initialFilters]
    );


    return (
        <div className="aligned-checkboxes flex-center filters__options">
            {educationLevels.map((education) => {
                return <CheckBox key={education.id}
                                 name={education.id}
                                 label={education.name}
                                 className="aligned-checkboxes__item"
                                 onChange={(e) => e.target.checked ?
                                     dispatch(addFilterValue(education, 'education')) :
                                     dispatch(deleteFilterValue(education, 'education'))}
                                 value={!!selectedEducations.find((level) => level.id === education.id)} />
            })}
        </div>
    )

}