import React from 'react';
import Job from "./job";

export default function Collection(props) {

    return (
        <div className="mar-top-30 mar-bot-30">
            {props.collection.map((job) => {
                return <Job key={job.id} job={job}/>
            })}
        </div>
    )

}