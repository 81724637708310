import React from 'react';


export default function AfterLoginMessage()  {


    return (
            <div className="side-box__inner">
                <h3>Successfully logged in!</h3>
            </div>
    )

}


