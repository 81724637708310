// Converts an integer into its most compact representation
export function compactInteger(input) {
    const number = parseInt(input, 10);
    const signString = number < 0 ? '-' : '';
    const unsignedNumber = Math.abs(number);
    const unsignedNumberString = String(unsignedNumber);
    const numberLength = unsignedNumberString.length;
    const numberLengths = [13, 10, 7, 4];
    const bigNumPrefixes = ['T', 'B', 'M', 'k'];

    // small numbers
    if (unsignedNumber < 1000) {
        return `${signString}${unsignedNumberString}`;
    }

    // really big numbers
    if (numberLength > numberLengths[0] + 3) {
        return number.toExponential(0).replace('e+', 'x10^');
    }

    // 999 < unsignedNumber < 999,999,999,999,999
    let length;
    for (let i = 0; i < numberLengths.length; i++) {
        const _length = numberLengths[i];
        if (numberLength >= _length) {
            length = _length;
            break;
        }
    }

    const decimalIndex = numberLength - length + 1;
    const unsignedNumberCharacterArray = unsignedNumberString.split('');

    const wholePartArray = unsignedNumberCharacterArray.slice(0, decimalIndex);
    const wholePart = wholePartArray.join('');

    return `${signString}${wholePart}${bigNumPrefixes[numberLengths.indexOf(length)]}`;
}