import React from 'react';

export default function SuccessModal(props) {
    return  (
        <div className="grid-x align-center">
            <div className="cell text-center">
                <div className="upgrade-icon discount-sent small-mar-bot-20"></div>
                <h3 className="mar-bot-10">Discount sent to your mail!</h3>
                <p className='popup__text-large'>
                    We’ve sent a confirmation email and your unique student discount to <span className="popup__email">{props.email}</span>.
                </p>
                <p className='popup__text popup__text--grey'>
                    Make sure to check your junk/promo folders if you cannot see it in your inbox, or speak to your institution’s IT administrator.
                </p>
            </div>
        </div>
    )
}