
import React, {useState, useRef, useLayoutEffect} from 'react';

import {useSelector, shallowEqual, useDispatch, Provider} from "react-redux";
import { createSelector } from 'reselect';
import SelectedFilters from '../selectedFilters';

//import TabsContentMobile from './tabsContentMobile';
import TabsContent from './tabsContent';

import Tabs from '../tabs';
import {deleteAllFilters} from '../../actions/filterActions';
import TabsOverlay from "../tabsOverlay";
import useWindowSize from "../../useWindowSize";
//import PopUp from "../../base/popup";
//import store from "../../store/store";
//import FiltersModal from "../filtersModal";
import HoverButton from "../../buttons/HoverButton";

export default function Filters(props){

    const [currentTab, setTab] = useState(null);
    const size = useWindowSize();

    const filtersModal = useRef(null);

    const titlesRef = useRef(null);
    const [titlesHeight, setTitlesHeight] = useState(null);

    const [filterButtonVisibility, setFilterButtonVisibility] = useState(true);

    const dispatch = useDispatch();


    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );

    const anyFiltersSelector = createSelector(
        state => state.filters.selected,
        (selectedFilters) => selectedFilters.length > 0
    );

    const anyFilters = useSelector(state => anyFiltersSelector(state));
    const filterStatus = anyFilters ? ' filtered' : '';

    let { profileCompleted, selectedIndustries, selectedPersonalities,selectedMotivators,  selectedEducationLevels, matches, salary, growth, qsearch, user } = useSelector(state => ({
            profileCompleted: state.filters.profileCompleted,
            selectedIndustries: filtersSelector(state, 'industry'),
            selectedPersonalities: filtersSelector(state, 'personality'),
            selectedMotivators: filtersSelector(state, 'motivator'),
            selectedEducationLevels: filtersSelector(state, 'education'),
            matches: singleFilterSelector(state, 'match'),
            salary: singleFilterSelector(state, 'salary'),
            growth: singleFilterSelector(state, 'growth'),
            qsearch: filtersSelector(state, 'qsearch'),
            user: state.filters.user
    }), shallowEqual);

    let suitabilityTabs = [{name: 'Interests', key: 'industry', selected: selectedIndustries.length > 0},
        {name: 'Personality', key: 'personality', selected: selectedPersonalities.length > 0},
        {name: 'Motivator', key: 'motivator', selected: selectedMotivators.length > 0},
        {name: 'Match %', key: 'matches', selected: (!!matches)}
    ];


    let tabs = [{name: 'Suitability', key: 'suitability', multi: true,
        selected: (!!matches || selectedIndustries.length > 0 || selectedPersonalities.length > 0 || selectedMotivators.length > 0),
        hidden: false, childTabs: suitabilityTabs},
        {name: 'Salary', key: 'salary', selected: (!!salary), hidden: false},
        {name: 'Growth', key: 'growth', selected: (!!growth), hidden: false},
        {name: 'Education', key: 'education', selected: selectedEducationLevels.length > 0, hidden: false},
        {name: 'Search', key: 'qsearch', selected: qsearch.length > 0, hidden: true},
    ];



    const clearAll = () => {
        closeTab();
        dispatch(deleteAllFilters());
    };

    const scrollTo = (element, to, duration) => {
        if (duration <= 0) return;
        let difference = to - element.scrollTop;
        let perTick = difference / duration * 10;

        setTimeout(function() {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollTo(element, to, duration - 10);
        }, 10);
    };

    const toggleFilters = () => {
        if (currentTab === null) {
            setTab('industry');
            setFilterButtonVisibility(false);
            document.querySelector('body').classList.add('no-scroll');
        } else {
            setFilterButtonVisibility(true);
        }
        if (titlesRef.current) {
            setTimeout(() => {
                setTitlesHeight(titlesRef.current.clientHeight);
            }, 1)
        }
    };

    const search = () => {
        closeTab();
       // filtersModal.current.close();
        props.search();
    };

    const closeTab = () => {
        document.querySelector('body').classList.remove('no-scroll');
        setFilterButtonVisibility(true);
        setTab(null);
    };

    const onTitlesRef = (ref) => {
        titlesRef.current = ref;
    };

    const updateTitlesHeight = () => {
        if (titlesRef.current) {
            setTitlesHeight(titlesRef.current.clientHeight);
        }
    };

    useLayoutEffect(() => {
        updateTitlesHeight();
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => updateTitlesHeight(), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    let calculatedHeight = () => {
        if (size.width > 1199) {
            return size.height - titlesHeight  - 325 + 'px';
        } else if (size.width > 1024 && size.width < 1200) {
            return size.height - titlesHeight  - 305 + 'px';
        } else {
            return size.height - titlesHeight - 180 + 'px';
        }
    };

    return (
        <React.Fragment>
            <div className="filter-area">
                <div className="filter-area__container">
                    <div className="grid-container no-padding">
                        <div className="grid-x">
                            <div className="large-6 cell"><h1>Careers</h1></div>
                            <div className="large-6 cell flex-end show-for-large"><p className="filter-area__info"><span
                                className="small-icons grey-tooltip-icon"/>Complete all tests to reveal your matches</p></div>
                        </div>
                    </div>
                    {/*<HoverButton onClick={() => {filtersModal.current.open()}}*/}
                    {/*             className={'filter-menu__buttons__item hide-at-large' + filterStatus }*/}
                    {/*             color={'white'}>*/}
                    {/*    Filter{anyFilters ? <span className="filter-menu__buttons__notification"/> : null}*/}
                    {/*</HoverButton>*/}
                    <Tabs setTab={setTab}
                          currentTab={currentTab}
                          onRef={onTitlesRef}
                          showFilterButton={filterButtonVisibility}
                          tabs={tabs}
                          hasSearch={props.hasSearch}
                          clearAll={clearAll}
                          closeTab={closeTab}
                          head_text={"Browse our database of popular careers"}
                          tooltip_info={"Complete all tests to reveal your matches"}
                          toggleFilters={toggleFilters}>

                        <TabsContent currentTab={currentTab}
                                     calculatedHeight={calculatedHeight()}
                                     profileCompleted={profileCompleted}
                                     closeTab={closeTab}
                                     search={search} user={user}
                        />

                    </Tabs>
                    <SelectedFilters search={props.search}/>
                </div>
            </div>
            <TabsOverlay setTab={setTab} currentTab={currentTab}/>
            {/*<PopUp ref={filtersModal} className="filters-popup" >*/}
            {/*    <Provider store={store}>*/}
            {/*        <FiltersModal hasSearch={props.hasSearch}*/}
            {/*                      setTab={setTab}*/}
            {/*                      currentTab={currentTab}*/}
            {/*                      search={search}*/}
            {/*                      tabs={tabs}>*/}
            {/*            <TabsContentMobile currentTab={currentTab}*/}
            {/*                               calculatedHeight={calculatedHeight()}*/}
            {/*                               profileCompleted={profileCompleted}*/}
            {/*                               closeTab={closeTab}*/}
            {/*                               search={search} user={user}*/}
            {/*            />*/}

            {/*        </FiltersModal>*/}
            {/*    </Provider>*/}
            {/*</PopUp>*/}
        </React.Fragment>
    )
}