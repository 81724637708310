import React from 'react';
import LazyLoad from 'react-lazyload';
import Carousel from './carousel';

export default function lazyCoursesCarousel(props) {

    return(
        <LazyLoad>
            <Carousel {...props} />
        </LazyLoad>
    )

}