import {actions} from "react-fields";

export default class CareerMatchesAPI {

    static refresh(options) {
        let url = ` /api/career_matches/refresh`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

}