import React, {useRef} from "react";
import PopUp from "../base/popup";
import HoverButton from "../buttons/HoverButton";
import {FadeInSection} from "../fadeIn";

export default function UpgradeButton(props) {

    const noticeModal = useRef(null);

    let interestsStatus = props.user ? props.user.interests_status : (typeof localStorage !== 'undefined' ? localStorage.getItem(`interests_status`) : '');
    if (!interestsStatus){ interestsStatus = 'not_started';}

    const showPopup = (e) => {
        e.preventDefault();
        noticeModal.current.open();
    };

    return (
        <React.Fragment>
            {props.user && props.user.full_access ?
                <React.Fragment>
                    <FadeInSection>
                        <HoverButton className={'upgrade__button'} onClick={showPopup}>
                            Unlock Tests <span className="small-icons white-small-lock-s"/>
                        </HoverButton>
                    </FadeInSection>
                    <PopUp ref={noticeModal}>
                        <div className="grid-x align-center">
                            <div className="cell text-center">
                                <h3 className="mar-bot-10">You already have full access!</h3>
                                <a href={'/dashboard'} className="button button--green">Go to Dashboard</a>
                            </div>
                        </div>
                    </PopUp>
                </React.Fragment>

                :
                <FadeInSection>
                    <HoverButton className={'upgrade__button'} href={'/checkout'}>
                        Unlock Tests <span className="small-icons white-small-lock-s"/>
                    </HoverButton>
                </FadeInSection>
            }
            {props.take_test_button && interestsStatus === 'not_started' ?
                <FadeInSection>
                    <HoverButton href={"/tests/interests/take"} ariaLabel="Try free test" className="button button--white button--big">
                        Try our FREE test
                    </HoverButton>
                </FadeInSection> :
                null}
        </React.Fragment>

    )
}