import React from 'react';
import { CSSTransition } from "react-transition-group";

export default class Block extends React.Component {

    constructor(props) {
        super(props);
        this.state = {block: this.props.block, mounted: true}
    }

    static getDerivedStateFromProps(props, state) {
        if (state.block !== props.block && state.mounted) {
            return {mounted: false, nextBlock: props.block};
        }
        return null;
    };

    change = (block) => {
        this.setState({block: block});
    };

    onFadeOutFinished = () => {
        if (!!this.state.nextBlock)
            this.setState({mounted: true, block: this.state.nextBlock});
    };

    render() {
        let blockHtmlAttrs = {};
        const block = this.state.block;
        if (block.image_path) {
            blockHtmlAttrs.style = {backgroundImage: `linear-gradient(rgba(10, 30, 46, 0.5), rgba(10, 30, 46, 0.5)), url("${block.image_path}")`}
        }
        return (
            <CSSTransition
                in={this.state.mounted}
                classNames="fading-block"
                timeout={3000}
                onExited={this.onFadeOutFinished}
            >
                <div className="card industries__card large-3 medium-6 small-12 cell" {...blockHtmlAttrs}>
                    <h3 className="industries__name">{block.name}</h3>
                </div>
            </CSSTransition>
        );
    }

}