import React from 'react';

export default class CollectionWithAds extends React.Component {

    render(){
        return (
            <div className="grid-x mar-top-30 aligned-margin">
                {this.props.collection.map((item, i) => {
                    return (
                        <div className="large-3 medium-6 small-12 cell" key={i}>
                            {React.Children.map(this.props.children, child =>
                                React.cloneElement(child, {item: item})
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }

}