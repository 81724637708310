import React, {useEffect, useRef, useState} from "react";
import SearchInput from "./filters/searchInput";
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import {useDispatch} from "react-redux";
import HoverButton from "../buttons/HoverButton";

export default function FiltersModal(props) {

    const dispatch = useDispatch();



    let TabNames = {industry: 'Interests',
        personality: 'Personality',
        motivator: 'Motivator',
        matches: 'Match %',
        salary: 'Salary',
        growth: 'Growth',
        education: 'Education'};

    const [currentTab, setTab] = useState(props.currentTab);
    const [currentMultiTab, setMultiTab] = useState(null);

    const toggleTab = (e, tabName) => {
        e.preventDefault();
        props.setTab(tabName);
        setMultiTab(null)
        setTab(tabName);
        if(!tabName) dispatch(setNeedRefreshFiltersSearch(true));
      //  document.querySelector('body').classList.add('no-scroll');
    };

    const toggleMultiTab = (e, tabName) => {
        e.preventDefault();
        if(currentMultiTab === tabName){
            setMultiTab(null)
        } else {
            setMultiTab(tabName);
        }

    };

    const back = () => {
        props.setTab(null);
        setTab(null);
    };

    useEffect(() => {
       console.log('currentTab', props.currentTab)
    }, [props.currentTab]);


    return(
       <div>
           <h3 className="popup__title"> Filter results</h3>
           {props.hasSearch && !currentTab ? <SearchInput  /> : null }
           {currentTab && <div className={'text-left'} onClick={back}><span style={{ display: "inline-block"}} className="small-icon blue-left-arrow is-left"/>{TabNames[currentTab]}</div>}
           <div className="tabs-container">
               <div className="tabs-content">
                   {!currentTab && <ul className={`tabs filter-tabs ${currentTab === null ? "" : "active"}`}>
                       {props.tabs.map((tab) => {
                           if(tab.key === 'qsearch')
                               return;
                           return <React.Fragment>
                               <li key={tab.key} className={"filter-tabs__title" + (currentTab === tab.key || currentMultiTab === tab.key ? ' is-active' : '')}>
                                   <a className="filter-tabs__link" onClick={(e) => {tab.multi ? toggleMultiTab(e,tab.key) : toggleTab(e,tab.key)}} >
                                       {tab.name}
                                       {tab.selected ? <span className="filter-tabs__notification"/> : null}
                                   </a>
                               </li>
                               {tab.multi && currentMultiTab === tab.key &&
                                   <ul>
                                       {tab.childTabs.map((childTab) => {
                                   return <li key={childTab.key} className={"filter-tabs__title--child" + (currentTab === childTab.key ? ' is-active' : '')}>
                                           <a className="filter-tabs__link" onClick={(e) => {toggleTab(e,childTab.key)}} >
                                               {childTab.name}
                                               {childTab.selected ? <span className="filter-tabs__notification"/> : null}
                                           </a>
                                       </li>
                               })}
                                   </ul>
                               }
                               <div className="flex-center filters__apply">
                                   <HoverButton onClick={props.search} color={'filter'}>Done</HoverButton>
                               </div>
                           </React.Fragment>
                       })}
                   </ul>}
               </div>
           </div>
           {React.cloneElement(props.children, { currentTab: currentTab })}
       </div>


    )

}