import {combineReducers} from 'redux';
import filters from './filtersReducer';
import userStatus from './userStatusReducer';


const rootReducer = combineReducers({
    filters,
    userStatus
});

export default rootReducer;