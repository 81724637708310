import React, { Component } from 'react';
import TermsCheckboxField from "../base/termsCheckbox";
import {actions, InputTypeField} from "react-fields";
import {FadeInSection} from "../../components/fadeIn";
import CouponInput from "./couponInput";
import TotalPrice from "./totalPrice";
const APPLE_PAY_VERSIONS = [10,9,8,7,6,5,4,3,2,1];

class ApplePayForm extends Component {

    state = {
        price: this.props.price,
        latestSupportVersion: null
    };

    componentDidMount() {
        console.log('ApplePaySession');
        APPLE_PAY_VERSIONS.every((version) => {
            if(window.ApplePaySession && window.ApplePaySession.supportsVersion(version)){
                this.setState({latestSupportVersion: version});
                console.log(`Apple pay version ${version}`);
                return false;
            } else
                return true;
        });

    }


    handleFieldChange = (e) => {
        let applePay = this.props.applePay;
        let field_name = e.target.name;
        applePay[field_name] = e.target.checked;
        applePay.errors.base = "";
        this.props.onChange(applePay);
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            this.props.validateCouponCode();
        }
    };




    onApplePayButtonClicked = async () => {
        const price = (this.props.price.price - this.props.discountAmount).toFixed(2);
        const request = {
            countryCode: 'LV',
            currencyCode: this.props.price.currency.code,
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: this.props.paymentDescription, amount: price },
        };
        let appleSession = new window.ApplePaySession(this.state.latestSupportVersion, request);

        let userValid = await this.props.validate();
        if (!userValid){
            console.log('invalid');
            return;
        }
        appleSession.begin();
        console.log(appleSession);
        appleSession.onvalidatemerchant = event => {
            console.log(event.validationURL);
            let validationUrl =  event.validationURL;
            // Call your own server to request a new merchant session.
            fetch(`/api/apple-pay-authorize?${actions.paramsToURI({url: validationUrl})}`)
                .then(res => res.json()) // Parse response as JSON.
                .then(merchantSession => {
                    appleSession.completeMerchantValidation(merchantSession);
                })
                .catch(err => {
                    console.error("Error fetching merchant session", err);
                });
        };

        appleSession.onpaymentauthorized = event => {
            const payment_data = JSON.stringify(event.payment.token.paymentData);
            this.props.pay(payment_data, this.onSuccess);
        };
        this.setState({appleSession: appleSession});
    };


    onSuccess = (response = {}) => {
        console.log(response);
        let enrollment = response.enrollment;
        let status = enrollment.status;
        let appleSession = this.state.appleSession;
        if (status === 'approved') {
            appleSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            window.location.href = `${this.props.afterPaymentUrl}/${enrollment.encrypted_key}`;
        } else {
            appleSession.completePayment(window.ApplePaySession.STATUS_FAILURE);
        }
    };

    render() {
        if(!this.state.latestSupportVersion){
            return null;
        }
        let applePay = this.props.applePay;
        let hideCouponArea = this.props.hideCouponArea;
        const termsErrorMessage = 'Terms and Privacy must be accepted';
        return(
            <FadeInSection>
                <div className="tabs-panel is-active">

                    <div className="grid-x align-middle">
                        <div className={`small-12 cell ${this.props.user && !!this.props.user.id ? "flex-end" : " "}`}>
                            <div className={`license-checkout__submit ${(applePay.errors.base) && (applePay.errors.base ===termsErrorMessage) ? "termsError" : " "} `}>
                                {this.props.user && !!this.props.user.id ? null :
                                    <TermsCheckboxField name="agree" value={applePay.agree}
                                                        errorMessage={applePay.errors.base || this.props.user.errors.agreeTerms}
                                                        errorTypeMessage={true}
                                                        onChange={this.handleFieldChange}/>
                                }
                                {!this.props.hideTotalArea && <TotalPrice price={this.props.price}
                                                                          discountAmount={this.props.discountAmount}
                                                                          student_code={!!this.props.user.student_discount_code || !!this.props.coupon.student_discount}/>
                                }
                            </div>
                        </div>
                         <div className={`small-12 cell flex-end`}>
                            <div className="apple-pay-button-with-text apple-pay-button-black-with-text" onClick={this.onApplePayButtonClicked}>
                                <span className="text">Pay with</span>
                                <span className="logo"/>
                            </div>
                        </div>
                        <div className="small-12 cell">
                            { !hideCouponArea &&
                                <CouponInput show_student_discount={this.props.show_student_discount}
                                             discountAmount={this.props.discountAmount}
                                             coupon={this.props.coupon}
                                             student_discount_code={this.props.user.student_discount_code}
                                             onChangeCoupon={this.props.onCouponChange}
                                             validateCouponCode={this.props.validateCouponCode}
                                /> }
                        </div>
                    </div>
                </div>
            </FadeInSection>
        )
    }
}

export default ApplePayForm;
