import {actions} from 'react-fields'

export default class PopupAPI {

    static trackShow(id, action, clickType = null) {
        const url = `/api/flexible_popups/${id}`;
        let data = { track_action: action, click_type: clickType };

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
        }).catch(error => {
            console.error(error);
        });
    }




}