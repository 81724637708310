import React from 'react';
import HoverButton from '../../buttons/HoverButton';

export default function CheckEmailButton(props) {
    return  (
        <HoverButton className="capsule capsule--saved" onClick={props.onClick}>
            <span className="capsule__icon small-icon checked"/>
            <p className="capsule__text capsule__check-email">Saved<span className="hide-for-small-only">, check your inbox</span></p>
        </HoverButton>
    )
}