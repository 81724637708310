import React, {useEffect, useState} from 'react';
import {actions} from "react-fields";

const BTNS_TO_SHOW = 3;

export default function Pagination(props) {

    const pageName = props.pageName || 'page';

    const getPages = () => {
        let pages = [];
        if (props.totalPages <= 1){
            return [];
        }

        let firstPage = props.page -1;
        let lastPage = props.page + 1;
        if (props.page <= (BTNS_TO_SHOW-1)) {
            firstPage = 1;
            lastPage = BTNS_TO_SHOW;
        }
        if (props.page === props.totalPages) {
            lastPage = props.totalPages;
            firstPage = props.totalPages - BTNS_TO_SHOW + 1;
        }
        if (props.totalPages <= BTNS_TO_SHOW) {
            firstPage = 1;
            lastPage = props.totalPages;
        }

        for (let i = firstPage; i <= lastPage; i++){
            pages.push(i);
        }
        return pages;
    };

    let [pages, setPages] = useState(getPages());

    useEffect(
        () => {
            setPages(getPages());
        }, [props.page, props.totalPages]
    );

    if (pages.length === 0){
        return null;
    }

    const getHref = (page) => {
        let options = {...props.options};
        delete options[pageName];
        if (page > 1) {options[pageName] = page}
        const path = props.path || `${location.origin}${location.pathname}`;
        const search = actions.paramsToURI(options);
        return search.length > 0 ? `${path}?${search}` : path;
    };

    return (
        <div className="grid-x flex-center">
            <nav aria-label="Pagination">
                <ul className="pagination text-center">
                    {props.page > 1 ? <li className="pagination-previous">
                        <a href={getHref(props.page -1)} onClick={(e) => props.goToPage(e, props.page -1 )} rel={'prev'} />
                    </li> : null}
                    {pages.map((page) => {
                        let options = {href: getHref(page), onClick: (e) => props.goToPage(e, page)};
                        if (props.page-1 === page) {options['rel'] = 'prev';}
                        if (props.page+1 === page) {options['rel'] = 'next';}
                        return <li key={page} className={props.page === page ? "current" : ""}>
                            <a {...options}>{page}</a>
                        </li>
                    })}
                    {props.page < props.totalPages ? <li className="pagination-next">
                        <a href={getHref(props.page +1)} onClick={(e) => props.goToPage(e, props.page + 1 )} rel={'next'} />
                    </li> : null}
                </ul>
            </nav>
        </div>
    )

}