import React from "react";
import HoverButton from "../buttons/HoverButton";
export default function RewriteResults(props) {

    return(
        <div className="grid-x">
            <div className="cell">
                <h3 className="popup__title">Overwrite existing test progress?</h3>
                <p className='popup__text'>
                    It seems you have already started the Career Interests test on a previous occasion.
                </p>
                <p className='popup__text'>
                    Would you look to replace your previous progress and results with your new test results? Keep in mind that once replaced, your old test progress cannot be recovered.
                </p>
            </div>
            <div className="cell mar-top-20">
                <HoverButton onClick={props.onConfirm} className="button button--green mar-right-10"
                             data-open="saved">Yes, replace
                </HoverButton>
                <HoverButton onClick={props.onCancel} className="button button--grey-white"
                             data-open="saved">No, keep
                </HoverButton>

            </div>
        </div>
    )

}