import React, {useState, useEffect} from 'react';

export default function Bubble(props) {


    const [score, setScore] = useState(props.score);
    const [isScoreHidden, setScoreVisibility] = useState(props.hideScore);

    let pulseScore = parseInt(score/10);
    const [parentClassName, setParentClassName] = useState(`match-tank pulse_${pulseScore}`);
    const [childClassName, setChildClassName] = useState(`fill fillAction_${pulseScore}`);
    const [translate, setTranslate] = useState(`translate(0, ${(68 - ((68 * score) / 100))}px)`);
    const matchText = props.matchText || 'match';


    useEffect(
        () => {
            clearBubble();
            setScore(props.score);
            setScoreVisibility(props.hideScore);
            const timeOutId = setTimeout(() => {fillBubble(props.score)}, 500);
            return () => {
                clearTimeout(timeOutId);
            };
        },
        [props.score],
    );

    function fillBubble(newScore) {
        let pulseClass = parseInt(newScore/10);
        setParentClassName(`match-tank pulse_${pulseClass}`);
        setChildClassName(`fill fillAction_${pulseClass}`);
        let translateY = 60 - ((60 * newScore) / 100);
        let fillTranslate = `translate(0, ${translateY}px)`;
        setTranslate(fillTranslate);
    }

    function clearBubble() {
        let pulseClass = parseInt(score/10);
        setParentClassName(`match-tank pulseDrain_${pulseClass}`);
        setChildClassName(`drainAction_${pulseClass}`);
    }

    return (
        <div className={parentClassName}>
            <div className="inner">
                <div className={childClassName} style={{transform: translate}}>
                    <svg version="1.1" x="0px" y="0px" width="88px" height="88px" viewBox="0 0 88 88"
                         enableBackground="new 0 0 88 88">
                        <path className="wave-shape" d="M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4
c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9
c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z"/>
                    </svg>
                </div>
            </div>
            <div className={`match-percent hidden ${props.hideMatch ? 'centered' : ''}`}>
                <div className="qm">{isScoreHidden ? '?' : `${score}%`}</div>
                {props.hideMatch ? '' : matchText}
            </div>
        </div>
    );

}