import React from 'react';
import {InputTypeField, Loader, TextField, SelectField} from "react-fields";
import userAPI from '../api/userAPI';
import HoverButton from "../buttons/HoverButton";

class DeleteAccountForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false, error: null, showCheckEmail: false };
    }

    onSuccess = () => {
        this.setState({isLoading: false, showCheckEmail: true});
    };

    onError = () => {
        this.setState({isLoading: false, error: 'An error occurred, please try again later'});
    };

    onSubmit = () => {
        this.setState({isLoading: true}, () => {
            userAPI.delete(this.onSuccess, this.onError);
        });
    };

    onCancel = () => {
        this.setState({showCheckEmail: false});
        this.props.onCancel();
    };

    render() {
        if (this.state.showCheckEmail) {
            return (<div className="grid-x align-center">
                <div className="small-12 cell text-center">
                    <h3 className="mar-bot-10">Check your email</h3>
                    <p className='popup__text'>An account delete confirmation was sent, please check your inbox.</p>
                </div>
                <div className="cell text-center popup__buttons">
                    <HoverButton onClick={this.onCancel}>Close</HoverButton>
                </div>
            </div>)
        }

        return  (
            <div className="grid-x align-center">
                <div className="cell">
                    <h3 className="popup__title">Are you sure you want to delete your account?</h3>
                    {this.state.isLoading ? <Loader /> : null}
                    {this.state.error ? <p className='error'>{this.state.error}</p> : null}
                    <p className='popup__text'>
                        Deleting your account will remove all your progress and results from our system. This process is irreversible. Please only click the ‘Confirm’ button if you are sure you no longer want to be a part of CareerHunter.
                    </p>
                </div>
                <div className="cell text-center popup__buttons">
                    <HoverButton onClick={this.onCancel}>Cancel</HoverButton>
                    <HoverButton onClick={this.onSubmit} color={'red'}>Confirm</HoverButton>
                </div>
            </div>
        )
    }

}
export default DeleteAccountForm;