import React, {useEffect} from 'react';
import CheckBox from '../../base/checkbox';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function CourseLevel(props) {

    const levels = [{id: 'Introductory', name: 'Introductory'},
        {id: 'Intermediate', name: 'Intermediate'},
        {id: 'Advanced', name: 'Advanced'},
        {id: 'Postgraduate', name: 'Postgraduate'},
        {id: 'Undergraduate', name: 'Undergraduate'}];

    let selectedLevels = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'level'));
    let initialFilters = useSelector(state => state.filters.initialFilters['level']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            if (!initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = levels.find(level => level.id === filter);
                if (item) {
                    dispatch(addFilterValue(item, 'level'))
                }
            });
        }, [initialFilters]
    );


    return (
        <div className="aligned-checkboxes flex-center filters__options">
            {levels.map((level) => {
                return <CheckBox key={level.id}
                                 name={level.id}
                                 label={level.name}
                                 className="aligned-checkboxes__item"
                                 onChange={(e) => e.target.checked ?
                                     dispatch(addFilterValue(level, 'level')) :
                                     dispatch(deleteFilterValue(level, 'level'))}
                                 value={!!selectedLevels.find((l) => l.id === level.id)} />
            })}
        </div>
    )

}