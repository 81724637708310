import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import ShareButtons from './buttons';

export default function SharePopup(props) {

    const sharePopup = useRef(null);

    const popupText = props.popup_text ?  props.popup_text : `${props.score}% match`;


    return (
        <React.Fragment>
            <div className="career-details__report career-details__report--share" onClick={() => {sharePopup.current.open()}}>
                <p>Share my results</p>
                <div className="small-icons share-white"/>
            </div>
            <PopUp ref={sharePopup}  className="share-match-popup">
                <div className="share-match-popup__block">
                    <div className="grid-x align-center">
                        <div className="small-12 cell">
                            <div className="large-icon share-career small-mar-bot-20"/>
                            <h3 className="share-match-popup__title">Share my match</h3>
                        </div>
                    </div>
                    <div className="grid-x align-center">
                        <div className="large-4 medium-4 small-12 cell">
                          <img src={props.image} alt={props.title}/>
                        </div>
                        <div className="large-8 medium-8 small-12 cell">
                            <h5 className="share-match-popup__career">{props.title}</h5>
                            <p className='share-match-popup__match'> {popupText}</p>
                        </div>
                    </div>
                    <div className="grid-x align-center share-match-popup__line">
                        <ShareButtons {...props}/>
                    </div>
                </div>
            </PopUp>
        </React.Fragment>

    )
}