import React, {useState, useEffect, useCallback, useRef} from 'react'
import jobsApi from '../api/jobsAPI';
import Pagination from '../base/pagination';
import {actions, Loader} from 'react-fields';
import Filters from '../filtering/jobs/index';
import NotFound from './notFound';
import Collection from './collection';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import ScrollToTop from '../ScrollToTop/index';
import PaginationMeta from '../paginationMeta';

export default function Index(props) {

    let [jobs, setJobs] = useState([]);
    let [totalPages, setTotalPages] = useState(0);
    let [totalJobsNumber, setTotalJobsNumber] = useState('');
    let [page, setPage] = useState(props.initialOptions && props.initialOptions.page ? parseInt(props.initialOptions.page) : 1);
    let [isLoading, setLoading] = useState(true);

    const contentRef = useRef(null);

    const dispatch = useDispatch();

    const filtersSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.filter(filter => filter.type === type)
    );

    const singleFilterSelector = createSelector(
        state => state.filters.selected,
        (_, type) => type,
        (selectedFilters, type) => selectedFilters.find(filter => filter.type === type)
    );

    let needRefreshSearch = useSelector(state => state.filters.needRefreshSearch);


    let { selectedCareers, selectedJobTypes, qSearch, location, salary, selectedIndustries, selectedSectors} = useSelector(state => ({
        selectedCareers: filtersSelector(state, 'career'),
        selectedJobTypes: filtersSelector(state, 'job_type'),
        qSearch: filtersSelector(state, 'qsearch'),
        location: filtersSelector(state, 'location'),
        salary: singleFilterSelector(state, 'salary'),
        selectedIndustries: filtersSelector(state, 'industry'),
        selectedSectors: filtersSelector(state, 'sector'),
    }), shallowEqual);



    useEffect(
        () => {
            loadCollection(props.initialOptions);
        }, []
    );

    useEffect(
        () => {
            if(needRefreshSearch) {
                runSearch();
                dispatch(setNeedRefreshFiltersSearch(false))
            }
        }
    );


    const goToPage = (e, page) => {
        e.preventDefault();
        let options = {...prepareOptionsSearch(), page: page};
        runSearch(options, page);
        window.scrollTo({top: contentRef.current.offsetTop - 180, behavior: 'smooth'});
    };

    const loadCollection = (options = {}, updateHistory = false) => {
        jobsApi.search(options)
            .then((result) => {
                setJobs(result.job_posts);
                setTotalPages(result.meta.total_pages);
                setLoading(false);
                setTotalJobsNumber(result.meta.human_total);
                PaginationMeta.update(options, result.meta.total, result.meta.total_pages, 24, updateHistory);
            }).catch((error) => {
                console.log(error);
            });
    };

    const runSearch = (options = prepareOptionsSearch(), page = 1) =>  {
        setLoading(true);
        setPage(page);
        loadCollection(options, true);
    };

    const prepareOptionsSearch = () => {
        let options = {page: 1};
        if(selectedCareers){options.careers = selectedCareers.map(career => career.id)}
        if(selectedJobTypes){options.job_types = selectedJobTypes.map(type => type.id)}
        if(selectedIndustries){options.industries = selectedIndustries.map(ind => ind.id)}
        if(selectedSectors){options.sectors = selectedSectors.map(sec => sec.id)}
        if(qSearch){options.terms = qSearch.map(qTerm => qTerm.name)}
        if(location){options.locations = location.map(loc => loc.name)}
        if (!!salary) {options.salary = {from: salary.value[0], to: salary.value[1]}}
        return options;
    };


    return (
        <React.Fragment>
            <div className="filter-area">
                <div className="filter-area__container">
                    <div className="grid-container no-padding">
                        <div className="breadcrumbs-nav"><a href="/jobs">Jobs</a>
                            <div className="small-icon arrow-breadcrumbs"/>
                            Search results
                        </div>
                        <div className="grid-x">
                            <div className="small-12 medium-6 large-12 cell">
                                <h1> {totalJobsNumber} job search results</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Filters search={runSearch}/>
            {!isLoading && jobs.length === 0 ?
                <NotFound/> :
                (<div className="grid-container results-area" ref={contentRef}>
                    {isLoading ? <Loader size={'small'}/> : null}
                    <Collection collection={jobs}/>
                    <ScrollToTop/>
                    <Pagination page={page} options={prepareOptionsSearch()}
                                totalPages={totalPages}
                                goToPage={goToPage}/>
                </div>)

            }
        </React.Fragment>

    )
}
