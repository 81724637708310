import React, {useEffect, useRef} from "react";
import PopUp from "../base/popup";
import Index from './modal/index';

export default function FeedbackModal(props) {

    const modal = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            modal.current.open();
        }, 60000);

        return () => clearTimeout(timer);
    }, []);

    return(
        <PopUp ref={modal} className="feedback">
            <Index close={() => modal.current.close()} />
        </PopUp>
    )

}