import React, { useEffect, useState, Fragment } from "react";
import {
    PaymentElement, CardElement, PaymentRequestButtonElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {FadeInSection} from "../../components/fadeIn";
import TermsCheckboxField from "../base/termsCheckbox";
import {InputTypeField, ErrorMessage, actions} from 'react-fields';
import CouponInput from './couponInput';

export default function StripeForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState(null);

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const hideCouponArea = !!props.hideCouponArea;

    useEffect(() => {
        console.log('!!!! useEffect stripeForm ApplePay');
        if (!stripe) {
            return;
        }

        let priceOption = props.price.price;
        if (!!props.discountAmount) {
            console.log(priceOption, props.discountAmount);
            priceOption = (props.price.price - props.discountAmount).toFixed(2);
            console.log(priceOption);
        }
        priceOption = parseInt((priceOption*100));
        console.log(priceOption);

        if (paymentRequest) {
            paymentRequest.update({
                total: {
                    amount: priceOption,
                    label: props.paymentDescription,
                }
            });
            return;
        }

        const prOptions = {
            country: props.countryCode,
            currency: props.price.currency.code.toLowerCase(),
            total: {
                label: props.paymentDescription,
                amount: priceOption,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        };
        console.log(prOptions);

        const pr = stripe.paymentRequest({
            country: 'US',
            currency: props.price.currency.code.toLowerCase(),
            total: {
                label: props.paymentDescription,
                amount: priceOption,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        pr.on('paymentmethod', async (ev) => {
            console.log('opm1 onPaymentMethod');
            let userValid = await props.validate();
            if (!userValid){
                console.log('invalid');
                return;
            }
            console.log('opm2 valid');

            // Confirm the PaymentIntent without handling potential next actions (yet).
            const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                props.clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
            );

            if (confirmError) {
                console.log('opm3 confirm error');
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
            } else {
                console.log('opm3 confirm success');
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent.status === "requires_action") {
                    console.log('opm4 confirm require action');
                    // Let Stripe.js handle the rest of the payment flow.
                    const {error} = await stripe.confirmCardPayment(props.clientSecret);
                    if (error) {
                        console.log('opm5 confirm action fail', error);
                        // The payment failed -- ask your customer for a new payment method.
                    } else {
                        console.log('opm5 confirm action success');
                        props.pay(onSuccess, paymentIntent);
                        // The payment has succeeded.
                    }
                } else {
                    console.log('opm4 confirm no require action');
                    props.pay(onSuccess, paymentIntent);
                    // The payment has succeeded.
                }
            }
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            console.log('canMakePayment:');
            console.log(result);
            if (result) {
                setPaymentRequest(pr);
            }
        });

    }, [stripe, props.discountAmount]);

    const onSuccess = (response) => {
        let enrollment = response.enrollment;
        window.location.href = `${props.afterPaymentUrl}/${enrollment.encrypted_key}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        let userValid = await props.validate();
        console.log('afterValidate');
        console.log(userValid);
        if (!userValid){
            console.log('User not valid');
            setIsLoading(false);
            return;
        }
        console.log('User is valid');

        console.log('confirmParams.payment_method_data.billing_details.address.country');
        const confirmOptions = {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: `${props.afterPaymentUrl}`,
            payment_method: {card: elements.getElement(CardElement)}
        };
        console.log(confirmOptions);
        stripe.confirmCardPayment(props.clientSecret, confirmOptions)
            .then(function(result) {
                console.log('confirm payment result');
                console.log(result);
                if (result.error) {
                    console.log("Error result");
                    console.log(result.error);
                    if (result.error.type === "card_error" || result.error.type === "validation_error") {
                        setMessage(result.error.message);
                    } else {
                        setMessage("An unexpected error occurred.");
                    }
                    setIsLoading(false);
                } else {
                    props.pay(onSuccess, result);
                }
            });
    };

    const handleFieldChange = (e) => {
        let applePay = props.applePay;
        let field_name = e.target.name;
        applePay[field_name] = e.target.checked;
        applePay.errors.base = "";
        props.onChange(applePay);

    };

    const onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            props.validateCouponCode();
        }
    };

    const applePay = props.applePay;
    const simpleForm = !!props.simpleForm;
    const termsErrorMessage = 'Terms and Privacy must be accepted';
    console.log('apple pay fixes rollback');
    return (
        <FadeInSection>
            <div className="tabs-panel is-active">

                <div className="grid-x align-middle">
                    <div className={`small-12 cell`} style={{marginBottom: '10px'}} >
                        <div className={`license-checkout__submit ${(applePay.errors.base) && (applePay.errors.base ===termsErrorMessage) ? "termsError" : " "} `}>
                            {props.user && !!props.user.id ? null :
                                <TermsCheckboxField name="agree" value={applePay.agree}
                                                    errorMessage={applePay.errors.base || props.user.errors.agreeTerms}
                                                    errorTypeMessage={true}
                                                    onChange={handleFieldChange}/>
                            }
                        </div>
                    </div>
                    <div className={'cell'}>
                        {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}
                    </div>
                    <div className=" small-12 cell">
                        { !hideCouponArea &&  <CouponInput show_student_discount={props.show_student_discount}
                                                           discountAmount={props.discountAmount}
                                                           coupon={props.coupon}
                                                           student_discount_code={props.user.student_discount_code}
                                                           onChangeCoupon={props.onCouponChange}
                                                           validateCouponCode={props.validateCouponCode}
                        /> }
                    </div>
                </div>
            </div>
        </FadeInSection>
    );
}