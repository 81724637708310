import React, { useEffect } from "react";

const TooltipPopover = ({ children, className }) => {


    return (
        <div className={`tooltip tooltip--notice ${className ? className : ''}`}>
           {children}
        </div>
    );
};

export default TooltipPopover;
