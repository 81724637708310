import React, {Fragment} from "react";
import HoverButton from "../../buttons/HoverButton";
import ReviewFB from "../../../../assets/images/review-fb.png"
import ReviewGoogle from "../../../../assets/images/review-google.png"
import ReviewTrustpilot from "../../../../assets/images/review-trustpilot.png"
import ReviewYelp from "../../../../assets/images/review-yelp.png"
import CopyToClipboard from "../../copyToClipBoard";

export default function FeedbackSent(props) {

    const couponCodeArea = () => {
        return (props.coupon &&
            <div className="feedback__coupon-box">
                <div className="feedback__content">
                    <div className="feedback__left-area">
                        <p className="feedback__coupon-txt">Here is your unique thank you coupon for <span>30% OFF</span> your next purchase at CareerHunter.</p>
                        <p className="feedback__small-txt"><span className="small-icons blue-off-tooltip"/>Also sent to your email and is active for 1 year.</p>
                    </div>
                    <div className="feedback__right-area">
                        <div className="feedback__copy-coupon">
                            <CopyToClipboard hoverButton={true} name={'couponCode'} value={props.coupon} msg={'Coupon code copied to clipboard'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const fullAccessPaid = () => {
      return (
          <React.Fragment>
              <p className={'feedback__txt'}>Your comments are truly appreciated. We use your feedback to improve the testing experience for everyone.</p>
              <p className={'feedback__txt feedback__txt--bold'}>Care to leave us a review?</p>
              <HoverButton href={'https://www.trustpilot.com/review/www.careerhunter.io'} hrefOpts={{target: '_blank'}} color={'white-img'}><img src={ReviewTrustpilot} alt={'Trustpilot'} width="107" height="27"/></HoverButton>
              {/*<HoverButton href={'/'} hrefOpts={{target: '_blank'}} color={'white-img'}><img src={ReviewYelp} alt={'Review Yelp'} width="78" height="38"/></HoverButton>*/}
              {/*<HoverButton href={'/'} hrefOpts={{target: '_blank'}} color={'white-img'}><img src={ReviewGoogle} alt={'Review Google'} width="82" height="41"/></HoverButton>*/}
              {/*<HoverButton href={'https://www.facebook.com/pg/CareerHunterTest/reviews/'} hrefOpts={{target: '_blank'}} color={'white-img'}><img src={ReviewFB} alt={'Review Facebook'} width="108" height="49"/></HoverButton>*/}
          </React.Fragment>
      )
    };

    const individualPaid = () => {
        return <Fragment>
            <p className={'feedback__txt'}>Ready to continue testing and get your matches?</p>
            <ul className={'tick-list'}>
                <li>Access all six tests, full test results and insights </li>
                <li>Reveal your final career matches based on all your results</li>
                <li>Receive a complete and personalized career report</li>
            </ul>
            <HoverButton   href={'/upgrade'} className={'button--green--large'}>Get Access<span className="small-icons next-arrow-white-l"></span></HoverButton>
        </Fragment>
    };

    console.log(props);

    if (props.rate === 'poor_rate') {
        return (
            <Fragment>
                <h3 className="feedback__msg feedback__msg--with-space">Thanks for your feedback!</h3>
                <p className={'feedback__txt'}>We take any negative comments on board to improve the CareerHunter testing experience on upcoming, future updates.</p>
                <p className={'feedback__contact'}>See our <a href="/faq">FAQs</a> or <a href="/contact">Contact</a> us if you have any questions or need help.</p>
                {couponCodeArea()}
            </Fragment>
        );
    }

    return <Fragment>`
        <div className={"large-icon blue-clap-icon mar-auto"}/>
        <h3 className="feedback__msg feedback__msg--with-space">Feedback sent!</h3>
        {props.full_access ? fullAccessPaid() : individualPaid()}
        {couponCodeArea()}
    </Fragment>

}