import {actions} from 'react-fields'
import store from '../store/store';

class PaymentsApi {

    static submitPayment(order, paymentMethod, purchaseType, token) {
        const url = '/api/payments';

        let data = prepareData(order, paymentMethod, purchaseType, token);
        return fetch(actions.postRequest(url, data ))
                .then(actions.status)
                .then(actions.json)

    }

    static updatePayment(order, paymentMethod, purchaseType) {
        const url = `/api/payments/${order.enrollmentId}`;

        let data = prepareData(order, paymentMethod, purchaseType);
        return fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)

    }


    static checkStatus(key, onSuccess, onError){
        const url = `/api/check_status/${key}`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            onError();
        });
    }

    static validate(order, paymentMethod, purchaseType) {
        const url = '/api/payments/validate';
        let data = prepareData(order, paymentMethod, purchaseType);
        return fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

}

export default PaymentsApi;

function prepareData(order, paymentMethod, purchaseType, token = null) {
    let card = {}, paypal = {}, coupon = {}, apple_pay = {};
    let userDetails = {...order.userDetails};
    if (paymentMethod === "stripe"){
        let payment = order.cardDetails;
        card = {
            payment_intent_id: payment.payment_intent_id,
            agree: payment.agree
        };
        userDetails['agree_terms'] = card.agree;
        userDetails['newsletter_subscription'] = payment.newsletter_subscription;
    } else if (paymentMethod === "card"){
        let payment = order.cardDetails;
        card = {
            name: payment.name,
            number: payment.number,
            csc: payment.csc,
            expiry: (payment.expire_year && payment.expire_month) ? `${payment.expire_year}${payment.expire_month}` : '',
            agree: payment.agree
        };
        userDetails['agree_terms'] = card.agree;
        userDetails['newsletter_subscription'] = payment.newsletter_subscription;
    } else if (paymentMethod === "paypal") {
        paypal = {agree: order.payPalDetails.agree};
        userDetails['agree_terms'] = paypal.agree;
        userDetails['newsletter_subscription'] = order.payPalDetails.newsletter_subscription;
    } else if (paymentMethod === 'applepay') {
        let payment = order.cardDetails;
        card = {
            payment_intent_id: payment.payment_intent_id,
            agree: order.applePayDetails.agree
        };
       // apple_pay = {agree: order.applePayDetails.agree, payment_data: order.applePayDetails.payment_data || 'validation'};
        userDetails['agree_terms'] = order.applePayDetails.agree;
        userDetails['newsletter_subscription'] = order.applePayDetails.newsletter_subscription;
    }

    if (order.coupon) {
        coupon = {code: order.coupon}
    }


    delete userDetails.errors;
    let data = { user: userDetails,
        options: order.options,
        card: card,
        paypal: paypal,
        coupon: coupon,
        apple_pay: apple_pay,
        enrollment_id: order.enrollmentId,
        payment_method: paymentMethod,
        purchase_type: purchaseType,
        g_recaptcha_response: token};

    if (order.offer_id) {
        data['offer_id'] = order.offer_id;
    }

    if(order.career_test_id) {
        data['career_test_id'] = order.career_test_id;
    }

    if (!order.userDetails.id) {
        let testData = {};
        if(localStorage.getItem('interests')) {
            let tempAnswers = localStorage.getItem('interests');
            tempAnswers = JSON.parse(tempAnswers);
            let answers = [];
            tempAnswers.filter((page) => page.answered ).map((page) => {
                page.statements.map((statement) => {
                    answers.push({statement_id: statement.id, score: 5 - statement.order, page: page.page})
                })
            });
            testData = answers;
        }
        let interests_status = localStorage.getItem('interests_status') || 'not_started';
        data.answers = testData;
        data.user.interests_status = interests_status;
    }
    return data;
}