import React, {useEffect, useRef} from 'react';
import PopUp from '../base/popup';

export default function deletedConfirmationModal() {
    const popUpRef = useRef();

    useEffect(() => {
        popUpRef.current.open();
        localStorage.clear();
    }, []);

    return  (
       <PopUp ref={popUpRef}>
           <div className="grid-x align-center">
               <div className="cell text-center">
                   <h3 className="mar-bot-10">Account Deleted</h3>
                   <p className='popup__text'>Your account has been removed!</p>
               </div>
           </div>
       </PopUp>
    )

}