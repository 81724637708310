import React, {useState, useRef} from "react";
import Chevron from '../base/accordion/chevron';
import Checkbox from '../base/checkbox';

function AccordionWithCheckbox(props){

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    const toggleAccordion = () => {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
    };

    return (
        <div className="accordion__section">
            <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <Checkbox className="accordion__checkbox" onChange={(e) => props.toggleSelect(e,props.title)} value={props.selected}/>
                <div className="accordion__title">{props.title}</div>
                <Chevron className={`${setRotate}`} fill={"#fff"} />
            </div>
            <div className="accordion__content" ref={content} style={{ maxHeight: `${setHeight}` }}>
                {props.children}
            </div>
        </div>
    );
}


export default AccordionWithCheckbox;

