import {actions} from 'react-fields';
import {disableCaptchaForTest} from "../settings";

export default class ContactMessageAPI {

    static send(contactForm, captchaToken, subject, onSuccess, onError) {
        const url = '/api/contacts';
        let data = {
            message: {
                name: contactForm.name,
                subject: contactForm.subject,
                email: contactForm.email,
                phone: contactForm.phone,
                message: contactForm.message,
                agree_terms: contactForm.agree_terms
            },
            email_subject: subject,
            g_recaptcha_response: captchaToken
        };

        if (disableCaptchaForTest()) {
            data['g_recaptcha_disabled_for_test'] = true;
        }

        return fetch(actions.postRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async(response) => {
                onSuccess(response);
            }).catch(async error => {
                if (error.status === 422) {
                    error.json().then(function (value) {
                        onError(value);
                    });
                } else {
                    console.error(error);
                    onError();
                }
            });
    }


}