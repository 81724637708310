import React from 'react';
import User from './user';
import CouponCodeApi from '../api/couponCodesAPI';
import {Loader} from 'react-fields'
import {customPriceRounding} from "../utils";
import InvisibleRecaptcha from '../contact/invisibleRecaptcha';

import PaymentForm from "./paymentForm";
import PaymentTabs from "./paymentTabs";

const ACCESS_TEXT = {
    full: {
        title: "FULL ACCESS", description: 'Access all areas and enjoy the full career matching experience.',
        list: ["Unlock all 6 tests and full results", "Reveal your final career matches", "500+ page personalized report"],
        icon: "large-icon green-unlock"
    },
    interests: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Career Interest results.",
        list: ["Reveal all your career interests", "Get a 55-page personalized report", "View career recommendations"],
        icon: "large-icon interests-green"
    },
    personality: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Work Personality results.",
        list: ["Reveal all your personality traits", "Get a 55-page personalized report", "View career recommendations"],
        icon: "large-icon personality-green"
    },
    motivators: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Career Motivators results.",
        list: ["Reveal all your motivators traits", "Get a 55-page personalized report", "View career recommendations"],
        icon: "large-icon motivators-green"
    },
    abstract: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Abstract Reasoning results.",
        list: ["Reveal your abstract reasoning abilities", "Get a personalized report", "View career recommendations"],
        icon: "large-icon abstract-green"
    },
    numerical: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Numerical Reasoning results.",
        list: ["Reveal your numerical reasoning abilities", "Get a personalized report", "View career recommendations"],
        icon: "large-icon numerical-green"
    },
    verbal: {
        title: "INDIVIDUAL ACCESS", description: "Gain access to your full Verbal Reasoning results.",
        list: ["Reveal your verbal reasoning abilities", "Get a personalized report", "View career recommendations"],
        icon: "large-icon verbal-green"
    }
};

const RETAKES_TEXT = {
    full: {
        title: "TEST RETAKES", description: 'Purchase additional test retakes to regenerate your scores and matches.',
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon green-unlock"
    },
    interests: {
        title: "TEST RETAKES", description: "Purchase additional career interests test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon interests-green"
    },
    personality: {
        title: "TEST RETAKES", description: "Purchase additional personality  test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon personality-green"
    },
    motivators: {
        title: "TEST RETAKES", description: "Purchase additional motivators test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon motivators-green"
    },
    abstract: {
        title: "TEST RETAKES", description: "Purchase additional abstract test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon abstract-green"
    },
    numerical: {
        title: "TEST RETAKES", description: "Purchase additional numerical test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon numerical-green"
    },
    verbal: {
        title: "TEST RETAKES", description: "Purchase additional verbal test retakes to regenerate your scores and matches.",
        list: ["Additional 2 retakes on all tests", "Regenerate your career matches", "Update of your personalized reporting"],
        icon: "large-icon verbal-green"
    }
};

class Index extends PaymentForm {


    componentDidMount() {

        this.calculateStickySettings();
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);

        const btnScrollToPayent = document.querySelector('#btnScrollToPayment');
        btnScrollToPayent.addEventListener("click", () => {
            window.scrollTo({ top: this.state.stopTop, behavior: "smooth" });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.calculateStickySettings();
    };

    handleScroll = (e) => {
        const shrinkStickyBar = document.querySelector('#contentSection');
        if (window.innerWidth <= 1024) {
            if (window.pageYOffset >= this.state.stopTop) {
                shrinkStickyBar.style.paddingTop = this.state.calculatedPad;
                this.setState({
                    displayExtended: true
                });
            } else {
                shrinkStickyBar.style.paddingTop = "0";
                this.setState({
                    displayExtended: false
                });
            }
        }
    };



    calculateStickySettings = () => {
        const stickyTopBarHeight = 65; // because changes it's height on scroll, was document.querySelector('.top-menu.sticky-container').clientHeight;
        const stickyPaymentSubheaderHeight = 45;
        const orderDetailsOffsetTop = document.querySelector('#pay').offsetTop;
        const stopTop = orderDetailsOffsetTop - stickyTopBarHeight - stickyPaymentSubheaderHeight;
        const calculatedPad = ((window.innerWidth <= 1024) && (window.innerWidth >= 641)) ? "353px" : "345px";
        this.setState({stopTop: stopTop, calculatedPad: calculatedPad});
    };




    onSuccessValidCode =(code) => {
      let discountAmount = this.props.price.price;
      if(code.discount_type === 'percent') {
          discountAmount = this.props.price.price/100 * code.discount_amount;
          let priceWithDiscount = customPriceRounding(this.props.price.price - discountAmount);
          discountAmount = Math.round((this.props.price.price - priceWithDiscount) * 100) / 100;
      } else if (code.discount_type === 'amount') {
          discountAmount = code[`discount_amount_${this.props.price.currency.code.toLowerCase()}`];
      }
      if (discountAmount >= this.props.price.price){
          discountAmount = this.props.price.price - 0.01
      }
      this.setState({discountAmount: discountAmount.toFixed(2), coupon: {...this.state.coupon, applied: true, student_discount: code.student_discount}})
    };

    validateCouponCode = (e) => {
        if (e) {e.preventDefault();}
        let _this = this;
        let coupon = this.state.coupon;
        this.setState({isLoading: true}, () => {
            CouponCodeApi.check(coupon.code, this.props.purchaseType, this.props.test ? this.props.test.id : null).then((response) => {
                this.onSuccessValidCode(response);
                this.setState({isLoading: false});
            }).catch(error => {
                console.log(error);
                error.json().then((value) => {
                    coupon.errors = value;
                    _this.setState({coupon: coupon});
                    this.setState({isLoading: false});
                })
            })

        })
    };

    textCode = () => {
      return this.props.test ? this.props.test.code : 'full'
    };

    getText = (type) => {
        return this.props.purchaseType === 'retakes' || this.props.purchaseType ==='test_retake' ? RETAKES_TEXT[this.textCode()][type] : ACCESS_TEXT[this.textCode()][type]
    };

    render() {
        const { displayExtended } = this.state;
        const curSymbol = this.props.price.currency.symbol;

        const paymentProps = {
            user: this.state.user,
            pay: this.submitOrder,
            price: this.props.price,
            description: this.props.description,
            show_student_discount: this.props.show_student_discount,
            afterPaymentUrl: this.props.afterPaymentUrl,
            discountAmount: this.state.discountAmount,
            validateCouponCode: this.validateCouponCode,
            coupon: this.state.coupon,
            onCouponChange: this.onCouponChange,
            validate: this.validate,
            hideCouponArea: this.props.hideCouponArea
        };

        return (
            <React.Fragment>
                <div className={'grid-x'} id="contentSection">
                    <div className={"large-5 medium-12 small-12 cell"} >
                        <div className={`license-checkout__box `  + (displayExtended ? ' license-checkout__none' : ' ')}>
                            <div className={'grid-x license-checkout__widget ' }>
                                <div className="small-12 cell">
                                    <div className="flex-middle flex-direction-row">
                                        <div className="blue-box">
                                            <div className={this.getText('icon')}/>
                                        </div>
                                        <div className="content-box">
                                            <h3 className="white mar-bot-5">{this.getText('title')}</h3>
                                            <p className="content-box-desc">{this.getText('description')}</p>
                                        </div>
                                    </div>
                                    <p className="bold mar-bot-5">WHAT YOU GET:</p>
                                    {this.getText('list').map((p) => {
                                        return  <p className="bullet-point"><span className="checkout-icon green-tick"/>{p}</p>
                                        })}
                                    <div className="grid-x mar-top-20 align-end">
                                        <div className="medium-6 cell">
                                            <h3 className="grey">One-time fee of</h3>
                                        </div>
                                        <div className="medium-6 cell medium-flex-end">
                                            { this.state.discountAmount > 0 || this.props.price.price !== this.props.price.original_price ?
                                                <React.Fragment>
                                                    <div className="license-checkout__price small-order-2 medium-order-2">
                                                        <span className="license-checkout__sup small-order-1 medium-order-3">
                                                            <del>{curSymbol}{this.props.price.original_price}</del>
                                                        </span>
                                                        <span className="license-checkout__original-price">{curSymbol}{(this.props.price.price - this.state.discountAmount).toFixed(2)}</span>
                                                    </div>
                                                </React.Fragment>
                                                : <div className="license-checkout__price small-order-2 medium-order-2">{curSymbol}{this.props.price.price}</div>
                                            }
                                        </div>
                                    </div>
                                    {this.props.purchaseType === 'retakes' || this.props.purchaseType ==='test_retake' ? null :
                                        <div className="checkout-note"><div className="checkout-icon note-icon-grey"/>Includes up to 2 retakes per test and lifetime database updates to recalculate your results and matches.</div>}
                                </div>
                            </div>
                        </div>
                        <div className="show-for-large">
                            <div className="small-icons payment-icons"/>
                        </div>
                        <div className={`hide-for-large license-checkout__box  ` + (displayExtended ? ' license-checkout__is-stuck' : 'license-checkout__none')}>
                            <div className="grid-x license-checkout__widget">
                                <div className="small-7 cell license-checkout__control">
                                    <h3 className="license-checkout__widget__counter">LIFETIME ACCESS</h3>
                                </div>
                                <div className="small-5 cell flex-end">
                                    { this.state.discountAmount > 0 ?
                                        <React.Fragment>
                                            <div className="license-checkout__price small-order-2 medium-order-2">
                                                <p className="small-order-1 medium-order-3 mar-bot-0">
                                                    <span>{curSymbol}{(this.props.price.price - this.state.discountAmount).toFixed(2)}</span> <span className="license-checkout__sup license-checkout__old-price group__small group__small--title">{curSymbol}{this.props.price.price}</span>
                                                </p>

                                            </div>
                                        </React.Fragment>

                                        : <div className="license-checkout__price small-order-2 medium-order-2">{curSymbol}{this.props.price.price}</div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'large-7 medium-12 small-12 cell'}  id="pay">
                        <div className="license-checkout__box upgrade-form">
                            {this.state.isLoading ? <Loader/> : null}
                            <User setUser={this.setUser} user={this.state.user} />
                            <div className="grid-x form-container license-checkout__tab-area">
                                <PaymentTabs header={'Billing'}
                                             paymentMethod={this.state.paymentMethod}
                                             changePaymentMethod={this.changePaymentMethod}
                                             totalPrice={this.props.price.price}
                                             discountAmount={this.state.discountAmount}
                                             fontsCss={this.props.fontsCss}
                                             careerTest={this.props.test}
                                             cardProps={{
                                                 onChange:this.onCardChange,
                                                 card: this.state.card,
                                                 user: this.state.user,
                                                 pay: this.sendOrderWithoutCaptcha,
                                                 price: this.props.price,
                                                 description: this.props.description,
                                                 show_student_discount: this.props.show_student_discount,
                                                 afterPaymentUrl: this.props.afterPaymentUrl,
                                                 discountAmount: this.state.discountAmount,
                                                 validateCouponCode: this.validateCouponCode,
                                                 coupon: this.state.coupon,
                                                 onCouponChange: this.onCouponChange,
                                                 validate: this.validate,
                                                 hideCouponArea: this.props.hideCouponArea,
                                                 countryCode: this.props.countryCode,
                                                 purchaseType: this.props.purchaseType
                                             }}
                                             paypalProps={{...{
                                                     pay: this.sendOrderWithoutCaptcha,
                                                     paypal: this.state.paypal,
                                                     onChange: this.onPayPalChange,
                                                     purchaseType: this.props.purchaseType,
                                                     totalPrice: (this.props.price.price - this.state.discountAmount).toFixed(2),
                                                 }, ...paymentProps}}
                                             applePayProps={{...{
                                                     pay: this.sendOrderWithoutCaptcha,
                                                     applePay: this.state.applePay,
                                                     onChange: this.onApplePayChange,
                                                     countryCode: this.props.countryCode,
                                                     paymentDescription: "Full Access on CareerHunter",
                                                 }, ...paymentProps}}
                                />

                                    <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                                        sitekey={this.props.recaptchaKey}
                                                        verifyCallback={this.sendOrder} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-12 cell hide-for-large">
                        <div className="small-icons payment-icons" style={{marginTop: '20px'}}/>
                    </div>
            </React.Fragment>
        )

    }



}
export default Index;
