import React, {Component} from "react";
import Slider from "react-slick";
import {FadeInSection} from "../fadeIn";
import SliderArrow from "../base/sliderArrow"

export default function degreesCarousel() {


    const SLIDER_SETTINGS = {
        dots: false,
        infinite: false,
        arrows: true,
        variableWidth: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
        prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            }
        ]
    };


    return (
        <FadeInSection>
            <Slider  {...SLIDER_SETTINGS}>
                <div className="card card--degrees">
                    <div className="card__degree-content">
                        <div className="flex-middle spaced-between">
                            <h3 className="card__degree-title">Bachelor of Applied Arts and Science</h3>
                            <div className="card__degree-logo"></div>
                        </div>
                        <div className="card__degree-details">1 course | Undergraduate | 36-72 months</div>
                        <a target="_blank" className="card__search">Go to Degree <span className="small-icons next-arrow-green-s"></span></a>
                    </div>
                </div>
                <div className="card card--degrees">
                    <div className="card__degree-content">
                        <div className="flex-middle spaced-between">
                            <h3 className="card__degree-title">Bachelor of Applied Arts and Science</h3>
                            <div className="card__degree-logo"></div>
                        </div>
                        <div className="card__degree-details">1 course | Undergraduate | 36-72 months</div>
                        <a target="_blank" className="card__search">Go to Degree <span className="small-icons next-arrow-green-s"></span></a>
                    </div>
                </div>
                <div className="card card--degrees">
                    <div className="card__degree-content">
                        <div className="flex-middle spaced-between">
                            <h3 className="card__degree-title">Bachelor of Applied Arts and Science</h3>
                            <div className="card__degree-logo"></div>
                        </div>
                        <div className="card__degree-details">1 course | Undergraduate | 36-72 months</div>
                        <a target="_blank" className="card__search">Go to Degree <span className="small-icons next-arrow-green-s"></span></a>
                    </div>
                </div>
                <div className="card card--degrees">
                    <div className="card__degree-content">
                        <div className="flex-middle spaced-between">
                            <h3 className="card__degree-title">Bachelor of Applied Arts and Science</h3>
                            <div className="card__degree-logo"></div>
                        </div>
                        <div className="card__degree-details">1 course | Undergraduate | 36-72 months</div>
                        <a target="_blank" className="card__search">Go to Degree <span className="small-icons next-arrow-green-s"></span></a>
                    </div>
                </div>
            </Slider>
        </FadeInSection>

    )

}