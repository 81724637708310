import React, {useState, useEffect} from 'react';
import Carousel from '../ui/carousel';
import Course from './course';
import coursesAPI from "../api/coursesAPI";
import {actions} from "react-fields";
import SliderArrow from "../base/sliderArrow"

export default function featuredCoursesCarousel(props) {

    let [courses, setCourses] = useState([]);
    let [isLoading, setLoading] = useState(true);

    useEffect(
        () => {
            loadCollection();
        }, []
    );

    const SLIDER_SETTINGS = {
        dots: false,
        dotsClass: 'slider-bar',
        infinite: false,
        arrows: true,
        variableWidth: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
        prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    };

    const loadCollection = () => {
        let api;
        if(props.type === 'relevant'){
            api = coursesAPI.relevant();
        } else {
            api = coursesAPI.featured();
        }
        api.then((result) => {
            setCourses(result);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    if (isLoading) return null;

    return (
        <Carousel items={courses} component={Course} settings={SLIDER_SETTINGS}/>
    )

}