import React, {useEffect} from "react";

export default function useOutsideClickHandler(ref, onClick) {

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick()
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}