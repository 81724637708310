import React from 'react';
import {actions} from "react-fields";
import Block from './block';

export default class Blocks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {collection: [], blocks: [], latestAnimatedBlocks: [], isLoaded: false}
    }

    componentDidMount() {
        const url = `/api/randomized_blocks?code=${this.props.testCode}`;
        let _this = this;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                const shuffled = data.sort(() => 0.5 - Math.random());
                let selected = shuffled.slice(0, 6).map((block, i) => {return {...block, key: `item${i}`}});
                _this.setState({collection: data, blocks: selected, isLoaded: true});
                _this.setUpNextSwitch();
            }).catch(error => {
                console.log(error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    setUpNextSwitch = () => {
        setTimeout(this.switch, 2500);
    };

    switch = () => {
        let latestAnimatedBlocks = this.state.latestAnimatedBlocks;
        const key = this.state.blocks.filter(i => !latestAnimatedBlocks.includes(i.key))[Math.floor(Math.random()*(6-latestAnimatedBlocks.length))].key;

        if (latestAnimatedBlocks.length >= 2) {
            latestAnimatedBlocks = latestAnimatedBlocks.slice(-1);
        }
        latestAnimatedBlocks.push(key);

        const blocks = [...this.state.blocks];
        const switchElementIndex = blocks.findIndex(i => i.key === key);
        let item = this.state.collection.filter(i => i.id !== key)[Math.floor(Math.random()*(this.state.collection.length-1))];
        blocks[switchElementIndex] = {...item, key: key};
        this.setState({blocks: blocks, latestAnimatedBlocks: latestAnimatedBlocks}, this.setUpNextSwitch);
    };

    render() {
        return (
            <div className="grid-x">
                {this.state.blocks.map((item) => (<Block key={`item${item.key}`} block={item} />))}
            </div>
        );
    }

}