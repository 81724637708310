import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import OmniauthApi from "../../api/omniauthAPI";
import {tempResultsToHash} from "../../careerTestUtils";

export default function FacebookButton(props) {

    const callback = (response) => {
        console.log(response);
        if (!response.signedRequest) { console.error('No facebook signed request'); return; }
        props.onCallback(() => {
            let cookie_name = `fbsr_${props.appId}`;
            let match = document.cookie.match(new RegExp(cookie_name + '=([^;]+)'));
            //if(match == null) {
            document.cookie = cookie_name + "=" + response.signedRequest;
            //}

            const responseToParams = props.saveAnswers ? {...response, ...tempResultsToHash()} : response;
            OmniauthApi.loginWithProvider('facebook', responseToParams, props.onSuccess, props.onError);
        });
    };

    return (
        <FacebookLogin
            appId={props.appId}
            autoLoad={false}
            callback={callback}
            fields="email,first_name,last_name,name"
            scope="email,public_profile"
            render={renderProps => (
                <button onClick={(e) => {e.preventDefault(); renderProps.onClick(e);}} className="button button--clear">
                    <span className={props.icon || 'small-icons is-left facebook'} />
                    {props.label || 'Sign up using'} <span>Facebook</span>
                </button>
            )}
        />
    )

}