import React from 'react';
import ResendEmailButton from './resendEmailButton';
import PopUp from '../base/popup';
import UnconfirmedModal from "../base/modals/confirmationSent";

class ResendEmailLink extends ResendEmailButton {

    onClick = (e) => {
        e.preventDefault();
        if (this.state.isLoading) {return;}
        this.setState({isLoading: true}, () => { this.resendEmail(); });
    };

    render() {
        return  (
            <React.Fragment>
               <a onClick={this.onClick}>{this.state.btnName}</a>

               <PopUp ref={this.checkEmailModal}>
                   <UnconfirmedModal email={this.props.user ? this.props.user.email : ''}/>
               </PopUp>
            </React.Fragment>
        )
    }




}
export default ResendEmailLink;