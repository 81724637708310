import {actions} from 'react-fields'

class MatchesSharesAPI {

    static update() {
        const url = '/api/matches_share';
        let data = {};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( (response) => {
        }).catch(error => {
            console.log(error);
        });
    }

}

export default MatchesSharesAPI;