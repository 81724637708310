export default {
    development: {
        facebookID: '712746542539137',
        googleID: '706480718672-6llbmkgf2me3olbuvhmv7ruti917sjad.apps.googleusercontent.com'
    },
    staging: {
        facebookID: '712746542539137',
        googleID: '706480718672-6llbmkgf2me3olbuvhmv7ruti917sjad.apps.googleusercontent.com'
    },
    production: {
        facebookID: '746921042121968',
        googleID: '647146204636-heedt6etnjri7ebfp2vlfm42u9v5pm07.apps.googleusercontent.com'
    }
}[process.env.RAILS_ENV]