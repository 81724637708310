import React, {useState} from "react";
import Buttons from './buttons';
import Form from './form';

export default function ShareSteps(props) {

    const [step, setStep] = useState('buttons');
    const [platform, setPlatform] = useState(null);

    const openShareForm = (platform) => {
        setPlatform(platform);
        setStep('form');
    };

    switch(step) {
        case 'form':
            return <Form prevStep={() => setStep('buttons')} platform={platform} {...props.formProps} />;
        default:
            return <div>
                    <p className={'matches-popup__text'}>Share</p>
                    <Buttons onClick={openShareForm} url={props.formProps.url} {...props.buttonsProps} />
            </div>;
    }
}