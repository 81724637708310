import React, {useState, useEffect} from "react";
import {actions, TextareaField, TextField} from 'react-fields';
import HoverButton from "../../buttons/HoverButton";

import FacebookShareButton from 'react-share/es/FacebookShareButton';
import LinkedinShareButton from 'react-share/es/LinkedinShareButton';
import TwitterShareButton from 'react-share/es/TwitterShareButton';
import EmailShareButton from 'react-share/es/EmailShareButton';

export default function ShareStepForm(props) {

    const defaultText = () => {
        switch(props.platform) {
            case 'email':
                if (!!props.email) {
                    return props.email.msg
                }
                return `${props.defaultText} ${props.url}`;
            case 'linkedin':
                return !!props.hashtags ? `${props.defaultText} ${props.hashtags.join(' ')}` : props.defaultText;
            default:
                return props.defaultText;
        }
    };

    const [message, setMessage] = useState(defaultText());
    const [subject, setSubject] = useState(!!props.email ? props.email.subject : '');

    useEffect(() => {
        setMessage(defaultText());
        setSubject(!!props.email ? props.email.subject : '')
    }, [props.url]);

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
    };

    const onChangeSubject = (e) => {
        setSubject(e.target.value);
    };

    const shareProps = {url: props.url, title: message};
    let header = '';
    let button = '';
    switch(props.platform) {
        case 'facebook':
            header = 'Post to Facebook';
            button = (<FacebookShareButton url={props.url} quote={message} style={{outline: "none"}}>
                <HoverButton color='facebook' span={true}>Post</HoverButton>
            </FacebookShareButton>);
            break;
        case 'twitter':
            header = 'Post tweet';
            button = (<TwitterShareButton {...shareProps} via="CareerHunter" hashtags={!!props.hashtags ? props.hashtags.map(i => i.replace('#', '')) : []} style={{outline: "none"}}>
                <HoverButton color='twitter' span={true}>Post tweet</HoverButton>
            </TwitterShareButton>);
            break;
        case 'linkedin':
            header = 'Share update';
            button = (
                <LinkedinShareButton {...shareProps} style={{outline: "none"}}>
                    <HoverButton color='linkedin' span={true}>Share update</HoverButton>
                </LinkedinShareButton>
            );
            break;
        default:
            header = 'Send email';
            button = (
                <EmailShareButton {...shareProps} subject={subject} body={message} style={{outline: "none"}}>
                    <HoverButton span={true}>Send</HoverButton>
                </EmailShareButton>
            );
            break;
    }


    const iconColorPostfix = props.iconColor ? `-${props.iconColor}` : '';
    return (
        <div className={`${props.classPrefix || 'affiliates__share'}__form`}>
            <div className={`${props.classPrefix || 'affiliates__share'}__form-title`}>
                <a onClick={props.prevStep}><span className={`small-icon chevron-left${iconColorPostfix}`}/></a>
                <p>{header}</p>
                <span className={`share-icon ${props.platform}${iconColorPostfix}`}/>
            </div>
            <div className={`${props.classPrefix || 'affiliates__share'}__fields`}>
                {props.platform === 'email' ? <React.Fragment>
                    <label>
                        <span>Subject</span>
                        <TextField name="subject" onChange={onChangeSubject} value={subject}
                                   placeholder={'Type your subject ...'} />
                    </label>
                    <label>
                        <span>Message</span>
                    </label>
                </React.Fragment> : null }
                <TextareaField name={'message'} value={message} onChange={onChangeMessage} rows={5} />
                <div className="text-center mar-top-20">
                    {button}
                </div>
            </div>
        </div>
    );
}