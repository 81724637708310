import React, { Component } from 'react';
import careersAPI from '../../api/careersAPI';

import Block from './block';
import {generateRandomScore} from '../../utils';

class Index extends Component {

    state = {
        careers: [],
        allCareers: [],
        blocksNumber: null,
        isLoading: true
    };

    componentDidMount() {
        careersAPI.featured().then( (result) => {
            this.setBlocksByBreakpoint(result);
            window.addEventListener("resize", () => this.setBlocksByBreakpoint());
        }).catch((error) => {
        console.log(error);
        });
    }

    componentWillUnmount() {
        if (this.animationTimeout)
            clearTimeout(this.animationTimeout);
    }

    setBlocksByBreakpoint = (allCareers) => {
        const width = window.innerWidth;
        let blocksNumber = 6;
        if (width < 450) { blocksNumber = 1; }
        else if (width < 640) {blocksNumber = 2}
        else if (width < 769) {blocksNumber = 3}
        else if (width < 1024) {blocksNumber = 4}
        else if (width < 1367) {blocksNumber = 5}

        if (blocksNumber === this.state.blocksNumber) { return }

        if (!allCareers) {
            if (this.animationTimeout)
                clearTimeout(this.animationTimeout);
            allCareers = this.state.allCareers;
        }

        let careers = allCareers.slice(0, blocksNumber).map((block, i) => {return {...block, key: `item${i}`, score: generateRandomScore()}});
        this.setState({allCareers: allCareers, careers: careers, blocksNumber: blocksNumber, isLoading: true}, () => {
            this.setUpNextSwitch();
        });
    };

    setUpNextSwitch = (currentKey) => {
        this.animationTimeout = setTimeout(() => this.switch(currentKey), 3500);
    };

    switch = (currentKey) => {
        let blocks = [...this.state.careers];
        let selectedCareerIds = [];
        blocks.map(b => {selectedCareerIds.push(b.id)});
        const blocksNumber = this.state.blocksNumber;
        let switchElement, switchElementIndex;
        if (blocksNumber === 1) {
            switchElement = blocks[0];
            switchElementIndex = 0;
        } else if (!!currentKey) {
            switchElement = blocks.filter(i => i.key !== currentKey)[Math.floor(Math.random()*(blocksNumber-1))];
            switchElementIndex = blocks.findIndex(i => i.key === switchElement.key);
        } else {
            switchElementIndex = Math.floor(Math.random()*blocksNumber);
            switchElement = blocks[switchElementIndex];
        }
        let item = this.state.allCareers.filter(i => !selectedCareerIds.includes(i.id))[Math.floor(Math.random()*(this.state.allCareers.length-selectedCareerIds.length))];
        blocks[switchElementIndex] = {...item, key: switchElement.key, score: generateRandomScore()};
        this.setState({careers: blocks, prevAnimatingKey: currentKey, animatingkKey: switchElement.key});
    };

    removePreviousScore = () => {
        if (!this.state.prevAnimatingKey) { return null; }
        let blocks = [...this.state.careers];
        blocks = blocks.map((block) => {
            if (block.key === this.state.prevAnimatingKey) {
                return {...block, score: generateRandomScore()}
            } else {
                return block;
            }
        });
        this.setState({careers: blocks, prevAnimatingKey: null});
    };

    render() {
        if (!this.state.isLoading || this.state.careers.length === 0) {
            return null
        }

        return (
            <div className="grid-x grid-margin-x">
                {this.state.careers.map((item) => (<Block key={`item${item.key}`} career={item} onFadeIn={this.removePreviousScore} onAnimationEnd={() => this.setUpNextSwitch(item.key)} />))}
            </div>
        )
    }

}

export default Index;