import React, {useState, useEffect} from 'react';
import Carousel from '../ui/carousel';
import Course from './course';
import coursesAPI from "../api/coursesAPI";
import {actions} from "react-fields";

export default function coursesCarousel(props) {

    let [courses, setCourses] = useState([]);
    let [isLoading, setLoading] = useState(true);

    useEffect(
        () => {
            loadCollection();
        }, []
    );

    const loadCollection = () => {
        coursesAPI.relevant(props.limit).then((result) => {
            setCourses(result);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    };

    if (isLoading) return null;

    return (
        <div className="grid-x">
            {courses.map((course, index) => (
                    <div className="large-3 medium-6 small-12 cell show-for-large" key={`courseDahsboardA${index}`}>
                        <Course key={`courseDahsboardA${index}t`} item={course} className={'card--courses'} />
                    </div>
                )
            )}
           <div className="small-12 cell hide-for-large">
               <div className="courses-slider">
                   <Carousel items={courses} component={Course} />
               </div>
           </div>
        </div>
    )

}