import React, { Component } from 'react';
import TermsCheckboxField from "../base/termsCheckbox";
import HoverButton from "../buttons/HoverButton";
import {InputTypeField} from "react-fields";
import {generateForm, generateInput} from '../utils';
import {FadeInSection} from "../../components/fadeIn";
import CouponInput from "./couponInput";

class PayPalForm extends Component {

    state = {
        price: this.props.price
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.pay(this.onSuccess);
    };

    handleFieldChange = (e) => {
        let paypal = this.props.paypal;
        let field_name = e.target.name;
        paypal[field_name] = e.target.checked;
        paypal.errors.base = "";
        this.props.onChange(paypal);
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            this.props.validateCouponCode();
        }
    };


    onSuccess = (response) => {
        let enrollment = response.enrollment;
        let form = generateForm(this.props.paypal.url);
        form.appendChild(generateInput('cmd', '_xclick'));
        form.appendChild(generateInput('amount', enrollment.amount));
        form.appendChild(generateInput('item_name', enrollment.description));
        form.appendChild(generateInput('item_number', enrollment.id));
        form.appendChild(generateInput('custom', enrollment.email));
        form.appendChild(generateInput('business', this.props.paypal.business_email));
        form.appendChild(generateInput('currency_code', enrollment.currency));
        form.appendChild(generateInput('notify_url', this.props.paypal.notify_url));
        form.appendChild(generateInput('image_url', "https://careerhunter.io/images/CareerHunter-Logo-190.png"));
        form.appendChild(generateInput('return', `${this.props.afterPaymentUrl}/${enrollment.encrypted_key}`));
        form.appendChild(generateInput('rm', '1'));
        document.body.appendChild(form);
        form.submit();
    };

    render() {
        let paypal = this.props.paypal;
        let hideCouponArea = this.props.hideCouponArea;
        const termsErrorMessage = 'Terms and Privacy must be accepted';
        return(
            <FadeInSection>
                <div className="tabs-panel is-active">

                    <div className="grid-x align-middle">
                        <div className={` small-12 cell ${this.props.user && !!this.props.user.id ? "flex-end" : " "}`}>
                            <div className={`license-checkout__submit ${(paypal.errors.base) && (paypal.errors.base ===termsErrorMessage) ? "termsError" : " "} `}>
                                {this.props.user && !!this.props.user.id ? null :
                                    <TermsCheckboxField name="agree" value={paypal.agree}
                                                        errorMessage={paypal.errors.base}
                                                        errorTypeMessage={true}
                                                        onChange={this.handleFieldChange}/>
                                }
                                <HoverButton onClick={this.handleSubmit} className={'button--paypal  button--paypal--light'}>
                                    <span className="small-icons payPal-button"/>
                                </HoverButton>
                            </div>
                        </div>
                        <div className="small-12 cell">
                            { !hideCouponArea &&
                                <CouponInput show_student_discount={this.props.show_student_discount}
                                             discountAmount={this.props.discountAmount}
                                             coupon={this.props.coupon}
                                             student_discount_code={this.props.user.student_discount_code}
                                             onChangeCoupon={this.props.onCouponChange}
                                             validateCouponCode={this.props.validateCouponCode}
                                /> }
                        </div>
                    </div>
                </div>
            </FadeInSection>
        )
    }
}

export default PayPalForm;
