import {actions} from 'react-fields'

class SectorsAPI {

    static getCollection() {
        let url = '/api/jobs/by_sector?all=true';

        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }


}

export default SectorsAPI;