import React, {useEffect, useState} from 'react'
import IndustriesAPI from '../../api/industryAPI';
import Label from '../roundedLabel';
import { useDispatch, useSelector } from "react-redux";
import {addFilterValue, deleteFilterValue} from '../../actions/filterActions';

export default function Industries(props) {

    let [industries, setIndustries] = useState([]);

    let filteredIndustries = useSelector(state => state.filters.selected.filter((filter) => filter.type === 'industry'));
    let initialFilters = useSelector(state => state.filters.initialFilters['industries']);

    const dispatch = useDispatch();

    useEffect(
        () => {
            IndustriesAPI.getCollection().then((collection) => {
               setIndustries(collection);
            }).catch(error => {
                  console.log(error);
             });
        }, []
    );

    useEffect(
        () => {
            if (industries.length === 0 || !initialFilters) { return; }
            initialFilters.map((filter) => {
                let item = industries.find(industry => industry.id === parseInt(filter));
                if (item) {
                    dispatch(addFilterValue(item, 'industry'))
                }
            });
        }, [industries, initialFilters]
    );


    return(
        <div className="grid-x grid-padding-x mar-top-10 mar-bot-10 filters__options">
            {industries.map((item) => {
                return <Label key={item.id} option={item}
                              hasImage={true}
                              add={() => dispatch(addFilterValue(item, 'industry'))}
                              delete={() => dispatch(deleteFilterValue(item, 'industry'))}
                              selected={!!filteredIndustries.find((ind) => ind.id === item.id)} />
            })}
        </div>
    )

}