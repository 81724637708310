import React, {Component} from "react";
import Slider from "react-slick";
import {FadeInSection} from "../fadeIn";

export default class endorsmentSlider extends Component {

    constructor(props) {
        super(props);
        const itemsCount = this.props.collection.length;
        let sliderSettings = {
            dots: false,
            arrows: false,
            speed: 600,
            cssEase:  'ease-out',
            slidesToShow: 6,
            slidesToScroll: 1,
            className: 'endorsments__slider',
            autoplay: true,
            autoplaySpeed: 2500,
            infinite: itemsCount > 6,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: itemsCount > 5
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: itemsCount > 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: itemsCount > 3
                    }
                },
                {
                    breakpoint: 639,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: itemsCount > 2,
                    }
                }
            ]
        };
        this.state = {
            settings: this.props.settings ? {...sliderSettings, ...this.props.settings} : sliderSettings
        };
    }

    render() {
        return(
            <FadeInSection>
                <Slider {...this.state.settings}>
                    {this.props.collection.map((endorsment, i) => (
                    <div className="endorsments__slide" key={`endorsment${i}`}>
                        <img src={endorsment.logo.original} className="endorsments__logo"/>
                    </div> ))}
                </Slider>
            </FadeInSection>
        );
    }
}