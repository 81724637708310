import React, {useEffect, useState} from 'react';
// import CardForm from './cardForm';
import PayPalForm from "./paypalNewForm";
import ApplePayForm from './applePayFormStripe';
import BankwireForm from "../../educators_components/payment/bankwireForm";
const merchantIdentifier = 'merchant.com.careeraddict.test';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./stripeForm";
import {actions} from "react-fields";

import StripeSettings from '../stripeSettings';

const stripePromise = loadStripe(StripeSettings.publishableKey);

export default function PaymentTabs(props) {

    const [showApplePay, setShowApplePay] = useState(false);
    const [clientSecret, setClientSecret] = useState("");


    useEffect(() => {
        console.log('useEffect initApplePay');
        initApplePay();
        // Create PaymentIntent as soon as the page loads
        let url = '/api/payments/payment_intent';
        if (props.careerTest && props.careerTest.id) {
            url = `${url}?career_test_id=${props.careerTest.id}`;
        }
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
        })
            .then((res) => res.json())
            .then((data) => onPaymentIntentInit(data));
    }, []);



    const onPaymentIntentInit = (data) => {
        console.log('onPaymentIntentInit', data);
        setClientSecret(data.clientSecret);
        let card = props.cardProps.card;
        card['payment_intent_id'] = data.id;
        props.cardProps.onChange(card);
    };

    const initApplePay = () => {
        if(window.ApplePaySession && props.applePayProps) {
            let promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
            promise.then((canMakePayments) => {
                console.log('initApplePay', canMakePayments);
                if (canMakePayments)
                    setShowApplePay(true)
            });
        }
    };

    const appearance = {
        theme: 'stripe',
        labels: 'floating'
    };

    const options = {
        clientSecret,
        appearance,
        fonts: [
            {
                cssSrc: props.fontsCss
            }
        ]
    };

    const MAX_PRICE_FOR_PAYMENTS_ONLINE = 10000;
    const MIN_PRICE_FOR_BANK_WIRE = 1000;

    return (
        <React.Fragment>
            <div className={`cell mar-bot-20 ${props.user && props.user.id ? 'mar-top-20' : '' } `}>
                {props.header && <h3 className="billing-title">{props.header}</h3>}
                <div className="grid-x">

                            <ul className="tabs flex-middle">
                                {props.totalPrice < MAX_PRICE_FOR_PAYMENTS_ONLINE &&
                                <li className={`tabs-title ${props.paymentMethod === 'card' ? 'is-active' : ''}`}
                                    onClick={(e) => props.changePaymentMethod(e,'card')}>
                                    <a href={null}><div className="small-icons credit-card"/><span
                                        className="button__hovered"/></a>
                                </li> }
                                {props.applePayProps && showApplePay && props.totalPrice < MAX_PRICE_FOR_PAYMENTS_ONLINE ?
                                    <li className={`tabs-title ${props.paymentMethod === 'applepay' ? 'is-active' : ''}`}
                                        onClick={(e) => props.changePaymentMethod(e,'applepay')}>
                                        <a href={null}><span className="small-icons applePay"/><span
                                            className="button__hovered"/></a></li>
                                    : null}
                                {props.totalPrice < MAX_PRICE_FOR_PAYMENTS_ONLINE &&
                                <li className={`tabs-title ${props.paymentMethod === 'paypal' ? 'is-active' : ''}`}
                                    onClick={(e) => props.changePaymentMethod(e,'paypal')}>
                                    <a href={null}><span className="small-icons payPal"/><span
                                        className="button__hovered"/></a></li>}
                                {props.bankWireProps && props.totalPrice >= MIN_PRICE_FOR_BANK_WIRE ?
                                    <li className={`tabs-title bankwire-title ${props.paymentMethod === 'bankwire' ? 'is-active' : ''}`}
                                        onClick={(e) => props.changePaymentMethod(e,'bankwire')}>
                                        <a href={null}>Bankwire<span
                                            className="button__hovered"/></a>
                                    </li> : null}
                                {props.paymentMethod === 'card' ?
                                <li style={{height:'22px',textAlign:'right',width:'100%'}}>
                                    <div className="small-icons visa-card"/>
                                    <div className="small-icons master-card"/>
                                    <div className="small-icons am-express-card"/>
                                    <div className="small-icons union-card"/>
                                </li> : null }
                            </ul>
                        </div>
                    </div>
                    <div className="cell">
                        <div className="tabs-content">
                            {props.paymentMethod === 'card' ?
                                clientSecret && (
                                    <Elements options={options} stripe={stripePromise}>
                                    <StripeForm {...props.cardProps} clientSecret={clientSecret} />
                                </Elements>
                                )
                                : props.paymentMethod === 'paypal' ?
                                    <PayPalForm {...props.paypalProps} />
                                    : props.paymentMethod === 'applepay' ?
                                        clientSecret && (
                                            <Elements options={options} stripe={stripePromise}>
                                            <ApplePayForm {...props.applePayProps} clientSecret={clientSecret}/>
                                        </Elements>
                                        ) :
                                        props.paymentMethod === 'bankwire' ?
                                            <BankwireForm {...props.bankWireProps} /> : null
                            }
                        </div>
                    </div>
            </React.Fragment>
        )

}

