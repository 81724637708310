import React from 'react';
import PopUp from '../base/popup';
import ConfirmAccount from "../progress/modal/confirmAccount";
import HoverButton from './HoverButton';
import {FadeInSection} from "../fadeIn";

export default class StartTestButton extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            buttonName: this.props.buttonName || "Start",
            className: this.props.className || "button--with-icon",
            user: !!this.props.user ? {...this.props.user, ...{confirmed: this.props.user.confirmed || !!this.props.confirmed}} : {}
        };
        this.confirmAccountModal = React.createRef();

    }


    componentDidMount() {
        if (this.props.confirmreq && !this.state.user.confirmed){
           this.showConfirmationModal();
        }
    }


    showConfirmationModal = () => {
        this.confirmAccountModal.current.open();
    };

    renderButton = () => {
        const ButtonClass = this.props.buttonComponent ? this.props.buttonComponent : HoverButton;
        let user = this.state.user;
        let takeTestLink = <ButtonClass buttonName={this.state.buttonName} href={`/tests/${this.props.test.path}/take`}
                              className={this.state.className }>{this.state.buttonName} <span className="small-icons next-arrow-white-s"/>
                          </ButtonClass>;

    if (user.confirmed || !user.id)
         return takeTestLink;
    else
        {
            return <React.Fragment>
                <ButtonClass buttonName={this.state.buttonName} onClick={this.showConfirmationModal}
                             className={this.state.className}>{this.state.buttonName} <span className="small-icons next-arrow-white-xs"/>
                </ButtonClass>
                <PopUp ref={this.confirmAccountModal}>
                    <ConfirmAccount user={this.props.user}/>
                </PopUp>
            </React.Fragment>;

        }
    };

    render() {
        return  (
            <React.Fragment>
                <FadeInSection>
                {this.renderButton()}

                </FadeInSection>
            </React.Fragment>
        )
    }




}
