import React, {useEffect} from 'react';
import ReactSlider from 'react-slider';
import {useSelector, useDispatch } from 'react-redux';
import {addFilterValue, deleteFilterValue} from "../../actions/filterActions";

export default function DurationWeeksSlider(){

    const durationMin = 1, durationMax = 25;

    let value = useSelector(state => state.filters.selected.find(filter => filter.type === 'duration'));


    const dispatch = useDispatch();


    const onChange = (value) => {
        if(value[0] > durationMin || value[1] < durationMax) {
            dispatch(addFilterValue({id: 'duration', value: value,
                name: `${value[0]} weeks - ${value[1]} weeks duration`},
                'duration'));
        } else {
            dispatch(deleteFilterValue({id: 'duration'}, 'duration'));
        }
    };


    const renderHandle = (props, state) => {
        return (
            <div {...props}>
                <div className={`input-range__label--container`}>
                    <div className="input-range__label--top">
                        <div className="input-range__label--value">{state.valueNow}</div>
                    </div>
                </div>
            </div>
        )
    };

    const onSlide = () => {
    };

    const onClick = (newValue) => {
        let val = value ?  value.value : [durationMin,durationMax];
        if(Math.abs(val[0] - newValue) < Math.abs(val[1] - newValue)) {
            onChange([newValue, val[1]])
        } else {
            onChange([val[0],newValue])
        }

    };

    return (
        <React.Fragment>
            <h4 className="white text-center">Course duration weeks</h4>
            <div className={'range-slider__container'}>
                <span className={'input-range__slider--limit-label--min'}>{durationMin} week</span>
                <span className={'input-range__slider--limit-label--max for-duration'}>{durationMax} weeks</span>
                <ReactSlider
                    className="input-range"
                    thumbClassName="input-range__slider"
                    trackClassName="input-range__track"
                    defaultValue={[durationMin, durationMax]}
                    ariaLabel={['from', 'to']}
                    onChange={onSlide}
                    onAfterChange={onChange}
                    onSliderClick={onClick}
                    ariaValuetext={state => `${state.valueNow}`}
                    renderThumb={(props, state) => renderHandle(props, state)}
                    pearling
                    step={1}
                    value={value ? value.value : [durationMin,durationMax]}
                    min={durationMin}
                    max={durationMax}
                    minDistance={1} />

            </div>
        </React.Fragment>
    );
}