import React from 'react';
import { CSSTransition } from "react-transition-group";
import Career from '../career';

export default class Block extends React.Component {

    constructor(props) {
        super(props);
        this.state = {career: this.props.career, mounted: true}
    }

    static getDerivedStateFromProps(props, state) {
        if (state.career.id !== props.career.id && state.mounted) {
            //return {career: {...state.career, showScore: true, score: generateRandomScore()}, nextBlock: props.career};
            return {nextBlock: props.career, mounted: false};
        } else if (state.career.id === props.career.id && state.career.score !== props.career.score && state.mounted) {
            return {career: {...state.career, score: props.career.score, showScore: props.career.showScore}};
        }
        return null;
    };

    onFadeOutFinished = () => {
        if (!!this.state.nextBlock)
            this.setState({mounted: true, career: {...this.state.nextBlock, showScore: true}, nextBlock: null});
        this.props.onAnimationEnd();
    };

    render() {
        const block = this.state.career;

        return (
            <CSSTransition
                in={this.state.mounted}
                classNames="fading-block"
                timeout={2000}
                onEntering={this.props.onFadeIn}
                onExited={this.onFadeOutFinished}
            >
                <Career item={block} className={'cell auto'} hideScore={!block.showScore} featured={true} />
            </CSSTransition>
        );
    }

}