import React from "react";
import Slider from "react-slick";
import Test from './test';
import SliderArrow from "../base/sliderArrow";

class SliderTests extends React.Component {

    onSwipe = () => {
        if (this.slider) {
            this.slider.innerSlider.clickable = true;
        }
    };

    render() {
        const sliderRef = slider => {this.slider = slider;};

        let settings =  {
            dots: false,
            infinite: false,
            variableWidth: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 6,
            onSwipe: this.onSwipe,
            swipeToSlide: true,
            nextArrow: <SliderArrow props={this.props} direction="slider-arrow slider-arrow--next"/>,
            prevArrow: <SliderArrow props={this.props} direction="slider-arrow slider-arrow--prev"/>,
            className: 'test-tiles-slider with-navigation',
            responsive: [

                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 1281,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        variableWidth: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        variableWidth: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        variableWidth: false,
                        arrows: false
                    }
                }
            ]
        };

        let _this = this;
        return (
            <Slider {...settings} ref={sliderRef}>
                {this.props.options.map((opt, i) => (
                    <Test test={opt} key={opt.id}
                          user={_this.props.user}
                          confirmationRequired={_this.props.confirmationRequired}
                          status={_this.props.user[`${opt.code}_status`]}
                          progress={_this.props.progress[opt.code] || 0}
                    />
                ))}
            </Slider>
        );
    }
}
export default SliderTests;