import React from 'react';
import {disableCaptchaForTest} from "../settings";
import {TextField, TextareaField, InputTypeField, actions, SelectField, Loader} from 'react-fields';
import InvisibleRecaptcha from './invisibleRecaptcha';
import TermsCheckboxField from "../base/termsCheckbox";
import contactApi from '../api/contactAPI';
import Popup from '../base/popup';
import HoverButton from "../buttons/HoverButton";

const DEFAULT_FORM = {name: '', email: '', subject: '', phone: '', message: '', agree_terms: false, errors: {}};
const SUBJECTS = [{value: 'Advertising/Marketing'}, {value: 'Business/Partnerships'},
    {value: 'Customer Service'}, {value: 'Personal Data'},
    {value: 'Press'}, {value: 'Technical Support'}, {value: 'Other'}];

const PAR_SUBJECTS = [{value: 'Group Licensing'}, {value: 'Affiliates & Referrers'},
    {value: 'Courses'}, {value: 'Jobs'}, {value: 'Other'}];

export default class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {form: {...DEFAULT_FORM}, isLoading: false};

        this.sentPopup = React.createRef();
        this.invisibleCaptcha = React.createRef();
    }

    onChangeField = (e) => {
        let form = this.state.form;
        let name = e.target.name;
        form[name] = name === 'agree_terms' ? e.target.checked : e.target.value;
        form.errors[actions.camelize(name)] = "";
        this.setState({form: form});
    };

    cleanupForm = () => {
        this.setState({form: {...DEFAULT_FORM}, isLoading: false});
    };

    onSuccess = () => {
        this.sentPopup.current.open();
    };

    onError = (errors) => {
        let form = this.state.form;
        if (errors) { form.errors = errors}
        this.setState({form: form, isLoading: false});
    };

    onSubmit = (e) => {
        e.preventDefault();
        try {
            if (disableCaptchaForTest()) {
                console.log('captcha disabled');
                this.sendForm('valid');
            } else if (window.grecaptcha) {
                this.invisibleCaptcha.current.execute();
            }
        } catch (error) {
            console.warn('something is wrong with invisible captcha....');
            console.log(error);
        }
    };

    sendForm = (token) => {
        this.setState({isLoading: true}, () => {
            contactApi.send(this.state.form, token, this.props.subject, this.onSuccess, this.onError);
        });
        if (!disableCaptchaForTest()) { this.invisibleCaptcha.current.reset(); }
    };

    render() {
        let form = this.state.form;
        return(
            <form onSubmit={this.onSubmit}>
                {this.state.isLoading ? <Loader /> : null}
                <TextField name='name' value={form.name} placeholder='Name*'
                           onChange={this.onChangeField} errorMessage={form.errors.name} />
                <InputTypeField type='email' name='email' value={form.email} placeholder='Email*'
                                onChange={this.onChangeField} errorMessage={form.errors.email} />
                <InputTypeField type='tel' name='phone' value={form.phone} placeholder='Phone'
                                onChange={this.onChangeField} errorMessage={form.errors.phone} />

                <SelectField name='subject' selected={form.subject}
                             options={this.props.type ==='partners' ? PAR_SUBJECTS : SUBJECTS} optionsValue='value' optionsLabel='value'
                             onChange={this.onChangeField} errorMessage={form.errors.subject}
                             className='label-icon-right select-label' placeholder="Subject*" />

                <TextareaField name='message' value={form.message} placeholder="Your message*" rows={4}
                               onChange={this.onChangeField} errorMessage={form.errors.message} />
                <div className="grid-x align-middle align-center agree-text">
                    <div className="medium-8 small-text-center cell">
                        <TermsCheckboxField name='agree_terms' value={form.agree_terms} errorTypeMessage={this.props.errorType !== 'tooltip'}
                                            onChange={this.onChangeField} errorMessage={form.errors.agreeTerms} />
                    </div>
                    <div className="medium-4 medium-flex-end">
                        <HoverButton onClick={this.onSubmit}>
                            Send
                        </HoverButton>
                    </div>
                </div>

                <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                    sitekey={this.props.recaptchaKey}
                                    verifyCallback={this.sendForm} />

                <Popup ref={this.sentPopup} onClose={this.cleanupForm}>
                    <div className="grid-x align-center">
                        <div className="small-12 cell text-center">
                            <div className="popup__title popup__title--centered">
                                <h3>
                                    <span className="small-icon green-tick-big" style={{marginTop: '17px'}}/>
                                    Message sent
                                </h3>
                            </div>
                            <p className="popup__text"> {this.props.type ==='partners' ? 'Thank you for your interest in partnering with us. Please allow 2 business days for a member of our team to respond.' : 'Your message has been sent. A member of our support team wil be in touch with you shortly.'}</p>
                        </div>
                    </div>
                </Popup>
            </form>
        )
    }

}