import React, {useEffect, useRef, useState} from 'react';
import StartTestButton from '../buttons/startTestButton';
import HoverButton from "../buttons/HoverButton";
import TooltipPopover from "../tooltipPopover/TooltipPopover";
import Portal from "../tooltipPopover/Portal";
import TestsApi from "../api/careerTestAPI";
import {useSelector, useDispatch } from 'react-redux';
import {refreshUserDashboard} from "../actions/userStateActions";
import {FadeInSection} from "../fadeIn";
import PopUp from "../base/popup";
import ConfirmAccount from "../progress/modal/confirmAccount";

function Test (props) {

    const [testStatus, setStatus] = useState(props.status);
    const [timerId, setTimerId] = useState(null);
    const elmRef = React.createRef();
    const [coords, setCoords] = useState({});
    const [isOn, setOn] = useState(false);
    const [unLock, setLock] = useState(false);
    const getTooltip = document.querySelectorAll('[data-tooltip]');
    const updateTooltipCoords = getTooltip => {
        const rect = getTooltip.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY
        });
    };

    const progressStyle = {};
    const testPaid = props.user.full_access || props.user[`${props.test.code}_access`];

    const dispatch = useDispatch();

    const confirmAccountModal = useRef(null);

    function RetakeButton(props) {
            return <a href={props.href}
                      title={"Retake Test"}
                      onClick={props.onClick}
                      className={'progress__link float-right'}
                      onMouseEnter={handleButtonOnHover}>
                {props.buttonName}
                <span className="button__hovered"/>
            </a>
    }

    let status = testStatus || props.status;


    const infoTooltip =  <Portal>
        <TooltipPopover coords={coords} updateTooltipCoords={() => updateTooltipCoords(elmRef.current)} className={'tooltip--popover'}>
            <span>Learn more about this test</span>
        </TooltipPopover>
    </Portal>;

    const unlockTooltip =  <Portal>
        <TooltipPopover coords={coords} updateTooltipCoords={() => updateTooltipCoords(elmRef.current)} className={'tooltip__red'}>
            <span>{!!props.user.educator_id ? 'Access to this test is not currently permitted on your account. Contact your account\'s administrator to request additional access.' : 'Paid access required for this test'}.</span>
        </TooltipPopover>
    </Portal>;

    const lockIcon = <div className="tooltip-trigger small-icon lock-red float-right" data-tooltip ref={elmRef}
                          onMouseOver={e => {
                              updateTooltipCoords(e.target);
                              setLock(!unLock)
                          }} onMouseOut={e => { setLock(!unLock) }}/>;

    function button() {
         if (props.user && (props.user.full_access || props.user[`${props.test.code}_access`])) {
             switch (status) {
                 case 'started':
                     return <HoverButton href={`/tests/${props.test.path}/take`} color={'white'}>Continue</HoverButton>;
                 case 'finished':
                     return <HoverButton href={`/tests/${props.test.path}/result`} color={'white'}>Results</HoverButton>;
                 default:
                     return <StartTestButton user={props.user}
                                             confirmed={props.confirmed}
                                             test={{code: props.test.code, path: props.test.path}}
                                             buttonName={"Start"}
                                             className={"button button--green"}/>
             }
         } else {
             return  <HoverButton href={`/checkout/${props.test.code}`} color={'white-off'}>Get Access</HoverButton>;
         }
    }

    function smallTopButton() {
        if (!!props.user.educator_id) {
            return testPaid ? null : lockIcon;
        }
        let retakes = parseInt(props.user[`${props.test.code}_retakes`]);
        if (status !== 'finished' && !props.test.timed && !testPaid) {
            if(props.user.id && !props.user.confirmed && props.confirmationRequired){
               return <a href={`/tests/${props.test.path}/take`}
                   onClick={(e) => {e.preventDefault(); confirmAccountModal.current.open();}}
                   className={'progress__link float-right'}>Try for free</a>
            }
            return <a href={`/tests/${props.test.path}/take`} className={'progress__link float-right'}>Try for free</a>
        }

        if (status === 'finished' && !testPaid) {
            return <a href={`/tests/${props.test.path}/result`} className={'progress__link float-right'}>Results</a>
        }

        if (status === 'finished' && testPaid && retakes === 0) {
            return <a href={`/checkout/retakes?test=${props.test.code}`} className={'progress__link float-right'}>0 takes left</a>
        }

        if(testPaid && retakes > 0){
            if(status !== 'finished'){
                retakes += 1;
            }
            return <StartTestButton user={props.user}
                                    test={{code: props.test.code, path: props.test.path}}
                                    buttonName={`${retakes} ${retakes === 1 ? 'take' : 'takes'} left`}
                                    buttonComponent={RetakeButton}
                                    className={"button button--green--medium"}/>;
        }
        else if (props.test.timed && !testPaid) {
            return lockIcon;
        }

    }



    const resetStatus = () => {
        let startedAt = localStorage.getItem(`${props.test.code}StartedAt`);
        let timeLeft = startedAt ?  (parseInt(startedAt) + props.test.time * 60000) - Date.now() : 0;
        if (timeLeft <= 0){
             console.log('Reset Test');
            TestsApi.resetTest(props.test.id).then((data) => {
                localStorage.removeItem(`${props.test.code}StartedAt`);
                localStorage.removeItem(props.test.code);
                setStatus(data.status);
                let attr = {};
                attr[`${props.test.code}_status`] = data.status;
                dispatch(refreshUserDashboard(attr))
            });
        } else {
           let timer = setTimeout(() => {resetStatus()}, timeLeft+100);
            setTimerId(timer);
        }
    };

    useEffect(
        () => {
            if(props.status === 'started' && props.test.timed && !localStorage.getItem(props.test.code)){
                resetStatus();
            }
            return () => {
                if (timerId) {
                    clearTimeout(timerId);
                }
            };
        }, []
    );

    if (status === 'started') {
        progressStyle['height'] = `${props.progress}%`;
    }

    return (
        <React.Fragment>
        <FadeInSection>
        <div className={"progress__card " + (status)}>
            <div className="circle half-top"></div>
            <div className="circle right-small"></div>
            <div className={"progress__layer " + (status)} style={progressStyle}/>
            <div className="grid-x">
                <div className="small-6 cell">
                        <a href={`/tests/` + props.test.path}>
                            <div className="tooltip-trigger small-icons big-white-tooltip" data-tooltip ref={elmRef}
                                 onMouseOver={e => { updateTooltipCoords(e.target); setOn(!isOn); }} onMouseOut={e => {setOn(!isOn)}}>
                            </div>
                        </a>
                    {isOn && infoTooltip}
                </div>
                <div className="small-6 cell">
                    {smallTopButton()}
                    {unLock && unlockTooltip}
                </div>
            </div>
            <div className="card__section">
                <div className={"large-icon float-center " + props.test.code + "-small-white"}/>
            </div>
            <div className="">
                <p className="progress__test-title text-center">
                    {props.test.name}
                </p>
            </div>
            <div className="text-center">
                {button()}
            </div>
            <div className="grid-x">
                <div className="small-8 cell">
                    {props.test.timed ?
                        <div className="float-left flex-center alarm">
                            <div className="small-icons clock-red"/>
                            <p className="progress__details progress__details--timed">{props.test.time}m</p>
                        </div> :
                        <div className="float-left flex-center">
                            <div className="small-icons white-stopwatch"/>
                            <p className="progress__details progress__details--time">Not timed</p>
                        </div>
                    }
                </div>
                <div className="small-4 cell">
                    <div className="float-right flex-center">
                        <p className="progress__details progress__details--comments">{props.test.number_questions}</p>
                        <div className="small-icons white-chat-icon"/>
                    </div>
                </div>
            </div>
        </div>
        </FadeInSection>
            <PopUp ref={confirmAccountModal}>
                <ConfirmAccount user={props.user}/>
            </PopUp>
        </React.Fragment>
    );
}
export default Test;