import React from 'react'
import careersAPI from '../api/careersAPI';
import AbstractCareers from './abstract';
import Pagination from '../base/pagination';
import {actions, Loader} from 'react-fields';
import Filters from '../filtering/careers/index';
import NotFound from './notFound';
import {connect} from "react-redux";
import Collection from '../base/collection';
import Career from "./career";
import {setNeedRefreshFiltersSearch} from "../actions/filterActions";
import ScrollToTop from '../ScrollToTop/index';
import PaginationMeta from '../paginationMeta';

import store from '../store/store';

class Index extends AbstractCareers {

    constructor(props){
        super(props);
        this.state = {
            careers: this.props.initialCollection ? this.setRandomScores(this.props.initialCollection.career_scores) : [],
            page: this.props.initialOptions && this.props.initialOptions.page ? parseInt(this.props.initialOptions.page) : 1,
            totalPages: this.props.initialCollection ? this.props.initialCollection.total_pages : 1,
            isLoading: false,
            rendered: false
        };
        this.contentRef = React.createRef();
    }

    componentDidMount() {
        this.setState({rendered: true}, () => {
            if (!this.props.profileCompleted) {
                let careers = this.setRandomScores(this.state.careers);
                this.setState({careers: careers});
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.needRefreshSearch){
            store.dispatch(setNeedRefreshFiltersSearch(false));
            this.runSearch();
        }

        return null;
    }

    loadCollection = async (options = {}) => {
        let careers = [];
        let totalPages = 0;
        let total = 0;
        await careersAPI.searchCareers(options)
            .then( (result) => {
                careers = result.career_scores;
                totalPages = result.total_pages;
                total = result.total_count;
            }).catch((error) => {
                console.log(error);

            });

        return [careers, totalPages, total];
    };

    setRandomScores = (careers) => {
        return careers.map((career) => {
            let score = career.score > 0 ? career.score : Math.floor(Math.random() * 100);
            return {...career,...{score: score}}
        });
    };

    goToPage = (e, page) => {
        e.preventDefault();
        let options = {...this.prepareOptionsSearch(), page: page};
        this.runSearch(options, page);
        window.scrollTo({top: this.contentRef.current.offsetTop - 180, behavior: 'smooth'});
    };

    runSearch = (options = this.prepareOptionsSearch(), page = 1) => {
        let _this = this;
        this.setState({page: page, options: options, isLoading: true}, () => {
            _this.loadCollection(options).then(([careers, totalPages, total]) => {
                if (!_this.props.profileCompleted) {
                    careers = _this.setRandomScores(careers);
                    this.setState({careers: careers});
                }

                _this.setState({careers: careers, isLoading: false, totalPages: totalPages});

                PaginationMeta.update({...options, page: page}, total, totalPages, 32, true);
            })
        })
    };

    prepareOptionsSearch = () => {
        let options = {page: 1};
        if(this.props.selectedIndustries){options.industries = this.props.selectedIndustries.map((ind) => ind.id)}
        if(this.props.selectedPersonalities){options.personalities = this.props.selectedPersonalities.map((per) => per.id)}
        if(this.props.selectedMotivators){options.motivators = this.props.selectedMotivators.map((per) => per.id)}

        if (!!this.props.matches) {
            options.score = {from: this.props.matches.value[0], to: this.props.matches.value[1]}
        }
        if(this.props.selectedEducationLevels){options.educations = this.props.selectedEducationLevels.map((level) => level.id)}
        if (!!this.props.salary) {
            options.salary = {from: this.props.salary.value[0], to: this.props.salary.value[1]}
        }
        if (!!this.props.growth) {
            options.growth = {from: this.props.growth.value[0], to: this.props.growth.value[1]}
        }
        if(!!this.props.q){options.terms = this.props.q.map(qTerm => qTerm.name)}
        return options;
    };



    render(){
        return (
            <React.Fragment>
                {this.state.rendered ? <Filters search={this.runSearch} hasSearch={true}/> :
                    <div className="filter-area">
                        <div className="filter-area__container">
                            <div className="grid-container no-padding">
                                <div className="grid-x">
                                    <div className="small-12 medium-6 large-12 cell">
                                        <h1>Careers</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className="grid-container results-area aligned-container" ref={this.contentRef}>
                    {this.state.isLoading ? <Loader size={'small'}/> : null}
                    {!this.state.isLoading && this.state.careers.length === 0 ? <NotFound/> :
                        this.state.careers && this.state.careers.length > 0 ?
                            <React.Fragment>
                                <Collection collection={this.state.careers}>
                                    <Career hideScore={this.state.rendered ? !this.props.profileCompleted : !this.props.initialProfileCompleted} careersPage={true}/>
                                </Collection>
                                <div className="hide-for-large">
                                    <ScrollToTop/>
                                </div>
                                <Pagination page={this.state.page} options={this.prepareOptionsSearch()} path={this.props.path}
                                            totalPages={this.state.totalPages}
                                            goToPage={this.goToPage}/>
                            </React.Fragment> : null
                    }
                </div>


            </React.Fragment>

        )
    }
}


function mapStateToProps(state) {

    return {
        profileCompleted: state.filters.profileCompleted,
        selectedIndustries: state.filters.selected.filter((filter) => filter.type === 'industry'),
        selectedPersonalities: state.filters.selected.filter((filter) => filter.type === 'personality'),
        selectedMotivators: state.filters.selected.filter((filter) => filter.type === 'motivator'),
        selectedEducationLevels:  state.filters.selected.filter((filter) => filter.type === 'education'),
        matches: state.filters.selected.find((filter) => filter.type === 'match'),
        salary: state.filters.selected.find((filter) => filter.type === 'salary'),
        growth: state.filters.selected.find((filter) => filter.type === 'growth'),
        q: state.filters.selected.filter((filter) => filter.type === 'qsearch'),
        needRefreshSearch: state.filters.needRefreshSearch

    };
}

export default connect(mapStateToProps)(Index);