import * as types from "./actionTypes";

export function initialStateFilter(user, page, initialFilters={}) {
    return {
        type: types.SETUP_INITIAL_FILTERS_STATE,
        user: user,
        page: page,
        initialFilters: initialFilters
    };
}

export function addFilterValue(value, filterType) {
    return {
        type: types.ADD_FILTER_VALUE,
        value: {...value, type: filterType}
    };
}

export function deleteFilterValue(value, filterType) {
    return {
        type: types.DELETE_FILTER_VALUE,
        value: {...value, type: filterType}
    };
}

export function setNeedRefreshFiltersSearch(value) {
    return {
        type: types.SET_NEED_REFRESH_SEARCH,
        value: value
    };
}

export function setQSearchFilter(value) {
    return {
        type: types.SET_Q_SEARCH_FILTER,
        value: value
    };
}

export function setLocationFilter(value) {
    return {
        type: types.SET_LOCATION_FILTER,
        value: value
    };
}

export function deleteAllFilters() {
    return {
        type: types.DELETE_ALL_FILTERS
    }

}