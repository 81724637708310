import React, {useState, Fragment, Component} from "react";
import {TextareaField, ErrorMessage, Loader} from 'react-fields';
import feedbackAPI from '../../api/feedbackAPI';

import InvisibleRecaptcha from '../../contact/invisibleRecaptcha';
import HoverButton from "../../buttons/HoverButton";
import chLogo from "../../../../assets/images/CareerHunterBlue.png";
import RatePoor from "../../../../assets/images/rate-poor.png";
import RateAverage from "../../../../assets/images/rate-average.png";
import RateGreat from "../../../../assets/images/rate-great.png";
import {disableCaptchaForTest} from "../../settings";

export default class FeedbackRate extends Component {

    state = {
        isLoading: false,
        feedback: {
            comment: '',
            rate: this.props.rate,
            skip_coupon_code: this.props.hideCoupon,
            errors: {}
        }
    };

    invisibleCaptcha = React.createRef();

    submit = () => {
        this.setState({isLoading: true});
        let errors = {};
        let feedback = this.state.feedback;
        if (!feedback.comment) {
            errors['comment'] = "Comment can't be blank";
        }
        if (!feedback.rate) {
            errors['rate'] = "Select the rate to proceed";
        }
        if (Object.keys(errors).length) {
            feedback.errors = errors;
            this.setState({feedback: feedback, isLoading: false});
            return;
        }

        try {
            if (disableCaptchaForTest()) {
                console.log('captcha disabled');
                this.send('valid');
            } else if (window.grecaptcha) {
                this.invisibleCaptcha.current.execute();
            }
        } catch (error) {
            console.warn('something is wrong with invisible captcha....');
            console.log(error);
            this.setState({isLoading: false});
        }
    };

    send = (token) => {
        this.invisibleCaptcha.current.reset();
        feedbackAPI.send(this.state.feedback, token, this.props.onSuccess, () => this.setState({isLoading: false}));
    };

    onChange = (value, attribute) => {
        let feedback = this.state.feedback;
        feedback[attribute] = value;
        feedback.errors[attribute] = '';
        if (attribute === 'rate') {this.props.onRateChange(value);}
        this.setState({feedback: feedback})
    };

    rateSelector = (rateToSet, rateLabel, rateImg) => {
        return <div className={`feedback__rate${rateToSet === this.state.feedback.rate ? ' selected' : ''}`} onClick={() => this.onChange(rateToSet, 'rate')}>
            <div className={'feedback__rate-circle'}>
                <img src={rateImg} alt={rateLabel} width="65" height="65"/>
            </div>
            <p className={'feedback__rate-txt'}>{rateLabel}</p>
        </div>;
    };

    render () {
        console.log('renderRateScreen', this.props.rate);
        const feedback = this.state.feedback;
        return (
            <Fragment>
                {this.state.isLoading ? <Loader /> : null }
                <img src={chLogo} alt={'Career Hunter'} width="170" height="19"/>
                <h3 className="feedback__msg">Leave your honest feedback</h3>
                {this.rateSelector('poor_rate', 'Poor', RatePoor)}
                {this.rateSelector('average_rate', 'Average', RateAverage)}
                {this.rateSelector('great_rate', 'Great', RateGreat)}
                <div><ErrorMessage message={feedback.errors.rate} /></div>
                <div className={'feedback__textarea'}>
                    <TextareaField name='feedback' className="vertical-resize" placeholder="Let us know what you think about your overall experience, including your test results and career matches." rows={5}
                                   onChange={(e) => this.onChange(e.target.value, 'comment')} value={feedback.comment} errorMessage={feedback.errors.comment}  />
                </div>
                <HoverButton className={'button--green--large'} onClick={this.submit}>Submit Feedback <span className="small-icons next-arrow-white-l"></span></HoverButton>
                {this.props.hideCoupon ? <p className="feedback__coupon" /> : <p className="feedback__coupon">Claim your thank you coupon for <b>30% OFF</b> your next test or access purchase, which can even be shared to your friends or family.</p>}
                <p className="feedback__tip"><span className="small-icons blue-off-tooltip"></span> <span className="feedback__tip-txt"> Your feedback will help us improve our services and will be kept in the strictest confidence.</span></p>
                <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                    sitekey={this.props.recaptchaKey}
                                    verifyCallback={this.send} />
            </Fragment>
        )
    }
}